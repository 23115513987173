import { FormControlLabel, FormControlLabelProps, Radio } from "@mui/material";

type Props = {
  text: string;
  value: string | number;
  onClick?: () => void;
  selected?: boolean;
  control?: FormControlLabelProps["control"];
  disabled?: boolean;
};

export const RadioOption = ({
  value,
  text,
  onClick,
  selected,
  control,
  disabled = false,
}: Props) => {
  return (
    <FormControlLabel
      value={value}
      control={control || <Radio checked={selected} />}
      label={text}
      onClick={onClick}
      disabled={disabled}
    />
  );
};
