import { gql } from "@apollo/client";

export const CREATE_GIFT_CARD = gql`
  mutation createGiftcard($input: CreateGiftPromoInput!) {
    createGiftPromo(input: $input) {
      active
      code
      discountPercent
      discountPrice
      expiration
      gift
      giftPromoId
      senderEmail
      senderPhone
      reciverEmail
      name
      paid
      purchaseDate
      purchaseEmail
      reciverName
      senderName
      note
    }
  }
`;

export const UPDATE_GIFT_CARD = gql`
  mutation updateGiftcard($input: UpdateGiftPromoInput!) {
    updateGiftPromo(input: $input) {
      active
      code
      discountPercent
      discountPrice
      expiration
      gift
      giftPromoId
      senderEmail
      senderPhone
      reciverEmail
      name
      paid
      purchaseDate
      purchaseEmail
      reciverName
      senderName
      note
    }
  }
`;
