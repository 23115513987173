import { gql } from "@apollo/client";

export const FETCH_CHECKLIST_ITEMS = gql`
  query Items($filter: TablePartyChecklistItemFilterInput) {
    listPartyChecklistItems(filter: $filter) {
      items {
        checked
        checklistItemId
        description
        title
        partyUid
        editUser
      }
    }
  }
`;
