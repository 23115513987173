import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, CircularProgress, Typography } from "@mui/material";

import { sessionStorage } from "src/services";
import { DEFAULT_PARTY_VIBE_VALUE, RouteName } from "src/types";
import { toDayjs } from "src/utils";
import {
  fetchAllParties,
  updateParty,
  useAppDispatch,
  useAppSelector,
} from "src/store";

import { PartyInfoForm } from "./PartyInfoForm";
import { PartyBrideVibeForm } from "./PartyBrideVibeForm";
import { PartyBrideVibe, PartyInfo } from "./types";

enum Step {
  INFO,
  VIBE,
  SUBMIT,
}

export const EditParty = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { partyId } = useParams();
  const { partyList, isLoading } = useAppSelector(({ party }) => party);
  const editedParty = partyList.find(({ partyUid }) => partyUid === partyId);
  const [partyInfo, setPartyInfo] = useState<PartyInfo>({
    partyName: editedParty?.name || "",
    brideName: editedParty?.brideName || "",
    startDate: toDayjs(editedParty?.startDate || ""),
    endDate: toDayjs(editedParty?.endDate || ""),
    isNoDates: editedParty?.unknownDate || false,
    location: editedParty?.location || "",
    numberOfGuests: editedParty?.guests || 0,
  });
  const [partyBrideVibe, setPartyBrideVibe] = useState<PartyBrideVibe>({
    adventure: editedParty?.bVibeAdv || DEFAULT_PARTY_VIBE_VALUE,
    chill: editedParty?.bVibeChill || DEFAULT_PARTY_VIBE_VALUE,
    party: editedParty?.bVibeParty || DEFAULT_PARTY_VIBE_VALUE,
    wellness: editedParty?.bVibeWellness || DEFAULT_PARTY_VIBE_VALUE,
  });
  const [currentStep, setCurrentStep] = useState(Step.INFO);

  const goToNextStep = () => {
    const nextStep = currentStep === Step.INFO ? Step.VIBE : Step.SUBMIT;
    setCurrentStep(nextStep);
  };
  const userName = sessionStorage.getUsername() || "";

  useEffect(() => {
    if (!partyList.length) {
      dispatch(fetchAllParties(userName));
    }
  }, [partyList.length, userName, dispatch]);

  useEffect(() => {
    setPartyInfo({
      partyName: editedParty?.name || "",
      brideName: editedParty?.brideName || "",
      startDate: toDayjs(editedParty?.startDate || ""),
      endDate: toDayjs(editedParty?.endDate || ""),
      isNoDates: editedParty?.unknownDate || false,
      location: editedParty?.location || "",
      numberOfGuests: editedParty?.guests || 0,
    });
    setPartyBrideVibe({
      adventure: editedParty?.bVibeAdv || DEFAULT_PARTY_VIBE_VALUE,
      chill: editedParty?.bVibeChill || DEFAULT_PARTY_VIBE_VALUE,
      party: editedParty?.bVibeParty || DEFAULT_PARTY_VIBE_VALUE,
      wellness: editedParty?.bVibeWellness || DEFAULT_PARTY_VIBE_VALUE,
    });
  }, [editedParty]);

  useEffect(() => {
    if (currentStep === Step.SUBMIT) {
      dispatch(
        updateParty({
          partyUid: partyId || "",
          brideName: partyInfo?.brideName || "",
          name: partyInfo?.partyName || "",
          endDate: partyInfo?.endDate?.toISOString() || "",
          startDate: partyInfo?.startDate?.toISOString() || "",
          unknownDate: partyInfo?.isNoDates || false,
          location: partyInfo?.location || "",
          bVibeAdv: +(partyBrideVibe?.adventure || 0),
          bVibeChill: +(partyBrideVibe?.chill || 0),
          bVibeParty: +(partyBrideVibe?.party || 0),
          bVibeWellness: +(partyBrideVibe?.wellness || 0),
          editUsers: [userName],
          guests: partyInfo?.numberOfGuests || 0,
          viewUsers: editedParty?.viewUsers,
        })
      )
        .unwrap()
        .then(({ partyUid }) => {
          navigate(`${RouteName.PARTY}/${partyUid}${RouteName.VENDOR}`);
        });
    }
  }, [
    currentStep,
    userName,
    dispatch,
    navigate,
    partyBrideVibe,
    editedParty,
    partyId,
    partyInfo,
  ]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" py={10}>
        <CircularProgress size={60} />
      </Box>
    );
  }

  if (!editedParty) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" py={10}>
        <Typography
          variant="body1"
          fontSize={24}
          color="red"
          textAlign="center"
        >
          Party not found!
          <br />
          Please, go back and try again or select another party
        </Typography>
      </Box>
    );
  }

  return currentStep === Step.INFO ? (
    <PartyInfoForm
      variant="edit"
      partyInfo={partyInfo}
      setPartyInfo={setPartyInfo}
      onSubmit={goToNextStep}
    />
  ) : (
    <PartyBrideVibeForm
      variant="edit"
      vibe={partyBrideVibe}
      setVibe={setPartyBrideVibe}
      onSubmit={goToNextStep}
    />
  );
};
