import { Fragment, useCallback } from "react";
import { FormGroup } from "@mui/material";

import { addOrRemove } from "src/utils";
import { PollOptions } from "src/graphql";

import { CheckboxOption, TextOption } from "../options";

interface Props {
  options: PollOptions[];
  selectedOptions: string[];
  setSelectedOptions: CallableFunction;
  customAnswer: string;
  setCustomAnswer: CallableFunction;
}

export const CheckboxPoll = ({
  options,
  setSelectedOptions,
  customAnswer,
  selectedOptions,
  setCustomAnswer,
}: Props) => {
  const handleClick = useCallback(
    (value: string) => () => {
      setSelectedOptions((arr: string[]) => addOrRemove(arr, value));
    },
    [setSelectedOptions]
  );

  return (
    <FormGroup>
      {options.map(({ value, text, type }) => (
        <Fragment key={value}>
          <CheckboxOption
            text={text || ''}
            value={value || ''}
            key={value}
            onClick={handleClick(value || '') as any}
            selected={selectedOptions.includes(value!)}
          />
          {type === "text" && (
            <TextOption
              text={text || ''}
              value={customAnswer}
              setValue={setCustomAnswer}
              visible={selectedOptions.includes(value!)}
            />
          )}
        </Fragment>
      ))}
    </FormGroup>
  );
};
