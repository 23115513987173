import { Box, Switch, Typography } from "@mui/material";

interface Props {
  anonymous: boolean;
  handleChange: () => void;
}

export function AnonymousSwitch({ anonymous, handleChange }: Props) {
  return (
    <Box display="flex" alignItems="center">
      <Typography variant="body1" style={{ margin: "0 8px" }}>
        Public
      </Typography>
      <Switch
        checked={anonymous}
        onChange={handleChange}
        inputProps={{ "aria-label": "controlled" }}
        size="medium"
      />
      <Typography variant="body1" style={{ margin: "0 8px" }}>
        Anonymous
      </Typography>
    </Box>
  );
}
