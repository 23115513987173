import dayjs from "dayjs";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import {
  startCreatingEvent,
  useAppDispatch,
  useAppSelector,
  createNewCustomCalendarEvent,
} from "src/store";
import { Calendar } from "src/graphql";
import { TimePicker } from "src/components";

interface Props {
  open: boolean;
  handleClose: () => void;
  onCreate?: () => void;
}

export const CalendarEventDetailsModal = ({
  open,
  handleClose,
  onCreate,
}: Props) => {
  const dispatch = useAppDispatch();
  const { selectedEvent, createdEvent, isLoading } = useAppSelector(
    ({ calendar }) => calendar
  );
  const isCreatingEvent = !!createdEvent;
  const { startTime, name, description, allDay } = (
    isCreatingEvent ? createdEvent : selectedEvent
  ) as Calendar;

  const isDisabled = !isCreatingEvent || isLoading;

  const handleChange = (
    key: keyof Calendar,
    value: Calendar[keyof Calendar]
  ) => {
    if (isCreatingEvent) {
      dispatch(startCreatingEvent({ [key]: value }));
    }
  };

  const handleCreate = () => {
    if (isCreatingEvent) {
      dispatch(createNewCustomCalendarEvent(createdEvent));
      handleClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        outline: "none",
      }}
    >
      <Paper
        elevation={6}
        sx={{ padding: 3, borderRadius: 4, mx: 3, my: 2, minWidth: "50%" }}
      >
        <Typography variant="h5" mb={3}>
          {isCreatingEvent ? "Create event" : "Event Details"}
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width={1}
        >
          <Box display="flex" justifyContent="space-between" width={1} mb={2}>
            <TextField
              fullWidth
              required
              label="Name"
              variant="outlined"
              disabled={isDisabled}
              value={isCreatingEvent ? name || "" : name || "Event Name"}
              placeholder="Event Name"
              onChange={(event) => handleChange("name", event.target.value)}
              sx={{ mr: 1 }}
            />
          </Box>
          <TextField
            fullWidth
            label="Description"
            variant="outlined"
            value={description}
            placeholder="Description"
            disabled={isDisabled}
            multiline
            onChange={(event) =>
              handleChange("description", event.target.value)
            }
            sx={{ mb: 2 }}
          />
          <Box display="flex" justifyContent="space-between" mb={2} width={1}>
            <DatePicker
              value={startTime}
              onChange={(newDate) => handleChange("startTime", newDate || "")}
              disabled={isDisabled}
              renderInput={(props) => (
                <TextField
                  required
                  {...props}
                  sx={{ marginRight: 2 }}
                  fullWidth
                />
              )}
              label="Date"
            />
            <TimePicker
              value={dayjs(startTime)?.format("h:mm A") || ""}
              onChange={(newTime) =>
                handleChange(
                  "startTime",
                  dayjs(
                    new Date(
                      `${dayjs(startTime).format("YYYY/MM/DD")} ${newTime}`
                    )
                  ).toISOString()
                )
              }
              disabled={isDisabled}
              label="Time"
            />
          </Box>
          <FormControlLabel
            control={<Checkbox checked={!!allDay} />}
            label="All Day"
            sx={{ alignSelf: "flex-start" }}
            disabled={isDisabled}
            onChange={(_, checked) => handleChange("allDay", checked)}
          />
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Button
            variant="contained"
            sx={{
              my: 1.5,
              color: "#FFF",
              textTransform: "none",
              borderRadius: 6,
              fontSize: 18,
              px: 3,
              mr: 2,
            }}
            onClick={isCreatingEvent ? handleCreate : handleClose}
            disabled={isDisabled}
          >
            {isCreatingEvent ? "Create" : "Okay"}
          </Button>
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{
              my: 1.5,
              textTransform: "none",
              borderRadius: 6,
              fontSize: 18,
              px: 3,
            }}
            disabled={isDisabled}
          >
            Cancel
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
};
