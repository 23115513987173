import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";

import { useCartItems, sessionStorage } from "src/services";
import {
  bookAllCartItems,
  fetchAllBookingsByPartyId,
  fetchAllParties,
  setActivePartyId,
  updateGiftcard,
  useAppDispatch,
  useAppSelector,
} from "src/store";
import { SimpleInputPopup } from "src/components";
import { RouteName } from "src/types";
import { CartBooking } from "src/graphql";

import { getTotalPrice } from "../vendor/services";
import { CartItem } from "./CartItem";
import { CartForm } from "./CartForm";

export function Cart() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { activePartyId, partyList, selectedGiftcard } = useAppSelector(
    ({ party, giftcard }) => ({
      ...party,
      isLoading: party.isLoading,
      selectedGiftcard: giftcard.selectedGiftcard,
    })
  );
  const { items, isLoading } = useCartItems();
  const [isOpen, setIsOpen] = useState(false);

  const userName = sessionStorage.getUsername() || "";

  useEffect(() => {
    if (!partyList.length) {
      dispatch(fetchAllParties(userName));
      return;
    }

    if (!activePartyId) {
      dispatch(setActivePartyId(partyList?.[0]?.partyUid || ""));
    }
  }, [activePartyId, partyList, dispatch, userName]);

  const goToBookActivities = () =>
    navigate(`${RouteName.PARTY}/${activePartyId}${RouteName.VENDOR}`);

  if (!items.length) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        height="50%"
        alignItems="center"
      >
        <Typography variant="h4">Cart is empty</Typography>
        <SimpleInputPopup
          open={isOpen}
          onClick={goToBookActivities}
          onClose={() => {
            setIsOpen(false);
            goToBookActivities();
          }}
          buttonText="Keep Exploring"
          title={
            <>
              Thanks for
              <br />
              Booking!
            </>
          }
          text={
            <>
              Your booking request is confirmed!
              <br />
              Please check your email for details.
            </>
          }
        />
      </Box>
    );
  }

  const discount = Number(selectedGiftcard?.discountPrice) || 0;
  const { total, serviceFee } = calcCartNumbers(items);

  const handleBookCartItems = () => {
    dispatch(
      bookAllCartItems({
        cartItems: items,
        userId: userName,
        total,
        serviceFee,
        discount,
      })
    )
      .then(() =>
        dispatch(
          fetchAllBookingsByPartyId({ partyId: activePartyId, userName })
        )
      )
      .then(() =>
        selectedGiftcard
          ? dispatch(
              updateGiftcard({
                active: false,
                giftPromoId: selectedGiftcard?.giftPromoId || "",
              })
            )
          : null
      )
      .then(() => {
        setIsOpen(true);
      });
  };

  return (
    <Box
      display="flex"
      width="calc(100% - 400px)"
      justifyContent="space-between"
      padding={2}
      mt={1}
    >
      <Box display="flex" flexDirection="column">
        <Typography
          variant="h2"
          fontSize={28}
          fontWeight={600}
          fontFamily="Urbanist"
          pl={1}
          pt={1}
        >
          Booking Cart
        </Typography>
        {items.map((item, i) => (
          <CartItem key={item.cartItemId} item={item} disabled={isLoading} />
        ))}
      </Box>
      <CartForm
        items={items}
        isLoading={isLoading}
        total={total}
        serviceFee={serviceFee}
        discount={selectedGiftcard?.discountPrice || 0}
        onSubmit={handleBookCartItems}
      />
    </Box>
  );
}

function calcCartNumbers(items: CartBooking[]) {
  const { serviceFee, total } = items.reduce(
    (acc, { pricing, addons, guestsOrQuantity }) => {
      const { price = 0, perGroup = false } = pricing?.[0] || {};
      const { serviceFee: cartItemFee, totalWithoutFee: cartItemTotal } =
        getTotalPrice(
          price * (perGroup ? 1 : guestsOrQuantity || 1),
          addons?.map((addon) => ({ ...addon, selected: true })) || [],
          guestsOrQuantity || 0
        );

      return {
        serviceFee: acc.serviceFee + +cartItemFee,
        total: acc.total + +cartItemTotal,
      };
    },
    {
      serviceFee: 0,
      total: 0,
    }
  );

  return { serviceFee, total };
}
