import {
  CreateVendorBookingInput,
  getApolloClient,
  Query,
  UpdateVendorBookingInput,
  VendorBooking,
} from "src/graphql";
import { handleAuthError } from "src/utils";

import { SAVE_BOOKING, UPDATE_BOOKING } from "./mutations";
import { FETCH_VENDOR_BOOKING_LIST } from "./queries";

interface GetAllBookingsPayload {
  partyId: string;
  userName: string;
}

class BookingService {
  get apolloClient() {
    return getApolloClient();
  }

  async saveBooking(
    params: CreateVendorBookingInput
  ): Promise<Query["getVendorBooking"]> {
    try {
      const response = await this.apolloClient.mutate({
        mutation: SAVE_BOOKING,
        variables: {
          input: {
            createTime: params?.createTime,
            createUser: params?.createUser,
            endTime: params?.endTime,
            guestsOrQuantity: params?.guestsOrQuantity,
            partyUid: params?.partyUid,
            startTime: params?.startTime,
            vendorId: params?.vendorId,
            vendorOfferUid: params?.vendorOfferUid,
            vendorTypeId: params?.vendorTypeId,
            totalPrice: params?.totalPrice,
            status: params?.status,
            vendorOfferName: params?.vendorOfferName,
            addOns: params.addOns,
            address: params.address,
            notes: params.notes,
            pricing: params.pricing,
          },
        },
      });

      const vendorBooking = response?.data?.createVendorBooking;

      if (!vendorBooking) {
        throw new Error("Can not save the booking");
      }

      return vendorBooking;
    } catch (err) {
      handleAuthError(err);
      throw err;
    }
  }

  async updateBooking(
    params: UpdateVendorBookingInput
  ): Promise<Query["getVendorBooking"]> {
    try {
      const response = await this.apolloClient.mutate({
        mutation: UPDATE_BOOKING,
        variables: {
          input: {
            createTime: params?.createTime,
            createUser: params?.createUser,
            endTime: params?.endTime,
            guestsOrQuantity: params?.guestsOrQuantity,
            partyUid: params?.partyUid,
            startTime: params?.startTime,
            vendorId: params?.vendorId,
            vendorOfferUid: params?.vendorOfferUid,
            vendorTypeId: params?.vendorTypeId,
            totalPrice: params?.totalPrice,
            status: params?.status,
            vendorOfferName: params?.vendorOfferName,
            addons: params.addons,
            address: params.address,
            notes: params.notes,
            pricing: params.pricing,
            bookingId: params.bookingId,
          },
        },
      });

      const vendorBooking = response?.data?.updateVendorBooking;

      if (!vendorBooking) {
        throw new Error("Can not update the booking");
      }

      return vendorBooking;
    } catch (err) {
      handleAuthError(err);
      throw err;
    }
  }

  async getAllPartyBookings({ partyId, userName }: GetAllBookingsPayload) {
    try {
      const response = await this.apolloClient.query({
        query: FETCH_VENDOR_BOOKING_LIST,
        variables: {
          filter: {
            partyUid: {
              contains: partyId,
            },
            createUser: {
              contains: userName,
            },
          },
        },
        fetchPolicy: "network-only",
      });

      const bookings = response?.data?.listVendorBookings
        ?.items as VendorBooking[];

      if (!bookings) {
        throw new Error("Can not get bookings.");
      }

      return bookings;
    } catch (err) {
      handleAuthError(err);
      throw err;
    }
  }
}

export const bookingService = new BookingService();
