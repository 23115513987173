import { getApolloClient, Location, Query } from "src/graphql";
import { handleAuthError } from "src/utils";

import { FETCH_LOCATION_LIST } from "./queries";

class LocationService {
  get apolloClient() {
    return getApolloClient();
  }

  async fetchLocationList() {
    try {
      const response = await this.apolloClient.query<Query>({
        query: FETCH_LOCATION_LIST,
        variables: {
          filter: {
            active: {
              eq: true,
            },
          },
        },
        fetchPolicy: "network-only",
      });
      const locationItems = response?.data?.listLocations?.items || [];
      if (!locationItems) {
        throw new Error("Can not retrieve locations");
      }
      return locationItems as Location[];
    } catch (error) {
      handleAuthError(error);
      throw error;
    }
  }
}

export const locationService = new LocationService();
