import { useEffect } from "react";

import { useAppDispatch, useAppSelector, fetchAllCartItems } from "src/store";
import { sessionStorage } from "src/services";

export const useCartItems = () => {
  const dispatch = useAppDispatch();
  const { items, isLoading } = useAppSelector(({ cart }) => cart);

  useEffect(() => {
    if (!items.length && fetchAllCartItems) {
      const userName = sessionStorage.getUsername() || "";
      dispatch(fetchAllCartItems({ userName }));
    }
  }, [items.length, dispatch]);

  return {
    items,
    isLoading,
  };
};
