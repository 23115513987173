import { MouseEventHandler, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import { Add as PlusIcon } from "@mui/icons-material";

import {
  getGiftcardByCode,
  selectGiftcard,
  useAppDispatch,
  useAppSelector,
} from "src/store";
import { GiftPromo } from "src/graphql";

export const GiftcardCodeField = () => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(({ giftcard }) => giftcard);
  const [isOpened, setIsOpened] = useState(false);
  const [code, setCode] = useState("");

  const handleSubmit: MouseEventHandler<HTMLButtonElement> = async (event) => {
    event.stopPropagation();
    const giftcardCode = (await dispatch(getGiftcardByCode(code.trim().toLocaleLowerCase())))?.payload as GiftPromo;

    if (!giftcardCode) {
      return toast.error(`We cannot find gift card code "${code}"`, {
        position: "bottom-right",
      });
    }

    if (!giftcardCode.active) {
      return toast.error(`This gift card code is no longer active.`, {
        position: "bottom-right",
      });
    }

    // if (!giftcardCode?.paid) {
    //   return toast.error(`This gift card code is not paid yet.`, {
    //     position: "bottom-right",
    //   });
    // }

    dispatch(selectGiftcard(giftcardCode));
    setIsOpened(false);
    setCode('');
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mt={1.5}
    >
      {isOpened ? (
        <>
          <TextField
            value={code}
            size="small"
            disabled={isLoading}
            onChange={(event) => setCode(event.target.value)}
            variant="outlined"
            placeholder="Add a gift card code"
            sx={{ borderRadius: 4, width: "100%" }}
          />
          <Button
            disabled={isLoading}
            onClick={handleSubmit}
            sx={{
              backgroundColor: "#F3F6FF",
              borderRadius: 2,
              textTransform: "none",
              px: 3,
              py: 0.625,
              ml: 1,
            }}
          >
            Apply
          </Button>
        </>
      ) : (
        <>
          <Typography variant="body1" fontSize={16} fontWeight={400}>
            Add a gift card code
          </Typography>
          <IconButton
            sx={{ backgroundColor: "#F3F6FF" }}
            onClick={() => setIsOpened(true)}
          >
            <PlusIcon color="primary" />
          </IconButton>
        </>
      )}
    </Box>
  );
};
