import {
  Card,
  CardHeader,
  CardMedia,
  CardActionArea,
  Grid,
  Box,
  Typography,
  createTheme,
} from "@mui/material";

import { RouteName, PartyVendor, VendorTab } from "src/types";
import { sessionStorage } from "src/services";
import teal_theme from "src/design/theme/teal";
import ComingSoonImg from "src/design/images/coming-soon.jpg";

import { Link } from "../link";
import { Rating } from "../rating";

const theme = createTheme(teal_theme(null));

interface Props {
  item: PartyVendor;
  partyId: string;
  vendorType: VendorTab;
}

export function GridItem({ item, partyId, vendorType }: Props) {
  const textColor = theme.palette.secondary.light;
  const {
    vendorOfferUid,
    thumbImage,
    name,
    price,
    preview,
    bookingUrl,
    rating = 0,
  } = item;
  const title = (
    <Box display="flex" justifyContent="space-between">
      <Typography color={textColor} fontSize={16} pr={0.5}>
        {name}
      </Typography>
    </Box>
  );
  const subheader = (
    <Typography color="rgba(35, 35, 35, 0.5)" fontSize={16}>
      {preview}
    </Typography>
  );
  const isAuthenticated = !!sessionStorage.getToken();
  const baseUrl = isAuthenticated
    ? `${RouteName.PARTY}/${partyId}${RouteName.VENDOR}`
    : `${RouteName.ALL_VENDORS}`;

  return (
    <Grid item>
      <Card
        sx={{
          height: "100%",
          maxWidth: 220,
          borderRadius: 1,
          boxShadow: "none",
          overflow: "visible",
        }}
        onClick={() =>
          vendorType === VendorTab.ACCOMMODATION &&
          bookingUrl &&
          window.open(bookingUrl, "_blank", "noreferrer")
        }
      >
        <CardActionArea
          sx={{
            paddingX: 1.5,
            paddingY: 1.5,
            borderRadius: 4,
            position: "relative",
            height: "100%",
          }}
        >
          <Rating rating={rating} isAbsolute />
          <Link
            to={
              vendorType === VendorTab.ACCOMMODATION
                ? `${baseUrl}/`
                : `${baseUrl}/${vendorOfferUid}?type=${vendorType}`
            }
            sx={{
              height: "100%",
              display: "inline-flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <CardMedia
                component="img"
                height="150"
                width="250"
                image={thumbImage || ComingSoonImg}
                alt="Image coming soon"
                sx={{ borderRadius: 3, width: 200 }}
              />
              <CardHeader title={title} subheader={subheader} sx={{ px: 0 }} />
            </Box>
            {!!price && (
              <Typography variant="body1" fontSize={12} fontWeight="normal">
                from{" "}
                <span style={{ fontWeight: "bold", fontSize: 16 }}>
                  ${price}
                </span>
              </Typography>
            )}
          </Link>
        </CardActionArea>
      </Card>
    </Grid>
  );
}
