import { Box, Typography, useTheme } from "@mui/material";

import { ReactComponent as BookIcon } from "src/design/images/book-meeting.svg";
import { ReactComponent as CallIcon } from "src/design/images/call-us.svg";
import { ReactComponent as EmailIcon } from "src/design/images/email-us.svg";
import { ReactComponent as ChatIcon } from "src/design/images/chat-with-us.svg";

import { FAQItem } from "./FAQItem";
import { ContactItem } from "./ContactItem";

const SUPPORT_EMAIL = "hello@bachplace.com";
const SUPPORT_PHONE = "516.547.5879";
const DISPLAYED_SUPPORT_PHONE = "(516) 547 5879";
const PLANNING_SESSION_LINK = "https://calendly.com/bachplacedanielle";

export const ContactPage = () => {
  const theme = useTheme();
  const mainColor = theme.palette.primary.main;

  return (
    <Box display="flex" flexDirection="column" padding={3} bgcolor="#FBFBFB">
      <Typography
        variant="h1"
        fontSize={52}
        fontWeight={400}
        color={mainColor}
        mb={6}
      >
        Contact us. We're here to help 24/7.
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 2,
            width: "100%",
            height: "fit-content",
            maxWidth: { md: "840px" },
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", md: 370 },
              minWidth: { xs: "auto", sm: 370 },
            }}
          >
            <ContactItem
              title="Planning session"
              description="Book time with an expert"
              icon={<BookIcon width={48} height={48} />}
              buttonText="Book Now"
              onClick={() =>
                window.open(PLANNING_SESSION_LINK, "_blank", "noreferrer")
              }
            />
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: 370 },
              minWidth: { xs: "auto", sm: 370 },
            }}
          >
            <ContactItem
              title="Chat"
              description="We usually respond in minutes"
              icon={<ChatIcon width={48} height={48} />}
              buttonText="Chat Now"
              disabled
            />
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: 370 },
              minWidth: { xs: "auto", sm: 370 },
            }}
          >
            <ContactItem
              title="Email"
              description={SUPPORT_EMAIL}
              icon={<EmailIcon width={48} height={48} />}
              onClick={() => window.open(`mailto:${SUPPORT_EMAIL}`, "_blank")}
            />
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: 370 },
              minWidth: { xs: "auto", sm: 370 },
            }}
          >
            <ContactItem
              title="Call or Text"
              description={DISPLAYED_SUPPORT_PHONE}
              icon={<CallIcon width={48} height={48} />}
              onClick={() => window.open(`tel:${SUPPORT_PHONE}`, "_blank")}
            />
          </Box>
        </Box>
        <Box
          sx={{ flex: "1 1 200px", minWidth: 300, maxWidth: 500 }}
          flexShrink={4}
        >
          <FAQItem
            open
            title="BachPlace FAQs"
            description="Our BachPlace Platform is dedicated to creating unforgettable and customized experiences for the bride-to-be and her squad."
          />
          <FAQItem
            title="What is BachPlace?"
            description="BachPlace helps bachelorette planners organize bachelorette parties by providing curated recommendations that include accommodation, activities, dining options and party favors. Once you register for the platform, BachPlace will act as your personal assistant helping you with everything you need."
          />
          <FAQItem
            title="Why do I need to use BachPlace?"
            description="If you've ever planned a bachelorette party before, you know how much of a responsibility it is. Putting together a fun-filled weekend for 12 of the bride's closest friends who all have different interests, timelines, restrictions and opinions is no easy task. BachPlace makes planning a bachelorette super easy: we decrease the time spent on researching, communicating, and booking by providing you with curated recommendations that fit your party vibe."
          />
          <FAQItem
            title="Who does BachPlace choose to work with?"
            description="Each vendor that BachPlace works with has been vetted and is on contract to ensure the best experience for you. BachPlace has direct communication with each vendor in case an issue arises, and is here every step of the way. Each vendor goes through a series of interviews and is only accepted onto the platform after showing the ability to provide value."
          />
          <FAQItem
            title="What payment methods do you accept?"
            description="BachPlace accepts all major credit cards and ACH payments."
          />
          <FAQItem
            title="What are the fees?"
            description="A Transaction Fee is calculated by taking into account the credit card processing fees and experiences booked."
          />

          <FAQItem
            title="When will I be charged for my booking?"
            description="All charges occur immediately upon booking your reservation."
          />
          <FAQItem
            title="Is my credit card information secure?"
            description="100% - everything we do is PCI compliant, we do not store your payment on our servers."
          />
          <FAQItem
            title="What is your exchange/cancellation policy?"
            description="These policies are set by our vendors and vary by experience type. Make sure to read each cancellation policy section on each experience when making a reservation."
          />
          <FAQItem
            title="I need help / I'm having a technical problem. What should I do?"
            description="Please email us hello@bachplace.com to get in touch with one of our experts"
          />
          <FAQItem
            title="When can I expect my refund?"
            description="All refunds depend on the user's specific bank but typically arrive in 5 to 7 business days."
          />
          <FAQItem
            title="How do I contact BachPlace?"
            description={`Email us: hello@bachplace.com.\nText/call us: 516.547.5879`}
          />
          <FAQItem
            title="If there’s an issue with my reservation upon arrival, who do I contact?"
            description="You will receive all vendor information before you start your bachelorette weekend. If you cannot get in touch with a vendor and there are pressing issues, contact BachPlace ASAP by calling 516.547.5879"
          />
          <FAQItem
            title="How will I receive my booking confirmation/receipt?"
            description="ou will be emailed your receipt and confirmation after booking."
          />
          <FAQItem
            title="How will the weather impact my reservation?"
            description="Details regarding weather and cancellations are vendor specific. If this situation arises, we will work with the vendor to help you understand your options."
          />
          <FAQItem
            title="Can I change my pickup/booking time?"
            description="Each vendor has specific rules regarding booking changes. If this occurs, please contact the vendor your reservation is with. They will be able to assist you based on your specific reservation."
          />
          <FAQItem
            title="What if there's a travel emergency and I can't make my reservation?"
            description="Please contact customer support at hello@bachplace.com with your booking information and concerns."
          />
          <FAQItem
            title="Why haven't I received a confirmation email for my reservation?"
            description="Please contact customer service at hello@bachplace.com if you haven’t received a confirmation email."
          />
          <FAQItem
            title="I’d love to refer BachPlace to a friend?"
            description="We LOVE referrals! If you enjoyed using BachPlace and want to refer us to a friend, email danielle@bachplace.com to redeem a special offer for both you and the person you are referring."
          />
          <FAQItem
            title="How can I update my personal info?"
            description="You can edit your name and your “About You” section by tapping “Your Profile” in Settings. You can change your credit card information easily the next time you're making a booking by adding a new credit card. To change your email address, you'll need to reach out to us at help@thebach.com as you’re currently unable to do so directly in the app."
          />
          <FAQItem
            title="Is there insurance on my reservation?"
            description="Currently, we do not offer insurance packages for reservations. If you wish to purchase travel insurance, please reach out to hello@bachplace.com for our preferred vendors."
          />
        </Box>
      </Box>
    </Box>
  );
};
