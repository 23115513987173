import { useEffect, useState } from "react";
import { useNavigate, } from "react-router-dom";
import { Box, Container, Grid } from "@mui/material";

import { useAppSelector } from "src/store";
import { RouteName, SessionKeys } from "src/types";
import { Checklist, PollsModule } from "src/features";

import { CalendarPage } from "../calendar";
import { DashboardPartyInfo } from "./DashboardPartyInfo";

export function Dashboard() {
  const { partyList, isLoading } = useAppSelector(({ party }) => party);
  const navigate = useNavigate();
  const [isFetched, setIsFetched] = useState(false);

  useEffect(() => {
    const isFromLogin = !!sessionStorage.getItem(SessionKeys.IS_FROM_LOGIN);
    const isNoParties = !!partyList && !partyList?.length;
    const arePartiesLoaded = isFetched && !isLoading;

    if (arePartiesLoaded && isNoParties && isFromLogin) {
      sessionStorage.removeItem(SessionKeys.IS_FROM_LOGIN);
      setIsFetched(false);
      navigate(`${RouteName.CREATE_PARTY}?download_kit=true`, { replace: true, });
    }
  }, [partyList, isLoading, isFetched]);

  useEffect(() => {
    if (isLoading && !isFetched) {
      setIsFetched(true);
    }
  }, [isLoading]);

  return (
    <Container maxWidth={false} disableGutters sx={{ mt: 5.2 }}>
      <Grid container>
        <Grid item xs={12} md={9}>
          <Box display="flex" flexDirection="column">
            <Box width={1} minHeight={334} borderRadius={10} px={3}>
              <DashboardPartyInfo />
            </Box>
            <CalendarPage />
          </Box>
        </Grid>
        <Grid item xs={12} md={3} minWidth={200}>
          <Checklist />
          <PollsModule />
        </Grid>
      </Grid>
    </Container>
  );
}
