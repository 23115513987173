import { useEffect } from "react";
import { useRevalidator } from "react-router-dom";
import { Box, List, useTheme } from "@mui/material";
import {
  AccountBalanceWalletOutlined as BudgetIcon,
  ExitToApp as SignOutIcon,
  HistoryOutlined as BookingsHistoryIcon,
  Download as DownloadIcon,
} from "@mui/icons-material";

import { BLOG_URL, PLANNING_KIT_URL, RouteName } from "src/types";
import { signOutCognitoUser, sessionStorage } from "src/services";
import { fetchAllParties, useAppDispatch, useAppSelector } from "src/store";
import { ReactComponent as BlogIcon } from "src/design/images/blog.svg";
import { ReactComponent as BookingIcon } from "src/design/images/booking.svg";
import { ReactComponent as CalendarIcon } from "src/design/images/calendar.svg";
import { ReactComponent as ChecklistIcon } from "src/design/images/checklist.svg";
import { ReactComponent as DashboardIcon } from "src/design/images/dashboard.svg";
import { ReactComponent as GuestsIcon } from "src/design/images/my-guests.svg";

import { IconWrapper } from "../ui-kit";
import { CartIcon } from "../cart";
import { MobileMenuItem } from "./MobileMenuItem";

interface Props {
  open: boolean;
  pagename: string;
  onClick: () => void;
}

export function MobileMenu({ open = false, pagename = "", onClick }: Props) {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { revalidate } = useRevalidator();
  const [defaultParty] = useAppSelector(({ party }) => party.partyList);

  const partyId = defaultParty?.partyUid;
  const iconColor = theme.palette.primary.light;
  const redirectLink = partyId
    ? `${RouteName.PARTY}/${partyId}${RouteName.VENDOR}`
    : RouteName.PARTY;
  const isAuthenticated = !!sessionStorage.getToken();

  const handleSignOut = () => {
    signOutCognitoUser();
    revalidate();
  };

  useEffect(() => {
    if (!partyId) {
      const userName = sessionStorage.getUsername() ?? "";
      dispatch(fetchAllParties(userName));
    }
  }, [partyId, dispatch]);

  return (
    <Box
      sx={{
        height: "100%",
        width: open ? "100%" : 0,
        position: "fixed",
        zIndex: 99,
        left: 0,
        top: 0,
        backgroundColor: "rgba(0,0,0, 0.9)",
        overflowX: "hidden",
        transition: "0.5s",
      }}
    >
      <List
        component="nav"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          width: "100%",
          textAlign: "center",
          marginTop: 12,
          marginBottom: 8,
        }}
      >
        <MobileMenuItem
          title="Dashboard"
          link={RouteName.DASHBOARD}
          onClick={onClick}
          icon={
            <IconWrapper
              isActive={pagename === "Parties" || pagename === "Dashboard"}
              propName="stroke"
              color={iconColor}
            >
              <DashboardIcon />
            </IconWrapper>
          }
          active={pagename === "Parties" || pagename === "Dashboard"}
        />
        <MobileMenuItem
          title="Booking"
          link={redirectLink}
          onClick={onClick}
          icon={
            <IconWrapper
              isActive={pagename === "Browse"}
              propName="stroke"
              color={iconColor}
            >
              <BookingIcon />
            </IconWrapper>
          }
          active={pagename === "Browse"}
        />
        <MobileMenuItem
          title="My Bookings"
          onClick={onClick}
          link={RouteName.MY_BOOKINGS}
          icon={<BookingsHistoryIcon />}
          active={pagename === "My Bookings"}
        />
        <MobileMenuItem
          title="Checklist"
          link={RouteName.CHECKLIST}
          onClick={onClick}
          icon={
            <IconWrapper
              isActive={pagename === "Party Checklist"}
              propName="stroke"
              color={iconColor}
            >
              <ChecklistIcon />
            </IconWrapper>
          }
          active={pagename === "Party Checklist"}
        />
        <MobileMenuItem
          title="Gift Card"
          link={RouteName.GIFTCARD}
          onClick={onClick}
          icon={
            <IconWrapper
              isActive={pagename === "Giftcard"}
              propName="stroke"
              color={iconColor}
            >
              <ChecklistIcon />
            </IconWrapper>
          }
          active={pagename === "Giftcard"}
        />
        <MobileMenuItem
          title="Planning Kit"
          link={RouteName.DASHBOARD}
          onClick={() => {
            window.open(PLANNING_KIT_URL, "_blank", "noreferrer");
          }}
          icon={
            <IconWrapper
              isActive={pagename === "Planning Kit"}
              propName="stroke"
              color={iconColor}
            >
              <DownloadIcon />
            </IconWrapper>
          }
          active={pagename === "Giftcard"}
        />
        <MobileMenuItem
          title="My Guests"
          link={RouteName.MY_GUESTS}
          icon={
            <IconWrapper
              isActive={pagename === "My Guests"}
              propName="fill"
              color={iconColor}
            >
              <GuestsIcon />
            </IconWrapper>
          }
          active={pagename === "My Guests"}
        />
        <MobileMenuItem
          title="Inspiration"
          link={RouteName.PARTY}
          onClick={() => {
            onClick();
            window.open(BLOG_URL, "_blank", "noreferrer");
          }}
          icon={
            <IconWrapper isActive={false} propName="fill" color={iconColor}>
              <BlogIcon />
            </IconWrapper>
          }
        />
        <MobileMenuItem
          title="Calendar"
          link={RouteName.CALENDAR}
          onClick={onClick}
          icon={
            <IconWrapper
              isActive={pagename === "Calendar"}
              propName="fill"
              color={iconColor}
              sx={{ p: 0.5 }}
            >
              <CalendarIcon />
            </IconWrapper>
          }
          active={pagename === "Calendar"}
        />
        <MobileMenuItem
          title="Budget"
          link={RouteName.PARTY}
          icon={<BudgetIcon />}
          disabled
        />
        <MobileMenuItem
          title="Cart"
          link={RouteName.CART}
          onClick={onClick}
          icon={<CartIcon />}
          active={pagename === "Cart"}
        />

        <MobileMenuItem
          title="Help"
          link={RouteName.CONTACT}
          icon={<SignOutIcon />}
          onClick={onClick}
          active={pagename === "Contact us"}
        />

        {isAuthenticated && (
          <MobileMenuItem
            title="Log out"
            link={RouteName.PARTY}
            icon={<SignOutIcon />}
            active={false}
            onClick={handleSignOut}
            sx={{
              "&": { mt: 3.5 },
              "& div": {
                background: "transparent",
                color: theme.palette.primary.main,
              },
            }}
          />
        )}
      </List>
    </Box>
  );
}
