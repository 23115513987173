import { MouseEventHandler, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import {
  TextField,
  Popover,
  InputAdornment,
  IconButton,
  Box,
} from "@mui/material";
import { DateRange as DateRangeIcon } from "@mui/icons-material";
import { DateRange, RangeKeyDict } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import { Maybe } from "src/graphql";

interface Props {
  dates: [Maybe<Dayjs>, Maybe<Dayjs>];
  handlers: [CallableFunction, CallableFunction];
  errorTexts: [string, string];
  disabled?: boolean;
}

export function DateRangePicker({
  dates: [startDate, endDate],
  handlers: [handleStart, handleEnd],
  errorTexts: [errorTextStart, errorTextEnd],
  disabled = false,
}: Props) {
  const dateFormat = "MM/DD/YYYY";
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const onInputClick: MouseEventHandler<HTMLElement> = ({ currentTarget }) => {
    if (disabled) {
      return;
    }
    setIsCalendarVisible(true);
    setAnchorEl(currentTarget);
  };

  const onClose = () => {
    setIsCalendarVisible(false);
    setAnchorEl(null);
  };

  const onSelectDateRanges = ({ selection }: RangeKeyDict) => {
    const rangeStartDate = dayjs(selection.startDate);
    const rangeEndDate = dayjs(selection.endDate);

    handleStart(rangeStartDate.isValid() ? rangeStartDate : null);
    handleEnd(rangeEndDate.isValid() ? rangeEndDate : null);
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <TextField
          label="Start Date"
          fullWidth={true}
          onClick={onInputClick}
          value={dayjs(startDate).format(dateFormat)}
          error={!!errorTextStart}
          helperText={errorTextStart}
          disabled={disabled}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={onInputClick} disabled={disabled}>
                  <DateRangeIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="End Date"
          fullWidth={true}
          value={dayjs(endDate).format(dateFormat)}
          onClick={onInputClick}
          error={!!errorTextEnd}
          helperText={errorTextEnd}
          disabled={disabled}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={onInputClick} disabled={disabled}>
                  <DateRangeIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{ ml: 1 }}
        />
      </Box>
      <Popover
        open={isCalendarVisible}
        anchorEl={anchorEl}
        sx={{ ml: -6 }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={onClose}
      >
        <Box>
          <DateRange
            ranges={[
              {
                startDate: startDate?.toDate(),
                endDate: endDate?.toDate(),
                key: "selection",
              },
            ]}
            months={1}
            direction="horizontal"
            onChange={onSelectDateRanges}
            minDate={new Date()}
            showMonthAndYearPickers={false}
            showDateDisplay={false}
          />
        </Box>
      </Popover>
    </Box>
  );
}
