import { createAsyncThunk } from "@reduxjs/toolkit";
import { CreateCalendarInput, DeleteCalendarInput } from "src/graphql";

import { calendarService } from "src/services";

type Payload = Parameters<typeof calendarService.getAllCustomCalendarEvents>[0];

export const fetchAllCalendarEventsByPartyId = createAsyncThunk(
  "calendar/getAllCalendarEvents",
  async ({ partyId }: Payload) => {
    const calendarEvents = await calendarService.getAllCustomCalendarEvents({
      partyId,
    });

    return calendarEvents;
  }
);

export const createNewCustomCalendarEvent = createAsyncThunk(
  "calendar/createNewEvent",
  async (newCalendarEventInput: CreateCalendarInput) => {
    const newEvent = await calendarService.createCustomCalendarEvent(
      newCalendarEventInput
    );

    return newEvent;
  }
);

export const deleteCustomCalendarEvent = createAsyncThunk(
  "calendar/deleteCustomEvent",
  async (input: DeleteCalendarInput) => {
    const deletedEvent = await calendarService.deleteCustomCalendarEvent(input);

    return deletedEvent;
  }
);
