import { createSlice } from "@reduxjs/toolkit";

import { BachParty, BachPartyInvite } from "src/graphql";

import {
  fetchAllParties,
  createNewParty,
  updateParty,
  fetchAllPartyInvites,
  createNewInvite,
  deleteInvite,
  updateInvite,
} from "./partyThunk";
import { setActivePartyId } from "./partyAction";

export interface PartyState {
  activePartyId: string;
  partyList: BachParty[];
  invites: BachPartyInvite[];
  isLoading: boolean;
}

const initialState: PartyState = {
  activePartyId: "",
  partyList: [],
  invites: [],
  isLoading: false,
};

const partySlice = createSlice({
  name: "party",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAllParties.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllParties.fulfilled, (state, action) => {
        state.isLoading = false;
        state.partyList = action.payload;
        if (!state.activePartyId) {
          state.activePartyId = action.payload?.[0]?.partyUid || "";
        }
      })
      .addCase(fetchAllParties.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(createNewParty.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createNewParty.fulfilled, (state, action) => {
        state.isLoading = false;
        state.partyList = [...state.partyList, action.payload];
      })
      .addCase(createNewParty.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(updateParty.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateParty.fulfilled, (state, action) => {
        const updatedParty = action.payload;
        state.isLoading = false;
        state.partyList = state.partyList.map((party) =>
          party.partyUid === updatedParty.partyUid ? updatedParty : party
        );
      })
      .addCase(updateParty.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(setActivePartyId, (state, action) => {
        state.activePartyId = action.payload;
        state.invites = [];
      })

      // INVITES
      .addCase(fetchAllPartyInvites.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllPartyInvites.fulfilled, (state, action) => {
        state.isLoading = false;
        state.invites = action.payload;
      })
      .addCase(fetchAllPartyInvites.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(createNewInvite.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createNewInvite.fulfilled, (state, action) => {
        state.isLoading = false;
        state.invites = action.payload
          ? [...state.invites, action.payload]
          : state.invites;
      })
      .addCase(createNewInvite.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(deleteInvite.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteInvite.fulfilled, (state, action) => {
        state.isLoading = false;
        state.invites = state.invites.filter(
          (i) => i.inviteId !== action.payload?.inviteId
        );
      })
      .addCase(deleteInvite.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(updateInvite.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateInvite.fulfilled, (state, action) => {
        const updatedInvite = action.payload;
        state.isLoading = false;
        state.invites = state.invites.map((i) =>
          i.inviteId === updatedInvite?.inviteId ? updatedInvite : i
        );
      })
      .addCase(updateInvite.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const partyReducer = partySlice.reducer;
