import { Box, Typography } from "@mui/material";

import { generateTimeValues } from "src/utils";

interface Props {
  startHour: string;
}

export const DayTimeline = ({ startHour }: Props) => {
  const hourValues = generateTimeValues("0", false);

  const defaultIndex = hourValues.indexOf("11 AM");
  const actualIndex = hourValues.indexOf(startHour);
  const index = Math.min(...[defaultIndex, actualIndex].filter((i) => i >= 0));
  const displayedHours = hourValues.slice(index);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      pt={10.5}
      minWidth={44}
      height={1}
    >
      {displayedHours.map((hour, i) => (
        <Box
          key={hour}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Dot />
          <Dot />
          <Dot />
          <Typography variant="body1" lineHeight="16px">
            {hour}
          </Typography>
          {i + 1 === displayedHours.length && (
            <>
              <Dot />
              <Dot />
              <Dot />
              <Dot />
            </>
          )}
        </Box>
      ))}
      <Dot />
    </Box>
  );
};

function Dot() {
  return (
    <Box
      width={4}
      height={4}
      borderRadius="50%"
      my="6px"
      sx={{ backgroundColor: "#c7c0c0" }}
    />
  );
}
