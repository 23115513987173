import { FC, SVGProps } from "react";
import { Tab as MuiTab, createTheme } from "@mui/material";

import teal_theme from "src/design/theme/teal";
import { VendorTab } from "src/types";

const theme = createTheme(teal_theme(null));

interface Props {
  activeTab: VendorTab;
  IconComponent: FC<SVGProps<SVGSVGElement> & { title?: string | undefined }>;
  value: VendorTab;
  onClick: (tab: VendorTab) => () => void;
}

export function Tab({ activeTab, IconComponent, value, onClick }: Props) {
  const primaryColor = theme.palette.primary.main;
  const inactiveColor = theme.palette.primary.light;
  const isActive = activeTab === value;
  const color = isActive ? "white" : inactiveColor;
  const icon = <IconComponent stroke={color} />;

  return (
    <MuiTab
      onClick={onClick(value)}
      icon={icon}
      iconPosition="start"
      label={value}
      value={value}
      sx={{
        fontWeight: isActive ? 700 : 500,
        fontSize: 16,
        color,
        marginRight: 1,
        opacity: 1,
        height: "fit-content",
        minHeight: "fit-content",
        paddingX: 3,
        paddingY: 2,
        paddingTop: 2.2,
        paddingBottom: 2.2,
        textTransform: "none",
        ...(isActive
          ? {
              borderRadius: 32,
              paddingX: 3,
              paddingY: 1,
              backgroundColor: primaryColor,
            }
          : {}),
      }}
    />
  );
}
