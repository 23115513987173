import { Box, CircularProgress, Paper, Typography } from "@mui/material";

import { usePolls } from "src/services";
import { useGetCurrentPartyInfo } from "src/hooks";

import { groupAnswersByPolls } from "./utils";
import { Poll } from "./Poll";

export function PollsModule() {
  const { activeParty } = useGetCurrentPartyInfo();
  const { items, isLoading, answers } = usePolls(activeParty?.partyUid || "");
  const answersByPollId = groupAnswersByPolls(answers);

  return (
    <Box display="flex" mt={3}>
      <Paper
        elevation={6}
        sx={{
          padding: 3,
          borderRadius: 13,
          width: "100%",
          alignSelf: "center",
          mx: 0.5,
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography
            variant="h2"
            fontSize={20}
            fontWeight={600}
            fontFamily="Urbanist"
          >
            Polls
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column">
          {isLoading ? (
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          ) : (
            items.map((p) => (
              <Poll
                key={p.pollId}
                data={p}
                answers={answersByPollId[p.pollId] || []}
              />
            ))
          )}
          {!items.length && "No polls yet"}
        </Box>
      </Paper>
    </Box>
  );
}
