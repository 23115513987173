import { useEffect } from "react";
import {
  Box,
  CircularProgress,
  Paper,
  Typography,
} from "@mui/material";

import {
  fetchAllChecklistItemsByPartyId,
  updateChecklistItem,
  useAppDispatch,
  useAppSelector,
} from "src/store";
import { useGetCurrentPartyInfo } from "src/hooks";

import { ChecklistItem } from "./ChecklistItem";

export function Checklist() {
  const dispatch = useAppDispatch();
  const { checklistItems, isPartyLoading, isChecklistLoading, activePartyId } =
    useAppSelector(({ checklist, party }) => ({
      ...checklist,
      activePartyId: party.activePartyId,
      isPartyLoading: party.isLoading,
      isChecklistLoading: checklist.isLoading,
    }));
  const { isAdmin } = useGetCurrentPartyInfo();

  const isLoading = isPartyLoading || isChecklistLoading;

  useEffect(() => {
    dispatch(fetchAllChecklistItemsByPartyId({ partyId: activePartyId }));
  }, [checklistItems.length, activePartyId, dispatch]);

  const onClick = (id: string, currentChecked: boolean) => () => {
    if (!isAdmin) {
      return;
    }
    dispatch(
      updateChecklistItem({
        partyUid: activePartyId,
        checklistItemId: id,
        checked: !currentChecked,
      })
    );
  };

  return (
    <Box display="flex">
      <Paper
        elevation={6}
        sx={{
          maxWidth: 552,
          padding: 2,
          borderRadius: 13,
          width: '100%',
          alignSelf: "center",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Typography
            variant="h2"
            fontSize={18}
            fontWeight={600}
            fontFamily="Urbanist"
            pl={1}
            pt={1}
          >
            My Checklist
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column">
          {checklistItems.map((item) => (
            <ChecklistItem
              key={item.checklistItemId}
              item={item}
              onClick={onClick}
              disabled={!isAdmin}
            />
          ))}
          {!checklistItems.length && !isLoading && (
            <Typography
              variant="body1"
              fontSize={20}
              fontWeight={500}
              alignSelf="center"
            >
              No checklist items yet
            </Typography>
          )}
          {!checklistItems.length && isLoading && <CircularProgress />}
        </Box>
      </Paper>
    </Box>
  );
}
