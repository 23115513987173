import { Box, Button, Divider, Paper, Typography } from "@mui/material";

import { CartBooking } from "src/graphql";
import { GiftcardCodeField } from "./GiftcardCodeField";

interface Props {
  isLoading: boolean;
  items: CartBooking[];
  onSubmit: () => void;
  serviceFee: number;
  total: number;
  discount: number;
}

export const CartForm = ({
  isLoading,
  onSubmit,
  items,
  serviceFee,
  total,
  discount,
}: Props) => {
  const totalWithFeeAndDiscount = total + serviceFee - discount;
  const displayedTotal = (
    totalWithFeeAndDiscount > 0 ? totalWithFeeAndDiscount : 0
  ).toFixed(2);

  return (
    <Paper
      elevation={6}
      sx={{
        display: "flex",
        minWidth: 380,
        position: "fixed",
        top: 120,
        right: 8,
        padding: 3,
        flexDirection: "column",
      }}
    >
      <Typography
        variant="body1"
        fontWeight={600}
        fontSize={26}
        lineHeight="30px"
        mb={2.5}
      >
        Cart Total
      </Typography>
      <Button
        type="button"
        fullWidth
        onClick={onSubmit}
        variant="contained"
        disabled={isLoading}
        sx={{
          "&": {
            mb: 2,
            textTransform: "none",
            borderColor: "#394460",
            borderRadius: 10,
            alignItems: "center",
            px: 3,
            py: 2.5,
            fontSize: 16,
            lineHeight: "19px",
            fontWeight: 500,
            color: "#FFF",
          },
          "&:hover": {
            borderColor: "#394460",
            opacity: 0.95,
          },
        }}
      >
        Pay Now
      </Button>
      <Typography
        variant="body1"
        textAlign="center"
        fontSize={14}
        color="#5E5E5E"
        mb={3}
      >
        You won't be charged yet
      </Typography>
      {items.map(({ pricing, pricingName, vendorOfferName, totalPrice }, i) => {
        return (
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Typography lineHeight="20px" fontWeight={600}>
              {pricingName || pricing?.[0]?.name || vendorOfferName}
            </Typography>
            <Typography lineHeight="20px">${totalPrice.toFixed(2)}</Typography>
          </Box>
        );
      })}
      {!!discount && (
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Typography lineHeight="20px" fontWeight={600}>
            Discount
          </Typography>
          <Typography lineHeight="20px">-{discount.toFixed(2)}$</Typography>
        </Box>
      )}
      <Box display="flex" justifyContent="space-between"></Box>
      <GiftcardCodeField />
      <Divider sx={{ my: 3 }} />
      <Box display="flex" justifyContent="space-between">
        <Typography lineHeight="20px">Total</Typography>
        <Typography lineHeight="20px" fontWeight={600}>
          ${displayedTotal}
        </Typography>
      </Box>
    </Paper>
  );
};
