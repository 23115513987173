import { FormGroup, Typography } from "@mui/material";
import { PollAnswers, PollOptions } from "src/graphql";
import { PollResultAnswers } from "../components";
import { checkAnsweredByType } from "../utils";
import { PollVariant } from "../types";

interface Props {
  options: PollOptions[];
  answers: PollAnswers[];
  anonymous?: boolean;
}

export const TextAnswers = ({ answers, options, anonymous }: Props) => {
  const answerText = answers?.[0]?.answers?.[0];

  return (
    <FormGroup>
      <Typography
        variant="body1"
        fontSize={18}
        fontWeight={500}
        lineHeight="30px"
      >
        Thank you for your answer! You answered:{" "}
        <b>
          <i>"{answerText}"</i>
        </b>
      </Typography>
      {!anonymous && (
        <PollResultAnswers
          answers={answers.filter((ans) =>
            checkAnsweredByType({
              answers: (ans?.answers as string[]) || [],
              options,
              type: PollVariant.Text,
              value: "",
            })
          )}
          withAnswers={!anonymous}
        />
      )}
    </FormGroup>
  );
};
