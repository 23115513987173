import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import {
  WarningOutlined as WarningOutlinedIcon,
  DeleteOutlineOutlined as DeleteIcon,
  EditOutlined as EditIcon,
} from "@mui/icons-material";

import { createPollAnswer, useAppDispatch } from "src/store";
import { sessionStorage } from "src/services";
import { useGetCurrentPartyInfo } from "src/hooks";
import { Question } from "./Question";
import { PollVariant } from "./types";
import { CheckboxPoll, RadioPoll } from "./variations";
import { TextPoll } from "./variations/TextPoll";
import { CheckboxAnswers, RadioAnswers, TextAnswers } from "./answers";
import {
  CreatePollAnswersInput,
  PollAnswers,
  PollData,
  PollOptions,
} from "src/graphql";

const componentsByType = {
  [PollVariant.SelectOne]: RadioPoll,
  [PollVariant.SelectMany]: CheckboxPoll,
  [PollVariant.Text]: TextPoll,
};

const answersByType = {
  [PollVariant.SelectOne]: RadioAnswers,
  [PollVariant.SelectMany]: CheckboxAnswers,
  [PollVariant.Text]: TextAnswers,
};

interface Props {
  data: PollData;
  answers: PollAnswers[];
  withManagement?: boolean;
  onUpdate?: (id: string) => void;
  onDelete?: (partyUid: string, id: string) => void;
}

const CUSTOM_TEXT_OPTION_TYPE = "text";

export function Poll({
  data,
  answers,
  withManagement,
  onUpdate,
  onDelete,
}: Props) {
  const userId = sessionStorage.getUsername() ?? "";

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [customAnswer, setCustomAnswer] = useState("");
  const [isAnswered, setIsAnswered] = useState(
    answers?.some((a) => a.userId === userId) || false
  );
  const { activeParty, isPartyLoading } = useGetCurrentPartyInfo();
  const dispatch = useAppDispatch();

  const { question, type, options = [] } = data;
  const isAnonymous = !!data.anonymous;
  const Component = componentsByType[type as PollVariant];
  const AnswerComponent = answersByType[type as PollVariant];
  const partyId = activeParty?.partyUid;
  const isDisabled =
    isPartyLoading || (!selectedOptions.length && !customAnswer?.trim());

  useEffect(() => {
    const isPollAnswered = !!answers?.some((a) => a.userId === userId);
    if (isPollAnswered) {
      setIsAnswered(true);
    }
  }, [answers, userId]);

  const onSubmit = useCallback(() => {
    const customOption = options?.find(
      (o) => o?.type === CUSTOM_TEXT_OPTION_TYPE
    );
    const isCustomSelected = selectedOptions.includes(customOption?.value!);
    const selectedCustomOption = customAnswer?.trim();

    if (!selectedOptions.length && !customAnswer?.trim()) {
      toast.error(
        `You should select at least one option to submit your answer.`,
        {
          position: "bottom-right",
        }
      );
    }

    let payload: CreatePollAnswersInput | null = null;

    if (type === PollVariant.SelectOne) {
      payload = {
        pollAnswerId: "",
        pollId: data.pollId,
        userId,
        partyUid: partyId || "",
        answers:
          isCustomSelected && selectedCustomOption
            ? [selectedCustomOption]
            : selectedOptions,
      };
    }

    if (type === PollVariant.SelectMany) {
      const selectedWithoutCustom = isCustomSelected
        ? selectedOptions.filter((o) => o !== customOption?.value)
        : selectedOptions;
      payload = {
        pollAnswerId: "",
        pollId: data.pollId,
        partyUid: partyId || "",
        userId,
        answers:
          isCustomSelected && selectedCustomOption
            ? [...selectedWithoutCustom, selectedCustomOption]
            : selectedOptions,
      };
    }

    if (type === PollVariant.Text) {
      payload = {
        pollId: data.pollId,
        partyUid: partyId || "",
        pollAnswerId: "",
        userId,
        answers: [customAnswer],
      };
    }

    !!payload && dispatch(createPollAnswer(payload));
  }, [options, selectedOptions, customAnswer]);

  const handleUpdate = useCallback(() => {
    onUpdate?.(data.pollId);
  }, [data.pollId, onUpdate]);

  const handleDelete = useCallback(() => {
    onDelete?.(data.pollId, partyId || '');
  }, [data.pollId, onDelete]);

  return (
    <Box display="flex" flexDirection="column" px={1}>
      <Divider sx={{ margin: "12px 0" }} />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Question text={question} />{" "}
        {!!withManagement && (
          <Box display="flex" alignItems="center" justifyContent="center">
            <IconButton onClick={handleUpdate}>
              <EditIcon color="primary" />
            </IconButton>
            <IconButton onClick={handleDelete}>
              <DeleteIcon color="error" />
            </IconButton>
          </Box>
        )}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        width="max-content"
        minWidth={200}
        sx={{ display: "contents", wordWrap: "break-word" }}
      >
        {isAnswered ? (
          <AnswerComponent
            options={options as PollOptions[]}
            answers={answers}
            anonymous={isAnonymous}
          />
        ) : (
          <Component
            options={options as PollOptions[]}
            selectedOptions={selectedOptions}
            setSelectedOptions={setSelectedOptions}
            customAnswer={customAnswer}
            setCustomAnswer={setCustomAnswer}
          />
        )}

        {!isAnswered && (
          <Box display="flex" alignItems="center">
            <Button
              variant="contained"
              onClick={onSubmit}
              disabled={isDisabled}
              sx={{
                color: "#FFF",
                textTransform: "none",
                borderRadius: 6,
                fontSize: 18,
                px: 2.5,
                maxWidth: 300,
                alignSelf: "flex-start",
                mt: 1.25,
              }}
            >
              Submit
            </Button>
            {!isAnonymous && (
              <Box display="flex" alignItems="flex-end" mt={1} ml={2}>
                <WarningOutlinedIcon htmlColor="#F4C000" />
                <Typography variant="body2" ml={0.5}>
                  Answers are not anonymous
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}
