import { ReactNode } from "react";
import {
  Box,
  Button,
  Tooltip,
  TooltipProps,
  Typography,
  useTheme,
} from "@mui/material";

interface Props {
  title: string;
  description?: string;
  buttonText?: string;
  onClick?: () => void;
  icon: ReactNode;
  disabled?: boolean;
}

export const ContactItem = ({
  title,
  description,
  buttonText,
  icon,
  disabled,
  onClick = () => {},
}: Props) => {
  const theme = useTheme();
  const mainColor = theme.palette.primary.main;

  const buttonComponent = (
    <Button
      disabled={disabled}
      sx={{
        py: 2.5,
        px: 4,
        fontSize: 16,
        color: mainColor,
        fontWeight: 600,
        textTransform: "none",
        backgroundColor: "#FBFBFB",
        borderRadius: 100,
      }}
    >
      {buttonText}
    </Button>
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      padding={3}
      borderRadius={10}
      bgcolor="#FFF"
      minHeight={256}
      onClick={onClick}
      sx={{ cursor: "pointer" }}
    >
      <Typography
        variant="body1"
        fontSize={32}
        fontWeight={400}
        color={mainColor}
        textAlign="center"
        mb={1.5}
      >
        {title}
      </Typography>
      <Box display="flex" justifyContent="center" mb={2}>
        {icon}
      </Box>
      {!!description && (
        <Typography
          variant="body1"
          fontSize={15}
          color={mainColor}
          textAlign="center"
          mb={2}
        >
          {description}
        </Typography>
      )}
      {!!buttonText &&
        (disabled ? withTooltip(buttonComponent) : buttonComponent)}
    </Box>
  );
};

function withTooltip(children: TooltipProps["children"]) {
  return (
    <Tooltip
      title="Comming soon"
      arrow
      placement="top"
      slotProps={{
        tooltip: {
          style: { marginBottom: -6 },
        },
      }}
    >
      <span>{children}</span>
    </Tooltip>
  );
}
