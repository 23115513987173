import { Box, Typography } from "@mui/material";

export function PrivacyPolicy() {
  return (
    <Box display="flex" flexDirection="column" px={4} py={8}>
      <Typography alignSelf="center" variant="h1" sx={{ marginBottom: 5 }}>
        Privacy Policy
      </Typography>
      <Typography fontSize={24} variant="body2" marginLeft={10} marginRight={10}>
      <p>
        <span>
          BachPlace Inc. values your privacy and makes every effort to create a positive user experience.
          In certain instances, BachPlace Inc. may collect and use information that is provided to us. This
          Privacy Policy focuses on the collection, use and disclosure of information that BachPlace Inc.
          may collect while you use the website, application, or other offerings supplied by BachPlace Inc.
          (collectively, the “Platform”). Unless otherwise stated, the terms that are used but not defined in
          this Privacy Policy have the same meanings as in the BachPlace Inc. Terms and Conditions.
        </span>
    </p>
    <p>
      <span>
        This Privacy Policy applies only to the BachPlace Inc. Platform and does not apply to any other
        platforms, applications, websites, services or products provided by third parties that are not
        directly affiliated with BachPlace Inc. that you may visit (such as a link you click on through the
        Platform). The Privacy Policies of third party platforms, applications, websites, services or
        products are not controlled by BachPlace Inc. (even when we provide clickable links). To find
        out more about data security policies and only privacy of third party platforms, please visit their
        appropriate Privacy Policies as they may differ from BachPlace Inc.
      </span>
    </p>
    <p>
      <span>
        Submitting your information to BachPlace Inc. and using the Platform assumes that you have
        read, understood, and agreed to BachPlace Inc.'s Privacy Policy, BachPlace Inc.'s Terms and
        Conditions, and all other relevant BachPlace Inc. rules and policies (collectively, the
        “Agreement”). Additionally, this policy assumes that you give BachPlace Inc. consent to use
        your information as described in this Agreement. Should you disagree with any part of the
        Privacy Policy, then you should not access or use the Platform.
      </span>
    </p>
    <p>
      <span>
        To learn about the different types of information that BachPlace Inc. collects, how that
        information is used, what information is disclosed to third parties and how BachPlace Inc.
        protects your information, please review this Privacy Policy regularly as BachPlace Inc. makes
        adjustments periodically. We will notify you of any changes to this Privacy Policy by posting the
        updated Privacy Policy to the Platform. The information that BachPlace Inc. collects is and will
        be subject to the Privacy Policy in effect at the time the information is gathered.
      </span>
    </p>
    <p>
      <span>
        &zwj;1. User Information Collected by BachPlace Inc
      </span>
    </p>
    <p>
      <span>
        1.1 Personal Information and Non-Identifying Information
      </span>
      <span>&nbsp;</span>
    </p>
    <p>
      <span>
        When using the BachPlace Inc. Platform, such as creating an account or registering as a user
        (“User”), you may be asked for personally identifiable information. This type of information is
        information that can be used to identify or contact you (“Personal Information). Personal
        Information includes, but is not limited to, your full name, email address, email, and any other
        information that can be used by BachPlace Inc. to personally identify you. BachPlace Inc. also
        collects other information when registering as a User to administer and personalize your
        account that does not identify you (“Non-Identifying Information”). Such Non-Identifying
        Information includes, but is not limited to, individual preferences and your zip code (on its own).
        Specific Non-Identifying Information would be considered a part of your Personal Information if it
        were combined with other identifiers (ex combining your preferences with your profile or zip
        code with your street address) in a way that enables you to be identified. But the same pieces of
        information are considered Non-Identifying Information when taken alone or combined only with
        other non-identifying information (for example, your birthday or your viewing preferences).
      </span>
      <span>
        The main reason BachPlace Inc.uses your Personal Information (in some cases with your Non-
        Identifying Information) is to communicate with you, to provide you with the functionality and the
        features of the BachPlace Inc. Platform, and to facilitate and complete vendor transactions for

        you when initiated by you. We may combine your Personal Information with Non-Identifying
        Information and other information we collect to provide you with an enhanced experience to
        improve the quality of the BachPlace Inc. Platform, and to analyze how the Platform is used by
        BachPlace Inc. Users. Another reason in which we may use your Personal Information is to
        contact you with marketing and promotional materials, periodic newsletters, targeting via social
        media platforms such as Meta, and provide you with other information that may be relevant to
        you. At all times, you may unsubscribe from BachPlace Inc. if you no longer wish to receive
        such communications. To unsubscribe, please follow the instructions in our communications
        (usually located at the bottom), or update your account settings.
      </span>
    </p>
    <p><span>&zwj;1.2 Usage Data</span></p>
    <p>
      <span>
        When you visit the Platform, whether you are logged in or just visiting without logging in, our
        servers automatically record information that your browser or device may send whenever you
        visit a website or use a similar application or online service. The information sent automatically
        by your device is referred to as “Usage Data.” This Usage Data may include information such as
        the manufacturer and model of your device; your Internet Service Provider (ISP); your device's
        Internet Protocol (“IP”) address (or other device identifier), browser type, and operating system;
        referring/exit pages; clickstream data; Platform access times and dates, pages or areas of the
        Platform that you visit, the time spent on those pages, and information you search for on the
        Platform; and other statistics. Usage Data also includes geolocation data. The Platform collects
        and uses geolocation data to provide you with a better experience, to improve the quality and
        value of the Platform, and to analyze and understand how the Platform is used. We use this
        information for technical administration of the Platform, including to monitor and analyze use of
        the Platform, to increase the functionality and user-friendliness of the Platform, and to better
        tailor the Platform to Users' needs. 2 Usage Data may be non-identifying or it may be
        associated with you. Whenever we associate Usage Data with Personal Information, we will
        treat it as Personal Information.
      </span>
    </p>
    <p><span>&zwj;2. Information Collection</span></p>
    <p>
      <span>
        &zwj;‍BachPlace Inc. collects and stores any and all information that you enter on the Platform and
        may use numerous techniques and technologies that are different from each other to collect
        Personal Information, Non-Identifying Information, and Usage Data. BachPlace Inc. may use
        pixel tags, log files, cookies, clear gifs, embedded scripts, and/or other technology used to
        monitor your use of the BachPlace Inc. Platform.
      </span>
    </p>

    <p ><span >&zwj;2.1 Forms and Questionnaires</span></p>
    <p >
      <span>
        When registering as a User, creating a party, completing your User profile, requesting/initiating a
        transaction with a vendor, or communicating with other Users, vendors, or BachPlace Inc., we
        request certain information from you through the use of various forms.
      </span>
    </p>

    <p ><span >2.2 Cookies</span></p>
    <p >
      <span>
        Cookies are text files with small pieces of data — like a username and password — that are
        used to identify your computer as you use a computer network. When a cookie is exchanged
        between your computer and the network server, the server reads the ID and knows what
        information it should specifically serve you. As cookies are regularly used by several websites,
        users will be prompted to accept cookies or decline them. Disabling cookies may limit certain
        features on the website, but that is up to the User's discretion. In our case, cookies are sent to
        and stored on your device from the BachPlace Inc. server. BachPlace Inc. receives insights on
        what parts of the platform are most used by (and how much time they are used for) through the
        use of cookies and similar technologies. One reason BachPlace Inc. uses cookies is to
        understand if a User is active and for additional business related purposes. BachPlace Inc. may

        include different types of cookies. For example, first-party cookies are created and used on a
        single domain - they send data directly to BachPlace Inc. and agents and contractors (Vendors)
        acting on behalf of BachPlace Inc. These could also be cookies used to analyze, monitor and
        administer the Platform. An additional type of cookie that may be used to collect information is a
        third-party cookie. These cookies send data to our agents and contractors for their commercial
        use. Through the use of technology such as Google Analytics and other retargeting technology,
        BachPlace Inc. will track a User's activity and follow that user for up to the 2 industry standard of
        30 (thirty) days. BachPlace Inc. may also use retargeting software for its email service provider
        and other software such as Mailchimp, HubSpot Marketing Hub and Salesforce Marketing
        Cloud.
      </span>
    </p>

    <p >
      <span >2.3</span
      ><span >&nbsp;Collected Usage Data </span>
    </p>
    <p >
      <span>
        BachPlace Inc. automatically collects the usage data on its servers and software. This means
        that your session on the Platform will be tracked due to the collected Usage Data when any
        User is on the Platform. By being on the Platform, the User agrees that BachPlace Inc. may
        collect and also use data (technical data and related information such as technical information).
        This information includes but is not limited to your device, system, application software, and
        peripherals, to maintain and support the BachPlace Inc. platform). We may also use pixel tags
        or clear gifs (tiny graphic images) so that we can measure the effectiveness of the searches that
        Users conduct, understand what parts of the Platform users have visited/seen, and allow us to
        understand what emails were sent and opened/unopened (ensuring that the emails we send are
        of interest to our Users). In some of those email messages, BachPlace Inc. may insert a
        clickable URL (also called a click-through URL) that is linked to content posted on
        BachPlace.com. Should a User click on one of these URLs. That User will pass through the
        BachPlace Inc. server before arriving at the intended web page. To figure out if a user is
        interested in the specific content, and to measure the effectiveness of such User
        communications, BachPlace Inc. will track the click-through data. Any user can prevent tracking
        by not clicking on the text or graphic links received in emails from Bach Place Inc.
      </span>
    </p>

    <p >
      <span >2.4</span><span>&zwj;</span
      ><span >&nbsp;Tracking Opt-Out</span>
    </p>
    <p >
      <span>
        Some web browsers can be set to send “Do Not Track” signals to platforms, or Users may use
        other ways to indicate their preference that certain web technologies not be used to track the
        User's online activity. BachPlace.com does not comply or accept such Do Not Track signals or
        similar mechanisms.
      </span>
    </p>

    <p >
      <span >2.5 Public Messages</span><span >&nbsp;</span>
    </p>
    <p >
      <span>
        It's important to be aware that if you use any public communication methods, such as chat
        rooms, commenting on posts, posting on bulletin boards, or such forums on BachPlace.com, or
        features offered through the Platform, or post any publicly available information that can be
        viewed by other Users, all information that you share will be visible to others on the Platform.
        Other Users can read, use and collect this information and can send you unsolicited messages
        outside of the Platform if they wish. BachPlace Inc. is not responsible for how other Users may
        use the Personal Information you choose to share on the BachPlace Inc. Platform.
      </span>
    </p>

    <p >
      <span
        >&zwj;3. Handling and Dissemination of Information collected by
        BachPlace Inc.</span>
    </p>
    <p >
      <span>
        BachPlace Inc. collects User information and adds it to its database. Some of the uses of this
        information is for business-related purposes, marketing and promotional purposes, and to
        improve the User experience by analyzing statistical data of User behavior for reasons related
        but not limited to product improvement and development, content enhancement, and Platform
        layout. To provide the User with relevant information, BachPlace Inc. may send you emails
        about the BachPlace Inc. Platform or third-party products. Users have the option of

        unsubscribing or opting-out of such email communications by going to their account settings or
        clicking on “Unsubscribe” on any email. If a User agrees to BachPlace Inc. sharing information
        with a third party, BachPlace Inc. may share the User information with that third party.
        Additionally, BachPlace Inc. may share User Information with third parties for the following
        reasons:
      </span>
    </p>

    <p ><span >3.1 Promotions</span></p>
    <p >
      <span>
        BachPlace Inc. may offer promotions (offers, deals, sales, discounts, special offers, incentives,
        contests, sweepstakes, bonuses, etc.) through the Platform that require additional registration.
        Participating in any of the above examples but not limited to them, the User agrees to the official
        rules of the promotion (for example, providing requisition information). Any information that is
        collected with regards to the promotion will follow the official rules of the promotion in addition to
        this Privacy Policy.
      </span>
    </p>

    <p ><span >3.2 Business Related Transfers</span></p>
    <p >
      <span>
        BachPlace Inc. may participate in a sale of assets, acquisition, merger, reorganization, or
        bankruptcy. Within this action, BachPlace Inc. may buy, sell or share assets in connection with
        one of the activities above. If one of those activities occurs, User Information may be transferred
        as part of the business asset. Should BachPlace Inc. itself (or any of its assets) be sold, User
        information may be transferred to a relevant party.
      </span>
    </p>

    <p ><span >3.3 Vendors and Partners</span></p>
    <p >
      <span>
        BachPlace Inc. employs third party services, companies and other individuals (“Vendors and
        Partners”) for several reasons including but not limited to Platform facilitation and services.
        These services, without limitation, could include performance related services, maintenance
        service, database management, fulfillment, web analytics, and feature and functionality
        improvement, and to help BachPlace Inc. in understanding and analyzing how the Platform is
        being used. In some instances, you may not grant BachPlace Inc. permission to share your
        information, and BachPlace Inc. will not share that information with its Vendors and Partners.
        Should you choose to not grant BachPlace Inc. permission to share your information with its
        Vendors and Partners, your Platform experience may differ. For example, you may have limited
        access to BachPlace Inc.'s services if you are not logged in.
      </span>
    </p>

    <p >
      <span
        >3.4 Legal Compliance and Protection of BachPlace Inc and Third
        Parties</span>
    </p>
    <p >
      <span>
        BachPlace Inc. may release User information in certain circumstances where we believe that it
        is appropriate. These reasons are in compliance with the law for reasons including but not
        limited to (1) to protect BachPlace Inc.'s rights, property, safety of our Users, or others (2) to
        apply or enforce this Agreement, including the Platform Terms and Conditions and other
        applicable agreements, rules, and policies (3) responding to a subpoena served on BachPlace
        Inc. or (4) or to prevent activity that we believe (in our sole discretion) may be or in the future
        may become illegal, unethical, or legally actionable. Such examples are exchanging User
        information with other companies and organizations for fraud protection.
      </span>
    </p>

    <p >
      <span >&zwj;3.5 Aggregate Website Use Information</span>
    </p>
    <p >
      <span>
        BachPlace Inc. may release User information to advertisers and/or other third parties. This is in
        an effort to promote or describe the use of the Platform. BachPlace will not reveal any Personal
        Information about you when doing so.
      </span>
    </p>

    <p ><span >&zwj;4. International Transfer</span></p>
    <p >
      <span>
        BachPlace Inc. controls and operates all parts of the Platform in offices located in the United
        States of America. By accessing and using the Platform, you agree to and acknowledge this,

        and to the fact that the BachPlace Inc. Platform is intended for users located in the United
        States of America. BachPlace Inc. makes no representation that the Platform is available for
        use in other locations outside of the United States of America unless stated. Any User who
        accesses the Platform from outside of the United States of America agrees to and
        acknowledges that their information may be transferred to to and maintained on servers located
        out of their respective country, province, state, or other governmental jurisdiction where the
        privacy laws may not be as protective. Accessing, using and submitting information to the
        Platform represents your agreement to: (1) the transfer of such information to the United States
        of America and (2) the collection, usage, and disclosure of your information in accordance with
        United States of America law and BachPlace Inc.'s Privacy Policy.
      </span>
    </p>

    <p ><span >5. Security and Protection</span></p>
    <p >
      <span>
        In an effort to protect User information from unauthorized access, BachPlace Inc. employs
        administrative, physical, and electronic measures. Such examples of reasonable security
        measures are firewalls and encryption to protect User information. Any information that a User
        transmits to BachPlace Inc. is done at their own risk, as no security system is impenetrable.
        Additionally, BachPlace Inc. does not guarantee the security of our databases or that the
        information Users send won't be intercepted during the transmission of information (over a
        network). Should a breach of the security, confidentiality or integrity of your unencrypted
        electronically stored “personal data” occur (as defined in applicable state statutes on security
        breach notification), BachPlace Inc. will make any legally required disclosures. This
        communication will be done through email or via the Platform in the quickest and most efficient
        manner without unreasonable delay. BachPlace Inc. will comply with all legitimate needs of law
        enforcement and take necessary steps to understand the cause of the breach. BachPlace Inc.
        will make an effort to restore the integrity of the data system.
      </span>
    </p>

    <p ><span >6. Modifying or Removing Your Data</span></p>
    <p >
      <span>
        Users with a BachPlace Inc. Platform account may review, update, or correct their Personal
        Information by going to account settings or contacting BachPlace Inc. Deleting all your
        information may lead to the deactivation of your account. For legitimate business purposes and
        as required by law, BachPlace Inc. may keep an archived copy of your records.
      </span>
    </p>

    <p ><span >&zwj;7. Privacy of Minors</span></p>
    <p >
      <span>
        BachPlace Inc. is committed to protecting the privacy of minors (children). To sign up for the
        Platform, Users must be at the age of thirteen (13). Users under the age of thirteen (13) who
        access the Platform are unauthorized, unlicensed, and are in violation of the Agreement. By
        using the BachPlace Inc. Platform, Users agree that they are at least thirteen (13) years of age
        and agree to commit to all terms and conditions outlined in the Agreement. If at any time
        BachPlace Inc. has reason to believe that a User is under the age of thirteen (13), or that the
        User is not old enough to consent and be legally bound by the agreement, it may, at any time, in
        its sole description with our without notice, do the following: (1) end the Users ability to access
        or utilize the Platform (or any specific aspect, feature or part of it), or (2) remove any content or
        information submitted to or posted on the Platform.
      </span>
    </p>
    <p ><span >8. California Residents</span></p>
    <p >
      <span>
        The California Consumer Privacy Act (CCPA) requires us to provide some additional info to
        California residents. We have collected Personal Information, Non-Identifying Information,
        Usage Information, financial information and inferences drawn from the preceding from you,
        your devices, our partners, advertisers, and other third parties.

        We keep your information only so long as we need it to provide services to you and fulfill the
        purposes described here and in our Privacy Policy. When we no longer need to use your
        information and there is no need for us to keep it to comply with our legal or regulatory
        obligations, we'll either remove it from our systems or depersonalize it so that we can&#39;t identify
        you.
        We sometimes allow third parties to collect information about you from cookies and related
        technologies, such as how you interact with our site, and with advertising technology partners in
        order to create user profiles and present advertising messages based on your preferences.
        Under California law, these activities may constitute “sharing” or “selling”.

        Your rights under the CCPA:
        <ul>
          <li>
            Right to Know and Access. You may submit a verifiable request to learn more about
            our information practices and for the specific pieces of personal information we have
            collected about you.
          </li>
          <li>
            Right to Correct. You can correct inaccurate information by updating your profile
            information in your account.
          </li>
          <li>
            Right to Delete. You may submit a verifiable request that we delete personal information
            we have collected from you.
          </li>
          <li>
            Right to Opt-out of Sales/Sharing: You have the right to opt out of “sharing” or “sales”
            of your information. If you have an account with us and you visit our site with an opt-out
            preference signal (such as Global Privacy Control) enabled, we will treat this as an opt-
            out request.
          </li>
          <li>
            Right to Equal Service. We will not discriminate against you if you exercise your
            privacy rights.
          </li>
        </ul>
      </span>
    </p>
    <p ><span ></span></p>
    <p >
      <span
        >For any questions regarding BachPlace Incs Privacy Policy, please
        contact us at: </span>
      <span>
        <a href="mailto:info@bachplace.com">info@bachplace.com</a></span>
    </p>

      </Typography>
    </Box>
  );
}
