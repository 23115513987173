import { SyntheticEvent, useEffect } from "react";
import { Box, Grid, Paper, Tabs, Container } from "@mui/material";
import { TabContext } from "@mui/lab";

import { VendorTab } from "src/types";
import { Tab, TabPanel } from "src/components";
import { ReactComponent as ActivitiesIcon } from "src/design/images/activities.svg";
import { ReactComponent as DiningIcon } from "src/design/images/dining.svg";
import { ReactComponent as AccommodationsIcon } from "src/design/images/accommodations.svg";
import { ReactComponent as PartyFavorsIcon } from "src/design/images/party-favors.svg";
import {
  fetchAllVendorsByType,
  useAppDispatch,
  useAppSelector,
  changeActiveTab,
} from "src/store";
import { filterByLocation } from "./services";
import { VendorLocationSelect } from "./components";

export function Vendor() {
  const dispatch = useAppDispatch();
  const { isLoading, vendorsByType, activeTab, selectedLocation } =
    useAppSelector(({ vendor }) => vendor);
  const vendorsList = vendorsByType[activeTab];

  useEffect(() => {
    if (vendorsList.length < 2) {
      dispatch(fetchAllVendorsByType({ type: activeTab }));
    }
  }, [vendorsList, activeTab, dispatch]);

  const handleTabChange = (event: SyntheticEvent, newValue: VendorTab) => {
    dispatch(changeActiveTab(newValue));
  };

  const onTabClick = (tab: VendorTab) => () => {
    dispatch(changeActiveTab(tab));
  };

  return (
    <Container
      maxWidth="xl"
      sx={{ mt: 4, mb: 4, pl: 3.25, pr: 0.8 }}
      disableGutters
    >
      <Grid container spacing={0}>
        <Grid item xs={12} md={12} lg={12}>
          <Paper
            elevation={4}
            sx={{
              borderRadius: 10,
              p: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box width={1} paddingLeft={1.8}>
              <TabContext value={activeTab}>
                <Box>
                  <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    sx={{
                      "& .MuiTabs-indicator": { display: "none" },
                      "& .MuiTabs-flexContainer": { overflowX: "auto", paddingBottom: 0.5 },
                    }}
                  >
                    <Tab
                      IconComponent={ActivitiesIcon}
                      value={VendorTab.ACTIVITIES}
                      activeTab={activeTab}
                      onClick={onTabClick}
                    />
                    <Tab
                      IconComponent={DiningIcon}
                      value={VendorTab.DINING}
                      activeTab={activeTab}
                      onClick={onTabClick}
                    />
                    <Tab
                      IconComponent={PartyFavorsIcon}
                      value={VendorTab.PARTY_FAVORS}
                      activeTab={activeTab}
                      onClick={onTabClick}
                    />
                    <Tab
                      IconComponent={AccommodationsIcon}
                      value={VendorTab.ACCOMMODATION}
                      activeTab={activeTab}
                      onClick={onTabClick}
                    />
                  </Tabs>
                </Box>
                <Box mt={2} maxWidth={300}>
                  <VendorLocationSelect />
                </Box>
                <TabPanel
                  isLoading={isLoading}
                  data={filterByLocation(
                    vendorsByType[VendorTab.ACTIVITIES],
                    selectedLocation
                  )}
                  activeTab={activeTab}
                  value={VendorTab.ACTIVITIES}
                />
                <TabPanel
                  isLoading={isLoading}
                  data={filterByLocation(
                    vendorsByType[VendorTab.DINING],
                    selectedLocation
                  )}
                  activeTab={activeTab}
                  value={VendorTab.DINING}
                />
                <TabPanel
                  isLoading={isLoading}
                  data={filterByLocation(
                    vendorsByType[VendorTab.PARTY_FAVORS],
                    selectedLocation
                  )}
                  activeTab={activeTab}
                  value={VendorTab.PARTY_FAVORS}
                />
                <TabPanel
                  isLoading={isLoading}
                  data={filterByLocation(
                    vendorsByType[VendorTab.ACCOMMODATION],
                    selectedLocation
                  )}
                  activeTab={activeTab}
                  value={VendorTab.ACCOMMODATION}
                />
              </TabContext>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
