import { gql } from "@apollo/client";

export const CREATE_PAYMENT_BOOKING = gql`
  mutation CreatePaymentBooking($input: CreatePaymentBookingInput!) {
    createPaymentBooking(input: $input) {
      bachPaymentid
      bookingIds
      serviceFee
      status
      totalPrice
    }
  }
`;
