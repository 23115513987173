import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Paper,
  useTheme,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { EditOutlined as EditOutlinedIcon } from "@mui/icons-material";

import { useAppDispatch, useAppSelector, fetchAllParties } from "src/store";
import { sessionStorage } from "src/services";
import { RouteName } from "src/types";
import { Link } from "src/components";
import { formatToPrettyDate } from "src/utils";

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Party",
    width: 280,
    renderCell: getBookLink,
  },
  { field: "brideName", headerName: "Bride's Name", width: 180 },
  {
    field: "startDate",
    headerName: "Start Date",
    width: 180,
    valueFormatter: ({ value }) => formatToPrettyDate(value),
  },
  {
    field: "endDate",
    headerName: "End Date",
    width: 180,
    valueFormatter: ({ value }) => formatToPrettyDate(value),
  },
  {
    field: "edit",
    headerName: "Edit party",
    width: 90,
    align: "center",
    headerAlign: "center",
    renderCell: ({ row }) => (
      <IconButton onClick={row.onClick}>
        <EditOutlinedIcon />
      </IconButton>
    ),
  },
];

function getBookLink(params: any) {
  return (
    <Link to={`${RouteName.PARTY}/${params.row.id}${RouteName.VENDOR}`}>
      {params.row.name}
    </Link>
  );
}

export function Party() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { partyList } = useAppSelector(({ party }) => party);

  const updatedPartyList = useMemo(
    () =>
      partyList.map((currentParty) => {
        return {
          ...currentParty,
          id: currentParty?.partyUid,
          onClick: () =>
            navigate(
              `${RouteName.PARTY}/${currentParty?.partyUid}${RouteName.EDIT_PARTY}`
            ),
        };
      }),
    [partyList, navigate]
  );

  useEffect(() => {
    const userName = sessionStorage.getUsername() ?? "";
    dispatch(fetchAllParties(userName));
  }, [dispatch]);

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paper
            elevation={4}
            sx={{
              p: 4,
              display: "flex",
              flexDirection: "column",
              height: 540,
              borderRadius: 10,
            }}
          >
            <Box>
              <Button
                component={Link}
                to={RouteName.CREATE_PARTY}
                variant="contained"
                sx={{
                  "&": { mt: -1, mb: 1, color: "#FFF" },
                  "&:hover": {
                    background: theme.palette.primary.main,
                    opacity: 0.9,
                  },
                }}
              >
                Create Party
              </Button>
            </Box>
            <Box style={{ height: 400, width: "100%" }}>
              {
                <DataGrid
                  rows={updatedPartyList}
                  columns={columns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  disableSelectionOnClick
                />
              }
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
