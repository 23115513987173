import { Dispatch, SetStateAction, useCallback, useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import { PollVariant } from "../../poll";

interface Props {
  pollType: PollVariant;
  setPollType: Dispatch<SetStateAction<PollVariant>>;
}

const pollTypeOptions = [
  { value: PollVariant.SelectOne, label: "Select one answer" },
  { value: PollVariant.SelectMany, label: "Select multiple answers" },
  { value: PollVariant.Text, label: "Text answer" },
];

export function PollTypeSelect({ pollType, setPollType }: Props) {
  const handlePollTypeChange = useCallback(
    (event: SelectChangeEvent<PollVariant>) => {
      const value = event.target.value as PollVariant;
      setPollType(value);
    },
    []
  );

  return (
    <FormControl
      fullWidth
      sx={{
        "& fieldset": {
          border: "1px solid #e3e3e3 !important",
        },
        "&": {
          maxWidth: 250,
        },
      }}
    >
      <InputLabel id="select-poll-type">Poll type</InputLabel>
      <Select
        labelId="select-poll-type"
        value={pollType}
        label="Poll type"
        onChange={handlePollTypeChange}
        placeholder="Choose poll type"
        sx={{
          "&": {
            height: 40,
          },
        }}
      >
        {pollTypeOptions.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
