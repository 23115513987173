import { createSlice } from "@reduxjs/toolkit"

import { Location } from "src/graphql";

import { fetchAllLocations } from "./locationThunk";

export interface LocationState {
  locationList: Location[];
  isLoading: boolean;
}

const initialState: LocationState = {
  locationList: [],
  isLoading: false,
}

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAllLocations.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllLocations.fulfilled, (state, action) => {
        state.isLoading = false;
        state.locationList = action.payload
      })
      .addCase(fetchAllLocations.rejected, (state) => {
        state.isLoading = false;
      });
  },
});
 
export const locationReducer = locationSlice.reducer;
