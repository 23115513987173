import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import dayjs from "dayjs";

import { sessionStorage } from "src/services";
import { DEFAULT_PARTY_VIBE_VALUE, RouteName } from "src/types";
import { createNewParty, setActivePartyId, useAppDispatch } from "src/store";

import { PartyInfoForm } from "./PartyInfoForm";
import { PartyBrideVibeForm } from "./PartyBrideVibeForm";
import { PartyBrideVibe, PartyInfo } from "./types";
import { DynamicInfoPopup } from "src/components";

enum Step {
  INFO,
  VIBE,
  SUBMIT,
}

const DOWNLOAD_KIT_SEARCH_KEY = 'download_kit';

export const CreateParty = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [partyInfo, setPartyInfo] = useState<PartyInfo>({
    partyName: "",
    brideName: "",
    startDate: dayjs(),
    endDate: dayjs().add(2, "day"),
    isNoDates: false,
    location: "",
    numberOfGuests: 0,
  });
  const [partyBrideVibe, setPartyBrideVibe] = useState<PartyBrideVibe>({
    adventure: DEFAULT_PARTY_VIBE_VALUE,
    chill: DEFAULT_PARTY_VIBE_VALUE,
    party: DEFAULT_PARTY_VIBE_VALUE,
    wellness: DEFAULT_PARTY_VIBE_VALUE,
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const hasDownloadKit = !!searchParams.get(DOWNLOAD_KIT_SEARCH_KEY);

  const [currentStep, setCurrentStep] = useState(Step.INFO);
  const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(hasDownloadKit);

  const goToNextStep = () => {
    const nextStep = currentStep === Step.INFO ? Step.VIBE : Step.SUBMIT;
    setCurrentStep(nextStep);
  };

  useEffect(() => {
    if (hasDownloadKit) {
      setIsDownloadModalVisible(true);
      searchParams.delete(DOWNLOAD_KIT_SEARCH_KEY)
      setSearchParams(searchParams)
    }

  }, [hasDownloadKit, searchParams]);

  useEffect(() => {
    if (currentStep === Step.SUBMIT) {
      const userName = sessionStorage.getUsername();
      dispatch(
        createNewParty({
          brideName: partyInfo?.brideName || "",
          name: partyInfo?.partyName || "",
          endDate: partyInfo?.endDate?.toISOString() || "",
          startDate: partyInfo?.startDate?.toISOString() || "",
          unknownDate: partyInfo?.isNoDates || false,
          location: partyInfo?.location || "",
          bVibeAdv: +(partyBrideVibe?.adventure || 0),
          bVibeChill: +(partyBrideVibe?.chill || 0),
          bVibeParty: +(partyBrideVibe?.party || 0),
          bVibeWellness: +(partyBrideVibe?.wellness || 0),
          editUsers: [userName || ""],
          guests: partyInfo?.numberOfGuests || 0,
        })
      )
        .unwrap()
        .then(({ partyUid }) => {
          dispatch(setActivePartyId(partyUid));
          navigate(`${RouteName.PARTY}/${partyUid}${RouteName.VENDOR}`);
        });
    }
  }, [currentStep, dispatch, navigate, partyBrideVibe, partyInfo]);

  return currentStep === Step.INFO ? (
    <>
      <PartyInfoForm
        variant="create"
        partyInfo={partyInfo}
        setPartyInfo={setPartyInfo}
        onSubmit={goToNextStep}
      />
      <DynamicInfoPopup
        open={!!isDownloadModalVisible}
        onClose={() => setIsDownloadModalVisible(false)}
        buttonText="Download Here!"
        title={
          <>
            Welcome to
            <br />
            BachPlace
          </>
        }
        text={
          <>
            <b>Click below to download your free<br />bachelorette planning kit!</b><br/>
            <u><b>Inside, you'll find:</b></u><br />
            Downloadable Planning Template<br />
            Fun bachelorette games to play<br />
            Tips and tricks on bachelorette planning<br />
            And so much more!
          </>
        }
      />
    </>
  ) : (
    <PartyBrideVibeForm
      variant="create"
      vibe={partyBrideVibe}
      setVibe={setPartyBrideVibe}
      onSubmit={goToNextStep}
    />
  );
};
