import { memo } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { AccessTime as ClockIcon } from "@mui/icons-material";

import { timeValues, defaultTimeValue } from "./time-values";

interface Props {
  value: string;
  onChange: (time: string) => void;
  disabled?: boolean;
  label?: string;
}

export const TimePicker = memo(({
  value,
  onChange,
  disabled,
  label = "Time",
}: Props) => {
  return (
    <FormControl fullWidth>
      <InputLabel disabled={disabled} id="select-time-label">
        {label}
      </InputLabel>
      <Select
        labelId="select-time-label"
        value={value}
        required
        label={label}
        defaultValue={defaultTimeValue}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        disabled={disabled}
        placeholder={label}
        inputProps={{ IconComponent: () => null }}
        endAdornment={<ClockIcon />}
        MenuProps={{
          sx: {
            "& .MuiMenu-paper": {
              maxHeight: 190,
            },
          },
        }}
      >
        {timeValues.map((time) => (
          <MenuItem key={time} value={time}>
            {time}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});
