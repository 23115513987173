import { Box, useTheme } from "@mui/material";

interface Props {
  open: boolean;
  onClick: () => void;
}

export function BurgerMenuIcon({ open, onClick }: Props) {
  const theme = useTheme();
  const mainColor = theme.palette.primary.main;

  return (
    <Box
      id="nav-icon"
      onClick={onClick}
      className={open ? "open" : ""}
      sx={{
        "&": {
          position: "relative",
          top: 0,
          width: 42,
          height: 32,
          cursor: 'pointer',
          zIndex: 99999,
        },
        "& span": {
          width: "100%",
          height: 6,
          background: mainColor,
          display: "block",
          transition: "all .25s ease-in-out",
          position: "absolute",
          borderRadius: 4,
          left: 0,
        },
        "& span:nth-child(1)": { top: 0 },
        "& span:nth-child(2),& span:nth-child(3)": { top: 12 },
        "& span:nth-child(4)": { top: 24 },
        "&.open span:nth-child(1)": { top: 12, width: 0, left: "50%" },
        "&.open span:nth-child(2)": {
          transform: "rotate(45deg)",
        },
        "&.open span:nth-child(3)": {
          transform: "rotate(-45deg)",
        },
        "&.open span:nth-child(4)": {
          top: 12,
          width: 0,
          left: "50%",
        },
      }}
    >
      <Box component="span"></Box>
      <Box component="span"></Box>
      <Box component="span"></Box>
      <Box component="span"></Box>
    </Box>
  );
}
