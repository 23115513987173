import { useRef } from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";

import { ReactComponent as CalendarIcon } from "src/design/images/dashboard-calendar.svg";
import { ReactComponent as LocationIcon } from "src/design/images/dashboard-location.svg";
import { ReactComponent as VibeIcon } from "src/design/images/dashboard-vibe.svg";
import ChillBackground from "src/design/images/chill-bachelorette-party-planning-bachplace.jpg";
import AdventureBackground from "src/design/images/adventure-bachelorette-party-planning-bachplace.jpg";
import PartyBackground from "src/design/images/party-bachelorette-party-planning-bachplace.jpg";
import WellnessBackground from "src/design/images/wellness-bachelorette-party-planning-bachplace.jpg";

import { useAppSelector } from "src/store";
import { formatDateRange } from "src/utils";
import { RouteName } from "src/types";
import { Link } from "src/components";

import { CountdownAll } from "./components";
import { useNavigate } from "react-router";

enum Vibe {
  CHILL = "Chill Vibe",
  ADVENTURE = "Adventure Vibe",
  PARTY = "Party Vibe",
  WELLNESS = "Wellness Vibe",
}

const backgroundImage = {
  [Vibe.CHILL]: ChillBackground,
  [Vibe.ADVENTURE]: AdventureBackground,
  [Vibe.PARTY]: PartyBackground,
  [Vibe.WELLNESS]: WellnessBackground,
};

export const DashboardPartyInfo = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);
  const { partyList, activePartyId } = useAppSelector(({ party }) => party);
  const activeParty = partyList?.find(
    ({ partyUid }) => activePartyId === partyUid
  );
  const textColor = theme.palette.primary.dark;
  const {
    name,
    startDate = "",
    endDate = "",
    location,
    bVibeChill = 0,
    bVibeAdv = 0,
    bVibeParty = 0,
    bVibeWellness = 0,
  } = activeParty || {};
  const maxVibeValue = Math.max(
    bVibeChill!,
    bVibeAdv!,
    bVibeParty!,
    bVibeWellness!
  );
  const bestVibeName = Object.entries({
    [Vibe.CHILL]: bVibeChill,
    [Vibe.ADVENTURE]: bVibeAdv,
    [Vibe.PARTY]: bVibeParty,
    [Vibe.WELLNESS]: bVibeWellness,
  }).find(([, v]) => v === maxVibeValue)?.[0] as Vibe;
  const displayedDateRange = formatDateRange(startDate, endDate);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      ref={ref}
      width={1}
      height={1}
      minHeight={334}
      borderRadius={10}
      overflow="hidden"
      sx={{
        position: "relative",
        outline: "none",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${backgroundImage[bestVibeName]})`,
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        flexDirection="column"
      >
        <Box display="flex">
          <Typography
            variant="body1"
            fontSize={60}
            lineHeight="70px"
            fontWeight={500}
            color={textColor}
            height={70}
            overflow="hidden"
          >
            {name}
          </Typography>
        </Box>
        {!activeParty ? (
          <Typography
            variant="body1"
            fontSize={30}
            fontWeight={600}
            color="#FFF"
            lineHeight="33px"
            ml={0.5}
          >
            No parties yet.{" "}
            <Link
              to={RouteName.CREATE_PARTY}
              sx={{
                lineHeight: "33px",
                fontSize: 30,
                fontWeight: 600,
                color: "#FFF",
                textDecoration: "underline",
              }}
            >
              Click here
            </Link>{" "}
            to create a party
          </Typography>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexWrap="wrap"
            whiteSpace="nowrap"
          >
            <Box display="flex" alignItems="center" mr={2}>
              <CalendarIcon width={32} height={32} />
              <Typography
                variant="body1"
                fontSize={30}
                fontWeight={600}
                color="#FFF"
                lineHeight="33px"
                ml={0.5}
              >
                {displayedDateRange}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" mr={2} whiteSpace="nowrap">
              <LocationIcon width={32} height={32} />
              <Typography
                variant="body1"
                fontSize={30}
                fontWeight={600}
                color="#FFF"
                lineHeight="33px"
                ml={0.5}
              >
                {location}
              </Typography>
            </Box>

            <Box display="flex" alignItems="center" mr={2} whiteSpace="nowrap">
              <VibeIcon width={32} height={32} />
              <Typography
                variant="body1"
                fontSize={30}
                fontWeight={600}
                color="#FFF"
                lineHeight="33px"
                ml={0.5}
              >
                {bestVibeName}
              </Typography>
            </Box>
          </Box>
        )}
        <Box my={2}>
          <CountdownAll deadline={startDate} />
        </Box>
        <Button
          variant="outlined"
          color="primary"
          sx={{
            background: "rgba(103, 127, 217, 0.44)",
            borderRadius: 2,
          }}
          style={{ borderWidth: 2 }}
          onClick={() => navigate(`${RouteName.PARTY}/${activePartyId}${RouteName.EDIT_PARTY}`)}
        >
          <Typography
            variant="body1"
            textTransform="none"
            sx={{ color: "#ced8ff", fontWeight: 800 }}
          >
            Edit Party
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};
