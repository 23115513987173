import { createAsyncThunk } from "@reduxjs/toolkit";

import { CartBooking, Pricing, VendorBooking } from "src/graphql";
import { bookingService, cartService, paymentService } from "src/services";

type FetchListPayload = Parameters<typeof cartService.fetchCartItemList>[0];
type CreateCartItemPayload = Parameters<
  typeof cartService.createNewCartItemBooking
>[0];
type UpdateCartItemPayload = Parameters<
  typeof cartService.updateCartItemBooking
>[0];
type RemoveCartItemPayload = Parameters<
  typeof cartService.removeCartItemBooking
>[0];
type BookAllCartItemsPayload = {
  cartItems: CartBooking[];
  userId: string;
  total: number;
  serviceFee: number;
  discount?: number;
};

export const fetchAllCartItems = createAsyncThunk(
  "cart/getAllCartItems",
  async ({ userName }: FetchListPayload) => {
    const cartItems = await cartService.fetchCartItemList({
      userName,
    });

    return cartItems;
  }
);

export const addCartItem = createAsyncThunk(
  "cart/addItemToCart",
  async (params: CreateCartItemPayload) => {
    const cartItem = await cartService.createNewCartItemBooking(params);

    return cartItem;
  }
);

export const updateCartItem = createAsyncThunk(
  "cart/updateCartItem",
  async (params: UpdateCartItemPayload) => {
    const cartItem = await cartService.updateCartItemBooking(params);

    return cartItem;
  }
);

export const removeCartItem = createAsyncThunk(
  "cart/removeItemFromCart",
  async ({ cartItemId, userId }: RemoveCartItemPayload) => {
    const cartItem = await cartService.removeCartItemBooking({
      cartItemId,
      userId,
    });

    return cartItem;
  }
);

export const bookAllCartItems = createAsyncThunk(
  "cart/bookAllCartItems",
  async ({
    cartItems,
    userId,
    total,
    serviceFee,
    discount = 0,
  }: BookAllCartItemsPayload) => {
    const results = await Promise.allSettled(
      cartItems.map((cartItem) => {
        cartService.removeCartItemBooking({
          cartItemId: cartItem.cartItemId,
          userId,
        });
        const { __typename, ...pricingRest } = cartItem.pricing?.[0] || {};

        return bookingService
          .saveBooking({
            createTime: new Date().toISOString(),
            createUser: userId,
            endTime: cartItem.endTime,
            guestsOrQuantity: cartItem.guestsOrQuantity,
            partyUid: cartItem.partyUid,
            startTime: cartItem.startTime,
            vendorId: cartItem.vendorId,
            vendorOfferUid: cartItem.vendorOfferUid,
            vendorTypeId: cartItem.vendorTypeId,
            totalPrice: cartItem.totalPrice,
            status: "pending",
            vendorOfferName: cartItem.vendorOfferName,
            addOns: cartItem.addons || [],
            address: cartItem.address || "",
            notes: cartItem.notes || "",
            pricingName: cartItem.pricing?.[0]?.name || "",
            pricingDescription: cartItem.pricing?.[0]?.description || "",
            pricing: [pricingRest as Pricing],
          })
          .then((item) => item)
          .catch((err) => new Error(err));
      })
    );

    const bookingIds = results
      .map(
        (item) =>
          item.status === "fulfilled" &&
          (item.value as VendorBooking)?.bookingId
      )
      .filter(Boolean) as string[];

    const totalWithDiscountAndFee =
      total + serviceFee - discount < 0 ? 0 : total + serviceFee - discount;

    await paymentService.createPaymentBooking({
      bachPaymentid: "",
      serviceFee,
      status: "pending",
      totalPrice: +(totalWithDiscountAndFee).toFixed(2),
      bookingIds,
    });

    const newCartItems = await cartService.fetchCartItemList({
      userName: userId,
    });

    const stripeRedirectUrl = await paymentService.initiateCartBookingsPayment({
      items: cartItems,
    });

    return { newCartItems, redirectUrl: stripeRedirectUrl };
  }
);
