import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, Paper, Typography, useTheme } from "@mui/material";

import { deletePoll, useAppDispatch } from "src/store";
import { useGetCurrentPartyInfo } from "src/hooks";
import { usePolls } from "src/services";

import { ViewPolls } from "./ViewPolls";
import { ModifyPolls } from "./ModifyPolls";

enum Mode {
  VIEW = "view",
  CREATE = "create",
  UPDATE = "update",
}

export function PollManagement() {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [currentMode, setCurrentMode] = useState(Mode.VIEW);
  const [updatedPollId, setUpdatedPollId] = useState("");
  const { isPartyLoading, activeParty, isAdmin } = useGetCurrentPartyInfo();
  const activePartyId = activeParty?.partyUid || "";
  const { items, isLoading: isPollLoading, answers } = usePolls(activePartyId);

  const isLoading = isPartyLoading || isPollLoading;
  const isViewMode = currentMode === Mode.VIEW;

  const updatedPoll = items.find((p) => p.pollId === updatedPollId);

  const handleInitiateUpdatePoll = useCallback((id: string) => {
    setUpdatedPollId(id);
  }, []);

  const handleDeletePoll = useCallback((id: string, partyUid: string) => {
    dispatch(deletePoll({ pollId: id, partyUid, }));
  }, []);

  const onSubmit = useCallback(() => {
    setCurrentMode(Mode.VIEW);
  }, []);

  useEffect(() => {
    setCurrentMode(updatedPollId ? Mode.UPDATE : Mode.VIEW);
  }, [updatedPollId]);

  useEffect(() => {
    if (currentMode === Mode.UPDATE) {
      return;
    }

    setUpdatedPollId("");
  }, [currentMode]);

  const componentByMode = useMemo(
    () => ({
      [Mode.VIEW]: (
        <ViewPolls
          items={items}
          answers={answers}
          isLoading={isLoading}
          onUpdate={handleInitiateUpdatePoll}
          onDelete={handleDeletePoll}
        />
      ),
      [Mode.CREATE]: <ModifyPolls isLoading={isLoading} onSubmit={onSubmit} />,
      [Mode.UPDATE]: (
        <ModifyPolls
          poll={updatedPoll}
          isLoading={isLoading}
          onSubmit={onSubmit}
        />
      ),
    }),
    [items, answers, isLoading, updatedPoll]
  );

  const onClick = () => {
    if (!isAdmin) {
      return;
    }

    setCurrentMode((prev) => (prev === Mode.VIEW ? Mode.CREATE : Mode.VIEW));
  };

  return isAdmin ? (
    <Box display="flex">
      <Paper
        elevation={6}
        sx={{
          padding: 3,
          mx: "auto",
          borderRadius: 13,
          width: "100%",
          alignSelf: "center",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Typography
            variant="h2"
            fontSize={18}
            fontWeight={600}
            fontFamily="Urbanist"
            pl={1}
            pt={1}
          >
            Polls Management
          </Typography>
          <Button
            variant="contained"
            onClick={onClick}
            sx={{
              color: "#FFF",
              textTransform: "none",
              borderRadius: 6,
              fontSize: 16,
              px: 2.5,
            }}
          >
            {isViewMode ? <>Add&nbsp;poll</> : "Cancel"}
          </Button>
        </Box>
        {componentByMode[currentMode]}
      </Paper>
    </Box>
  ) : null;
}
