import {
  CreateGiftPromoInput,
  GiftPromo,
  Mutation,
  UpdateGiftPromoInput,
  getApolloClient,
} from "src/graphql";
import { handleAuthError } from "src/utils";

import { CREATE_GIFT_CARD, UPDATE_GIFT_CARD } from "./mutations";
import { GET_GIFTCARD_BY_CODE } from "./queries";

class GiftcardService {
  get apolloClient() {
    return getApolloClient();
  }

  async createGiftcard({
    name,
    discountPrice,
    senderEmail,
    senderName,
    senderPhone,
    reciverEmail,
    reciverName,
    note,
  }: CreateGiftPromoInput): Promise<Mutation["createGiftPromo"]> {
    try {
      const response = await this.apolloClient.mutate({
        mutation: CREATE_GIFT_CARD,
        variables: {
          input: {
            discountPrice,
            senderEmail,
            senderName,
            senderPhone,
            reciverEmail,
            reciverName,
            note,
            gift: true,
            active: true,
            name,
          },
        },
      });

      const createdGiftcard = response?.data?.createGiftPromo;

      if (!createdGiftcard) {
        throw new Error("Can not create giftcard.");
      }

      return createdGiftcard;
    } catch (err) {
      handleAuthError(err);
      throw err;
    }
  }

  async updateGiftcard({
    active,
    giftPromoId,
  }: UpdateGiftPromoInput): Promise<Mutation["updateGiftPromo"]> {
    try {
      const response = await this.apolloClient.mutate({
        mutation: UPDATE_GIFT_CARD,
        variables: {
          input: {
            giftPromoId,
            active,
          },
        },
      });

      const updatedGiftcard = response?.data?.updateGiftPromo;

      if (!updatedGiftcard) {
        throw new Error("Can not update giftcard.");
      }

      return updatedGiftcard;
    } catch (err) {
      handleAuthError(err);
      throw err;
    }
  }

  async getGiftcardByCode(code: string) {
    try {
      const response = await this.apolloClient.query({
        query: GET_GIFTCARD_BY_CODE,
        variables: {
          code,
        },
        fetchPolicy: "network-only",
      });

      const giftcard = response?.data?.getGiftPromo_bycode as GiftPromo;

      if (!giftcard) {
        throw new Error("Can not get giftcard by code.");
      }

      return giftcard;
    } catch (err) {
      handleAuthError(err);
      throw err;
    }
  }
}

export const giftcardService = new GiftcardService();
