import { Box, Typography, useTheme } from "@mui/material";
import {
  CalendarMonthOutlined as CalendarIcon,
  PeopleAltOutlined as GuestsIcon,
} from "@mui/icons-material";

import { AddOns, CartBooking } from "src/graphql";
import { addUnits, formatDate } from "src/utils";
import { TRANSACTION_FEE_TOOLTIP_TEXT } from "src/types";
import { InfoTooltip } from "src/components";

import { getTotalPrice } from "../vendor/services";

interface Props {
  item: CartBooking;
}

export const CartItemDetails = ({ item }: Props) => {
  const theme = useTheme();
  const {
    pricingName,
    vendorOfferName,
    pricingDescription,
    startTime,
    endTime,
    address,
    guestsOrQuantity = 1,
    pricing,
  } = item;
  const {
    name,
    perGroup,
    price = 0,
    duration,
    description,
  } = pricing?.[0] || {};
  const endDate =
    startTime && duration
      ? addUnits(
          new Date(startTime)?.toISOString(),
          duration || 1,
          "hours"
        ).toISOString()
      : endTime;
  const textColor = theme.palette.secondary.light;
  const boldPrice = <b>${price.toFixed(2)}</b>;
  const displayedPriceSuffix = price ? `${perGroup ? "" : " per guest"}` : "";
  const displayedName = name || pricingName || vendorOfferName;
  const displayedDates = Array.from(
    new Set([
      startTime && formatDate(startTime, "MMM D"),
      endDate && formatDate(endDate, "MMM D"),
    ])
  )
    .filter(Boolean)
    .join(" - ");
  const title = (
    <Typography
      color={textColor}
      fontSize={18}
      pr={0.5}
      mb={0.8}
      lineHeight="20px"
      fontWeight={500}
    >
      {displayedName}
    </Typography>
  );
  const subtitle = (
    <Typography color="rgba(35, 35, 35, 0.5)" fontSize={16} lineHeight="20px">
      {description || pricingDescription || address}
    </Typography>
  );
  const addOns = (item?.addons as AddOns[]) || [];

  const packagePriceNum = price * (perGroup ? 1 : guestsOrQuantity!);
  const packagePrice = packagePriceNum.toFixed(2);
  const { serviceFee, total } = getTotalPrice(
    packagePriceNum,
    addOns.map((p) => ({ ...p, selected: true })),
    guestsOrQuantity!
  );

  return (
    <Box display="flex" flexDirection="column">
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        mb={4}
      >
        <Box display="flex" flexDirection="column" mr={1}>
          {title}
          {subtitle}
        </Box>
        <Typography
          color={textColor}
          fontSize={18}
          whiteSpace="nowrap"
          textAlign="right"
          lineHeight="20px"
        >
          {boldPrice}
          {displayedPriceSuffix}
        </Typography>
      </Box>

      <Box display="flex" mb={3.5}>
        <Box display="flex" flexDirection="column" mr={6}>
          <Typography
            fontSize={12}
            lineHeight="15px"
            fontWeight={600}
            textTransform="uppercase"
            mb={2}
          >
            Dates
          </Typography>
          <Typography
            display="flex"
            alignItems="center"
            fontSize={16}
            lineHeight="20px"
            fontWeight={400}
          >
            <CalendarIcon sx={{ fontSize: 24, height: 24, mr: 1 }} />
            {displayedDates}
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column">
          <Typography
            fontSize={12}
            lineHeight="15px"
            fontWeight={600}
            textTransform="uppercase"
            mb={2}
          >
            Guests
          </Typography>
          <Typography
            fontSize={16}
            lineHeight="20px"
            fontWeight={400}
            display="flex"
            alignItems="center"
          >
            <GuestsIcon sx={{ fontSize: 24, height: 24, mr: 1 }} />
            {guestsOrQuantity} guests
          </Typography>
        </Box>
      </Box>

      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={1.8}
        >
          <Typography fontSize={16} lineHeight="20px" fontWeight={400} mr={1.5}>
            {displayedName} - ${price} x {perGroup ? 1 : guestsOrQuantity}
          </Typography>
          <Typography fontSize={16} lineHeight="20px" fontWeight={400}>
            ${packagePrice}
          </Typography>
        </Box>
        {addOns.map(({ name, perPerson, price }) => (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1.8}
          >
            <Typography fontSize={16} lineHeight="20px" fontWeight={400}>
              {name} - ${price} x {perPerson ? guestsOrQuantity : 1}
            </Typography>
            <Typography fontSize={16} lineHeight="20px" fontWeight={400}>
              ${(price * (perPerson ? guestsOrQuantity || 1 : 1)).toFixed(2)}
            </Typography>
          </Box>
        ))}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={1.8}
        >
          <Typography
            fontSize={16}
            lineHeight="20px"
            fontWeight={400}
            display="flex"
            alignItems="center"
          >
            Transaction Fee
            <InfoTooltip text={TRANSACTION_FEE_TOOLTIP_TEXT} />
          </Typography>
          <Typography fontSize={16} lineHeight="20px" fontWeight={400}>
            ${serviceFee}
          </Typography>
        </Box>
        <hr />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={1.8}
        >
          <Typography fontSize={16} lineHeight="20px" fontWeight={400}>
            Total
          </Typography>
          <Typography fontSize={16} lineHeight="20px" fontWeight={600}>
            ${total}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
