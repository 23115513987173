import { useRef, useEffect } from "react";
import Tick from "@pqina/flip";
import "@pqina/flip/dist/flip.min.css";
import "./styles.css";

interface Props {
  value: string;
}

export const Countdown = ({ value }: Props) => {
  const divRef = useRef<HTMLDivElement>(null);
  const tickRef = useRef<Record<string, unknown>>();

  useEffect(() => {
    const didInit = (tick: Record<string, unknown>) => {
      tickRef.current = tick;
    };

    const currDiv = divRef.current;
    const tickValue = tickRef.current;
    Tick.DOM.create(currDiv, {
      value,
      didInit,
    });
    return () => Tick.DOM.destroy(tickValue);
  });

  useEffect(() => {
    if (tickRef.current) {
      tickRef.current.value = value;
    }
  }, [value]);

  return (
    <div ref={divRef} className="tick">
      <div data-repeat="true">
        <span data-view="flip">Tick</span>
      </div>
    </div>
  );
};
