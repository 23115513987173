import { Grid } from "@mui/material";
import { useParams } from "react-router";

import { PartyVendor, VendorTab } from "src/types";

import { GridItem } from "./GridItem";
import { GridItemSkeleton } from "./GridItemSkeleton";

interface Props {
  data: PartyVendor[];
  vendorType: VendorTab;
  isLoading?: boolean;
}

export function DataGrid({ data, vendorType, isLoading }: Props) {
  const { partyId } = useParams();

  return (
    <Grid container paddingLeft={0}>
      {isLoading
        ? Array(6).fill(null).map((_, i) => <GridItemSkeleton key={i} />)
        : data.map((item: PartyVendor) => (
            <GridItem
              item={item}
              key={item.vendorOfferUid}
              partyId={partyId || "unknown"}
              vendorType={vendorType}
            />
          ))}
    </Grid>
  );
}
