import { createSlice } from "@reduxjs/toolkit";

import { PartyVendor, VendorTab } from "src/types";
import { addOrReplace } from "src/utils";

import { fetchAllVendorsByType, fetchVendorByIdAndType } from "./vendorThunk";
import { changeActiveTab, selectVendorLocation } from "./vendorAction";

export interface VendorState {
  vendorsByType: { [key in VendorTab]: PartyVendor[] };
  isLoading: boolean;
  activeTab: VendorTab;
  selectedLocation: string;
}

const initialState: VendorState = {
  vendorsByType: {
    [VendorTab.ACCOMMODATION]: [],
    [VendorTab.ACTIVITIES]: [],
    [VendorTab.DINING]: [],
    [VendorTab.PARTY_FAVORS]: [],
  },
  isLoading: false,
  activeTab: VendorTab.ACTIVITIES,
  selectedLocation: "",
};

const vendorSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAllVendorsByType.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllVendorsByType.fulfilled, (state, action) => {
        state.isLoading = false;
        const type = action.meta.arg.type;
        state.vendorsByType[type] = action.payload;
      })
      .addCase(fetchAllVendorsByType.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(fetchVendorByIdAndType.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchVendorByIdAndType.fulfilled, (state, action) => {
        state.isLoading = false;
        const type = action.meta.arg.type;
        state.vendorsByType[type] = addOrReplace(
          state.vendorsByType[type],
          action.payload,
          "vendorOfferUid"
        );
      })
      .addCase(fetchVendorByIdAndType.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(changeActiveTab, (state, action) => {
        state.activeTab = action.payload;
      })

      .addCase(selectVendorLocation, (state, action) => {
        state.selectedLocation = action.payload;
      });
  },
});

export const vendorReducer = vendorSlice.reducer;
