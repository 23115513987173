import { ReactNode } from "react";
import { useNavigate } from "react-router";
import {
  Typography,
  ListItem,
  Chip,
  Box,
  useTheme,
  SxProps,
  useMediaQuery,
} from "@mui/material";

import { Link } from "../ui-kit";

interface Props {
  title: string;
  icon: ReactNode;
  link: string;
  disabled?: boolean;
  active?: boolean;
  sx?: SxProps;
  onClick?: CallableFunction;
}

export function MobileMenuItem({
  link,
  title,
  icon,
  disabled,
  active,
  sx = {},
  onClick,
}: Props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const matchesSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const mainColor = theme.palette.primary.main;
  const opacity = disabled ? 0.5 : 1;
  const activeOrDisabled = active || disabled;
  const handleClick = () => {
    if (!disabled) {
      onClick ? onClick() : navigate(link);
    }
  };
  const disabledMl = matchesSmall ? 2 : 12;

  return (
    <ListItem
      onClick={handleClick}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        pl: 3,
        cursor: disabled ? "default" : "pointer",
        py: 2,
        "&:hover div": {
          display: "flex",
          opacity: activeOrDisabled ? 1 : 0.5,
          transition: "all 0.2s",
        },
        ...sx,
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="center">
        <Link to={link}>
          <Typography
            variant="body1"
            fontSize={36}
            sx={{
              ml: disabled ? disabledMl : 2,
              opacity,
              color: active ? mainColor : "#818181",
              cursor: disabled ? "default" : "pointer",
            }}
          >
            {title}
            {disabled && (
              <Chip
                label="Coming soon"
                variant="outlined"
                sx={{
                  "&": {
                    display: "inline-flex !important",
                    height: "fit-content",
                    color: "#FFF",
                    ml: 1,
                  },
                  "& span": { p: 0.5, px: 1, fontSize: 10 },
                }}
              />
            )}
          </Typography>
        </Link>
      </Box>
    </ListItem>
  );
}
