import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
} from "@mui/material";

import {
  fetchAllParties,
  useAppDispatch,
  useAppSelector,
  setActivePartyId,
} from "src/store";
import { sessionStorage } from "src/services";
import { RouteName } from "src/types";

export const ActivePartySelect = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLoading, partyList, activePartyId } = useAppSelector(
    ({ party }) => party
  );
  const defaultPartyId = partyList?.[0]?.partyUid || "";
  const createPartyLabel = "Create Party";

  const handleChange = (event: SelectChangeEvent<string>) => {
    if (event.target.value === createPartyLabel) {
      return navigate(RouteName.CREATE_PARTY);
    }
    dispatch(setActivePartyId(event.target.value));
  };

  const addBodyClass = () => document.body.classList.add("body-visible");
  const removeBodyClass = () => document.body.classList.remove("body-visible");

  useEffect(() => {
    if (defaultPartyId) {
      dispatch(setActivePartyId(defaultPartyId));
    }

    return () => {
      removeBodyClass();
    };
  }, [defaultPartyId, dispatch]);

  useEffect(() => {
    const userName = sessionStorage.getUsername() ?? "";
    if (!activePartyId) {
      dispatch(fetchAllParties(userName));
    }
  }, [activePartyId, dispatch]);

  return isLoading && !partyList.length ? (
    <Skeleton
      height="100%"
      width="100%"
      sx={{ display: "flex", transform: "none" }}
    />
  ) : (
    <FormControl
      fullWidth
      sx={{
        "& fieldset": {
          border: "1px solid #e3e3e3 !important",
        },
      }}
    >
      <InputLabel id="select-party-label">Party</InputLabel>
      <Select
        labelId="select-party-label"
        value={activePartyId || defaultPartyId}
        label="Party"
        defaultValue={defaultPartyId}
        onChange={handleChange}
        placeholder="Choose party"
        onOpen={addBodyClass}
        onClose={removeBodyClass}
        sx={{
          "&": {
            height: 40,
          },
        }}
      >
        {partyList.map(({ name, partyUid }) => (
          <MenuItem key={name} value={partyUid}>
            {name}
          </MenuItem>
        ))}
        <MenuItem key="create-party" value={createPartyLabel}>
          {createPartyLabel}
        </MenuItem>
      </Select>
    </FormControl>
  );
};
