import {
  useState,
  createContext,
  useContext,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";

import { ClientProvider } from "src/types";

interface ApolloContextProps {
  clientName: ClientProvider;
  setClientName: Dispatch<SetStateAction<ClientProvider>>;
}

export const ApolloClientContext = createContext<ApolloContextProps>({
  clientName: "apollo",
  setClientName: () => {},
});

export const ApolloClientProvider = ({ children }: { children: ReactNode }) => {
  const [clientName, setClientName] = useState<ClientProvider>(
    "apollo" as const
  );

  return (
    <ApolloClientContext.Provider value={{ clientName, setClientName }}>
      {children}
    </ApolloClientContext.Provider>
  );
};

export const useApolloClient = () => useContext(ApolloClientContext);
