import { ReactNode } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";

interface Props {
  title: string;
  description: ReactNode;
  open?: boolean;
}

export const FAQItem = ({ title, description, open }: Props) => {
  return (
    <Accordion
      disableGutters
      defaultExpanded={open}
      sx={{
        backgroundColor: "transparent",
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
        py: 1.5,
        boxShadow: "none",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="body1" fontSize={20} fontWeight={400} color="#222">
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          variant="body1"
          color="#717171"
          fontSize={18}
          fontWeight={400}
          lineHeight="24px"
        >
          {description}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};
