import { PollAnswers, PollOptions } from "src/graphql";
import { PollVariant } from "../types";

interface Props {
  type: PollVariant;
  answers: string[];
  value: string;
  options: PollOptions[];
}

export const checkAnsweredByType = ({
  type,
  answers,
  value,
  options,
}: Props) => {
  const isOptionSelected = answers?.includes(value!);

  if (isOptionSelected) {
    return true;
  }

  if (type === PollVariant.Text) {
    const optionValues = options.map(({value}) => value);
    const isCustomOptionSelected =  answers?.some((ans) => !optionValues.includes(ans!));

    return isCustomOptionSelected;
  }

  return false;
};
