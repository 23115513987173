import { gql } from "@apollo/client";

export const FETCH_ACC_VENDOR_LIST = gql`
  query Items($filter: TableAccommodationVendorFilterInput) {
    listAccommodationVendors(filter: $filter) {
      items {
        address1
        city
        images
        name
        state
        thumbImage
        vendorId
        vendorOfferUid
        vendorTypeId
        location
        zip
        address2
        availUrl
        bookingUrl
        price
        preview
        time
        details
        meetingAndPickup
        cancellationPolicy
        vendorName
        rating
      }
    }
  }
`;

export const FETCH_DINE_VENDOR_LIST = gql`
  query Items($filter: TableDiningVendorFilterInput) {
    listDiningVendors(filter: $filter) {
      items {
        address1
        address2
        availUrl
        bookingUrl
        city
        cuisineType
        description
        images
        name
        location
        price
        preview
        time
        details
        meetingAndPickup
        cancellationPolicy
        vendorName
        rating
        state
        thumbImage
        vendorId
        vendorOfferUid
        vendorTypeId
        zip
        pricing {
          description
          duration
          name
          price
          perGroup
          minGuests
          maxGuests
        }
        addons {
          description
          name
          price
          perPerson
        }
      }
    }
  }
`;

export const FETCH_ACTIVITY_VENDOR_LIST = gql`
  query Items($filter: TableActivitiesVendorFilterInput) {
    listActivitiesVendors(filter: $filter) {
      items {
        address1
        address2
        availUrl
        bookingUrl
        city
        description
        images
        name
        price
        location
        preview
        time
        details
        meetingAndPickup
        cancellationPolicy
        vendorName
        rating
        state
        thumbImage
        vendorId
        vendorOfferUid
        vendorTypeId
        zip
        pricing {
          description
          name
          price
          perGroup
          duration
          minGuests
          maxGuests
        }
        addons {
          description
          name
          price
          perPerson
        }
      }
    }
  }
`;

export const FETCH_PARTY_FAVORS_VENDOR_LIST = gql`
  query Items($filter: TablePartyFavorsVendorFilterInput) {
    listPartyFavorsVendors(filter: $filter) {
      items {
        address1
        address2
        availUrl
        location
        bookingUrl
        city
        description
        images
        name
        price
        preview
        details
        meetingAndPickup
        cancellationPolicy
        vendorName
        rating
        state
        thumbImage
        vendorId
        vendorOfferUid
        vendorTypeId
        zip
        pricing {
          description
          name
          price
          perGroup
          duration
          minGuests
          maxGuests
        }
        addons {
          description
          name
          price
          perPerson
        }
      }
    }
  }
`;

export const FETCH_ACC_VENDOR_ById = gql`
  query Item($vendorOfferUid: ID!) {
    getAccommodationVendor(vendorOfferUid: $vendorOfferUid) {
      address1
      address2
      availUrl
      bookingUrl
      location
      city
      images
      name
      price
      preview
      time
      details
      meetingAndPickup
      cancellationPolicy
      vendorName
      rating
      state
      thumbImage
      vendorId
      vendorOfferUid
      vendorTypeId
      description
      zip
    }
  }
`;

export const FETCH_DINE_VENDOR_ById = gql`
  query Item($vendorOfferUid: ID!) {
    getDiningVendor(vendorOfferUid: $vendorOfferUid) {
      address1
      address2
      availUrl
      bookingUrl
      city
      cuisineType
      description
      location
      images
      name
      price
	    preview
	    time
	    details
	    meetingAndPickup
	    cancellationPolicy
      vendorName
      rating
      state
      thumbImage
      vendorId
      vendorOfferUid
      vendorTypeId
      zip
      pricing {
        description
        maxGuests
        minGuests
        name
        perGroup
        duration
        price
      }
      addons {
        description
        name
        price
        perPerson
      }
    }
  }
`;

export const FETCH_ACTIVITY_VENDOR_ById = gql`
  query Item($vendorOfferUid: ID!) {
    getActivitiesVendor(vendorOfferUid: $vendorOfferUid) {
      address1
      address2
      availUrl
      bookingUrl
      city
      description
      location
      images
      name
      price
	    preview
	    time
	    details
	    meetingAndPickup
	    cancellationPolicy
      vendorName
      rating
      state
      thumbImage
      vendorId
      vendorOfferUid
      vendorTypeId
      zip
      pricing {
        description
        maxGuests
        minGuests
        name
        perGroup
        duration
        price
      }
      addons {
        description
        name
        price
        perPerson
      }
    }
  }
`;

export const FETCH_PARTY_FAVORS_VENDOR_ById = gql`
  query Item($vendorOfferUid: ID!) {
    getPartyFavorsVendor(vendorOfferUid: $vendorOfferUid) {
      address1
      address2
      availUrl
      bookingUrl
      city
      description
      images
      name
      location
      price
	    preview
	    details
	    meetingAndPickup
	    cancellationPolicy
      vendorName
      rating
      state
      thumbImage
      vendorId
      vendorOfferUid
      vendorTypeId
      zip
      pricing {
        description
        maxGuests
        minGuests
        name
        perGroup
        duration
        price
      }
      addons {
        description
        name
        price
        perPerson
      }
    }
  }
`;
