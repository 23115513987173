import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import {
  DeleteOutlineOutlined as DeleteIcon,
  EditRounded as EditIcon,
} from "@mui/icons-material";

import { removeCartItem, useAppDispatch } from "src/store";
import { CartBooking } from "src/graphql";
import { sessionStorage } from "src/services";

import { CartItemCard } from "./CartItemCard";
import { CartItemDetails } from "./CartItemDetails";
import { RouteName, vendorNamesById } from "src/types";

interface Props {
  item: CartBooking;
  disabled: boolean;
}

export const CartItem = ({ item, disabled }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { cartItemId, partyUid, vendorOfferUid, vendorTypeId } = item;

  const handleDelete = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();
    const userName = sessionStorage.getUsername() || "";
    dispatch(
      removeCartItem({
        cartItemId,
        userId: userName,
      })
    );
  };

  const handleEdit = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    navigate(
      encodeURI(
        `${RouteName.PARTY}/${partyUid}${
          RouteName.VENDOR
        }/${vendorOfferUid}?type=${
          vendorNamesById[vendorTypeId - 1]
        }&editedItem=${cartItemId}`
      )
    );
  };

  return (
    <Box
      display="flex"
      maxWidth={760}
      borderBottom="1px solid #DDDDDD"
      mb={2}
      pb={5}
    >
      <Box display="flex" flexDirection="column" mr={2.5}>
        <CartItemCard item={item} />
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <Box
            onClick={handleEdit}
            px={2}
            sx={{
              "&:hover": {
                opacity: 0.9,
                cursor: "pointer",
              },
            }}
          >
            <Typography
              variant="body1"
              fontSize={16}
              fontWeight={600}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <EditIcon sx={{ marginRight: 1, height: 20, width: 20 }} /> Edit
            </Typography>
          </Box>
          <Box
            onClick={handleDelete}
            px={2}
            sx={{
              "&:hover": {
                opacity: 0.9,
                cursor: "pointer",
              },
            }}
          >
            <Typography
              variant="body1"
              fontSize={16}
              fontWeight={600}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <DeleteIcon sx={{ marginRight: 1, height: 20, width: 20 }} />{" "}
              Delete
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box pt={2.5} width={1}>
        <CartItemDetails item={item} />
      </Box>
    </Box>
  );
};
