import {
  PartyChecklistItem,
  Query,
  UpdatePartyChecklistItemInput,
  getApolloClient,
} from "src/graphql";
import { handleAuthError } from "src/utils";

import { UPDATE_CHEKCLIST_ITEM } from "./mutations";
import { FETCH_CHECKLIST_ITEMS } from "./queries";

interface GetChecklistItemsPayload {
  partyId: string;
}

class ChecklistService {
  get apolloClient() {
    return getApolloClient();
  }

  async updateChecklistItem({
    partyUid,
    checklistItemId,
    checked,
  }: UpdatePartyChecklistItemInput): Promise<Query["getPartyChecklistItem"]> {
    try {
      const response = await this.apolloClient.mutate({
        mutation: UPDATE_CHEKCLIST_ITEM,
        variables: {
          input: {
            partyUid,
            checklistItemId,
            checked,
          },
        },
      });

      const updatedItem = response?.data?.updatePartyChecklistItem;

      if (!updatedItem) {
        throw new Error("Can not update the party checklist item");
      }

      return updatedItem;
    } catch (err) {
      handleAuthError(err);
      throw err;
    }
  }

  async getAllPartyChecklistItems({ partyId }: GetChecklistItemsPayload) {
    try {
      const response = await this.apolloClient.query({
        query: FETCH_CHECKLIST_ITEMS,
        variables: {
          filter: {
            partyUid: {
              eq: partyId,
            },
          },
        },
        fetchPolicy: "network-only",
      });

      const checklistItems = response?.data?.listPartyChecklistItems
        ?.items as PartyChecklistItem[];

      if (!checklistItems) {
        throw new Error("Can not get party checklist items.");
      }

      return checklistItems;
    } catch (err) {
      handleAuthError(err);
      throw err;
    }
  }
}

export const checklistService = new ChecklistService();
