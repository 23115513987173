import { FormEvent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Grid,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import { registerCognitoUser, useOAuthForToken } from "src/services";
import { Link, SimpleInput } from "src/components";
import {
  RouteName,
  NAME_MAX_LENGTH,
  NAME_MIN_LENGTH,
  EMAIL_MIN_LENGTH,
  EMAIL_PATTERN,
  PHONE_PATTERN,
  PASSWORD_MIN_LENGTH,
  PASSWORD_MAX_LENGTH,
  PASSWORD_PATTERN,
  PASSWORD_PATTERN_MESSAGE,
  EMAIL_PATTERN_MESSAGE,
  PHONE_PATTERN_MESSAGE,
  PHONE_MASK,
  MAX_PHONE_LENGTH,
} from "src/types";
import { createMaskedPhone, validate } from "src/utils";
import { ReactComponent as GoogleIcon } from "src/design/images/google.svg";

import {
  AuthWithProviderButton,
  BackgroundPane,
  BottomGradient,
  ContentPane,
} from "./components";

interface ErrorState {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  password?: string;
}

export function SignUp() {
  const navigate = useNavigate();
  const { handler } = useOAuthForToken("signup");
  const [phone, setPhone] = useState("");
  const [isPolicyChecked, setIsPolicyChecked] = useState(false);
  const [maskedValue, setMaskedValue] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [errorState, setErrorState] = useState<ErrorState>({});

  const validatePassword = useCallback(() => {
    const { error: passwordError } = validate("Password", password, {
      required: true,
      minLength: PASSWORD_MIN_LENGTH,
      maxLength: PASSWORD_MAX_LENGTH,
      trimmed: true,
      pattern: {
        regExp: PASSWORD_PATTERN,
        message: PASSWORD_PATTERN_MESSAGE,
      },
    });

    setErrorState((prev) => ({
      ...prev,
      password: passwordError,
    }));

    return passwordError;
  }, [password, setErrorState]);

  useEffect(() => {
    validatePassword();
  }, [validatePassword]);

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const name = `${firstName} ${lastName}`;

    const { error: firstNameError } = validate("First Name", firstName, {
      required: true,
      minLength: NAME_MIN_LENGTH,
      maxLength: NAME_MAX_LENGTH,
    });
    const { error: lastNameError } = validate("Last Name", lastName, {
      required: true,
      minLength: NAME_MIN_LENGTH,
      maxLength: NAME_MAX_LENGTH,
    });
    const { error: phoneError } = validate("Phone", phone, {
      required: true,
      pattern: {
        regExp: PHONE_PATTERN,
        message: PHONE_PATTERN_MESSAGE,
      },
    });

    const { error: emailError } = validate("Email", email, {
      required: true,
      minLength: EMAIL_MIN_LENGTH,
      pattern: {
        regExp: EMAIL_PATTERN,
        message: EMAIL_PATTERN_MESSAGE,
      },
    });

    const passwordError = validatePassword();

    setErrorState({
      firstName: firstNameError,
      lastName: lastNameError,
      email: emailError,
      phone: phoneError,
      password: passwordError,
    });

    if (
      firstNameError ||
      lastNameError ||
      emailError ||
      phoneError ||
      passwordError
    ) {
      return;
    }

    try {
      await registerCognitoUser({
        email,
        phone,
        password,
        name,
      });
      toast.info(
        "Please check your email and click on the verification link.",
        { position: "bottom-left" }
      );
      navigate(RouteName.LOGIN);
    } catch (error: any) {
      toast.error(error?.message);
      if (error?.message?.toLowerCase()?.includes("password")) {
        setErrorState((prev) => ({ ...prev, password: error?.message || "" }));
      }
    }
  }

  const handlePhoneChange = (input: string) => {
    const phoneNumber = input
      .replaceAll(/[ -]/gi, "")
      .slice(0, MAX_PHONE_LENGTH);
    const lastChar = phoneNumber.at(-1) ?? "";
    const isValidNumber = lastChar.trim() !== "" && Number.isInteger(+lastChar);
    const isPlusFirst = phoneNumber.length === 1 && lastChar === "+";

    if (isValidNumber || isPlusFirst) {
      const maskedVal = createMaskedPhone(phoneNumber);
      setMaskedValue(maskedVal);
      setPhone(phoneNumber);
    }
  };

  const handleOAuthSignup = () => {
    handler();
  };

  return (
    <Grid
      container
      component="main"
      sx={{ minHeight: "100vh", position: "relative", overflowX: "hidden" }}
    >
      <ContentPane title="Create an Account">
        <AuthWithProviderButton
          text="Continue with Google"
          icon={<GoogleIcon width={30} height={30} />}
          onClick={handleOAuthSignup}
        />
        <Typography
          variant="body2"
          fontStyle="italic"
          fontWeight={400}
          fontSize={14}
          lineHeight="17px"
          textAlign="center"
          my={4.2}
        >
          OR
        </Typography>
        <Box width={1} component="form" noValidate onSubmit={handleSubmit}>
          <Box display="flex" marginBottom={1} justifyContent="space-between">
            <Box width={1} mr={1.25}>
              <SimpleInput
                value={firstName}
                onChange={(value) => setFirstName(value)}
                errorText={errorState.firstName}
                id="firstName"
                name="firstName"
                placeholder="First Name"
                autoComplete="given-name"
                autoFocus
              />
            </Box>
            <Box width={1} ml={1.25}>
              <SimpleInput
                value={lastName}
                onChange={(value) => setLastName(value)}
                errorText={errorState.lastName}
                id="lastName"
                name="lastName"
                placeholder="Last Name"
                autoComplete="family-name"
              />
            </Box>
          </Box>
          <Box width={1} my={2}>
            <SimpleInput
              value={email}
              onChange={(value) => setEmail(value)}
              errorText={errorState.email}
              id="email"
              name="email"
              placeholder="Email Address"
              autoComplete="email"
              type="email"
            />
          </Box>
          <Box width={1} mb={2}>
            <SimpleInput
              value={maskedValue}
              onChange={handlePhoneChange}
              errorText={errorState.phone}
              id="phone"
              name="phone"
              placeholder={PHONE_MASK}
              autoComplete="phone"
            />
          </Box>
          <Box width={1}>
            <SimpleInput
              value={password}
              onChange={(value) => setPassword(value)}
              errorText={errorState.password}
              id="password"
              name="password"
              placeholder="Password"
              autoComplete="new-password"
              type="password"
            />
          </Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={isPolicyChecked}
                onChange={() => setIsPolicyChecked(!isPolicyChecked)}
                color="success"
              />
            }
            label={
              <Typography variant="body1" color="#394460">
                Yes, I understand and agree to the privacy policy and cookie
                policy.
              </Typography>
            }
          />
          <Button
            type="submit"
            fullWidth
            disabled={!isPolicyChecked}
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              textTransform: "none",
              background:
                "linear-gradient(94.01deg, #B26E6F 0%, #FE9688 100.02%)",
              boxShadow: "0px 10px 50px rgba(254, 150, 136, 0)",
              borderRadius: 10,
              py: 1.5,
              fontSize: 16,
              color: "#FFF",
            }}
          >
            Create Account
          </Button>
          <Button
            type="button"
            fullWidth
            onClick={handleOAuthSignup}
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              textTransform: "none",
              background:
                "linear-gradient(94.01deg, #B26E6F 0%, #FE9688 100.02%)",
              boxShadow: "0px 10px 50px rgba(254, 150, 136, 0)",
              borderRadius: 10,
              py: 1.5,
              fontSize: 16,
              color: "#FFF",
            }}
          >
            Sign up with Google
          </Button>
          <Box id="google-box"></Box>
          <Box display="flex" justifyContent="center">
            <Link to={RouteName.LOGIN}>
              <Typography color="#394460">
                Already have an account?{" "}
                <b>
                  <u>Login here</u>
                </b>
              </Typography>
            </Link>
          </Box>
        </Box>
      </ContentPane>
      <BackgroundPane />
      <BottomGradient />
    </Grid>
  );
}
