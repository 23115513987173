import { createSlice } from "@reduxjs/toolkit";

import { VendorBooking } from "src/graphql";
import { fetchAllBookingsByPartyId, updateBooking } from "./bookingThunk";

export interface BookingState {
  bookingList: VendorBooking[];
  isLoading: boolean;
}

const initialState: BookingState = {
  bookingList: [],
  isLoading: false,
};

const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAllBookingsByPartyId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllBookingsByPartyId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.bookingList = action.payload;
      })
      .addCase(fetchAllBookingsByPartyId.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(updateBooking.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateBooking.fulfilled, (state, action) => {
        state.isLoading = false;
        const updatedBooking = action.payload;
        state.bookingList = state.bookingList.map((bk) =>
          bk.bookingId === updatedBooking?.bookingId ? updatedBooking : bk
        );
      })
      .addCase(updateBooking.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const bookingReducer = bookingSlice.reducer;
