import { useEffect } from "react";
import { Box, Divider } from "@mui/material";

import {
  formatToDate,
  getBareHours,
  getDateRange,
  getDaysDuration,
  isToday,
  isWithinDayRange,
  toDayjs,
} from "src/utils";
import {
  fetchAllBookingsByPartyId,
  fetchAllCalendarEventsByPartyId,
  fetchAllParties,
  useAppDispatch,
  useAppSelector,
} from "src/store";
import {
  mapBookingToEvent,
  mapCalendarEvent,
  sessionStorage,
  wrapWithCalendarUIAttrs,
} from "src/services";
import { CalendarEvent, VendorTypeId } from "src/types";

import { DayTimeline } from "./DayTimeline";
import { CalendarColumn } from "./CalendarColumn";

export const Calendar = () => {
  const dispatch = useAppDispatch();
  const {
    booking,
    party,
    calendar: { calendarEvents },
  } = useAppSelector(({ booking, party, calendar }) => ({
    booking,
    party,
    calendar,
  }));
  const [{ bookingList }, { activePartyId: partyId, partyList }] = [
    booking,
    party,
  ];
  const defaultDate = new Date().toISOString();

  const selectedParty =
    partyList.find(({ partyUid }) => partyId === partyUid)! || {};
  const durationDays = getDaysDuration(
    selectedParty?.startDate || defaultDate,
    selectedParty?.endDate || defaultDate
  );

  useEffect(() => {
    const userName = sessionStorage.getUsername() ?? "";
    if (partyId) {
      dispatch(fetchAllBookingsByPartyId({ partyId, userName }));
      dispatch(fetchAllCalendarEventsByPartyId({ partyId }));
    } else {
      dispatch(fetchAllParties(userName));
    }
  }, [partyId, dispatch]);

  const filteredBookings = bookingList.filter(
    ({ startTime, vendorTypeId }) =>
      startTime &&
      isWithinDayRange(
        startTime,
        durationDays,
        selectedParty?.startDate || defaultDate
      ) &&
      vendorTypeId !== VendorTypeId.PARTY_FAVORS
  );
  const displayedBookings = mapBookingToEvent(filteredBookings);

  const filteredCustomEvents = calendarEvents.filter(
    ({ startTime }) =>
      startTime &&
      isWithinDayRange(
        startTime,
        durationDays,
        selectedParty?.startDate || defaultDate
      )
  );
  const displayedCustomEvents = mapCalendarEvent(filteredCustomEvents);
  const allEvents = [...displayedBookings, ...displayedCustomEvents];

  const allEventsWithAttrs = wrapWithCalendarUIAttrs(allEvents);
  const startHour = allEvents.length
    ? Math.min(
        ...allEvents.map(({ startTime }) => new Date(startTime!).getHours())
      )
    : toDayjs(new Date()).hour();

  const formattedStartHour = getBareHours(new Date().setHours(startHour));
  const displayedDates = getDateRange(selectedParty.startDate, durationDays);
  const eventsByStartDate = allEventsWithAttrs.reduce((acc, booking) => {
    const formattedDate = formatToDate(booking.startTime || new Date());
    acc[formattedDate] = [...(acc[formattedDate] || []), booking];
    return acc;
  }, {} as Record<string, Array<CalendarEvent & { width: number; position: number }>>);

  return (
    <Box display="flex" maxHeight="60vh" overflow="auto">
      <Box display="flex" height={1} width={1}>
        <DayTimeline startHour={formattedStartHour} />
        {displayedDates.map((date) => (
          <Box display="flex" key={date.toISOString()} flexGrow={1}>
            <CalendarColumn
              date={date}
              events={eventsByStartDate[formatToDate(date.toDate())] || []}
              isToday={isToday(date)}
              startHour={startHour}
            />
            <Divider orientation="vertical" sx={{ height: "100%" }} flexItem />
          </Box>
        ))}
      </Box>
    </Box>
  );
};
