import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";

interface Props {
  anchorEl: HTMLElement | null;
  setAnchorEl: CallableFunction;
  onDelete: () => void;
}

export function CalendarMenu({ anchorEl, onDelete, setAnchorEl }: Props) {
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={handleClose}
      TransitionComponent={Fade}
    >
      <MenuItem onClick={onDelete}>Delete event</MenuItem>
    </Menu>
  );
}
