import { gql } from "@apollo/client";

export const SAVE_BOOKING = gql`
  mutation createVendorBooking($input: CreateVendorBookingInput!) {
    createVendorBooking(input: $input) {
      bookingId
    }
  }
`;

export const UPDATE_BOOKING = gql`
  mutation updateVendorBooking($input: UpdateVendorBookingInput!) {
    updateVendorBooking(input: $input) {
      addons {
        description
        name
        perPerson
        price
      }
      address
      bookingId
      createTime
      createUser
      endTime
      guestsOrQuantity
      notes
      partyUid
      pricing {
        description
        duration
        maxGuests
        minGuests
        name
        perGroup
        price
      }
      pricingDescription
      pricingName
      startTime
      status
      totalPrice
      vendorId
      vendorOfferName
      vendorOfferUid
      vendorTypeId
    }
  }
`;
