import { PollAnswers } from "src/graphql";

export const groupAnswersByPolls = (answers: PollAnswers[]) => {
  const answersByPoll = answers.reduce((acc, answer) => {
    return {
      ...acc,
      [answer.pollId]: [...(acc[answer.pollId] || []), answer],
    };
  }, {} as Record<string, PollAnswers[]>);

  return answersByPoll;
};
