import { Fragment } from "react";
import { RadioGroup } from "@mui/material";
import { RadioOption, TextOption } from "../options";
import { PollOptions } from "src/graphql";

interface Props {
  options: PollOptions[];
  selectedOptions: string[];
  setSelectedOptions: CallableFunction;
  customAnswer: string;
  setCustomAnswer: CallableFunction;
}

export const RadioPoll = ({
  options,
  setSelectedOptions,
  customAnswer,
  selectedOptions,
  setCustomAnswer,
}: Props) => {
  return (
    <RadioGroup>
      {options.map(({ value, text, type }) => (
        <Fragment key={value}>
          <RadioOption
            text={text || ''}
            value={value || ''}
            key={value}
            onClick={() => setSelectedOptions([value])}
            selected={selectedOptions.includes(value!)}
          />
          {type === "text" && (
            <TextOption
              text={text || ''}
              value={customAnswer}
              setValue={setCustomAnswer}
              visible={selectedOptions.includes(value!)}
            />
          )}
        </Fragment>
      ))}
    </RadioGroup>
  );
};
