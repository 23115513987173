import { useEffect } from "react";
import { Outlet, useLocation, useRevalidator } from "react-router-dom";
import { Box, useMediaQuery, useTheme } from "@mui/material";

import { AppBarContent, Footer } from "src/components";
import { MOBILE_BREAKPOINT, routePageNameMapper } from "src/types";

const getPageName = (currentRoute: string) => {
  const routeEntries = Array.from(routePageNameMapper.entries());
  for (let [regExp, pageName] of routeEntries) {
    const isPageMatches = regExp.test(currentRoute);
    if (isPageMatches) {
      return pageName;
    }
  }
  return "";
};

export const Layout = () => {
  const location = useLocation();
  const theme = useTheme();
  const matchesSmall = useMediaQuery(theme.breakpoints.down(MOBILE_BREAKPOINT));
  const { revalidate } = useRevalidator();

  const pageName = getPageName(location.pathname);
  const bgColor = theme.palette.primary.dark;

  useEffect(() => {
    revalidate();
  }, [location.pathname, revalidate]);

  return (
    <Box display="flex" bgcolor={bgColor} width={1}>
      <AppBarContent pagename={pageName} />
      <Box
        component="main"
        pt={matchesSmall ? 14 : 11}
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light" ? bgColor : theme.palette.grey[900],
          flexGrow: 1,
        }}
      >
        <Outlet />
        <Footer />
      </Box>
    </Box>
  );
};
