import { ReactNode } from "react";
import {
  Paper,
  Box,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";

import Logo from "src/design/images/Primary_Logo.png";
import { ReactComponent as SerpentineViolet } from "src/design/images/serpentine-violet.svg";
import { ReactComponent as Star } from "src/design/images/star.svg";

interface Props {
  children: ReactNode;
  title: string;
}

export const ContentPane = ({ children, title }: Props) => {
  const theme = useTheme();
  const matchesSmall = useMediaQuery(theme.breakpoints.down(700));

  return (
    <Grid
      item
      position="relative"
      xs={12}
      sm={matchesSmall ? 12 : 6}
      md={6}
      component={Paper}
      elevation={6}
      square
      sx={{ backgroundColor: "#FE9688" }}
    >
      <Box
        position="relative"
        zIndex={4}
        sx={{
          px: 6,
          py: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box alignSelf="flex-start" mb="10%">
          <img src={Logo} alt="BachPlace" height={46} />
        </Box>
        <Box px={3}>
          <Typography
            component="h1"
            variant="h1"
            fontWeight={400}
            fontFamily="GFS Didot"
            alignSelf="flex-start"
            color="#394460"
            fontSize={54}
            mb={2.5}
          >
            {title}
          </Typography>
          <Typography
            variant="body1"
            alignSelf="flex-start"
            color="#394460"
            mb={5}
          >
            Our <b>BachPlace</b> Platform is dedicated to creating unforgettable
            and customized experiences for the bride-to-be and her squad.
          </Typography>
          {children}
        </Box>
        <Box height={30} />
      </Box>
      <Box position="absolute" left={0} top="70%" zIndex={3}>
        <SerpentineViolet />
      </Box>
      <Box position="absolute" right={150} top={100} zIndex={3}>
        <Star />
      </Box>
      <Box
        position="absolute"
        top="-55%"
        right="-90%"
        width="180%"
        height="110%"
        sx={{
          background: "#FFFFFF",
          filter: "blur(200px)",
        }}
      />
    </Grid>
  );
};
