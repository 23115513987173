import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { Box, Typography } from "@mui/material";
import {
  ArrowForwardIos as NextIcon,
  ArrowBackIos as PrevIcon,
} from "@mui/icons-material";

import { ModalBase } from "src/components";

interface Props {
  selectedImageIndex: number | null;
  setSelectedImageIndex: Dispatch<SetStateAction<number | null>>;
  images: string[];
}

const THROTTLE_MS = 500;

export function ImageModal({
  selectedImageIndex,
  setSelectedImageIndex,
  images,
}: Props) {
  const throttleRef = useRef<{
    timeout: NodeJS.Timeout | string;
    isEnabled: boolean;
    isOpened: boolean;
  }>({
    timeout: "",
    isEnabled: true,
    isOpened: false,
  });

  const throttle = useCallback((func: CallableFunction) => {
    return (...params: unknown[]) => {
      if (!throttleRef.current.isEnabled || !throttleRef.current.isOpened) {
        return;
      }

      throttleRef.current.isEnabled = false;
      func(...params);
      const timeoutId = setTimeout(
        () => (throttleRef.current.isEnabled = true),
        THROTTLE_MS
      );
      throttleRef.current.timeout = timeoutId;
    };
  }, []);

  const onNextClick = useMemo(
    () =>
      throttle(() => {
        setSelectedImageIndex(
          (prevIndex) => ((prevIndex || 0) + 1) % images.length
        );
      }),
    [throttle, images.length, setSelectedImageIndex]
  );

  const onPrevClick = useMemo(
    () =>
      throttle(() => {
        setSelectedImageIndex(
          (prevIndex) => ((prevIndex || 0) - 1 + images.length) % images.length
        );
      }),
    [setSelectedImageIndex, throttle, images.length]
  );

  const isPrevHidden = selectedImageIndex === 0;
  const isNextHidden = selectedImageIndex === images?.length - 1;
  const timeoutRef = throttleRef.current.timeout;

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      switch (event.key) {
        case "ArrowRight":
          onNextClick();
          break;
        case "ArrowLeft":
          onPrevClick();
          break;
        default:
          break;
      }
    },
    [onPrevClick, onNextClick]
  );

  const handleWheel = useCallback(
    (event: WheelEvent) => {
      if (event.deltaY > 0) {
        onNextClick();
      } else {
        onPrevClick();
      }
    },
    [onNextClick, onPrevClick]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("wheel", handleWheel);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("wheel", handleWheel);
      clearTimeout(timeoutRef);
    };
  }, [images.length, timeoutRef, handleWheel, handleKeyDown]);

  useEffect(() => {
    throttleRef.current.isOpened = selectedImageIndex !== null;
  }, [selectedImageIndex]);

  return (
    <ModalBase
      open={selectedImageIndex !== null}
      onClose={() => setSelectedImageIndex(null)}
      width="90%"
    >
      <Box
        display="flex"
        height="inherit"
        position="relative"
        flexDirection="column"
        padding={2}
      >
        <img
          width="fit-content"
          style={{ objectFit:"contain" }}
          height="100%"
          src={images?.[selectedImageIndex || 0]}
          alt="Asset is deleted"
        />
        {!isNextHidden && (
          <Box
            display="flex"
            position="absolute"
            right={16}
            top="50%"
            alignItems="center"
            justifyContent="center"
            onClick={onNextClick}
            width={20}
            height={20}
            sx={{
              fontSize: 16,
              background: "#FFF",
              borderRadius: "50%",
              cursor: "pointer",
            }}
          >
            <NextIcon fontSize="inherit" />
          </Box>
        )}
        {!isPrevHidden && (
          <Box
            display="flex"
            position="absolute"
            left={16}
            top="50%"
            alignItems="center"
            onClick={onPrevClick}
            justifyContent="center"
            width={20}
            height={20}
            sx={{
              fontSize: 16,
              background: "#FFF",
              borderRadius: "50%",
              cursor: "pointer",
            }}
          >
            <PrevIcon fontSize="inherit" sx={{ mr: -0.5 }} />
          </Box>
        )}
        <Typography
          variant="body1"
          fontSize={18}
          sx={{ margin: "auto", marginTop: 1 }}
        >
          {(selectedImageIndex || 0) + 1} / {images.length}
        </Typography>
      </Box>
    </ModalBase>
  );
}
