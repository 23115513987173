import { ReactNode } from "react";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

interface Props {
  text: string;
  onClick: () => void;
  icon: ReactNode;
  disabled?: boolean;
}

export function UserMenuItem({ text, onClick, icon, disabled = false }: Props) {
  return (
    <ListItem key={text} disablePadding>
      <ListItemButton disabled={disabled} onClick={onClick}>
        <ListItemIcon sx={{ paddingRight: 1.5, minWidth: "auto" }}>
          {icon}
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItemButton>
    </ListItem>
  );
}
