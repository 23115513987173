import dayjs from "dayjs";
import {
  Box,
  Button,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import { useAppSelector } from "src/store";
import { VendorBooking } from "src/graphql";
import { TimePicker } from "src/components";

interface Props {
  open: boolean;
  handleClose: () => void;
}

export const BookingEventDetailsModal = ({ open, handleClose }: Props) => {
  const { selectedEvent } = useAppSelector(({ calendar }) => calendar);
  const {
    notes,
    startTime,
    endTime,
    guestsOrQuantity,
    address,
    pricingDescription,
    pricingName,
    vendorOfferName,
  } = selectedEvent as VendorBooking;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        outline: "none",
      }}
    >
      <Paper
        elevation={6}
        sx={{ padding: 3, borderRadius: 4, mx: 3, my: 2, minWidth: "50%" }}
      >
        <Typography variant="h5" mb={3}>
          Event Details
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width={1}
        >
          <Box display="flex" justifyContent="space-between" width={1} mb={2}>
            <TextField
              fullWidth
              label="Name"
              variant="outlined"
              disabled
              value={pricingName || vendorOfferName || "Event Name"}
              placeholder="Event Name"
              sx={{ mr: 1 }}
            />
            <TextField
              fullWidth
              type="number"
              label="Guests or Quantity"
              variant="outlined"
              disabled
              value={guestsOrQuantity || 1}
              placeholder="Guests or quantity"
            />
          </Box>
          <TextField
            fullWidth
            label="Description"
            variant="outlined"
            value={pricingDescription || "No description provided"}
            placeholder=""
            disabled
            multiline
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Notes"
            value={notes || "No notes provided"}
            placeholder="Notes"
            disabled
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Address"
            value={address || "No address provided"}
            placeholder="Address"
            disabled
            sx={{ mb: 2 }}
          />
          <Box display="flex" justifyContent="space-between" mb={2} width={1}>
            <DatePicker
              value={startTime}
              onChange={() => {}}
              disabled
              renderInput={(props) => (
                <TextField {...props} sx={{ marginRight: 2 }} fullWidth />
              )}
              label="Start Date"
            />
            <TimePicker
              value={dayjs(startTime)?.format("h:mm A") || ""}
              onChange={() => {}}
              disabled
              label="Start Time"
            />
          </Box>
          <Box display="flex" justifyContent="space-between" mb={2} width={1}>
            <DatePicker
              value={endTime}
              onChange={() => {}}
              disabled
              minDate={startTime}
              renderInput={(props) => (
                <TextField {...props} sx={{ marginRight: 2 }} fullWidth />
              )}
              label="End Date"
            />
            <TimePicker
              value={endTime ? dayjs(endTime)?.format("h:mm A") : ""}
              onChange={() => {}}
              disabled
              label="End Time"
            />
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Button
            variant="contained"
            sx={{
              my: 1.5,
              color: "#FFF",
              textTransform: "none",
              borderRadius: 6,
              fontSize: 18,
              px: 3,
              mr: 2,
            }}
            onClick={handleClose}
          >
            Okay
          </Button>
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{
              my: 1.5,
              textTransform: "none",
              borderRadius: 6,
              fontSize: 18,
              px: 3,
            }}
          >
            Cancel
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
};
