import { createSlice } from "@reduxjs/toolkit";

import {
  createGiftcard,
  getGiftcardByCode,
  updateGiftcard,
} from "./giftcardThunk";
import { GiftPromo } from "src/graphql";
import { selectGiftcard } from "./giftcardAction";

export interface GiftcardState {
  isLoading: boolean;
  selectedGiftcard: GiftPromo | null;
}

const initialState: GiftcardState = {
  isLoading: false,
  selectedGiftcard: null,
};

const giftcardSlice = createSlice({
  name: "giftcard",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(createGiftcard.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createGiftcard.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createGiftcard.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(getGiftcardByCode.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getGiftcardByCode.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getGiftcardByCode.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(selectGiftcard, (state, action) => {
        state.selectedGiftcard = action.payload;
      })

      .addCase(updateGiftcard.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateGiftcard.fulfilled, (state, action) => {
        const updatedItem = action.payload;

        state.isLoading = false;
        state.selectedGiftcard = null;
      })
      .addCase(updateGiftcard.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const giftcardReducer = giftcardSlice.reducer;
