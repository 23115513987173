import { gql } from "@apollo/client";

export const FETCH_LOCATION_LIST = gql`
query Items($filter: TableLocationFilterInput) {
  listLocations(filter: $filter) {
    items {
      location
      active
    }
  }
}
`;
