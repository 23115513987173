import AWS from "aws-sdk/global";
import {
  CognitoUserPool,
  CognitoUser,
  CognitoUserSession,
  CognitoIdToken,
  CognitoAccessToken,
  CognitoRefreshToken,
} from "amazon-cognito-identity-js";

import { sessionStorage } from "../storage/sessionStorage";

AWS.config.region = "us-east-1";

const poolData = {
  UserPoolId: process.env.REACT_APP_USER_POOL_ID ?? "", // Your user pool id here
  ClientId: process.env.REACT_APP_CLIENT_ID ?? "", // Your client id here
};

const userPool = new CognitoUserPool(poolData);

export async function getIsAuthenticatedWithGoogle() {
  const userName = sessionStorage.getUsername() || "";
  const user = new CognitoUser({
    Username: userName,
    Pool: userPool,
  });

  const isAuthenticated: boolean = await new Promise(async (resolve) => {
    user.getSession((error: null, session: CognitoUserSession) => {
      if (error) {
        return resolve(false);
      }

      return resolve(session.isValid());
    });
  });
  return isAuthenticated;
}


interface TokensPayload {
  idToken: string;
  accessToken: string;
  refreshToken: string;
}

export const createSessionForGoogleUser = ({
  idToken,
  accessToken,
  refreshToken,
}: TokensPayload) => {
  const idTokenCognito = new CognitoIdToken({ IdToken: idToken });
  const accessTokenCognito = new CognitoAccessToken({
    AccessToken: accessToken,
  });
  const refreshTokenCognito = new CognitoRefreshToken({
    RefreshToken: refreshToken,
  });
  const session = new CognitoUserSession({
    IdToken: idTokenCognito,
    AccessToken: accessTokenCognito,
    RefreshToken: refreshTokenCognito,
  });

  const payload = idTokenCognito.decodePayload();
  const userName: string = payload?.email || "";
  // const fullName: string = payload?.name || "";

  const user = new CognitoUser({
    Username: userName,
    Pool: userPool,
  });

  sessionStorage.setUser(user);
  user.setSignInUserSession(session);
};
