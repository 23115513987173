import { Box, Typography } from "@mui/material";

interface Props {
  selected: boolean;
  setSelected: () => void;
  price: number;
}

export const PriceButton = ({ selected, setSelected, price }: Props) => {
  return (
    <Box
      border={1}
      onClick={setSelected}
      borderColor={selected ? "black" : "#DBDBDB"}
      display="flex"
      borderRadius={40}
      py={1}
      px={3}
      sx={{ cursor: "pointer" }}
      mr={1}
    >
      <Typography variant="body1" fontWeight={600}>
        ${price}
      </Typography>
    </Box>
  );
};
