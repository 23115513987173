import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";

import GirlsImage from "src/design/images/girls.png";
import { ReactComponent as SerpentinePink } from "src/design/images/serpentine-pink.svg";

export const BackgroundPane = () => {
  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.down("md"));
  const matchesSmall = useMediaQuery(theme.breakpoints.down(700));

  return (
    <Grid
      item
      position="relative"
      xs={false}
      sm={matchesSmall ? 12 : 6}
      md={6}
      sx={{
        backgroundImage: `url(${GirlsImage})`,
        backgroundRepeat: "no-repeat",
        width: "100%",
        backgroundColor: (t) =>
          t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
        backgroundSize: "cover",
        backgroundPosition: "top right 10%",
        minHeight: matchesSmall ? 600 : undefined,
      }}
    >
      <Box
        width={1}
        height="95%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="flex-end"
        alignSelf="flex-end"
      >
        <Typography
          fontWeight={450}
          fontFamily="GFS Didot"
          alignSelf="flex-start"
          color="#394460"
          fontSize={matchesMd ? 50 : 70}
          left="6%"
          position="relative"
          zIndex={2}
        >
          Let's get this
        </Typography>
        <Typography
          fontWeight={450}
          fontFamily="GFS Didot"
          color="#394460"
          fontSize={matchesMd ? 50 : 70}
          alignSelf="flex-end"
          position="relative"
          right="6%"
          zIndex={2}
        >
          party started!
        </Typography>
      </Box>
      <Box position="absolute" left={-30} top="45%">
        <SerpentinePink />
      </Box>
    </Grid>
  );
};
