import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

import { partyReducer } from "./party";
import { bookingReducer } from "./booking";
import { locationReducer } from "./location";
import { vendorReducer } from "./vendor";
import { cartReducer } from "./cart";
import { calendarReducer } from "./calendar";
import { checklistReducer } from "./checklist";
import { giftcardReducer } from "./giftcard";
import { pollReducer } from "./poll";

export const store = configureStore({
  reducer: {
    party: partyReducer,
    vendor: vendorReducer,
    booking: bookingReducer,
    location: locationReducer,
    cart: cartReducer,
    calendar: calendarReducer,
    checklist: checklistReducer,
    giftcard: giftcardReducer,
    poll: pollReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
