import { gql } from "@apollo/client";

export const CREATE_CART_ITEM_BOOKING = gql`
  mutation createCartItemBooking($input: CreateCartBookingInput!) {
    createCartBooking(input: $input) {
      addons {
        description
        name
        perPerson
        price
      }
      address
      cartItemId
      createTime
      pricing {
        description
        duration
        name
        price
        perGroup
        minGuests
        maxGuests
      }
      endTime
      guestsOrQuantity
      notes
      partyUid
      pricingDescription
      pricingName
      startTime
      thumbImage
      totalPrice
      userId
      vendorId
      vendorOfferName
      vendorOfferUid
      vendorTypeId
    }
  }
`;

export const UPDATE_CART_ITEM_BOOKING = gql`
  mutation updateCartItemBooking($input: UpdateCartBookingInput!) {
    updateCartBooking(input: $input) {
      addons {
        description
        name
        perPerson
        price
      }
      address
      cartItemId
      createTime
      pricing {
        description
        duration
        name
        price
        perGroup
        minGuests
        maxGuests
      }
      endTime
      guestsOrQuantity
      notes
      partyUid
      pricingDescription
      pricingName
      startTime
      thumbImage
      totalPrice
      userId
      vendorId
      vendorOfferName
      vendorOfferUid
      vendorTypeId
    }
  }
`;

export const REMOVE_CART_ITEM_BOOKING = gql`
  mutation deleteCartItemBooking($input: DeleteCartBookingInput!) {
    deleteCartBooking(input: $input) {
      cartItemId
    }
  }
`;
