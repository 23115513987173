import { Dayjs } from "dayjs";
import {
  AccommodationVendor,
  ActivitiesVendor,
  Calendar,
  DiningVendor,
  PartyFavorsVendor,
  VendorBooking,
} from "src/graphql";

export enum SessionKeys {
  TOKEN = "userToken",
  USERNAME = "userName",
  FULLNAME = "fullName",
  CART_ITEMS = "cartItems",
  GOOGLE_ID_TOKEN = "googleIdToken",
  GUEST_CREDENTIALS = "guestCredentials",
  IS_FROM_LOGIN = 'isFromLogin',
}

export enum RouteName {
  // auth
  SIGN_UP = "/signup",
  LOGIN = "/login",
  RESET_PASSWORD = "/reset-password",

  // party
  PARTY = "/party",
  CREATE_PARTY = "/party/create",
  EDIT_PARTY = "/edit",

  // booking
  MY_BOOKINGS = "/my-bookings",

  // vendor
  VENDOR = "/vendors",
  ALL_VENDORS = "/all-vendors",

  // legal
  PRIVACY = "/privacy",
  TERMS = "/terms",

  // contact
  CONTACT = "/contact",

  // cart
  CART = "/cart",

  // calendar
  CALENDAR = "/calendar",

  // checklist
  CHECKLIST = "/checklist",

  // dashboard
  DASHBOARD = "/dashboard",

  // giftcard
  GIFTCARD = "/giftcard",

  // guests
  MY_GUESTS = "/my-guests",
}

export enum VendorTab {
  ACCOMMODATION = "Accommodation",
  ACTIVITIES = "Activities",
  DINING = "Dining",
  PARTY_FAVORS = "Party Favors",
}

export type PartyVendor =
  | ActivitiesVendor
  | AccommodationVendor
  | DiningVendor
  | PartyFavorsVendor;

export type DateValue = string | Date | number | Dayjs;

export type CalendarEvent = {
  startTime: VendorBooking["startTime"];
  endTime: VendorBooking["endTime"];
  name: Calendar["name"];
  description: Calendar["description"];
  __typename?: Calendar["__typename"] | VendorBooking["__typename"];
  allDay: Calendar["allDay"];
} & Omit<Partial<VendorBooking>, "__typename">;

export enum VendorTypeId {
  ACCOMMODATION = 1,
  DINNING = 2,
  ACTIVITY = 3,
  PARTY_FAVORS = 4,
}

export const vendorNamesById = [
  VendorTab.ACCOMMODATION,
  VendorTab.DINING,
  VendorTab.ACTIVITIES,
  VendorTab.PARTY_FAVORS,
];

export type ClientProvider = "apollo" | "guest";
