import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  BachParty,
  BachPartyInvite,
  CreateBachPartyInput,
  CreateBachPartyInviteInput,
  DeleteBachPartyInviteInput,
  UpdateBachPartyInput,
  UpdateBachPartyInviteInput,
} from "src/graphql";
import { partyService } from "src/services";
import { invitesService } from "src/services/invites";

export const fetchAllParties = createAsyncThunk(
  "party/getAllParties",
  async (userName: string) => {
    const parties = (await partyService.fetchPartyList(
      userName
    )) as BachParty[];
    return parties;
  }
);

export const createNewParty = createAsyncThunk(
  "party/createNewParty",
  async (newBachPartyInput: CreateBachPartyInput) => {
    const newParty = await partyService.createParty(newBachPartyInput);

    return newParty;
  }
);

export const updateParty = createAsyncThunk(
  "party/updateParty",
  async (input: UpdateBachPartyInput) => {
    const updatedParty = await partyService.updateParty(input);

    return updatedParty;
  }
);

export const fetchAllPartyInvites = createAsyncThunk(
  "party/invite/getAllInvites",
  async (partyUid: string) => {
    const invites = (await invitesService.fetchAllPartyInvites({
      partyUid,
    })) as BachPartyInvite[];
    return invites;
  }
);

export const createNewInvite = createAsyncThunk(
  "party/invite/createNewPartyInvite",
  async (input: CreateBachPartyInviteInput) => {
    const invite = await invitesService.createPartyInvite(input);

    return invite;
  }
);

export const deleteInvite = createAsyncThunk(
  "party/invite/deleteInvite",
  async (input: DeleteBachPartyInviteInput) => {
    const invite = await invitesService.deletePartyInvite(input);

    return invite;
  }
);

export const updateInvite = createAsyncThunk(
  "party/invite/updateInvite",
  async (input: UpdateBachPartyInviteInput) => {
    const invite = await invitesService.updatePartyInvite(input);

    return invite;
  }
);
