import { Grid } from "@mui/material";

interface Props {
  setSelectedImageIndex: CallableFunction;
  images: string[];
}

export function ExtendedCarousel({ images, setSelectedImageIndex }: Props) {
  const onImageClick = (i: number) => {
    setSelectedImageIndex(i);
  };

  return (
    <Grid container spacing={1.75}>
      {images.length > 0 && (
        <Grid item xs={images[1] ? 6 : 12}>
          <img
            src={images[0]}
            onClick={() => onImageClick(0)}
            alt={images[0]}
            style={{
              width: "100%",
              height: "100%",
              cursor: "pointer",
              borderTopLeftRadius: 20,
              borderBottomLeftRadius: 20,
            }}
          />
        </Grid>
      )}
      <Grid container item xs={6} sm={6} md={6} spacing={1.75}>
        {!!images[2] && (
          <Grid item xs={images[3] ? 6 : 12}>
            <img
              src={images[1]}
              onClick={() => onImageClick(1)}
              alt={images[1]}
              style={{
                width: "100%",
                height: "100%",
                cursor: "pointer",
                [!images[3] ? "borderTopRightRadius" : ""]: 20,
              }}
            />
          </Grid>
        )}
        {!!images[3] && (
          <Grid item xs={6}>
            <img
              src={images[3]}
              onClick={() => onImageClick(3)}
              alt={images[3]}
              style={{
                width: "100%",
                height: "100%",
                cursor: "pointer",
                borderTopRightRadius: 20,
              }}
            />
          </Grid>
        )}
        {!!images[2] && (
          <Grid item xs={images[4] ? 6 : 12}>
            <img
              src={images[2]}
              onClick={() => onImageClick(2)}
              alt={images[2]}
              style={{
                width: "100%",
                height: "100%",
                cursor: "pointer",
                [!images[4] ? "borderBottomRightRadius" : ""]: 20,
              }}
            />
          </Grid>
        )}
        {!!images[4] && (
          <Grid item xs={6}>
            <img
              src={images[4]}
              onClick={() => onImageClick(4)}
              alt={images[4]}
              style={{
                width: "100%",
                height: "100%",
                cursor: "pointer",
                borderBottomRightRadius: 20,
              }}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
