import { PHONE_MASK, US_PHONE_CODE } from "src/types";

export const createMaskedPhone = (input: string) => {
  const [firstChar, secondChar, ...other] = input.split("");

  if (firstChar !== "+" && firstChar !== US_PHONE_CODE) {
    return `+${US_PHONE_CODE} ${firstChar}`;
  }

  if (secondChar !== US_PHONE_CODE) {
    return `+${US_PHONE_CODE} `;
  }

  const maskWithNumbers = other.reduce((acc, currChar) => {
    return acc.replace("X", currChar);
  }, PHONE_MASK);

  const maskedPhone = maskWithNumbers.replaceAll(/[-X]+$/g, "");

  return maskedPhone;
};