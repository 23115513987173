import { TextField } from "@mui/material";
import { ChangeEvent, HTMLInputTypeAttribute } from "react";

interface Props {
  value: string;
  onChange: (value: string) => void;
  errorText?: string;
  id?: string;
  placeholder?: string;
  name?: string;
  autoComplete?: string;
  type?: HTMLInputTypeAttribute;
  autoFocus?: boolean;
}

export function SimpleInput({
  value,
  onChange,
  errorText,
  id,
  name,
  placeholder,
  autoComplete,
  type = "text",
  autoFocus = false,
}: Props) {
  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    onChange(target.value);
  };

  return (
    <TextField
      required
      fullWidth
      variant="outlined"
      value={value}
      type={type}
      onChange={handleChange}
      error={!!errorText}
      helperText={errorText || ""}
      id={id}
      placeholder={placeholder}
      name={name}
      autoComplete={autoComplete}
      autoFocus={autoFocus}
      inputProps={{
        style: {
          borderRadius: 30,
          backgroundColor: "#FFF",
          paddingLeft: 20,
          fontFamily: type === "password" ? "GFS Didot" : "Urbanist",
        },
      }}
      sx={{
        border: "none",
        "& fieldset": { border: "none" },
      }}
    />
  );
}
