import {
  Box,
  IconButton,
  LinearProgress,
  Popover,
  Typography,
  useTheme,
} from "@mui/material";
import { Groups as GroupsIcon } from "@mui/icons-material";

import { PollAnswers, PollOptions } from "src/graphql";
import { useState } from "react";

interface Props {
  answers: PollAnswers[];
  withAnswers?: boolean;
  options?: PollOptions[];
}

export const PollResultAnswers = ({ answers, withAnswers, options }: Props) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const textColor = theme.palette.secondary.light;
  const borderColor = theme.palette.primary.light;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const colonComponent = withAnswers ? ": " : "";
  const notCustomAnswers = options?.map((o) => o.value) || [];

  return (
    <>
      <IconButton style={{ width: 20, height: 20 }} onClick={handleClick}>
        <GroupsIcon color="primary" />
      </IconButton>
      <Popover
        id={!!anchorEl ? "simple-popover" : undefined}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorPosition={{ top: 200, left: 400 }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        PaperProps={{ style: { borderRadius: 12 } }}
      >
        <Box
          px={1.5}
          py={1}
          borderRadius={3}
          sx={{ border: `1px solid ${borderColor}` }}
        >
          {answers.map((ans) => (
            <Typography
              key={ans.pollAnswerId}
              variant="body1"
              fontSize={16}
              color={textColor}
            >
              <b>{ans.userId + colonComponent}</b>
              <i>
                {withAnswers
                  ? `${
                      ans.answers
                        ?.filter((a) => !notCustomAnswers.includes(a))
                        ?.join(", ") || "-"
                    }`
                  : ""}
              </i>
            </Typography>
          ))}
        </Box>
      </Popover>
    </>
  );
};
