import { Box, Typography, useTheme } from "@mui/material";

import { DateValue } from "src/types";
import { getSimpleDate, getWeekDay } from "src/utils";

interface Props {
  isToday?: boolean;
  date: DateValue;
}

export const CalendarDate = ({ isToday = false, date }: Props) => {
  const theme = useTheme();

  const textColor = theme.palette.primary.light;
  const dateStr = getSimpleDate(date);
  const weekDayName = getWeekDay(date);

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box
        mb={1.5}
        sx={{
          backgroundColor: "#F4F4F4",
          padding: "12px 40px",
          borderRadius: 40,
        }}
      >
        <Typography variant="body1" fontWeight={isToday ? 700 : 500}>
          {weekDayName}
        </Typography>
      </Box>
      <Typography
        variant="body1"
        fontWeight={isToday ? 700 : 500}
        color={textColor}
      >
        {dateStr}
      </Typography>
    </Box>
  );
};
