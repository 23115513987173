import { ReactNode } from "react";
import { Modal, Box, Typography, Button, IconButton } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

import ModalBackgroundImg from "src/design/images/ModalBackground.png";

interface Props {
  open: boolean;
  onClose: () => void;
  onClick?: () => void;
  buttonText: string;
  title: ReactNode;
  text: ReactNode;
}

export const SimpleInputPopup = ({
  open,
  onClose,
  onClick = onClose,
  buttonText,
  title,
  text,
}: Props) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      onBackdropClick={onClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "none",
        outline: "none",
      }}
    >
      <Box
        sx={{
          position: "relative",
          outline: "none",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top center",
          backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), url(${ModalBackgroundImg})`,
        }}
        display="flex"
        justifyContent="center"
        textAlign="center"
        width={395}
        height={425}
        borderRadius={3}
      >
        <Typography
          variant="h5"
          color="#FFF"
          fontSize={48}
          fontWeight={500}
          mt={8}
          lineHeight="52px"
        >
          {title}
        </Typography>
        <Box
          position="absolute"
          display="flex"
          flexDirection="column"
          alignItems="center"
          height={200}
          width={1}
          bgcolor="#FFF"
          bottom={0}
          borderRadius="0 0 12px 12px"
          textAlign="center"
          padding={5.5}
          py={3.8}
        >
          <Typography
            variant="body1"
            color="#000"
            fontSize={18}
            lineHeight="22px"
            fontWeight={400}
            mb={2}
          >
            {text}
          </Typography>
          <Button
            variant="contained"
            onClick={onClick}
            sx={{
              "&": {
                my: 1.5,
                color: "#FFF",
                backgroundColor: "#677FD9",
                padding: "21px 24px 20px",
                textTransform: "none",
                borderRadius: 20,
                fontWeight: 400,
                fontSize: 16,
                width: "100%",
                lineHeight: "19px",
              },
              "&:hover": {
                backgroundColor: "#677FD9",
                opacity: 0.95,
              },
            }}
          >
            {buttonText}
          </Button>
        </Box>
        <IconButton
          onClick={onClose}
          sx={{
            "&": {
              position: "absolute",
              top: 20,
              right: 20,
              width: 24,
              height: 24,
              borderRadius: "50%",
              background: "rgba(255, 255, 255, 0.3)",
            },
            "&:hover": {
              background: "rgba(255, 255, 255, 0.3)",
              opacity: 0.9,
            },
          }}
        >
          <CloseIcon htmlColor="#FFF" sx={{ width: 12, height: 12 }} />
        </IconButton>
      </Box>
    </Modal>
  );
};
