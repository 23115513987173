import { ReactNode } from "react";
import { Box } from "@mui/material";

interface IconWrapperProps {
  children: ReactNode;
  propName: "fill" | "stroke";
  isActive?: boolean;
  color: string;
  sx?: any
}

export function IconWrapper({
  children,
  propName,
  isActive,
  color,
  sx = {},
}: IconWrapperProps) {
  return (
    <Box
      sx={{
        "&": { p: 0, display: "flex", ...sx },
        "& svg *": {
          [propName]: isActive ? "#FFF" : color,
        },
      }}
    >
      {children}
    </Box>
  );
}
