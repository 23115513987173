import { gql } from "@apollo/client";

export const CREATE_PARTY_INVITE = gql`
  mutation createBachPartyInvite($input: CreateBachPartyInviteInput!) {
    createBachPartyInvite(input: $input) {
      deleted
      email
      inviteId
      name
      partyUid
      phone
      status
    }
  }
`;

export const DELETE_PARTY_INVITE = gql`
  mutation deleteBachPartyInvite($input: DeleteBachPartyInviteInput!) {
    deleteBachPartyInvite(input: $input) {
      deleted
      email
      inviteId
      name
      partyUid
      phone
      status
    }
  }
`;

export const UPDATE_PARTY_INVITE = gql`
  mutation updateBachPartyInvite($input: UpdateBachPartyInviteInput!) {
    updateBachPartyInvite(input: $input) {
      deleted
      email
      inviteId
      name
      partyUid
      phone
      status
    }
  }
`;
