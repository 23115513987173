import { ChangeEvent, useCallback, useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { DeleteOutlineOutlined as DeleteIcon } from "@mui/icons-material";

import { PollOptions } from "src/graphql";

interface Props {
  label?: string;
  value?: string;
  isCustomAnswer?: boolean;
  index: number;
  onChange: (index: number, payload: Partial<PollOptions>) => void;
  onDelete?: (index: number) => void;
  onChangeCustom: (index: number, value: boolean) => void;
}

export function PollOptionManagement({
  label = "",
  value = "",
  isCustomAnswer = false,
  index,
  onChangeCustom,
  onChange,
  onDelete,
}: Props) {
  const [isTouched, setIsTouched] = useState(false);

  const handleLabelChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      onChange(index, {
        text: target.value,
        value: isTouched ? value : toValue(target.value),
      });
    },
    [index, isTouched, value]
  );

  const handleValueChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      if (!isTouched) {
        setIsTouched(true);
      }
      onChange(index, { value: target.value });
    },
    [index, isTouched]
  );

  const handleChangeCustom = useCallback(() => {
    onChangeCustom(index, !isCustomAnswer);
  }, [index, isCustomAnswer]);

  const handleDelete = useCallback(() => {
    onDelete?.(index);
  }, [index]);

  return (
    <Box display="flex" alignItems="center" mb={1}>
      <TextField
        id="label"
        label="Label"
        variant="standard"
        value={label}
        onChange={handleLabelChange}
        sx={{ mr: 1 }}
      />
      <TextField
        id="value"
        label="Value"
        variant="standard"
        value={value}
        onChange={handleValueChange}
        sx={{ mr: 1 }}
      />
      <FormControlLabel
        control={
          <Checkbox checked={isCustomAnswer} onChange={handleChangeCustom} />
        }
        label={
          <Typography variant="body2" fontSize={14}>
            Text answer
          </Typography>
        }
        sx={{ mr: 1 }}
      />
      {!!onDelete && (
        <IconButton onClick={handleDelete}>
          <DeleteIcon color="error" />
        </IconButton>
      )}
    </Box>
  );
}

function toValue(value: string) {
  const convertedValue =
    value?.toLocaleLowerCase()?.trim()?.replaceAll(/\s/g, "_") || "";

  return convertedValue;
}
