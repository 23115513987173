interface ValidationConfig {
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  pattern?: {
    regExp: RegExp,
    message: string;
  },
  trimmed?: boolean;
}

export const isRequired = (value = '') => !!value?.trim()?.length;

export const isTrimmed = (value = '') => value?.trim()?.length === value?.length;

export const isLengthValid = (value = '', minLength = 0, maxLength = Infinity) =>
  value.length >= minLength && value.length <= maxLength;

export const isMatchesPattern = (value = '', regExp: RegExp) => regExp.test(value);

export const validate = (fieldLabel: string, value = '', { required, minLength, maxLength, pattern, trimmed }: ValidationConfig) => {
  if (required && !isRequired(value)) {
    return { error: `${fieldLabel} is required.` };
  }

  if (trimmed && !isTrimmed(value)) {
    return { error: `${fieldLabel} sholdn't be surrounded by spaces.` };
  }

  if ((minLength || maxLength) && !isLengthValid(value, minLength, maxLength)) {
    const lengthRange = [minLength, maxLength].filter(Boolean).join('-');
    return { error: `${fieldLabel} has to be ${lengthRange} characters inclusive.` };
  }

  if (pattern && !isMatchesPattern(value, pattern.regExp)) {
    return { error: pattern.message };
  }

  return {};
}
