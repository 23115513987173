import { ReactNode, useEffect, useState } from "react";
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import ReactGA from "react-ga";
import { ApolloProvider } from "@apollo/client";
import { LicenseInfo } from "@mui/x-license-pro";
import { createTheme, ThemeProvider, CssBaseline } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  ApolloClientProvider,
  getApolloClient,
  useApolloClient,
} from "src/graphql";
import { store } from "src/store";
import teal_theme from "src/design/theme/teal";

import { router } from "./routes";

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID || "");
const theme = createTheme(teal_theme(null));
LicenseInfo.setLicenseKey(
  "9238d5ee503043bb93e7ca6293a680b7Tz03NDEyMyxFPTE3MjU2MTcyMjUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

const ApolloWrapper = ({ children }: { children: ReactNode }) => (
  <ApolloClientProvider>{children}</ApolloClientProvider>
);

const AppContent = () => {
  const { clientName } = useApolloClient();
  const [client, setClient] = useState(getApolloClient());

  useEffect(() => {
    setClient(getApolloClient());
  }, [clientName]);

  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <CssBaseline />
            <RouterProvider router={router} />
            <ToastContainer position="bottom-right" theme="colored" />
          </LocalizationProvider>
        </ThemeProvider>
      </Provider>
    </ApolloProvider>
  );
};

export const App = () => (
  <ApolloWrapper>
    <AppContent />
  </ApolloWrapper>
);
