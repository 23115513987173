import ReactGA from "react-ga";

export const trackEvent = ({
  category,
  action,
  label,
  value,
}: ReactGA.EventArgs) => {
  ReactGA.event({
    category,
    action,
    label,
    value,
  });
};

export const trackRouteEvent = async (routeName: string) => {
  trackEvent({
    category: "route",
    action: `visit ${routeName}`,
    label: `Visit route ${routeName}`,
    value: 1,
  });

  return null;
};
