import { useCallback, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { RouteName } from "src/types";

import { createSessionForGoogleUser } from "./googleAuthProvider";

const clientId = process.env.REACT_APP_CLIENT_ID || "";
const baseUrl = process.env.REACT_APP_BASE_COGNITO_URL;
const tokenEndpoint = `${baseUrl}/oauth2/token`;

export const useOAuthForToken = (type: "login" | "signup") => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const code = params?.get("code") || "";

  const getUserTokens = useCallback(async () => {
    try {
      const res = await fetch(tokenEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: `grant_type=authorization_code&client_id=${clientId}&code=${code}&redirect_uri=https%3A%2F%2F${window.location.hostname}%2F${type}`,
      });
      if (!res.ok) {
        return;
      }
      const {
        access_token: accessToken,
        refresh_token: refreshToken,
        id_token: idToken,
      } = await res.json();
      createSessionForGoogleUser({ accessToken, refreshToken, idToken });
      navigate(RouteName.DASHBOARD);
    } catch (error) {
      console.error("Error while getting OAuth token", error);
    }
  }, [code, type, navigate]);

  useEffect(() => {
    if (code) {
      getUserTokens();
    }
  }, [code, getUserTokens]);

  const handler = () => {
    window.location.replace(
      `${baseUrl}/oauth2/authorize?client_id=${clientId}&identity_provider=Google&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https%3A%2F%2F${window.location.hostname}%2F${type}`
    );
  };

  return {
    handler,
    code,
  };
};
