import { useEffect } from "react";
import { useRevalidator } from "react-router";
import {
  Drawer as MuiDrawer,
  IconButton,
  List,
  styled,
  useTheme,
  Box,
} from "@mui/material";
import {
  ChevronLeft as ChevronLeftIcon,
  AccountBalanceWalletOutlined as BudgetIcon,
  ExitToApp as SignOutIcon,
  HistoryOutlined as BookingsHistoryIcon,
  CardGiftcardRounded as CardGiftcardRoundedIcon,
  Download as DownloadIcon,
} from "@mui/icons-material";

import { BLOG_URL, DRAWER_WIDTH, PLANNING_KIT_URL, RouteName } from "src/types";
import { sessionStorage, signOutCognitoUser } from "src/services";
import { fetchAllParties, useAppDispatch, useAppSelector } from "src/store";

import { ReactComponent as Logo } from "src/design/images/BachPlace_Primary_Logo.svg";
import { ReactComponent as BlogIcon } from "src/design/images/blog.svg";
import { ReactComponent as BookingIcon } from "src/design/images/booking.svg";
import { ReactComponent as CalendarIcon } from "src/design/images/calendar.svg";
import { ReactComponent as ChecklistIcon } from "src/design/images/checklist.svg";
import { ReactComponent as DashboardIcon } from "src/design/images/dashboard.svg";
import { ReactComponent as GuestsIcon } from "src/design/images/my-guests.svg";

import { CartIcon } from "../cart";
import { IconWrapper } from "../ui-kit";
import { SidebarItem } from "./SidebarItem";

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: DRAWER_WIDTH,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

interface Props {
  isOpen: boolean;
  toggleOpen: () => void;
  pagename: string;
}

export function Sidebar({ isOpen = false, toggleOpen, pagename }: Props) {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { revalidate } = useRevalidator();
  const [defaultParty] = useAppSelector(({ party }) => party.partyList);

  const partyId = defaultParty?.partyUid;
  const bgColor = theme.palette.primary.dark;
  const iconColor = theme.palette.primary.light;
  const redirectLink = partyId
    ? `${RouteName.PARTY}/${partyId}${RouteName.VENDOR}`
    : RouteName.PARTY;
  const isAuthenticated = !!sessionStorage.getToken();

  const handleSignOut = () => {
    signOutCognitoUser();
    revalidate();
  };

  useEffect(() => {
    if (!partyId) {
      const userName = sessionStorage.getUsername() ?? "";
      dispatch(fetchAllParties(userName));
    }
  }, [partyId, dispatch]);

  return isOpen ? (
    <Drawer
      variant="permanent"
      open={isOpen}
      PaperProps={{ sx: { borderRadius: "0 30px 30px 0" }, elevation: 4 }}
      sx={{ background: bgColor }}
    >
      <>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            px: 1,
            py: [1],
            mb: 6,
          }}
        >
          <IconButton
            onClick={toggleOpen}
            sx={{ borderRadius: 3, pl: 1, pr: 2.8, mt: 3 }}
          >
            <ChevronLeftIcon />
            <Logo width={130} height={42} />
          </IconButton>
        </Box>
        <List component="nav" sx={{ position: "sticky", top: 0 }}>
          <SidebarItem
            title="Dashboard"
            link={RouteName.DASHBOARD}
            icon={
              <IconWrapper
                isActive={pagename === "Parties" || pagename === "Dashboard"}
                propName="stroke"
                color={iconColor}
              >
                <DashboardIcon />
              </IconWrapper>
            }
            active={pagename === "Parties" || pagename === "Dashboard"}
          />
          <SidebarItem
            title="Booking"
            link={redirectLink}
            icon={
              <IconWrapper
                isActive={pagename === "Browse"}
                propName="stroke"
                color={iconColor}
              >
                <BookingIcon />
              </IconWrapper>
            }
            active={pagename === "Browse"}
          />
          <SidebarItem
            title="My Bookings"
            link={RouteName.MY_BOOKINGS}
            icon={<BookingsHistoryIcon />}
            active={pagename === "My Bookings"}
          />
          <SidebarItem
            title="Checklist"
            link={RouteName.CHECKLIST}
            icon={
              <IconWrapper
                isActive={pagename === "Party Checklist"}
                propName="stroke"
                color={iconColor}
              >
                <ChecklistIcon />
              </IconWrapper>
            }
            active={pagename === "Party Checklist"}
          />
          <SidebarItem
            title="Gift Card"
            link={RouteName.GIFTCARD}
            icon={<CardGiftcardRoundedIcon />}
            active={pagename === "Gift Card"}
          />
          <SidebarItem
            title="Planning Kit"
            link={RouteName.CREATE_PARTY}
            icon={<DownloadIcon />}
            onClick={() => {
              window.open(PLANNING_KIT_URL, "_blank", "noreferrer");
            }}
            active={pagename === "Planning Kit"}
          />
          <SidebarItem
            title="My Guests"
            link={RouteName.MY_GUESTS}
            icon={
              <IconWrapper
                isActive={pagename === "My Guests"}
                propName="fill"
                color={iconColor}
              >
                <GuestsIcon />
              </IconWrapper>
            }
            active={pagename === "My Guests"}
          />
          <SidebarItem
            title="Inspiration"
            link={RouteName.PARTY}
            onClick={() => {
              window.open(BLOG_URL, "_blank", "noreferrer");
            }}
            icon={
              <IconWrapper isActive={false} propName="fill" color={iconColor}>
                <BlogIcon />
              </IconWrapper>
            }
          />
          <SidebarItem
            title="Calendar"
            link={RouteName.CALENDAR}
            icon={
              <IconWrapper
                isActive={pagename === "Calendar"}
                propName="fill"
                color={iconColor}
                sx={{ p: 0.5 }}
              >
                <CalendarIcon />
              </IconWrapper>
            }
            active={pagename === "Calendar"}
          />
          <SidebarItem
            title="Budget"
            link={RouteName.PARTY}
            icon={<BudgetIcon />}
            disabled
          />
          <SidebarItem
            title="Cart"
            link={RouteName.CART}
            icon={<CartIcon />}
            active={pagename === "Cart"}
          />

          {isAuthenticated && (
            <SidebarItem
              title="Log out"
              link={RouteName.PARTY}
              icon={<SignOutIcon />}
              active
              onClick={handleSignOut}
              sx={{
                "&": { mt: 3.5 },
                "& div": {
                  background: "transparent",
                  color: theme.palette.primary.main,
                },
              }}
            />
          )}
        </List>
      </>
    </Drawer>
  ) : null;
}
