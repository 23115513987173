import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { createGiftcard, useAppDispatch, useAppSelector } from "src/store";
import Giftcard1 from "src/design/images/giftcard1.png";
import Giftcard2 from "src/design/images/giftcard2.png";
import Giftcard3 from "src/design/images/giftcard3.png";
import Giftcard4 from "src/design/images/giftcard4.png";
import Giftcard5 from "src/design/images/giftcard5.png";
import Giftcard6 from "src/design/images/giftcard6.png";

import { PriceButton } from "./PriceButton";

const priceOptions = [100, 200, 300, 500, 1000];
const images = [
  <img src={Giftcard1} width="100%" alt="Giftcard1" />,
  <img src={Giftcard2} width="100%" alt="Giftcard2" />,
  <img src={Giftcard3} width="100%" alt="Giftcard3" />,
  <img src={Giftcard4} width="100%" alt="Giftcard4" />,
  <img src={Giftcard5} width="100%" alt="Giftcard5" />,
  <img src={Giftcard6} width="100%" alt="Giftcard6" />,
];

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

export function GiftcardPage() {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector(({ giftcard }) => giftcard);
  const theme = useTheme();
  const matchesSmall = useMediaQuery(theme.breakpoints.down(768));
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [price, setPrice] = useState(500);
  const [customPrice, setCustomPrice] = useState<number | undefined>(undefined);
  const [senderName, setSenderName] = useState("");
  const [senderEmail, setSenderEmail] = useState("");
  const [recieverName, setRecieverName] = useState("");
  const [recieverEmail, setRecieverEmail] = useState("");
  const [note, setNote] = useState("");

  const selectedImage = images[selectedImageIndex];

  const setCustomPriceZero = useCallback(() => {
    setCustomPrice((prev) => prev ? 0 : prev);
  }, [setCustomPrice]);

  const setPriceZero = useCallback(() => {
    setPrice((prev) => prev ? 0 : prev);
  }, [setPrice]);

  useEffect(() => {
    if (customPrice) {
      setPriceZero();
    }
  }, [customPrice, setPriceZero]);

  useEffect(() => {
    if (price) {
      setCustomPriceZero();
    }
  }, [price, setCustomPriceZero]);

  const onSubmit = () => {
    if (!senderName?.trim()) {
      return toast.error("Please enter sender name.", {
        position: "bottom-right",
      });
    }

    if (!senderEmail?.trim() || !emailRegex.test(senderEmail?.trim())) {
      return toast.error("Please enter valid sender email.", {
        position: "bottom-right",
      });
    }

    if ((customPrice || price) <= 0) {
      return toast.error("Price should be more than $0", {
        position: "bottom-right",
      });
    }

    if (!recieverName?.trim()) {
      return toast.error("Please enter reciver name.", {
        position: "bottom-right",
      });
    }

    if (!recieverEmail?.trim() || !emailRegex.test(recieverEmail?.trim())) {
      return toast.error("Please enter valid reciver email.", {
        position: "bottom-right",
      });
    }

    dispatch(
      createGiftcard({
        senderEmail,
        senderName,
        reciverEmail: recieverEmail,
        reciverName: recieverName,
        name: senderEmail,
        discountPrice: customPrice || price,
        note,
      })
    )
      .unwrap()
      .then((redirectUrl) => {
        if (redirectUrl) {
          window.location.href = redirectUrl;
        }
      });
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 5, mb: 4 }}>
      <Grid container spacing={4}>
        <Grid item xs={matchesSmall ? 12 : 9}>
          <Paper
            elevation={4}
            sx={{
              p: 4,
              display: "flex",
              flexDirection: "column",
              borderRadius: 10,
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              style={{ height: "100%", width: "100%" }}
            >
              <Typography variant="h1" fontSize={48} fontWeight={600} mb={4}>
                BachPlace Gift Cards
              </Typography>
              <Box mb={4.5}>
                <Typography
                  variant="h6"
                  fontSize={24}
                  fontWeight={600}
                  color="#000"
                  mb={3}
                >
                  Pick a design
                </Typography>
                <Box
                  display="flex"
                  flexWrap="wrap"
                  maxWidth={552}
                  alignItems="center"
                >
                  {images.map((img, i) => (
                    <Box
                      key={i}
                      display="flex"
                      mb={1.25}
                      mr={1.25}
                      border={2}
                      width={168}
                      borderRadius={4}
                      sx={{ cursor: "pointer" }}
                      onClick={() => setSelectedImageIndex(i)}
                      borderColor={
                        i === selectedImageIndex ? "#000" : "transparent"
                      }
                    >
                      {img}
                    </Box>
                  ))}
                </Box>
              </Box>
              <Box mb={4}>
                <Typography
                  variant="h6"
                  fontSize={24}
                  fontWeight={600}
                  color="#000"
                  mb={3}
                >
                  Pick an amount
                </Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{ overflowX: "auto" }}
                  pb={1}
                  mb={2.5}
                >
                  {priceOptions.map((pr) => (
                    <PriceButton
                      key={pr}
                      price={pr}
                      selected={price === pr}
                      setSelected={() => setPrice(pr)}
                    />
                  ))}
                </Box>
                <TextField
                  value={customPrice?.toString()}
                  disabled={isLoading}
                  onChange={(val) => {
                    const newValue = +val.target.value?.replaceAll(/[e-]/g, "");
                    setCustomPrice(newValue);
                  }}
                  variant="outlined"
                  placeholder="$ Custom Amount"
                  type="number"
                  sx={{ borderRadius: 12, width: "100%" }}
                />
              </Box>
              <Box mb={4}>
                <Typography
                  variant="h6"
                  fontSize={24}
                  fontWeight={600}
                  color="#000"
                  mb={3}
                >
                  Who is it for?
                </Typography>
                <Box
                  display="flex"
                  flexDirection="column"
                  borderRadius={4}
                  border={1}
                  borderColor="#C3C3C3"
                >
                  <Box display="flex" borderBottom={1} borderColor="#C3C3C3">
                    <TextField
                      value={recieverName}
                      disabled={isLoading}
                      onChange={(val) => setRecieverName(val.target.value)}
                      variant="outlined"
                      placeholder="Recipients name"
                      sx={{ width: "100%", "& *": { border: "none" } }}
                    />
                  </Box>
                  <Box display="flex">
                    <TextField
                      disabled={isLoading}
                      value={recieverEmail}
                      onChange={(val) => setRecieverEmail(val.target.value)}
                      type="email"
                      variant="outlined"
                      placeholder="Recipients email address"
                      sx={{ width: "100%", "& *": { border: "none" } }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box mb={4}>
                <Typography
                  variant="h6"
                  fontSize={24}
                  fontWeight={600}
                  color="#000"
                  mb={3}
                >
                  Add a note
                </Typography>
                <Box display="flex">
                  <TextField
                    multiline
                    disabled={isLoading}
                    value={note}
                    onChange={(ev) => setNote(ev.target.value)}
                    minRows={6}
                    sx={{
                      "&, & fieldset": {
                        border: "1px solid #C3C3C3",
                        borderRadius: 4,
                        width: "100%",
                      },
                    }}
                    placeholder="Gift message"
                  />
                </Box>
              </Box>
              <Box mb={4}>
                <Typography
                  variant="h6"
                  fontSize={24}
                  fontWeight={600}
                  color="#000"
                  mb={3}
                >
                  Who is this from?
                </Typography>
                <Box
                  display="flex"
                  flexDirection="column"
                  borderRadius={4}
                  border={1}
                  borderColor="#C3C3C3"
                >
                  <Box display="flex" borderBottom={1} borderColor="#C3C3C3">
                    <TextField
                      value={senderName}
                      disabled={isLoading}
                      onChange={(val) => setSenderName(val.target.value)}
                      variant="outlined"
                      placeholder="Senders name"
                      sx={{ width: "100%", "& *": { border: "none" } }}
                    />
                  </Box>
                  <Box display="flex">
                    <TextField
                      disabled={isLoading}
                      value={senderEmail}
                      onChange={(val) => setSenderEmail(val.target.value)}
                      type="email"
                      variant="outlined"
                      placeholder="Senders email address"
                      sx={{ width: "100%", "& *": { border: "none" } }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={isLoading}
                  onClick={onSubmit}
                  sx={{
                    color: "#FFF",
                    textTransform: "none",
                    borderRadius: 8,
                    fontSize: 16,
                    py: 1.5,
                    px: 7,
                  }}
                >
                  Buy&nbsp;Now
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid
          item
          xs={matchesSmall ? 6 : 3}
          sx={{ height: "fit-content", position: "sticky", top: 100 }}
          m={matchesSmall ? "auto" : 0}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            mt={5}
            width={1}
          >
            <Box mb={3} width={1} minWidth={200}>
              {selectedImage}
            </Box>
            <Typography
              variant="body1"
              fontSize={24}
              fontWeight={400}
              color="#000"
            >
              ${customPrice || price}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
