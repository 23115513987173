import {
  Typography,
} from "@mui/material";

interface Props {
  text: string;
}

export function Question({ text }: Props) {
  return (
      <Typography
        variant="h2"
        fontSize={16}
        mb={1.25}
        fontWeight={600}
        fontFamily="Urbanist"
      >
        {text}
      </Typography>
  );
}
