import { Box } from "@mui/material";

export const BottomGradient = () => (
  <Box
    position="absolute"
    bottom={0}
    left={0}
    width={1}
    height={450}
    sx={{
      background:
        "linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)",
      transform: "matrix(1, 0, 0, -1, 0, 0)",
    }}
  />
);
