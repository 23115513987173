import {
  Box,
  Paper,
  Container,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";

import {
  Calendar,
  BookingEventDetailsModal,
  CalendarEventDetailsModal,
} from "src/features";
import {
  removeCreatedEvent,
  removeSelectedEvent,
  startCreatingEvent,
  useAppDispatch,
  useAppSelector,
} from "src/store";
import { useGetCurrentPartyInfo } from "src/hooks";
import { ReactComponent as DiningBlueIcon } from "src/design/images/dining-event-icon.svg";
import { ReactComponent as ActivityBlueIcon } from "src/design/images/activity-event-icon.svg";
import Star from "src/design/images/star.png";

import { MOBILE_BREAKPOINT } from "src/types";

export function CalendarPage() {
  const theme = useTheme();
  const matchesSmall = useMediaQuery(theme.breakpoints.down(695));
  const matchesExtraSmal = useMediaQuery(
    theme.breakpoints.down(MOBILE_BREAKPOINT)
  );
  const { selectedEvent, createdEvent, partyId } = useAppSelector(
    ({ calendar, party }) => ({ ...calendar, partyId: party.activePartyId })
  );
  const dispatch = useAppDispatch();
  const { isAdmin } = useGetCurrentPartyInfo();
  const textColor = theme.palette.primary.main;

  const handleAddEventClick = () => {
    if (!isAdmin) {
      return;
    }
    dispatch(
      startCreatingEvent({
        __typename: "calendar",
        partyId,
        calendarItemId: "",
      })
    );
  };

  const handleClose = () => {
    dispatch(removeSelectedEvent());

    if (!!createdEvent) {
      dispatch(removeCreatedEvent());
    }
  };

  const isBookingEvent = selectedEvent?.__typename === "vendorBooking";
  const isCalendarEvent = selectedEvent?.__typename === "calendar";
  const isCalendarModalOpened = isCalendarEvent || !!createdEvent;

  const getMaxWidth = () => {
    if (matchesExtraSmal) {
      return "70vw";
    }
    if (matchesSmall) {
      return "82vw";
    }
  };

  return (
    <Container maxWidth="xl" sx={{ my: 4 }}>
      <Paper elevation={4} sx={{ padding: 3, borderRadius: 10, mx: 0.5 }}>
        <Box
          width={1}
          display="flex"
          flexDirection="column"
          maxWidth={getMaxWidth()}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
          >
            <Typography
              variant="h4"
              color="#0A2753"
              sx={{ fontWeight: 600, fontSize: 18 }}
            >
              Events for this week
            </Typography>
            <Box mx={1} display="flex" alignItems="center">
              <Box display="flex" alignItems="center" mr={2.5}>
                <DiningBlueIcon fill={textColor} width={20} height={20} />
                <Typography
                  variant="body1"
                  fontWeight={400}
                  fontSize={12}
                  color={textColor}
                  ml={0.5}
                >
                  Dining
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mr={2.5}>
                <ActivityBlueIcon stroke={textColor} width={20} height={20} />
                <Typography
                  variant="body1"
                  fontWeight={400}
                  fontSize={12}
                  color={textColor}
                  ml={0.5}
                >
                  Activities
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" mr={2.5}>
                <img src={Star} alt="Star" width={20} height={20} />
                <Typography
                  variant="body1"
                  fontWeight={400}
                  fontSize={12}
                  color={textColor}
                  ml={0.5}
                >
                  Custom
                </Typography>
              </Box>
            </Box>
            <Button
              variant="contained"
              disabled={!isAdmin}
              onClick={handleAddEventClick}
              sx={{
                my: 1.5,
                color: "#FFF",
                textTransform: "none",
                borderRadius: 6,
                fontSize: 18,
                px: 3,
              }}
            >
              Add Event
            </Button>
          </Box>
          <Calendar />
        </Box>
      </Paper>
      {isBookingEvent && (
        <BookingEventDetailsModal
          open={!!selectedEvent}
          handleClose={handleClose}
        />
      )}
      {isCalendarModalOpened && (
        <CalendarEventDetailsModal
          open={isCalendarModalOpened}
          handleClose={handleClose}
        />
      )}
    </Container>
  );
}
