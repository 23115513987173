import { Tooltip } from "@mui/material";
import { InfoOutlined as InfoIcon } from "@mui/icons-material";

interface Props {
  text: string;
}

export const InfoTooltip = ({ text }: Props) => (
  <Tooltip
    title={text}
    placement="top"
    sx={{ ml: 0.5, cursor: "pointer", mt: -0.25 }}
    color="#232323"
    arrow
  >
    <InfoIcon fontSize="small" />
  </Tooltip>
);
