import { gql } from "@apollo/client";

export const FETCH_PARTY_LIST = gql `
query Items($filter: TableBachPartyFilterInput) {
  listBachParties(filter: $filter) {
    items {
      bVibeAdv
      bVibeChill
      bVibeParty
      bVibeWellness
      brideName
      editUsers
      endDate
      name
      partyUid
      startDate
      viewUsers
      location
      guests
    }
  }
}
`;
