import { CalendarEvent } from "src/types";
import { getMinsDuration, toDayjs } from "src/utils";

const BASE_WIDTH_PERCENTS = 100;

type CalendarEventType = CalendarEvent & { width: number; position: number };

export const wrapWithCalendarUIAttrs = (events: CalendarEvent[]) => {
  // Assuming events is the input array of event objects
  // 1. Convert dates and sort by start time and duration
  const sortedEvents = events
    .map((event) => {
      const startDate = toDayjs(event.startTime!);
      const pricingDuration = event?.pricing?.[0]?.duration;
      const reservedEndTime = event.endTime
        ? toDayjs(event.endTime!)
        : startDate.add(1, "hour");
      const endDate = pricingDuration
        ? startDate.add(pricingDuration, "hour")
        : reservedEndTime;

      return {
        ...event,
        startDate,
        endDate,
        durationMins: getMinsDuration(startDate, endDate),
        intercepts: [event as CalendarEventType],
        width: BASE_WIDTH_PERCENTS,
        position: 0,
      };
    })
    .sort((a, b) => +a.startDate - +b.startDate || +b.endDate - +a.endDate);

  sortedEvents.forEach((event) => {
    event.intercepts = [event];
  });

  // Calculate interceptions
  for (let i = 0; i < sortedEvents.length - 1; i++) {
    for (let j = i + 1; j < sortedEvents.length; j++) {
      const eventA = sortedEvents[i];
      const eventB = sortedEvents[j];

      // If eventB starts before eventA ends (and it's not just "touching"), they intercept
      if (
        eventB.startDate.isBefore(eventA.endDate) &&
        !eventB.startDate.isSame(eventA.endDate)
      ) {
        eventA.intercepts.push(eventB);
        eventB.intercepts.push(eventA);
      }
      // If eventB starts after eventA ends, it can't intercept with eventA or any event before eventA
      else if (eventB.startDate.isAfter(eventA.endDate)) {
        break;
      }
    }
  }

  // Calculate width and position
  sortedEvents.forEach((event) => {
    // Remove duplicates from intercepts array
    const intercepts: CalendarEventType[] = event.intercepts.filter(
      (_event, i) => event.intercepts.indexOf(_event) === i
    );

    const width = BASE_WIDTH_PERCENTS / intercepts.length;
    intercepts.forEach((intercept) => {
      intercept.width = width;
      intercept.position = intercepts.indexOf(intercept) * width;
    });
  });

  return sortedEvents;
};
