import { FC, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Grid,
  Paper,
  Container,
  Box,
  Typography,
  Tabs,
  Tab,
  Theme,
  SxProps,
  useTheme,
  Skeleton,
} from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";

import { bookingService, paymentService } from "src/services";
import { Rating, SimpleInputPopup } from "src/components";
import { VendorTab, RouteName } from "src/types";

import {
  ActivityCalculator,
  DiningCalculator,
  ImageModal,
  PartyFavorsCalculator,
  ExtendedCarousel,
} from "./components";
import {
  fetchVendorByIdAndType,
  setActivePartyId,
  useAppDispatch,
  useAppSelector,
} from "src/store";
import { AddOnsInput, Pricing } from "src/graphql";

type NotAccomodationVendor = Exclude<VendorTab, VendorTab.ACCOMMODATION>;

const calculatorComponents: {
  [key in NotAccomodationVendor]: FC<any>;
} = {
  [VendorTab.ACTIVITIES]: ActivityCalculator,
  [VendorTab.DINING]: DiningCalculator,
  [VendorTab.PARTY_FAVORS]: PartyFavorsCalculator,
};

enum VendorTabsValue {
  DESCRIPTION = "Description",
  ADDITIONAL_INFO = "Additional Info",
  RATINGS = "Ratings",
  CANCEL_POLICY = "Cancellation Policy",
}

export function VendorDetails() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLoading, vendorsByType } = useAppSelector(({ vendor }) => vendor);
  const { partyId, vendorId } = useParams();
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(
    null
  );
  const vendorType = searchParams.get("type") as VendorTab;
  const textColor = theme.palette.secondary.light;
  const vendor = vendorsByType[vendorType].find(
    (v) => v.vendorOfferUid === vendorId
  );
  const vendorImages = (vendor?.images as string[]) || [];
  const [currentTab, setCurrentTab] = useState(VendorTabsValue.DESCRIPTION);
  const {
    name,
    description = "No description yet",
    details = "No details yet",
    cancellationPolicy = "",
    rating = 0,
    vendorName,
  } = vendor || {};

  const tabStyle: SxProps<Theme> = {
    textTransform: "capitalize",
    fontSize: 16,
    paddingX: 0,
    paddingY: 0,
    marginRight: 2,
    minHeight: 36,
  };

  const onSubmit = async ({
    date,
    totalPrice,
    qty,
    endDate,
    addOns,
    address,
    notes,
    pricing,
  }: {
    date: string;
    totalPrice: number;
    qty: number;
    endDate: string;
    addOns: AddOnsInput[];
    address: string;
    notes?: string;
    pricing: Pricing;
    serviceFee: number;
  }) => {
    const userId = window.sessionStorage.getItem("userName") ?? "";
    const { __typename, ...pricingRest } = pricing;
    const payload = {
      createTime: new Date().toString(),
      createUser: userId,
      endTime: endDate,
      guestsOrQuantity: qty,
      partyUid: partyId ?? "",
      startTime: date,
      totalPrice: totalPrice,
      vendorId: vendor?.vendorId ?? 0,
      vendorOfferUid: vendor?.vendorOfferUid ?? "",
      vendorTypeId: vendor?.vendorTypeId ?? 1,
      status: "Pending",
      vendorOfferName: vendor?.name ?? "",
      addOns: addOns || [],
      address,
      notes,
      pricing: [pricingRest],
      pricingName: pricing.name,
      pricingDescription: pricing.description,
    };

    await bookingService.saveBooking(payload);

    const redirectUrl = await paymentService.initiateCartBookingsPayment({
      items: [{ ...payload, cartItemId: "", userId }],
    });

    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  };

  const goToBookActivities = () =>
    navigate(`${RouteName.PARTY}/${partyId}${RouteName.VENDOR}`);

  useEffect(() => {
    if (!vendor?.vendorId) {
      dispatch(
        fetchVendorByIdAndType({ id: vendorId ?? "", type: vendorType })
      );
    }
  }, [vendor?.vendorId, dispatch, vendorId, vendorType]);

  useEffect(() => {
    dispatch(setActivePartyId(partyId || ""));
  }, [partyId, dispatch]);

  const Calculator = calculatorComponents[vendorType as NotAccomodationVendor];

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={12} lg={12}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box width={1} display="flex" marginBottom={6}>
              <ExtendedCarousel
                images={vendorImages ?? []}
                setSelectedImageIndex={setSelectedImageIndex}
              />
              <ImageModal
                selectedImageIndex={selectedImageIndex}
                setSelectedImageIndex={setSelectedImageIndex}
                images={vendorImages ?? []}
              />
            </Box>
            <Grid container justifyContent="space-between">
              <Grid item xs={12} md={6} px={2.5} mb={5}>
                <Box
                  mb={2}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box flexDirection="column">
                    <Typography
                      color={textColor}
                      fontSize={24}
                      fontWeight={600}
                    >
                      {name}
                    </Typography>
                    <Typography color={textColor} variant="body1">
                      {vendorName}
                    </Typography>
                  </Box>
                  <Rating rating={rating} />
                </Box>
                <Box>
                  <TabContext value={currentTab}>
                    <Tabs
                      value={currentTab}
                      onChange={(_, newTab) => setCurrentTab(newTab)}
                      sx={{
                        "&": { padding: 0, minHeight: "unset" },
                        "& *[role='tablist']": { overflowX: "auto" },
                      }}
                    >
                      <Tab
                        value={VendorTabsValue.DESCRIPTION}
                        label={VendorTabsValue.DESCRIPTION}
                        sx={tabStyle}
                      />
                      <Tab
                        value={VendorTabsValue.ADDITIONAL_INFO}
                        label={VendorTabsValue.ADDITIONAL_INFO}
                        sx={tabStyle}
                      />
                      <Tab
                        value={VendorTabsValue.CANCEL_POLICY}
                        label={VendorTabsValue.CANCEL_POLICY}
                        sx={tabStyle}
                      />
                    </Tabs>
                    <TabPanel
                      value={VendorTabsValue.DESCRIPTION}
                      sx={{ paddingX: 0, paddingY: 1.5 }}
                    >
                      <Typography color={textColor}>
                        {isLoading ? (
                          <Skeleton height={200} sx={{ transform: "none" }} />
                        ) : (
                          description
                        )}
                      </Typography>
                    </TabPanel>
                    <TabPanel
                      value={VendorTabsValue.ADDITIONAL_INFO}
                      sx={{ paddingX: 0, paddingY: 1.5 }}
                    >
                      <Typography color={textColor}>{details}</Typography>
                    </TabPanel>
                    <TabPanel
                      value={VendorTabsValue.CANCEL_POLICY}
                      sx={{ paddingX: 0, paddingY: 1.5 }}
                    >
                      <Typography color={textColor}>
                        {cancellationPolicy}
                      </Typography>
                    </TabPanel>
                  </TabContext>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                {!!vendor && (
                  <Calculator
                    vendor={vendor}
                    isLoading={isLoading}
                    onSubmit={onSubmit}
                  />
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <SimpleInputPopup
        open={isOpen}
        onClick={goToBookActivities}
        onClose={() => {
          setIsOpen(false);
          goToBookActivities();
        }}
        buttonText="Keep Exploring"
        title={
          <>
            Thanks for
            <br />
            Booking!
          </>
        }
        text={
          <>
            Your booking request is confirmed!
            <br />
            Please check your email for details.
          </>
        }
      />
    </Container>
  );
}
