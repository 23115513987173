import { gql } from "@apollo/client";

export const UPDATE_CHEKCLIST_ITEM = gql`
  mutation updatePartyChecklistItem($input: UpdatePartyChecklistItemInput!) {
    updatePartyChecklistItem(input: $input) {
      checklistItemId
      partyUid
      title
      description
      checked
      editUser
    }
  }
`;
