import { TabPanel as MuiTabPanel } from "@mui/lab";

import { VendorTab, PartyVendor } from "src/types";
import { DataGrid } from "../grid";
import { Typography } from "@mui/material";

interface Props {
  activeTab: VendorTab;
  value: VendorTab;
  data: PartyVendor[];
  isLoading?: boolean;
}

export function TabPanel({ activeTab, value, data, isLoading }: Props) {
  const isAccomodation = value === VendorTab.ACCOMMODATION;

  return (
    <MuiTabPanel value={value} sx={{ paddingLeft: 0 }}>
      {isAccomodation && (
        <>
          <Typography variant="h3" color="#232323" sx={{ mb: 2 }}>
            BachPlace Approved Housing
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            We understand that finding the perfect accommodation can be a
            daunting task, which is why we've teamed up with Arizona's finest
            and most dependable homeowners. “Disclosure: This page contains
            affiliate links, so we may earn a commission when you make a
            purchase through links on our site at no additional cost to you. You
            can read our full disclaimer here.
          </Typography>
        </>
      )}
      <DataGrid data={data} vendorType={activeTab} isLoading={isLoading} />
    </MuiTabPanel>
  );
}
