import { gql } from "@apollo/client";

export const SAVE_CALENDAR_EVENT = gql`
  mutation createCustomCalendarEvent($input: CreateCalendarInput!) {
    createCalendar(input: $input) {
      allDay
      calendarItemId
      description
      endDate
      endTime
      name
      partyId
      startDate
      startTime
    }
  }
`;

export const DELETE_CALENDAR_EVENT = gql`
  mutation deleteCustomCalendarEvent($input: DeleteCalendarInput!) {
    deleteCalendar(input: $input) {
      calendarItemId
    }
  }
`;
