import dayjs, { QUnitType } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from 'dayjs/plugin/timezone'
import isTodayPlugin from "dayjs/plugin/isToday";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

import { DateValue } from "src/types";

dayjs.extend(isTodayPlugin);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(utc);
dayjs.extend(timezone);

export const getSimpleDate = (date: DateValue) => dayjs(date).format("D MMMM");

export const getWeekDay = (date: DateValue) => dayjs(date).format("dddd");

export const getDateRange = (startDate: DateValue, days: number) => {
  const initialDate = dayjs(startDate);
  const dates = [];

  for (let i = 0; i < days; i++) {
    dates.push(initialDate.add(i, "days"));
  }

  return dates;
};

export const isToday = (date: DateValue) => dayjs(date).isToday();

export const toDayjs = (date: DateValue) =>
  dayjs(date).set("seconds", 0).set("milliseconds", 0);

export const formatToDate = (date: DateValue) =>
  dayjs(date).format("YYYY-MM-DD");

export const formatToPrettyDate = (date: DateValue) =>
  dayjs(date).format("MMMM D,YYYY");

export const formatDate = (date: DateValue, format: string) =>
  dayjs(date).format(format);

export const formatDateRange = (startDate: DateValue, endDate: DateValue) => {
  const [start, end] = [dayjs(startDate), dayjs(endDate)];

  const [startDay, endDay] = [start.date(), end.date()];
  const [startMonth, endMonth] = [
    formatDate(start, "MMM"),
    formatDate(end, "MMM"),
  ];
  const endYear = end.year();

  if (startMonth === endMonth) {
    return `${startDay} - ${endDay} ${endMonth}`;
  }

  if (endYear === new Date().getFullYear()) {
    return `${startDay} ${startMonth} - ${endDay} ${endMonth}`;
  }

  return `${startDay} ${startMonth} - ${endDay} ${endMonth} ${endYear}`;
};

export const isWithinDayRange = (
  checkDate: DateValue,
  days: number,
  startDate: DateValue = new Date()
) => {
  const start = dayjs(startDate).startOf("day");
  const end = dayjs(startDate).add(days, "days").endOf("day");
  const checkedDate = dayjs(checkDate);

  return checkedDate.isAfter(start) && checkedDate.isBefore(end);
};

export const getDuration = (
  startDate: DateValue,
  endDate: DateValue,
  unit: QUnitType,
  isFloat = false
) => dayjs(endDate).diff(dayjs(startDate), unit, isFloat);

export const getDaysDuration = (startDate: DateValue, endDate: DateValue) =>
  getDuration(startDate, endDate, "days") + 1;

export const getHoursDurationPrecise = (
  startDate: DateValue,
  endDate: DateValue
) => Math.floor(getDuration(startDate, endDate, "hours", true));

export const getMinsDurationPrecise = (
  startDate: DateValue,
  endDate: DateValue
) => Math.floor(getDuration(startDate, endDate, "minutes", true));

export const getMinsDuration = (startDate: DateValue, endDate: DateValue) =>
  getDuration(startDate, endDate, "minutes");

export const getSecsDuration = (startDate: DateValue, endDate: DateValue) =>
  getDuration(startDate, endDate, "seconds");

export const getDateTimeToEvent = (date: DateValue) => {
  const daysToEvent = Math.floor(getDuration(new Date(), date, "days", true));
  const withAddedDays = dayjs(new Date()).add(daysToEvent, "days");
  const hoursToEvent = Math.floor(getHoursDurationPrecise(withAddedDays, date));
  const withAddedHours = withAddedDays.add(hoursToEvent, "hours");
  const minsToEvent = Math.floor(getMinsDurationPrecise(withAddedHours, date));
  const withAddedMins = withAddedHours.add(minsToEvent, "minutes");
  const secsToEvent = Math.floor(getSecsDuration(withAddedMins, date));

  return {
    days: daysToEvent,
    hours: hoursToEvent,
    mins: minsToEvent,
    secs: secsToEvent,
  };
};

export const isBetween = (
  checkDate: DateValue,
  startDate: DateValue,
  endDate: DateValue
) => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  const checkedDate = dayjs(checkDate);

  return checkedDate.isAfter(start) && checkedDate.isBefore(end);
};
