import { Box } from "@mui/material";

import { CalendarEvent as CalendarEventType, DateValue } from "src/types";
import { isToday } from "src/utils";

import { CalendarDate } from "./CalendarDate";
import { CalendarEvent } from "./CalendarEvent";

interface Props {
  isToday?: boolean;
  date: DateValue;
  events: Array<CalendarEventType & { width: number; position: number }>;
  startHour: number;
}

export const CalendarColumn = ({ date, events, startHour }: Props) => {
  const sortedEvents = [...events].sort(
    (a, b) => +new Date(a.startTime!) - +new Date(b.startTime!)
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      mx={2}
      flexGrow={1}
    >
      <CalendarDate date={date} isToday={isToday(date)} />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width={1}
        position="relative"
      >
        {sortedEvents.map((event, i) => (
          <CalendarEvent
            key={event.createTime}
            event={event}
            startHour={startHour}
            zIndex={sortedEvents.length - i + 1}
          />
        ))}
      </Box>
    </Box>
  );
};
