import { useEffect, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";

import { Countdown } from "src/components";
import { getDateTimeToEvent, toTwoDigits } from "src/utils";

interface Props {
  deadline: string | Date;
}

export const CountdownAll = ({ deadline }: Props) => {
  const theme = useTheme();
  const [days, setDays] = useState(10);
  const [hours, setHours] = useState(23);
  const [mins, setMins] = useState(59);
  const [secs, setSecs] = useState(59);

  useEffect(() => {
    const { days, mins, hours, secs } = getDateTimeToEvent(deadline);

    setDays(days);
    setHours(hours);
    setMins(mins);
    setSecs(secs);
  }, [deadline]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (secs <= 0) {
        const { days, mins, hours } = getDateTimeToEvent(deadline);

        setDays(days);
        setHours(hours);
        setMins(mins);
        setSecs(59);

        return;
      }
      setSecs((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(interval);
  });

  const textColor = theme.palette.primary.dark;

  return deadline ? (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width={1}
      height={1}
      overflow="hidden"
      flexWrap="wrap"
    >
      {days > 0 && (
        <Box lineHeight={4} mr={2} display="flex" flexDirection="column">
          <Countdown value={toTwoDigits(days)} />
          <Typography
            variant="body1"
            color={textColor}
            fontSize={23}
            fontWeight={600}
          >
            {days === 1 ? "Day" : "Days"}
          </Typography>
        </Box>
      )}
      {(!!days || hours > 0) && (
        <Box lineHeight={4} mr={2} display="flex" flexDirection="column">
          <Countdown value={toTwoDigits(hours)} />
          <Typography
            variant="body1"
            color={textColor}
            fontSize={23}
            fontWeight={600}
          >
            Hours
          </Typography>
        </Box>
      )}
      {(!!days || !!hours || mins > 0) && (
        <Box lineHeight={4} mr={2} display="flex" flexDirection="column">
          <Countdown value={toTwoDigits(mins)} />
          <Typography
            variant="body1"
            color={textColor}
            fontSize={23}
            fontWeight={600}
          >
            Minutes
          </Typography>
        </Box>
      )}
      <Box lineHeight={4} mr={2} display="flex" flexDirection="column">
        <Countdown value={toTwoDigits(secs)} />
        <Typography
          variant="body1"
          color={textColor}
          fontSize={23}
          fontWeight={600}
        >
          Seconds
        </Typography>
      </Box>
    </Box>
  ) : null;
};
