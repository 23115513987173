import { createAction } from "@reduxjs/toolkit";

import { Calendar } from "src/graphql";
import { CalendarEvent } from "src/types";

export const selectEvent = createAction<CalendarEvent>("calendar/selectEvent");
export const removeSelectedEvent = createAction("calendar/removeSelectedEvent");

export const startCreatingEvent = createAction<Partial<Calendar>>(
  "calendar/startCreatingEvent"
);
export const removeCreatedEvent = createAction("calendar/removeCreatedEvent");
