import { ReactNode } from "react";
import { Box, IconButton, Modal } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 8,
  p: 4,
  outline: "none",
};

interface Props {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  width?: string | number;
  height?: string | number;
}

export function ModalBase({
  open,
  onClose,
  children,
  width,
  height,
}: Props) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ zIndex: 999999 }}
    >
      <Box sx={{ ...style, width, height }}>
        {children}
        <IconButton
          sx={{ position: "absolute", top: 5, right: 5, width: 40, height: 40 }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Modal>
  );
}
