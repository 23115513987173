import { Box, Typography } from "@mui/material";

export function TermsAndConditions() {
  return (
    <Box display="flex" flexDirection="column" px={4} py={8}>
      <Typography alignSelf="center" variant="h1" sx={{ marginBottom: 5 }}>
        Terms and Conditions
      </Typography>
      <Typography fontSize={24} variant="body2" marginLeft={10} marginRight={10}>
        <p>
          <span>Last updated: April 24, 2023</span>
        </p>
        <p>
          <span>Thank you for using BachPlace!</span>
        </p>
        <p>
          <span>These Terms and Conditions ("</span>
          <span>Terms</span>
          <span>") are a binding legal agreement between you and BachPlace and govern your right to use the websites,
          applications, and other offerings supplied by BachPlace (collectively, the "</span>
          <span>Platform</span>
          <span>"). When used in these Terms, "</span>
          <span>BachPlace</span>
          <span>," "</span>
          <span>we</span>
          <span>," "</span>
          <span>us</span>
          <span>," or "</span>
          <span>our</span>
          <span>" refers to </span>
          <span>BachPlace Inc., a corporation formed under the laws of the State of Delaware.</span>
        </p>
        <p>
          <span>BachPlace provides users with a one-stop shop to book accommodations, activities, and dining experiences
          ("</span>
          <span>Experiences</span>
          <span>") with various vendors ("</span>
          <span>Vendors</span>
          <span>") in sought-after bachelorette party destinations across the United States and to purchase certain
          party-related items ("</span>
          <span>e-Commerce</span>
          <span>"). The Platform is an online marketplace and planning tool for bachelorette party planners.</span>
        </p>
        <p>
          <span>Please read these Terms carefully before interacting with our website at</span>
          <span> </span>
          <span>
            <a
            href="https://www.google.com/url?q=https://www.bachplace.com/&amp;sa=D&amp;source=editors&amp;ust=1682883652113198&amp;usg=AOvVaw06ciHsKp75-tkkFQAgNmQ6">https://www.bachplace.com/</a>
          </span>
          <span>, or otherwise use any other portion of the Platform. These Terms contain important information regarding
          your use of the Platform and limitations of our liability. Your access to and use of the Platform is conditional upon your
          acceptance of and compliance with these Terms. These Terms apply to all users of the Platform. The term "user" refers to
          anyone accessing or using the Platform. If you disagree with any part of these Terms, then you do not have our permission to
          access or use the Platform. Please refer to our Privacy Policy for a description of how we collect, use, and disclose data
          about our users.</span>
        </p>
        <p>
          <span>NOTE: THESE TERMS CONTAIN PROVISIONS THAT AFFECT YOUR RIGHTS CONCERNING DISPUTES YOU MAY HAVE WITH
          US.</span>
        </p>
        <p>
          <span>Your Responsibilities and Assumption of Risk</span>
          <span> </span>
        </p>
        <p>
        <span>The Platform</span>
        <span>. The Platform may contain links to third-party Vendor and Experience websites, applications, services, or
        resources.</span>
        <span>You acknowledge and agree that we have no responsibility or liability for the acts or omissions of any person
        or entity related to any Vendor or Experience. We provide a platform to facilitate bookings between users on the one hand and
        Vendors and Experiences on the other. The Vendors and Experiences may be subject to different terms and privacy practices. You
        agree that we are not responsible for any Vendors or Experience's terms, actions, or practices, and do not verify any Vendors
        or Experience's compliance with applicable laws, regulations, or policies. We have no responsibility or liability for acts or
        omissions by any Vendor or Experience</span>
        <span>and links to such Vendors or Experiences are not an endorsement.</span></p>
        <p>
        <span>Your Responsibilities</span>
        <span>.</span>
        <span>You are responsible and liable for your own acts and omissions and are also responsible for the acts and
        omissions of anyone you invite to join or provide access to any Vendor or Experience.</span>
        <span> </span>
        <span>You acknowledge that many activities carry inherent risks and agree that, to the maximum extent permitted by
        applicable law, you assume the entire risk arising out of your access to and use of the Platform, including your involvement
        with any Vendor or participation in any Experience, or interaction with the Platform. For example, an Experience may carry the
        risk of illness, bodily injury, disability, or death, and you freely and willfully assume those risks by choosing to
        participate in those Experiences. You acknowledge and agree that BachPlace will have no liability with respect to any incident
        occurring with a Vendor or Experience.</span></p>
        <p>
          <span>Purchases</span>
        </p>
        <p>
          <span>For e-Commerce and when payment is required on the Platform, you will be using a third-party payment
          processor that will collect your payment information and process your payment. We are not responsible for the collection,
          use, sharing or security of your billing information by such third-party payment processor. Payments must be made in the
          payment method(s) accepted by the third-party payment processor.</span>
        </p>
        <p>
          <span>You hereby represent and warrant that you have the legal right to use the payment method(s) in connection
          with any purchase and that the information you supply to us and the third-party payment processor(s) is true, correct, and
          complete. We reserve the right to cancel your purchase for any reason, at our sole discretion, including but not limited to
          fraud, inaccuracies, and unavailability of the items or services purchased.</span>
        </p>
        <p>
          <span>Cancellation and Refund Policy</span>
        </p>
        <p>
          <span>We offer cancellation and refund on any reservations made on the Platform in line with that particular
          Vendor's or Experience's cancellation and refund policies. Please refer to the particular Vendor's or Experience's
          terms.</span>
        </p>
        <p>
          <span>Advance Payments</span>
        </p>
        <p>
          <span>We may ask you to provide an advance payment on any reservation made of Vendors or Experiences offered on
          the Platform. An advance payment is a partial or full payment of the total booking price of a Vendor or Experience.</span>
        </p>
        <p>
          <span>DISCLAIMER OF WARRANTIES</span>
        </p>
        <p>
          <span>YOU EXPRESSLY AGREE THAT ACCESS TO AND USE OF THE PLATFORM IS AT YOUR SOLE RISK. THE PLATFORM IS PROVIDED
          "AS IS". BACHPLACE DOES NOT MAKE, AND HEREBY DISCLAIMS, ANY REPRESENTATIONS OR WARRANTIES, EXPRESS, IMPLIED, OR STATUTORY,
          REGARDING (A) THE PLATFORM; (B) ANY PRODUCTS AND SERVICES OFFERED THROUGH THE PLATFORM; AND (C) THE ACTS OR OMISSIONS OF
          USERS, VENDORS AND EXPERIENCES THROUGH THE PLATFORM, INCLUDING (WITHOUT LIMITATION) IMPLIED WARRANTIES OF MERCHANTABILITY,
          FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT OF THIRD PARTY RIGHTS, AND ANY WARRANTIES ARISING BY COURSE OF
          DEALING OR CUSTOM OF TRADE.</span>
        </p>
        <p>
          <span>BACHPLACE MAKES NO REPRESENTATIONS OR WARRANTIES THAT YOUR ACCESS TO AND USE OF THE PLATFORM WILL BE
          UNINTERRUPTED OR ERROR-FREE, FREE OF VIRUSES, MALICIOUS CODE, OR OTHER HARMFUL COMPONENTS, OR OTHERWISE WILL BE
          SECURE.</span>
        </p>
        <p>
          <span>Remedies</span>
        </p>
        <p>
          <span>You also agree that the remedy for breach of these Terms as it relates to your access to and use of the
          Platform will be to pursue dispute resolution as provided in these Terms below. These remedies are intended to be your sole
          and exclusive remedies for any breach of these Terms as it relates to your access to and use of the Platform.</span>
        </p>
        <p>
          <span>User Account</span>
        </p>
        <p>
          <span>When you create an account on the Platform, you guarantee that you are eighteen (18) years of age or older
          and that the information that you provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or
          out-of-date information may result in the immediate suspension or termination of your account on the Platform and being
          precluded from future use of the Platform.</span>
        </p>
        <p>
          <span>You are solely responsible for maintaining the confidentiality of your account and password, including but
          not limited to the restriction of access to your computer and/or account. You agree to accept responsibility for any
          activities or actions that occur under your account and/or password, whether your password is with the Platform or a
          third-party service. We will not be liable, and you may be liable for losses, damages, liability, expenses, and fees incurred
          by us or a third party arising from someone else using your account, regardless of whether you have notified us of such
          unauthorized use. We reserve the right to terminate your account anytime at our sole discretion. You can terminate your
          account by contacting us.</span>
        </p>
        <p>
          <span>User Submitted Content</span>
        </p>
        <p>
          <span>Certain areas of the Platform may permit you to generate or submit feedback, information, data, text,
          software, messages, comments or other materials (each, a &quot;</span>
          <span>User Submission</span>
          <span>&quot;). You agree that you are solely responsible for all of your User Submissions and that any such User
          Submission is considered both non-confidential and non-proprietary. Further, we do not guarantee that you will be able to
          edit or delete any User Submission you have submitted.</span>
        </p>
        <p>
          <span>By submitting any User Submission, you are promising us that:</span>
        </p>
        <ol>
          <li>
            <span>Your User Submissions do not infringe the copyright, trademark, patent, trade secret, or other
            intellectual property rights, privacy rights, or any other rights of any third party;</span>
          </li>
          <li>
            <span>Your User Submission is not, and may not reasonably be considered to be, defamatory, libelous, hateful,
            racially, ethnically, religiously, or otherwise biased or offensive, unlawfully threatening, or unlawfully harassing to any
            individual or entity, vulgar, pornographic, obscene, or invasive of another&#39;s privacy;</span>
          </li>
          <li>
            <span>Your User Submission does not incorporate materials from a third-party website, or addresses, email
            addresses, contact information, or phone numbers (other than your own);</span>
          </li>
          <li>
            <span>Your User Submission does not contain any information that you consider confidential, proprietary, or
            personal; and</span>
          </li>
          <li>
            <span>Your User Submission does not contain or constitute any unsolicited or unauthorized advertising,
            promotional materials, junk mail, spam, chain letters, pyramid schemes, or any other form of solicitation.</span>
          </li>
        </ol>
        <p>
          <span>By submitting a User Submission, you grant to us an irrevocable, perpetual, transferable, non-exclusive,
          fully-paid, worldwide, royalty-free license (sublicensable through multiple tiers) to:</span>
        </p>
        <ol>
          <li>
            <span>Use, distribute, reproduce, modify, adapt, publish, translate, publicly perform, and publicly display your
            User Submission (or any modification thereto), in whole or in part, in any format or medium now known or later
            developed;</span>
          </li>
        </ol>
        <p></p>
        <ol>
          <li>
            <span>Use your User Submission in any manner and for any purpose that we deem appropriate in our sole
            discretion; and</span>
          </li>
        </ol>
        <p></p>
        <ol>
          <li>
            <span>Display advertisements in connection with your User Submissions and use your User Submissions for
            advertising and promotional purposes.</span>
          </li>
        </ol>
        <p>
          <span>We may, but are not obligated to, pre-screen User Submissions or monitor any area of the Platform through
          which User Submissions may be submitted. We are not required to host, display, or distribute any User Submissions on or
          through the Platform and may remove at any time or refuse any User Submissions for any reason.</span>
        </p>
        <p>
          <span>Feedback</span>
        </p>
        <p>
          <span>Any submissions by you to us (e.g., comments, questions, suggestions, materials - collectively, "</span>
          <span>Feedback</span>
          <span>"") through any communication whatsoever will be treated as both non-confidential and non-proprietary. Except
          as prohibited by applicable law, you hereby assign all right, title, and interest in, and we are free to use, without any
          attribution or compensation to you, any ideas, know-how, concepts, techniques, or other intellectual property and proprietary
          rights contained in the Feedback, whether or not patentable, for any purpose whatsoever, including but not limited to,
          developing, manufacturing, having manufactured, licensing, marketing, and selling, directly or indirectly, products and
          services using such Feedback. Where the foregoing assignment is prohibited by law, you hereby grant us an exclusive,
          transferable, worldwide, royalty-free, fully paid-up license (including the right to sublicense) to use and exploit all
          Feedback as we may determine in our sole discretion. Notwithstanding the foregoing, you understand and agree that we are not
          obligated to use, display, reproduce, or distribute any such ideas, know-how, concepts, or techniques contained in the
          Feedback, and you have no right to compel such use, display, reproduction, or distribution.</span>
        </p>
        <p>
          <span>Authorized Purposes</span>
        </p>
        <p>
          <span>The Platform is aimed at assisting users plan a bachelorette party. Authorized use of the Platform includes
          organizing a party; inviting others to join the party; communicating with other users; researching, making and paying for
          bookings and reservations with Vendors and Experiences; providing User Submission; and other uses as indicated by BachPlace
          from time to time.</span>
        </p>
        <p>
          <span>To begin using the Platform, users create an account by providing their first and last name, email, and
          general information on the party. We reserve complete discretion concerning the operation of the Platform. We also reserve
          the right to withdraw, suspend, or discontinue any feature or functionality of the Platform at any time.</span>
        </p>
        <p>
          <span>Prohibited Uses</span>
        </p>
        <p>
          <span>We authorize your use of the Platform only for authorized purposes. Any other use of the Platform beyond the
          authorized purposes is prohibited and, therefore, constitutes unauthorized use of the Platform.</span>
        </p>
        <p>
          <span>Further, you are not authorized to use the Platform to do the following (these are examples only and the
          list below is not a complete list of everything that you are not permitted to do):</span>
        </p>
        <ol>
          <li>
            <span>Misrepresent your identity or affiliation with any person or entity;</span>
          </li>
          <li>
            <span>Send or attempt to send any advertising or promotional material, including but not limited to spam, junk
            mail, chain mail or any similar material;</span>
          </li>
          <li>
            <span>Engage in any conduct that, as determined in our sole discretion, may harm us or the users of the
            Platform;</span>
          </li>
          <li>
            <span>Use the Platform in any manner that could disable, overburden, damage or impair the Platform or interfere
            with another party's use of the Platform or the proper working of the Platform; or</span>
          </li>
          <li>
            <span>Use the Platform in any way that violates any applicable federal, state or local laws, rules, regulations
            or these Terms.</span>
          </li>
        </ol>
        <p>
          <span>DAMAGES AND LIMITATION OF LIABILITY</span>
        </p>
        <p>
          <span>IN NO EVENT WILL BACHPLACE INC. BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL OR
          CONSEQUENTIAL DAMAGES ARISING OUT OF, RELATING TO OR IN ANY WAY CONNECTED WITH YOUR ACCESS TO OR USE OF THE PLATFORM OR WITH
          THE DELAY TO ACCESS OR INABILITY TO USE THE PLATFORM, INCLUDING BUT NOT LIMITED TO YOUR RELIANCE UPON INFORMATION ON THE
          PLATFORM; LINKED WEBSITES OPERATED BY THIRD PARTIES; AND PRODUCTS OR SERVICES OBTAINED THROUGH THE PLATFORM, WHETHER BASED ON
          A THEORY OF NEGLIGENCE, CONTRACT, TORT, STRICT LIABILITY, CONSUMER PROTECTION STATUTES OR OTHERWISE, EVEN IF BACHPLACE INC.
          HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</span>
        </p>
        <p>
          <span>THE AGGREGATE LIABILITY OF BACHPLACE INC. ARISING OUT OF OR RELATING TO THE PLATFORM, WHETHER ARISING OUT OF
          OR RELATED TO BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE SHALL BE LIMITED TO THE AMOUNT OF FEES YOU PAY TO
          BACHPLACE INC. DURING THE SIX (6) MONTHS IMMEDIATELY PRECEDING THE INCIDENT GIVING RISE TO SUCH CLAIM. THE LIMITATION OF
          LIABILITY PROVIDED IN THESE TERMS INURE TO THE BENEFIT OF BACHPLACE INC.</span>
        </p>
        <p>
          <span>Indemnification</span>
        </p>
        <p>
          <span>You agree to indemnify and hold harmless BachPlace Inc. and its officers, directors, employees, agents and
          affiliates (each, an </span>
          <span>"Indemnified Party"</span>
          <span>), from and against any losses, claims, actions, costs, damages, penalties, fines and expenses, including
          without limitation, attorneys' fees and expenses, that may be incurred by an Indemnified Party arising out of, relating to or
          resulting from (a) your User Submissions; (b) your misuse of the Platform; (c) your breach of these Terms or any
          representation, warranty or covenant in these Terms; or (d) your violation of any applicable laws, rules or regulations
          through or related to the use of the Platform. In the event of any claim, allegation, suit or proceeding alleging any matter
          potentially covered by the agreements in this paragraph, you agree to pay for the defense of the Indemnified Party, including
          reasonable costs and attorneys' fees incurred by the Indemnified Party. BachPlace reserves the right, at its own cost, to
          assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will
          fully cooperate with BachPlace in asserting any available defenses. You agree that the provisions in this paragraph will
          survive any termination of your account, these Terms, or your access to the Platform.</span>
        </p>
        <p>
          <span>Affiliate Disclosure</span>
        </p>
        <p>
          <span>This Platform may earn money by participating in affiliate programs. This means we may receive a small
          commission when you purchase a product from our link, at no additional cost to you. Many of the links on this Platform (as
          well as those in any email newsletter and social channels) may earn affiliate commissions. When you see product links on this
          Platform, please assume they are affiliate links. We attempt to include a line noting that in each listing that includes
          them.</span>
        </p>
        <p>
          <span>No Joint Venture or Partnership</span>
        </p>
        <p>
          <span>No joint venture, partnership, employment, or agency relationship exists between you, BachPlace or any third
          party as a result of these Terms or use of the Platform.</span>
        </p>
        <p>
          <span>Governing Law; Dispute Resolution; Venue</span>
        </p>
        <p>
          <span>These Terms will be governed and construed by the laws of the state of New York, United States, without
          regard to its conflict of laws provisions. Any controversy or claim arising out of or relating to these Terms including but
          not limited to the interpretation or breach thereof shall be resolved in the State of New York.</span>
        </p>
        <p>
          <span>Our failure to enforce any right or provision of these Terms will not be considered a waiver of that right
          or provision. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of
          these Terms will remain in effect. These Terms constitute the entire agreement between you and us regarding the Platform and
          supersede and replace any prior agreements we might have had with you regarding the Platform.</span>
        </p>
        <p>
          <span>YOU AND BACHPLACE INC. AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL
          CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION.</span>
        </p>
        <p>
          <span>Changes to Terms and Conditions</span>
        </p>
        <p>
          <span>We reserve the right to make changes to these Terms at any time and in our sole discretion by publishing the
          latest version at this URL. You should regularly review these Terms, as your continued use of the Platform after any update
          constitutes your agreement to such changes. Should you deem any such modification to the Terms to be unacceptable, you must
          stop accessing and using the Platform. All changes to the Terms will be effective immediately upon publication to this
          URL.</span>
        </p>
        <p>
          <span>Questions</span>
        </p>
        <p>
          <span>If you have any questions about our Terms, please contact us at info@bachplace.com.</span>
        </p>
      </Typography>
    </Box>
  );
}
