import { Box, IconButton, useTheme } from "@mui/material";
import { CheckCircle as CheckCircleIcon } from "@mui/icons-material";

interface Props {
  checked: boolean;
  onClick: () => void;
  disabled?: boolean;
}

export function RoundedCheckbox({ checked, onClick, disabled }: Props) {
  const theme = useTheme();
  const mainColor = theme.palette.primary.main;
  const borderColor = disabled
    ? theme.palette.primary.light
    : theme.palette.secondary.light;
  const noop = () => {};

  return checked ? (
    <IconButton
      sx={{
        width: 24,
        height: 24,
        fontSize: 28,
        color: mainColor,
        cursor: "pointer",
        float: "left",
        mr: 1,
      }}
      onClick={disabled ? noop : onClick}
      disabled={disabled}
    >
      <CheckCircleIcon fontSize="inherit" />
    </IconButton>
  ) : (
    <Box
      width={24}
      height={24}
      border={checked ? "none" : `2px solid ${borderColor}`}
      borderRadius="50%"
      onClick={disabled ? noop : onClick}
      sx={{ [disabled ? "" : "cursor"]: "pointer", float: "left", mr: 1 }}
    />
  );
}
