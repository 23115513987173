import { createSlice } from "@reduxjs/toolkit";

import { PartyChecklistItem } from "src/graphql";

import {
  fetchAllChecklistItemsByPartyId,
  updateChecklistItem,
} from "./checklistThunk";

export interface ChecklistState {
  checklistItems: PartyChecklistItem[];
  isLoading: boolean;
}

const initialState: ChecklistState = {
  checklistItems: [],
  isLoading: false,
};

const checklistSlice = createSlice({
  name: "checklist",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAllChecklistItemsByPartyId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllChecklistItemsByPartyId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.checklistItems = action.payload;
      })
      .addCase(fetchAllChecklistItemsByPartyId.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(updateChecklistItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateChecklistItem.fulfilled, (state, action) => {
        const updatedItem = action.payload;
        state.isLoading = false;
        state.checklistItems = state.checklistItems.map((item) =>
          item.checklistItemId === updatedItem?.checklistItemId
            ? updatedItem
            : item
        );
      })
      .addCase(updateChecklistItem.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const checklistReducer = checklistSlice.reducer;
