import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  CreatePollDataInput,
  DeletePollDataInput,
  UpdatePollDataInput,
} from "src/graphql";
import { pollsService } from "src/services";

type FetchListPayload = Parameters<typeof pollsService.fetchPollsList>[0];
type CreateAnswerPayload = Parameters<
  typeof pollsService.createNewPollAnswer
>[0];

export const fetchAllPollItems = createAsyncThunk(
  "poll/getAllPollItems",
  async ({ partyId }: FetchListPayload) => {
    const pollItems = await pollsService.fetchPollsList({
      partyId,
    });

    return pollItems;
  }
);

export const fetchAllPollAnswers = createAsyncThunk(
  "poll/getAllPollAnswers",
  async ({ partyId }: FetchListPayload) => {
    const pollAnswers = await pollsService.fetchPollAnswers({
      partyId,
    });

    return pollAnswers;
  }
);

export const createPollAnswer = createAsyncThunk(
  "poll/createPollAnswer",
  async (payload: CreateAnswerPayload) => {
    const pollItems = await pollsService.createNewPollAnswer(payload);

    return pollItems;
  }
);

export const createPoll = createAsyncThunk(
  "poll/createPoll",
  async (payload: CreatePollDataInput) => {
    const poll = await pollsService.createNewPoll(payload);

    return poll;
  }
);

export const updatePoll = createAsyncThunk(
  "poll/updatePoll",
  async (payload: UpdatePollDataInput) => {
    const poll = await pollsService.updatePoll(payload);

    return poll;
  }
);

export const deletePoll = createAsyncThunk(
  "poll/deletePoll",
  async (payload: DeletePollDataInput) => {
    const poll = await pollsService.deletePoll(payload);

    return poll;
  }
);
