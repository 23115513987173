import {
  Calendar,
  CreateCalendarInput,
  DeleteCalendarInput,
  getApolloClient,
  Query,
} from "src/graphql";
import { handleAuthError } from "src/utils";

import { DELETE_CALENDAR_EVENT, SAVE_CALENDAR_EVENT } from "./mutations";
import { FETCH_CALENDAR_EVENTS_LIST } from "./queries";

interface GetAllCalendarEventsPayload {
  partyId: string;
}

class CalendarService {
  get apolloClient() {
    return getApolloClient();
  }

  async createCustomCalendarEvent({
    allDay,
    description,
    endTime,
    startTime,
    name,
    partyId,
  }: CreateCalendarInput): Promise<Query["getCalendar"]> {
    try {
      const response = await this.apolloClient.mutate({
        mutation: SAVE_CALENDAR_EVENT,
        variables: {
          input: {
            allDay,
            description,
            endDate: "",
            endTime: "",
            name,
            partyId,
            startDate: "",
            startTime,
          },
        },
      });

      const newEvent = response?.data?.createCalendar;

      if (!newEvent) {
        throw new Error("Can not save the calendar event");
      }

      return newEvent;
    } catch (err) {
      handleAuthError(err);
      throw err;
    }
  }

  async getAllCustomCalendarEvents({ partyId }: GetAllCalendarEventsPayload) {
    try {
      const response = await this.apolloClient.query({
        query: FETCH_CALENDAR_EVENTS_LIST,
        variables: {
          filter: {
            partyId: {
              contains: partyId,
            },
          },
        },
        fetchPolicy: "network-only",
      });

      const calendarEvents = response?.data?.listCalendars?.items as Calendar[];

      if (!calendarEvents) {
        throw new Error("Can not get custom calendar events.");
      }

      return calendarEvents;
    } catch (err) {
      handleAuthError(err);
      throw err;
    }
  }

  async deleteCustomCalendarEvent({
    calendarItemId,
    partyId,
  }: DeleteCalendarInput) {
    try {
      const response = await this.apolloClient.mutate({
        mutation: DELETE_CALENDAR_EVENT,
        variables: {
          input: {
            calendarItemId,
            partyId,
          },
        },
      });

      const deletedEvent = response?.data?.deleteCalendar;

      if (!deletedEvent) {
        throw new Error("Can not delete the calendar event");
      }

      return deletedEvent as { calendarItemId: string };
    } catch (err) {
      handleAuthError(err);
      throw err;
    }
  }
}

export const calendarService = new CalendarService();
