import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Container, Grid, IconButton, Paper } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  CancelRounded as CancelIcon,
  EditOutlined as EditIcon,
} from "@mui/icons-material";

import {
  useAppDispatch,
  useAppSelector,
  fetchAllBookingsByPartyId,
  fetchAllParties,
  updateBooking,
} from "src/store";
import { sessionStorage } from "src/services";
import { useGetCurrentPartyInfo } from "src/hooks";
import { RouteName, vendorNamesById } from "src/types";
import { DynamicInfoPopup } from "src/components";

const MONTH_IN_MS = 24 * 60 * 60 * 1000 * 30;
const requestsEmail = "requests@bachplace.com";

const columns: GridColDef[] = [
  {
    field: "vendorOfferName",
    headerName: "Booking",
    width: 230,
  },
  {
    field: "guestsOrQuantity",
    headerName: "Guests",
    width: 80,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "startTime",
    headerName: "Date",
    width: 130,
    align: "center",
    headerAlign: "center",
    renderCell: ({ row }) => new Date(row.startTime).toDateString(),
  },
  {
    field: "time",
    headerName: "Time",
    width: 130,
    align: "center",
    headerAlign: "center",
    renderCell: ({ row }) =>
      new Date(row.startTime).toLocaleString("en-US", {
        hour: "2-digit",
        minute: "numeric",
        hour12: true,
      }),
  },
  {
    field: "totalPrice",
    headerName: "Price",
    width: 90,
    align: "center",
    headerAlign: "center",
    renderCell: ({ row }) => `$${row.totalPrice}`,
  },
  {
    field: "status",
    headerName: "Status",
    width: 90,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "edit",
    headerName: "Edit",
    width: 80,
    align: "center",
    headerAlign: "center",
    renderCell: ({ row }) => (
      <IconButton
        onClick={() => row.onEdit()}
        size="small"
        disabled={row.isDisabled}
      >
        <EditIcon color="primary" />
      </IconButton>
    ),
  },
  {
    field: "cancel",
    headerName: "Cancel",
    width: 80,
    align: "center",
    headerAlign: "center",
    renderCell: ({ row }) => (
      <IconButton
        onClick={() => row.onCancel()}
        size="small"
        disabled={row.isDisabled}
      >
        <CancelIcon color="primary" />
      </IconButton>
    ),
  },
];

export function MyBookings() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { booking, party } = useAppSelector(({ booking, party }) => ({
    booking,
    party,
  }));
  const { isAdmin, isPartyLoading: isLoading } = useGetCurrentPartyInfo();
  const { isLoading: isBookingLoading, bookingList } = booking;
  const { activePartyId: partyId } = party;
  const openModal = () => setIsModalOpen(true);

  const updatedBookingList = useMemo(
    () =>
      bookingList
        .filter((booking) => booking.status !== "canceled")
        .map((booking) => {
          const { __typename, pricing, addons, ...bookingPayload } = booking;
          const isLessThanMonthRemaining =
            !!booking?.startTime &&
            +new Date(booking?.startTime) < +new Date() + MONTH_IN_MS;

          return {
            ...booking,
            id: booking?.bookingId,
            onEdit: () =>
              !isLessThanMonthRemaining
                ? navigate(
                    encodeURI(
                      `${RouteName.PARTY}/${partyId}${RouteName.VENDOR}/${
                        booking.vendorOfferUid
                      }?type=${
                        vendorNamesById[booking.vendorTypeId - 1]
                      }&editedBooking=${booking.bookingId}`
                    )
                  )
                : openModal(),
            onCancel: () =>
              !isLessThanMonthRemaining
                ? dispatch(
                    updateBooking({ ...bookingPayload, status: "canceled" })
                  )
                : openModal(),
            isDisabled: !isAdmin || isLoading || isBookingLoading,
          };
        }),
    [
      bookingList,
      isAdmin,
      isLoading,
      isBookingLoading,
      dispatch,
      navigate,
      partyId,
    ]
  );

  useEffect(() => {
    const userName = sessionStorage.getUsername() ?? "";
    if (partyId) {
      dispatch(fetchAllBookingsByPartyId({ partyId, userName }));
    } else {
      dispatch(fetchAllParties(userName));
    }
  }, [partyId, dispatch]);

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4, height: "80%" }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paper
            elevation={4}
            sx={{
              p: 4,
              display: "flex",
              flexDirection: "column",
              height: 600,
              borderRadius: 10,
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              style={{ height: "100%", width: "100%" }}
            >
              <DataGrid
                rows={updatedBookingList}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableSelectionOnClick
                loading={isBookingLoading}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <DynamicInfoPopup
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        buttonText="Okay"
        title={
          <>
            Contact us
            <br />
            to complete
          </>
        }
        text={
          <>
            Hi! Decided to switch up the game plan? No problem, we're all about that. Since you're within the 30-day window for cancellations, kindly send an email to{" "}
            <a
              href={`mailto:${requestsEmail}`}
              target="_blank"
              style={{ color: "#000" }}
              rel="noreferrer"
            >
              {requestsEmail}
            </a>{" "}
            to initiate the cancellation process.
          </>
        }
      />
    </Container>
  );
}
