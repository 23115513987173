import { Fragment } from "react";
import { Box, FormGroup } from "@mui/material";
import {
  CheckBox as AnsweredIcon,
  CheckBoxOutlineBlank as EmptyIcon,
} from "@mui/icons-material";
import { CheckboxOption } from "../options";
import { PollAnswers, PollOptions } from "src/graphql";
import { sessionStorage } from "src/services";
import { PollResult } from "../components";
import { checkAnsweredByType } from "../utils";
import { PollVariant } from "../types";

interface Props {
  options: PollOptions[];
  answers: PollAnswers[];
  anonymous?: boolean;
}

export const CheckboxAnswers = ({ options, answers, anonymous }: Props) => {
  const userId = sessionStorage.getUsername() ?? "";
  const userAnswers = answers.filter((a) => a.userId === userId);
  const totalAnswers = answers.length;

  return (
    <FormGroup>
      {options.map(({ value, text, type }) => (
        <Fragment key={value}>
          <CheckboxOption
            text={text || ""}
            value={value || ""}
            disabled
            key={value}
            control={
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width={42}
                height={38}
              >
                {checkAnsweredByType({
                  answers: (userAnswers?.[0]?.answers as string[]) || [],
                  options,
                  type: type as PollVariant,
                  value: value || "",
                }) ? (
                  <AnsweredIcon color="primary" />
                ) : (
                  <EmptyIcon color="primary" />
                )}
              </Box>
            }
          />
          <PollResult
            total={totalAnswers}
            answers={answers.filter((ans) =>
              checkAnsweredByType({
                answers: (ans?.answers as string[]) || [],
                options,
                type: type as PollVariant,
                value: value || "",
              })
            )}
            withAnswers={type === PollVariant.Text}
            options={options}
            anonymous={anonymous}
          />
        </Fragment>
      ))}
    </FormGroup>
  );
};
