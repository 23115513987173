import { Box, Typography } from "@mui/material";

import { VendorTab } from "src/types";
import { CartBooking } from "src/graphql";

interface Props {
  item: CartBooking;
}

export function CartItemCard({ item }: Props) {
  const { thumbImage, vendorTypeId } = item;
  const vendorNamesById = [
    VendorTab.ACCOMMODATION,
    VendorTab.DINING,
    VendorTab.ACTIVITIES,
    VendorTab.PARTY_FAVORS,
  ];

  return (
    <Box position="relative" padding={1.5}>
      <Box
        style={{
          width: "328px",
          height: "210px",
          background: `no-repeat center/100% url("${thumbImage}")`,
          borderRadius: "24px",
        }}
      />
      <Box
        position="absolute"
        bgcolor="#FFF"
        top={24}
        left={24}
        py={0.5}
        px={1}
        borderRadius="4px"
      >
        <Typography
          variant="body1"
          fontSize={14}
          fontWeight={600}
          lineHeight="17px"
        >
          {vendorNamesById[vendorTypeId - 1]}
        </Typography>
      </Box>
    </Box>
  );
}
