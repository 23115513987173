import {
  CartBooking,
  CreatePaymentBookingInput,
  Mutation,
  getApolloClient,
} from "src/graphql";
import { handleAuthError } from "src/utils";

import { CREATE_PAYMENT_BOOKING } from "./mutations";

interface InitiatePaymentPayload {
  items: CartBooking[];
}

class PaymentService {
  get apolloClient() {
    return getApolloClient();
  }
  private url = process.env.REACT_APP_PAYMENT_SERVICE_URL || "";
  private apiKey = process.env.REACT_APP_PAYMENT_API_KEY || "";

  async initiatePayment(body: BodyInit) {
    try {
      const response = await fetch(this.url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": this.apiKey,
        },
        body,
      });
      const { ok } = response;

      if (!ok) {
        throw new Error("SOmething went wrong whili initiating payment");
      }

      const res = await response.json();

      return res;
    } catch (err) {
      handleAuthError(err);
      throw err;
    }
  }

  async initiateCartBookingsPayment({ items }: InitiatePaymentPayload) {
    try {
      const body = JSON.stringify({ listCartBookings: { items } });
      const response = await this.initiatePayment(body);
      const { sessionUrl: stripeRedirectUrl } = response;

      return stripeRedirectUrl;
    } catch (err) {
      handleAuthError(err);
      throw err;
    }
  }

  async initiateGiftcardPayment({
    code,
    totalPrice,
  }: {
    code: string;
    totalPrice: number;
  }) {
    try {
      const body = JSON.stringify({ giftcard: { code, totalPrice } });
      const response = await this.initiatePayment(body);
      const { sessionUrl: stripeRedirectUrl } = response;

      return stripeRedirectUrl;
    } catch (err) {
      handleAuthError(err);
      throw err;
    }
  }

  async createPaymentBooking({
    bachPaymentid,
    bookingIds,
    serviceFee,
    status,
    totalPrice,
  }: CreatePaymentBookingInput): Promise<Mutation["createPaymentBooking"]> {
    try {
      const response = await this.apolloClient.mutate({
        mutation: CREATE_PAYMENT_BOOKING,
        variables: {
          input: {
            bachPaymentid,
            bookingIds,
            serviceFee,
            status,
            totalPrice,
          },
        },
      });

      const createdItem = response?.data?.createPaymentBooking;

      if (!createdItem) {
        throw new Error("Can not create payment");
      }

      return createdItem;
    } catch (err) {
      handleAuthError(err);
      throw err;
    }
  }
}

export const paymentService = new PaymentService();
