import {
  AccommodationVendor,
  ActivitiesVendor,
  DiningVendor,
  PartyFavorsVendor,
  getApolloClient,
} from "src/graphql";
import { VendorTab } from "src/types";
import { handleAuthError } from "src/utils";

import {
  FETCH_ACC_VENDOR_ById,
  FETCH_ACC_VENDOR_LIST,
  FETCH_ACTIVITY_VENDOR_ById,
  FETCH_ACTIVITY_VENDOR_LIST,
  FETCH_DINE_VENDOR_ById,
  FETCH_DINE_VENDOR_LIST,
  FETCH_PARTY_FAVORS_VENDOR_ById,
  FETCH_PARTY_FAVORS_VENDOR_LIST,
} from "./queries";

class VendorService {
  get apolloClient() {
    return getApolloClient();
  }

  async fetchVendorByIdAndType(id: string, type: VendorTab) {
    const fetchersByType = {
      [VendorTab.ACCOMMODATION]: this.fetchAccVendorById.bind(this),
      [VendorTab.ACTIVITIES]: this.fetchActivityVendorById.bind(this),
      [VendorTab.DINING]: this.fetchDineVendorById.bind(this),
      [VendorTab.PARTY_FAVORS]: this.fetchPartyFavorsVendorById.bind(this),
    };
    const vendor = fetchersByType[type](id);

    return vendor;
  }

  async fetchVendorListByType(type: VendorTab) {
    const fetchersByType = {
      [VendorTab.ACCOMMODATION]: this.fetchAccVendorList.bind(this),
      [VendorTab.ACTIVITIES]: this.fetchActivityVendorList.bind(this),
      [VendorTab.DINING]: this.fetchDineVendorList.bind(this),
      [VendorTab.PARTY_FAVORS]: this.fetchPartyFavorsVendorList.bind(this),
    };
    const vendorList = fetchersByType[type]();

    return vendorList;
  }

  async fetchAccVendorList() {
    try {
      const response = await this.apolloClient.query({
        query: FETCH_ACC_VENDOR_LIST,
        variables: {
          filter: null,
        },
      });
      const accommodationVendors =
        response?.data?.listAccommodationVendors?.items;

      if (!accommodationVendors) {
        throw new Error("Can not retrieve accommodation vendors");
      }
      return accommodationVendors as AccommodationVendor[];
    } catch (err) {
      handleAuthError(err);
      throw err;
    }
  }

  async fetchDineVendorList() {
    try {
      const response = await this.apolloClient.query({
        query: FETCH_DINE_VENDOR_LIST,
        variables: {
          filter: null,
        },
      });
      const diningVendors = response?.data?.listDiningVendors?.items;
      if (!diningVendors) {
        throw new Error("Can not retrieve dinning vendors");
      }
      return diningVendors as DiningVendor[];
    } catch (err) {
      handleAuthError(err);
      throw err;
    }
  }

  async fetchActivityVendorList() {
    try {
      const response = await this.apolloClient.query({
        query: FETCH_ACTIVITY_VENDOR_LIST,
        variables: {
          filter: null,
        },
      });

      const activityVendors = response?.data?.listActivitiesVendors?.items;
      if (!activityVendors) {
        throw new Error("Can not retrieve activity vendors");
      }
      return activityVendors as ActivitiesVendor[];
    } catch (err) {
      handleAuthError(err);
      throw err;
    }
  }

  async fetchPartyFavorsVendorList() {
    try {
      const response = await this.apolloClient.query({
        query: FETCH_PARTY_FAVORS_VENDOR_LIST,
        variables: {
          filter: null,
        },
      });
      const partyFavorsVendors = response?.data?.listPartyFavorsVendors?.items;

      if (!partyFavorsVendors) {
        throw new Error("Can not retrieve party favors vendors");
      }
      return partyFavorsVendors as PartyFavorsVendor[];
    } catch (err) {
      handleAuthError(err);
      throw err;
    }
  }

  async fetchAccVendorById(vendorId: string) {
    try {
      const response = await this.apolloClient.query({
        query: FETCH_ACC_VENDOR_ById,
        variables: { vendorOfferUid: vendorId },
      });
      const accomodationVendor = response?.data?.getAccommodationVendor;
      if (!accomodationVendor) {
        throw new Error("Can not retrieve accommodation vendor");
      }
      return accomodationVendor as AccommodationVendor;
    } catch (err) {
      handleAuthError(err);
      throw err;
    }
  }

  async fetchDineVendorById(vendorId: string) {
    try {
      const response = await this.apolloClient.query({
        query: FETCH_DINE_VENDOR_ById,
        variables: { vendorOfferUid: vendorId },
      });
      const diningVendor = response?.data?.getDiningVendor;

      if (!diningVendor) {
        throw new Error("Can not retrieve dinning vendor");
      }
      return diningVendor as DiningVendor;
    } catch (err) {
      handleAuthError(err);
      throw err;
    }
  }

  async fetchActivityVendorById(vendorId: string) {
    try {
      const response = await this.apolloClient.query({
        query: FETCH_ACTIVITY_VENDOR_ById,
        variables: { vendorOfferUid: vendorId },
      });
      const activityVendor = response?.data?.getActivitiesVendor;

      if (!activityVendor) {
        throw new Error("Can not retrieve activity vendor");
      }
      return activityVendor as ActivitiesVendor;
    } catch (err) {
      handleAuthError(err);
      throw err;
    }
  }

  async fetchPartyFavorsVendorById(vendorId: string) {
    try {
      const response = await this.apolloClient.query({
        query: FETCH_PARTY_FAVORS_VENDOR_ById,
        variables: { vendorOfferUid: vendorId },
      });
      const partyFavorsVendor = response?.data?.getPartyFavorsVendor;

      if (!partyFavorsVendor) {
        throw new Error("Can not retrieve party favors vendor");
      }
      return partyFavorsVendor as PartyFavorsVendor;
    } catch (err) {
      handleAuthError(err);
      throw err;
    }
  }
}

export const vendorService = new VendorService();
