import { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import {
  useAppDispatch,
  useAppSelector,
  selectVendorLocation,
  fetchAllLocations,
} from "src/store";
import { ALL_LOCATIONS_VALUE } from "src/types";

export const VendorLocationSelect = () => {
  const dispatch = useAppDispatch();
  const [isTouched, setIsTouched] = useState(false);
  const {
    isLoading,
    selectedLocation,
    partyList,
    activePartyId,
    locationList,
  } = useAppSelector(({ vendor, party, location }) => ({
    ...vendor,
    partyList: party.partyList,
    activePartyId: party.activePartyId,
    locationList: location.locationList,
  }));

  const handleChange = (event: SelectChangeEvent<string>) => {
    dispatch(selectVendorLocation(event.target.value));
  };

  const allVendorLocations = locationList.map(({ location }) => location);

  const uniqueVendorLocations = Array.from(
    new Set([ALL_LOCATIONS_VALUE, selectedLocation, ...allVendorLocations])
  );

  useEffect(() => {
    if (!locationList.length) {
      dispatch(fetchAllLocations());
    }
  }, [dispatch, locationList.length]);

  useEffect(() => {
    if (
      (!selectedLocation || selectedLocation === ALL_LOCATIONS_VALUE) &&
      !isTouched
    ) {
      const currentPartyLocation = partyList.find(
        ({ partyUid }) => partyUid === activePartyId
      )?.location;
      dispatch(
        selectVendorLocation(currentPartyLocation || ALL_LOCATIONS_VALUE)
      );
    }
  }, [dispatch, selectedLocation, activePartyId, partyList, isTouched]);

  return isLoading ? (
    <Box display="flex" justifyContent="center">
      <CircularProgress />
    </Box>
  ) : (
    <FormControl fullWidth>
      <InputLabel id="select-location-label">Location</InputLabel>
      <Select
        labelId="select-location-label"
        value={selectedLocation}
        label="Location"
        onChange={handleChange}
        onClick={() => setIsTouched(true)}
        placeholder="Select location"
      >
        {uniqueVendorLocations.map((location) => (
          <MenuItem key={location} value={location}>
            {location}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
