import { MouseEventHandler, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRevalidator } from "react-router";
import {
  Box,
  ClickAwayListener,
  Fade,
  List,
  Paper,
  Popper,
  CircularProgress as Loader,
  useTheme,
  IconButton,
  Typography,
  Button,
} from "@mui/material";
import {
  Settings as SettingsIcon,
  ExitToApp as SignOutIcon,
  AccountCircle as AccountCircleIcon,
  AccountCircleOutlined as AccountCircleOutlinedIcon,
} from "@mui/icons-material";

import { signOutCognitoUser, sessionStorage } from "src/services";
import { RouteName } from "src/types";

import { UserMenuItem } from "./UserMenuItem";
import { CartIcon } from "../cart";
import { Link } from "../ui-kit";

export function UserMenu() {
  const theme = useTheme();
  const { revalidate } = useRevalidator();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [userName, setUserName] = useState("");

  const isOpen = !!anchorEl;
  const isAuthenticated = !!sessionStorage.getToken();
  const mainColor = theme.palette.primary.main;

  useEffect(() => {
    const getName = async () => {
      const username = await sessionStorage.getFullname();
      const [firstName] = username.split(" ");
      setUserName(firstName);
    };
    getName();
  }, []);

  const handleClick: MouseEventHandler<HTMLElement> = (event) => {
    if (isAuthenticated) {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    } else {
      navigate(RouteName.LOGIN);
    }
  };

  const handleSignOut = () => {
    signOutCognitoUser();
    revalidate();
    navigate(RouteName.LOGIN);
  };

  const loaderComponent = isAuthenticated ? (
    <Loader size={25} sx={{ opacity: 0.6, marginRight: 1.5 }} />
  ) : null;

  const userNameComponent = userName || loaderComponent;

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <Box display="flex" alignItems="center" sx={{ cursor: "pointer" }}>
        <Box mx={2} color={mainColor}>
          <CartIcon colorMode="secondary" rounded />
        </Box>
        <Box mx={1.5}>
          <Button
            variant="contained"
            component={Link}
            to={RouteName.CREATE_PARTY}
            sx={{
              color: "#FFF",
              textTransform: "none",
              borderRadius: 6,
              fontSize: 18,
              px: 2.5,
            }}
          >
            Create&nbsp;Party
          </Button>
        </Box>
        <IconButton
          onClick={handleClick}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 0.25,
            height: 74,
            width: 74,
          }}
        >
          <AccountCircleOutlinedIcon
            color="primary"
            sx={{ width: 36, height: 36 }}
          />
          <Typography
            display="flex"
            alignItems="center"
            fontSize={14}
            color={mainColor}
          >
            {userNameComponent}
          </Typography>
        </IconButton>
        <Popper
          open={isOpen}
          anchorEl={anchorEl}
          placement="bottom-start"
          transition
          sx={{ zIndex: 1201, position: "relative", left: -20 }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <List>
                  <UserMenuItem
                    text="Settings"
                    icon={<SettingsIcon />}
                    onClick={() => {}}
                    disabled
                  />
                  <UserMenuItem
                    text="Profile"
                    icon={<AccountCircleIcon />}
                    onClick={() => {}}
                    disabled
                  />
                  <UserMenuItem
                    text="Sign out"
                    icon={<SignOutIcon />}
                    onClick={handleSignOut}
                  />
                </List>
              </Paper>
            </Fade>
          )}
        </Popper>
      </Box>
    </ClickAwayListener>
  );
}
