import {
  CartBooking,
  CreateCartBookingInput,
  DeleteCartBookingInput,
  getApolloClient,
  Query,
  UpdateCartBookingInput,
} from "src/graphql";
import { handleAuthError } from "src/utils";

import { FETCH_CART_ITEMS } from "./queries";
import {
  CREATE_CART_ITEM_BOOKING,
  REMOVE_CART_ITEM_BOOKING,
  UPDATE_CART_ITEM_BOOKING,
} from "./mutations";

class CartService {
  get apolloClient() {
    return getApolloClient();
  }

  public async fetchCartItemList({ userName }: { userName: string }) {
    try {
      const response = await this.apolloClient.query<Query>({
        query: FETCH_CART_ITEMS,
        variables: {
          filter: {
            userId: {
              eq: userName,
            },
          },
        },
        fetchPolicy: "network-only",
      });
      const cartItems = response?.data?.listCartBookings?.items || [];
      if (!cartItems) {
        throw new Error("Can not retrieve cart items");
      }
      return cartItems as CartBooking[];
    } catch (error) {
      handleAuthError(error);
      throw error;
    }
  }

  public async createNewCartItemBooking({
    createTime,
    userId,
    endTime,
    guestsOrQuantity,
    partyUid,
    startTime,
    vendorId,
    vendorOfferUid,
    vendorTypeId,
    totalPrice,
    vendorOfferName,
    addons,
    address,
    notes,
    thumbImage,
    pricing,
  }: CreateCartBookingInput): Promise<Query["getCartBooking"]> {
    try {
      const response = await this.apolloClient.mutate({
        mutation: CREATE_CART_ITEM_BOOKING,
        variables: {
          input: {
            createTime,
            userId,
            endTime,
            guestsOrQuantity,
            partyUid,
            startTime,
            vendorId,
            vendorOfferUid,
            vendorTypeId,
            totalPrice,
            vendorOfferName,
            addons,
            address,
            notes,
            thumbImage,
            pricing,
          },
        },
      });

      const createdItem = response?.data?.createCartBooking;

      if (!createdItem) {
        throw new Error("Can not create cart booking");
      }

      return createdItem;
    } catch (err) {
      throw err;
    }
  }

  public async updateCartItemBooking({
    userId,
    endTime,
    guestsOrQuantity,
    partyUid,
    startTime,
    totalPrice,
    addOns,
    notes,
    cartItemId,
    pricing,
    pricingName,
    pricingDescription,
    address,
  }: UpdateCartBookingInput): Promise<Query["getCartBooking"]> {
    try {
      const response = await this.apolloClient.mutate({
        mutation: UPDATE_CART_ITEM_BOOKING,
        variables: {
          input: {
            cartItemId,
            userId,
            endTime,
            guestsOrQuantity,
            partyUid,
            startTime,
            totalPrice,
            addOns,
            notes,
            pricing,
            pricingName,
            pricingDescription,
            address,
          },
        },
      });

      const updatedItem = response?.data?.updateCartBooking;

      if (!updatedItem) {
        throw new Error("Can not update cart booking");
      }

      return updatedItem;
    } catch (err) {
      throw err;
    }
  }

  public async removeCartItemBooking({
    cartItemId,
    userId,
  }: DeleteCartBookingInput): Promise<Query["getCartBooking"]> {
    try {
      const response = await this.apolloClient.mutate({
        mutation: REMOVE_CART_ITEM_BOOKING,
        variables: {
          input: {
            cartItemId,
            userId,
          },
        },
      });

      const deletedItem = response?.data?.deleteCartBooking;

      if (!deletedItem) {
        throw new Error("Can not remove cart booking");
      }

      return deletedItem;
    } catch (err) {
      throw err;
    }
  }
}

export const cartService = new CartService();
