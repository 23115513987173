import { Link as RouterLink } from "react-router-dom";
import { ReactNode, forwardRef } from "react";
import { Link as MuiLink, SxProps, Theme } from "@mui/material";

interface Props {
  to: string;
  children?: ReactNode;
  className?: string;
  sx?: SxProps<Theme>;
}

export const Link = forwardRef<HTMLAnchorElement, Props>(function Link(
  { children = null, to, sx = {}, className },
  ref
) {
  return (
    <MuiLink
      component={RouterLink}
      ref={ref}
      to={to}
      variant="body2"
      className={className}
      color="secondary"
      sx={{ textDecoration: "none", cursor: "pointer", ...sx }}
    >
      {children}
    </MuiLink>
  );
});
