import { useState } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from "@mui/material";
import {
  RadioButtonChecked,
  RadioButtonUnchecked,
  KeyboardArrowDown as ArrowDown,
  KeyboardArrowUp as ArrowUp,
} from "@mui/icons-material";

interface Props {
  title: string;
  description?: string;
  price?: string | number;
  selected?: boolean;
  onClick?: () => void;
}

export const RadioListItem = ({
  title,
  description = "",
  price,
  selected,
  onClick = () => {},
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Accordion
      expanded={isOpen}
      onChange={(_, expanded) => setIsOpen(expanded)}
    >
      <AccordionSummary sx={{ width: "100%" }}>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            onClick();
          }}
        >
          {selected ? <RadioButtonChecked /> : <RadioButtonUnchecked />}
        </IconButton>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width={1}
        >
          <Box display="inline-flex">
            <Typography
              fontWeight={selected ? 700 : 500}
              sx={{ wordBreak: "break-word" }}
            >
              {title}
            </Typography>
            {isOpen ? <ArrowUp /> : <ArrowDown />}
          </Box>
          <Typography color="green" fontWeight={700}>
            ${price}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{description}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};
