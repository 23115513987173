import { Box, Typography, useTheme } from "@mui/material";

import { RoundedCheckbox } from "src/components";
import { PartyChecklistItem } from "src/graphql";

interface Props {
  item: PartyChecklistItem;
  onClick: (id: string, checked: boolean) => () => void;
  disabled?: boolean;
}

export function ChecklistItem({ item, onClick, disabled }: Props) {
  const theme = useTheme();

  const { title, description, checked, checklistItemId } = item;
  const textColor = theme.palette.primary.light;

  return (
    <Box
      display="flex"
      flexDirection="column"
      p={2}
      bgcolor="#FBFBFB"
      borderRadius={8}
      mb={1.5}
    >
      <Box mb={0.5}>
        <RoundedCheckbox
          checked={checked}
          onClick={onClick(checklistItemId, checked)}
          disabled={disabled}
        />
        <Typography variant="body1">
          {title}
        </Typography>
      </Box>
      {!!description && (
        <Box display="flex" flexDirection="column">
          <Typography
            variant="body1"
            fontSize={14}
            fontWeight={400}
            color={textColor}
          >
            Description
          </Typography>
          <Typography variant="body1" fontSize={16} fontWeight={500}>
            {description}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
