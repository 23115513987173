import { createAsyncThunk } from "@reduxjs/toolkit";

import { CreateGiftPromoInput, UpdateGiftPromoInput } from "src/graphql";
import { giftcardService, paymentService } from "src/services";

export const createGiftcard = createAsyncThunk(
  "giftcard/createGiftcard",
  async (input: CreateGiftPromoInput) => {
    const giftcard = await giftcardService.createGiftcard(input);

    const redirectUrl = await paymentService.initiateGiftcardPayment({
      code: giftcard?.code || "",
      totalPrice: input.discountPrice || 0,
    });

    return redirectUrl;
  }
);

export const getGiftcardByCode = createAsyncThunk(
  "giftcard/getGiftcardByCode",
  async (code: string) => {
    const giftcard = await giftcardService.getGiftcardByCode(code);

    return giftcard;
  }
);

export const updateGiftcard = createAsyncThunk(
  "giftcard/updateGiftcard",
  async (input: UpdateGiftPromoInput) => {
    const giftcards = await giftcardService.updateGiftcard(input);

    return giftcards;
  }
);
