import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import { DateValue } from "src/types";

dayjs.extend(utc);

const timeIntervals = {
  "15": [":00", ":15", ":30", ":45"],
  "30": [":00", ":30"],
  "0": [":00"],
};

export const generateTimeValues = (
  intervalMins: "15" | "30" | "0",
  withMins = true
) => {
  const timeValues = [];
  const times = timeIntervals[intervalMins];

  times.forEach((mins) => timeValues.push(withMins ? `12${mins} AM` : "12 AM"));

  for (let hour = 1; hour <= 11; hour++) {
    for (let time of times) {
      timeValues.push(withMins ? `${hour}${time} AM` : `${hour} AM`);
    }
  }

  times.forEach((mins) => timeValues.push(withMins ? `12${mins} PM` : "12 PM"));

  for (let hour = 1; hour <= 11; hour++) {
    for (let time of times) {
      timeValues.push(withMins ? `${hour}${time} PM` : `${hour} PM`);
    }
  }

  return timeValues;
};

export const roundTimeQuarterHour = (dateTime: string | Date) => {
  const roundedTime = new Date(dateTime);

  roundedTime.setMilliseconds(
    Math.round(roundedTime.getMilliseconds() / 1000) * 1000
  );
  roundedTime.setSeconds(Math.round(roundedTime.getSeconds() / 60) * 60);
  roundedTime.setMinutes(Math.round(roundedTime.getMinutes() / 15) * 15);

  return roundedTime;
};

export const getBareHours = (time: string | Date | number) => dayjs(time).format("h A");

export const getHourQuartersDifference = (
  startTime: DateValue,
  endTime: DateValue
) => {
  const diff = +dayjs(endTime).diff(dayjs(startTime), "h", true).toFixed(2);
  const hours = Math.floor(diff);
  const quarters = Math.round((diff - hours) / 0.25);

  return [hours, quarters];
};

export const parseTime = (time: string | Date) => ({
  hours: new Date(time).getHours(),
  mins: new Date(time).getMinutes(),
});

export const addUnits = (
  startTime: string | Date,
  units: number,
  unitName: dayjs.ManipulateType
) => dayjs(startTime).add(units, unitName);
