import { gql } from "@apollo/client";

export const SAVE_PARTY = gql`
  mutation createBachParty($input: CreateBachPartyInput!) {
    createBachParty(input: $input) {
      bVibeAdv
      bVibeChill
      bVibeParty
      bVibeWellness
      brideName
      editUsers
      endDate
      guests
      location
      name
      partyUid
      startDate
      unknownDate
      viewUsers
    }
  }
`;

export const UPDATE_PARTY = gql`
  mutation updateBachParty($input: UpdateBachPartyInput!) {
    updateBachParty(input: $input) {
      bVibeAdv
      bVibeChill
      bVibeParty
      bVibeWellness
      brideName
      editUsers
      endDate
      guests
      location
      name
      partyUid
      startDate
      unknownDate
      viewUsers
    }
  }
`;
