import { Typography } from "@mui/material";
import { Link } from "../ui-kit";

export function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary.light" align="center">
      {"Copyright © "}
      <Link sx={{ color: "#232323" }} to="/">
        BachPlace
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
