import { ThemeOptions } from "@mui/material";

export default function teal_theme(props: any): ThemeOptions {
  return {
    palette: {
      mode: "light",
      primary: {
        main: "#677FD9",
        light: "#8F8F8F",
        dark: "#FBFBFB",
      },
      secondary: {
        main: "#f50057",
        light: "#232323",
      },
      success: {
        main: "#5D6E9D",
      },
    },
    typography: {
      fontFamily: "Urbanist",
      h1: {
        fontFamily: "Urbanist",
        color: "#232323",
      },
      h2: {
        fontFamily: "Urbanist",
      },
      h3: {
        fontFamily: "Urbanist",
      },
      h4: {
        fontFamily: "Urbanist",
      },
      h5: {
        fontFamily: "Urbanist",
        fontWeight: 700,
      },
      h6: {
        fontFamily: "Urbanist",
        fontWeight: 700,
      },
      body1: {
        fontFamily: "Urbanist",
        fontWeight: 400,
        color: "#232323",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          contained: {
            "&:hover": {
              backgroundColor: "#677FD9", // Disable background change on hover
              opacity: 0.9,
            },
            "&:active": {
              backgroundColor: "#677FD9", // Disable background change on click
              opacity: 0.9,
            },
          },
        },
      },
    },
  };
}
