import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, IconButton, useMediaQuery, useTheme } from "@mui/material";

import { MOBILE_BREAKPOINT, RouteName } from "src/types";
import { sessionStorage } from "src/services";
import { ActivePartySelect } from "src/features";
import { ReactComponent as Logo } from "src/design/images/BachPlace_Primary_Logo.svg";

import { BurgerMenuIcon } from "./BurgerMenuIcon";
import { MobileMenu } from "./MobileMenu";

export const MobileHeader = ({ pagename = "Dashboard" }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const matchesSmall = useMediaQuery(theme.breakpoints.down(MOBILE_BREAKPOINT));
  const [isOpen, setIsOpen] = useState(false);

  const bgColor = theme.palette.primary.dark;
  const primaryColor = theme.palette.primary.main;
  const isAuthenticated = sessionStorage.getToken();

  const toggleMenu = () => {
    document.body.style.overflow = isOpen ? "visible" : "hidden";
    setIsOpen((prev) => !prev);
  };

  return (
    <>
      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        position="absolute"
        left={0}
        top={0}
        flexWrap="wrap"
        p={4}
        pb={2}
        bgcolor={bgColor}
      >
        <Box display="flex">
          <IconButton
            onClick={() => navigate(RouteName.DASHBOARD)}
            sx={{
              alignSelf: "flex-start",
              borderRadius: 3,
              pl: 1,
              pr: 2.8,
            }}
          >
            <Logo width={130} height={42} />
          </IconButton>
        </Box>
        {!!isAuthenticated && !matchesSmall && (
          <Box maxWidth={200} height={40} mr={2} alignSelf="center">
            <ActivePartySelect />
          </Box>
        )}
        <Box width={50} height={1}>
          <BurgerMenuIcon open={isOpen} onClick={toggleMenu} />
        </Box>
        <MobileMenu open={isOpen} pagename={pagename} onClick={toggleMenu} />
      </Box>
      {!!matchesSmall && (
        <Box
          display="flex"
          width="100%"
          justifyContent="center"
          alignItems="center"
          position="absolute"
          left={0}
          top={96}
          flexWrap="wrap"
          p={0.75}
          bgcolor={primaryColor}
          color="#FFF"
        >
          For a better experience, please use a web browser
        </Box>
      )}
    </>
  );
};
