import { Box, Typography } from "@mui/material";
import { StarRateRounded } from "@mui/icons-material";

interface Props {
  rating: number | null;
  isAbsolute?: boolean;
}

export const Rating = ({ rating, isAbsolute = false }: Props) => {
  return !!rating ? (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      position={isAbsolute ? "absolute" : "static"}
      top={20}
      left={20}
      px={1.5}
      py={1.25}
      borderRadius={25}
      sx={{
        background: "rgba(153, 153, 153, 0.30)",
        backdropFilter: "blur(10px)",
      }}
    >
      <Typography
        variant="body1"
        color="#FFF"
        fontWeight={400}
        fontSize={16}
        lineHeight="18px"
        display="flex"
        alignItems="center"
      >
        <StarRateRounded fontSize="inherit" sx={{ marginRight: 1 }} />
        {rating?.toFixed(1)}
      </Typography>
    </Box>
  ) : null;
};
