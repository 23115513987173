import { TextField } from "@mui/material";
import { ChangeEvent } from "react";

interface Props {
  text: string;
  value: string;
  setValue: CallableFunction;
  visible?: boolean;
}

export const TextOption = ({ text, value, setValue, visible }: Props) => {
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <TextField
      label={text}
      id={text}
      size="small"
      placeholder={text}
      value={value}
      onChange={onChange}
      sx={{
        marginTop: 1.25,
        marginBottom: 1,
        wordWrap: "break-word",
        display: visible ? "flex" : "none",
      }}
    />
  );
};
