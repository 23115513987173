import {
  BachPartyInvite,
  CreateBachPartyInviteInput,
  DeleteBachPartyInviteInput,
  Mutation,
  UpdateBachPartyInviteInput,
  getApolloClient,
} from "src/graphql";
import { handleAuthError } from "src/utils";

import {
  CREATE_PARTY_INVITE,
  UPDATE_PARTY_INVITE,
  DELETE_PARTY_INVITE,
} from "./mutations";
import { LIST_PARTY_INVITES } from "./queries";

class PartyInvitesService {
  get apolloClient() {
    return getApolloClient();
  }

  async createPartyInvite({
    name,
    email,
    partyUid,
    deleted,
    phone,
    status,
  }: CreateBachPartyInviteInput): Promise<Mutation["createBachPartyInvite"]> {
    try {
      const response = await this.apolloClient.mutate({
        mutation: CREATE_PARTY_INVITE,
        variables: {
          input: {
            email,
            partyUid,
            deleted,
            phone,
            status: status || 'pending',
            name,
          },
        },
      });

      const createdInvite = response?.data?.createBachPartyInvite;

      if (!createdInvite) {
        throw new Error("Can not create invite.");
      }

      return createdInvite;
    } catch (err) {
      handleAuthError(err);
      throw err;
    }
  }

  async updatePartyInvite({
    name,
    email,
    partyUid,
    deleted,
    phone,
    status,
    inviteId,
  }: UpdateBachPartyInviteInput): Promise<Mutation["updateBachPartyInvite"]> {
    try {
      const response = await this.apolloClient.mutate({
        mutation: UPDATE_PARTY_INVITE,
        variables: {
          input: {
            name,
            email,
            partyUid,
            deleted,
            phone,
            status,
            inviteId,
          },
        },
      });

      const updatedInvite = response?.data?.updateBachPartyInvite;

      if (!updatedInvite) {
        throw new Error("Can not update invite.");
      }

      return updatedInvite;
    } catch (err) {
      handleAuthError(err);
      throw err;
    }
  }

  async deletePartyInvite({
    inviteId,
    partyUid,
  }: DeleteBachPartyInviteInput): Promise<Mutation["deleteBachPartyInvite"]> {
    try {
      const response = await this.apolloClient.mutate({
        mutation: DELETE_PARTY_INVITE,
        variables: {
          input: {
            inviteId,
            partyUid,
          },
        },
      });

      const deletedInvite = response?.data?.deleteBachPartyInvite;

      if (!deletedInvite) {
        throw new Error("Can not delete invite.");
      }

      return deletedInvite;
    } catch (err) {
      handleAuthError(err);
      throw err;
    }
  }

  async fetchAllPartyInvites({
    partyUid,
  }: { partyUid: string }) {
    try {
      const response = await this.apolloClient.query({
        query: LIST_PARTY_INVITES,
        variables: {
          filter: {
            partyUid: {
              contains: partyUid,
            }
          },
        },
        fetchPolicy: "network-only",
      });

      const invites = response?.data?.listBachPartyInvites?.items as BachPartyInvite[] || [];

      if (!invites) {
        throw new Error("Can not get invites.");
      }

      return invites;
    } catch (err) {
      handleAuthError(err);
      throw err;
    }
  }
}

export const invitesService = new PartyInvitesService();
