import { gql } from "@apollo/client";

export const FETCH_CART_ITEMS = gql`
  query Items($filter: TableCartBookingFilterInput) {
    listCartBookings(filter: $filter) {
      items {
        addons {
          description
          name
          perPerson
          price
        }
        address
        cartItemId
        createTime
        endTime
        guestsOrQuantity
        notes
        partyUid
        pricingDescription
        pricingName
        pricing {
          description
          duration
          name
          price
          perGroup
          minGuests
          maxGuests
        }
        startTime
        thumbImage
        totalPrice
        userId
        vendorId
        vendorOfferName
        vendorOfferUid
        vendorTypeId
      }
    }
  }
`;
