import { createAsyncThunk } from "@reduxjs/toolkit";
import { UpdateVendorBookingInput } from "src/graphql";

import { bookingService } from "src/services";

type Payload = Parameters<typeof bookingService.getAllPartyBookings>[0]

export const fetchAllBookingsByPartyId = createAsyncThunk(
  'booking/getAllBookings',
  async ({ partyId, userName }: Payload) => {
    const bookings = await bookingService.getAllPartyBookings({ partyId, userName });

    return bookings;
  }
);

export const updateBooking = createAsyncThunk(
  'booking/updateBooking',
  async (params: UpdateVendorBookingInput) => {
    const booking = await bookingService.updateBooking(params);

    return booking;
  }
);
