import { Box } from "@mui/material";
import { Checklist } from "./Checklist";
import { PollManagement } from "../poll-management";

export const ChecklistPage = () => {
  return (
    <Box my={5.5} px={2}>
      <Checklist />
      <Box mt={2}>
        <PollManagement />
      </Box>
    </Box>
  );
};
