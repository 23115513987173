import { AddOns } from "src/graphql";

const SERVICE_FEE = +(process.env.REACT_APP_SERVICE_FEE || 0.05);

export const getTotalPrice = (
  selectedPackagePrice: number,
  addOns: (Partial<Pick<AddOns, "perPerson" | "price">> & { selected: boolean })[],
  numberOfGuests: number
) => {
  const totalAddonsPrice = addOns
    .filter(({ selected }) => selected)
    .reduce(
      (acc, { perPerson, price = 0 }) =>
        acc + (perPerson ? price * numberOfGuests : price),
      0
    );
  const totalWithoutFee = selectedPackagePrice + totalAddonsPrice;
  const serviceFee = (totalWithoutFee * SERVICE_FEE).toFixed(2);
  const total = (totalWithoutFee + parseFloat(serviceFee)).toFixed(2);

  return { total, serviceFee, totalWithoutFee };
};
