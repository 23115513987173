import { createAsyncThunk } from "@reduxjs/toolkit";

import { locationService } from "src/services";

export const fetchAllLocations = createAsyncThunk(
  'location/getAllLocations',
  async () => {
    const locations = await locationService.fetchLocationList();

    return locations;
  }
);
