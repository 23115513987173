import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  Paper,
  Box,
  Typography,
  useTheme,
  Divider,
  Button,
  TextField,
  Skeleton,
  List,
} from "@mui/material";
import { Remove as MinusIcon, Add as PlusIcon } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";

import {
  DynamicInfoPopup,
  InfoTooltip,
  RadioListItem,
  SelectListItem,
  TimePicker,
} from "src/components";
import {
  ActivitiesVendor,
  Pricing,
  CreateCartBookingInput,
  UpdateCartBookingInput,
  UpdateVendorBookingInput,
} from "src/graphql";
import {
  ALL_LOCATIONS_VALUE,
  RouteName,
  TRANSACTION_FEE_TOOLTIP_TEXT,
} from "src/types";
import {
  addCartItem,
  fetchAllBookingsByPartyId,
  updateBooking,
  updateCartItem,
  useAppDispatch,
  useAppSelector,
} from "src/store";
import { sessionStorage } from "src/services";
import { addUnits, toDayjs } from "src/utils";

import { getTotalPrice, getKey } from "../services";
import { SubmitMode } from "../types";

dayjs.extend(utc);

interface Props {
  vendor: ActivitiesVendor;
  onSubmit: CallableFunction;
  isLoading?: boolean;
}

export function DiningCalculator({ vendor, onSubmit, isLoading }: Props) {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { partyList, cartItems, bookingItems } = useAppSelector(
    ({ party, cart, booking }) => ({
      ...party,
      cartItems: cart.items,
      bookingItems: booking.bookingList,
    })
  );
  const navigate = useNavigate();
  const { partyId } = useParams();
  const theme = useTheme();
  const [ticketsCount, setTicketsCount] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [error, setError] = useState("");
  const [address, setAddress] = useState("");
  const [allergies, setAllergies] = useState("");
  const [dateTime, setDateTime] = useState<Dayjs | null>(
    dayjs().set("minutes", 30).set("hours", 9)
  );
  const editedCartItemId = searchParams.get("editedItem");
  const editedCartItem = cartItems.find(
    ({ cartItemId }) => cartItemId === editedCartItemId
  );

  const editedBookingId = searchParams.get("editedBooking");
  const editedBookingItem = bookingItems.find(
    ({ bookingId }) => bookingId === editedBookingId
  );
  const userId = sessionStorage.getUsername() || "";

  const packages = (vendor?.pricing || []) as Pricing[];
  const [selectedPackageKey, setSelectedPackageKey] = useState(
    getKey(packages[0]?.name, packages[0]?.price)
  );
  const [displayedAddons, setDisplayedAddons] = useState(
    (vendor.addons || []).map((addon) => ({
      ...addon,
      selected: false,
    }))
  );
  const selectedPackage = packages.find(
    ({ name, price }) => getKey(name, price) === selectedPackageKey
  );
  const { guests, startDate, location } =
    partyList.find(({ partyUid }) => partyUid === partyId) || {};

  const {
    price = 0,
    minGuests,
    maxGuests,
    perGroup,
    duration,
  } = selectedPackage || {};
  const maximum = maxGuests || 100000;
  const minimum = minGuests || 0;

  const textColor = theme.palette.secondary.light;
  const packagePriceNum = price * (perGroup ? 1 : ticketsCount);
  const packagePrice = packagePriceNum.toFixed(2);
  const { serviceFee, total } = getTotalPrice(
    packagePriceNum,
    displayedAddons,
    ticketsCount
  );
  const priceSkeleton = <Skeleton sx={{ display: "inline-flex" }} width={40} />;
  const priceComponent = isLoading ? priceSkeleton : `$${packagePrice ?? ""}`;
  const pricePerGroupComponent = isLoading
    ? priceSkeleton
    : `$${price.toFixed(2) ?? ""}`;
  const selectedAddOns = displayedAddons.filter(({ selected }) => selected);
  const isAuthenticated = !!sessionStorage.getToken();

  useEffect(() => {
    if (guests) {
      setTicketsCount(guests);
    }

    if (startDate) {
      setDateTime(dayjs(startDate).set("minutes", 30).set("hours", 9));
    }
  }, [guests, startDate]);

  useEffect(() => {
    if (editedCartItem?.cartItemId) {
      setTicketsCount((prev) => editedCartItem.guestsOrQuantity || prev);
      setDateTime((prev) => toDayjs(editedCartItem.startTime || "") || prev);
      setSelectedPackageKey(
        (prev) =>
          getKey(
            editedCartItem?.pricing?.[0]?.name || "",
            editedCartItem?.pricing?.[0]?.price || 0
          ) || prev
      );
      setAddress(editedCartItem?.address || "");
      setAllergies(editedCartItem?.notes || "");

      const addonsSet = new Set(
        editedCartItem?.addons?.map((adn) => getKey(adn?.name!, adn?.price!))
      );
      setDisplayedAddons((prev) =>
        prev.map((adn) => ({
          ...adn,
          selected: addonsSet.has(getKey(adn?.name!, adn?.price!)),
        }))
      );
    }
  }, [editedCartItem]);

  useEffect(() => {
    if (editedBookingItem?.bookingId) {
      setTicketsCount((prev) => editedBookingItem.guestsOrQuantity || prev);
      setDateTime((prev) => toDayjs(editedBookingItem.startTime || "") || prev);
      setSelectedPackageKey(
        (prev) =>
          getKey(
            editedBookingItem?.pricing?.[0]?.name || "",
            editedBookingItem?.pricing?.[0]?.price || 0
          ) || prev
      );
      setAddress(editedBookingItem?.address || "");
      setAllergies(editedBookingItem?.notes || "");

      const addonsSet = new Set(
        editedBookingItem?.addons?.map((adn) => getKey(adn?.name!, adn?.price!))
      );
      setDisplayedAddons((prev) =>
        prev.map((adn) => ({
          ...adn,
          selected: addonsSet.has(getKey(adn?.name!, adn?.price!)),
        }))
      );
    }
  }, [editedBookingItem]);

  useEffect(() => {
    if (editedBookingId && !editedBookingItem) {
      dispatch(
        fetchAllBookingsByPartyId({ partyId: partyId || "", userName: userId })
      );
    }
  }, [editedBookingId, dispatch, editedBookingItem, partyId, userId]);

  const handlePlus = () => {
    const newTicketsCount = ticketsCount + 1;
    setTicketsCount(newTicketsCount);
  };

  const handleMinus = () => {
    const newTicketsCount = ticketsCount - 1;
    setTicketsCount(newTicketsCount < 0 ? 0 : newTicketsCount);
  };

  const handleSubmit = (mode: SubmitMode) => () => {
    if (!isAuthenticated) {
      navigate(RouteName.LOGIN);
      return;
    }

    if (
      vendor?.location &&
      location !== vendor?.location &&
      vendor.location !== ALL_LOCATIONS_VALUE
    ) {
      toast.error(
        `You need to change your party location to ${vendor?.location} to perform this action.`,
        { position: "bottom-right" }
      );
      return;
    }

    if (ticketsCount > maximum || ticketsCount < minimum) {
      setError(
        `Selected package can be chosen only for ${minimum}-${maximum} guests, but you have ${ticketsCount}.`
      );
      return;
    } else {
      setError("");
    }

    const mappedAddOns = selectedAddOns.map(
      ({
        selected,
        __typename,
        name = "",
        description = "",
        perPerson = false,
        price = 0,
        ...rest
      }) => ({
        name,
        description,
        perPerson,
        price,
        ...rest,
      })
    );

    const { __typename, ...pricing } = selectedPackage || ({} as Pricing);

    if (mode === SubmitMode.UPDATE_CART_ITEM) {
      const cartItem: UpdateCartBookingInput = {
        cartItemId: editedCartItemId || "",
        startTime: dateTime?.toISOString() || null,
        totalPrice: Number(total),
        guestsOrQuantity: ticketsCount,
        addOns: mappedAddOns,
        pricingName: selectedPackage?.name || "",
        pricingDescription: selectedPackage?.description || "",
        partyUid: partyId || "",
        pricing: selectedPackage ? [pricing] : [],
        userId,
        notes: allergies,
        address,
        endTime: dateTime
          ? addUnits(
              dateTime?.toISOString(),
              duration || 1,
              "hours"
            ).toISOString()
          : undefined,
      };

      dispatch(updateCartItem(cartItem))
        .unwrap()
        .then(() => {
          toast.success(
            `Item "${selectedPackage?.name || ""}" was successfully updated.`,
            { position: "bottom-right" }
          );
          navigate(RouteName.CART);
        });

      return;
    }

    if (mode === SubmitMode.UPDATE_BOOKING) {
      const booking: UpdateVendorBookingInput = {
        bookingId: editedBookingId || "",
        startTime: dateTime?.toISOString() || null,
        totalPrice: Number(total),
        guestsOrQuantity: ticketsCount,
        addons: mappedAddOns,
        pricingName: selectedPackage?.name || "",
        pricingDescription: selectedPackage?.description || "",
        partyUid: partyId || "",
        pricing: selectedPackage ? [pricing] : [],
        endTime: dateTime
          ? addUnits(
              dateTime?.toISOString(),
              duration || 1,
              "hours"
            ).toISOString()
          : undefined,
        createTime: editedBookingItem?.createTime || "",
        createUser: editedBookingItem?.createUser || "",
        status: editedBookingItem?.status || "",
        vendorId: editedBookingItem?.vendorId || 0,
        vendorOfferName: editedBookingItem?.vendorOfferName || "",
        vendorOfferUid: editedBookingItem?.vendorOfferUid || "",
        vendorTypeId: editedBookingItem?.vendorTypeId || 0,
        address,
        notes: allergies,
      };

      dispatch(updateBooking(booking))
        .unwrap()
        .then(() => {
          toast.success(
            `Booking "${
              selectedPackage?.name || ""
            }" was successfully updated.`,
            { position: "bottom-right" }
          );
          navigate(RouteName.MY_BOOKINGS);
        });

      return;
    }

    if (mode === SubmitMode.BUY_NOW) {
      onSubmit({
        date: dateTime?.toISOString() || null,
        totalPrice: Number(total),
        qty: ticketsCount,
        addOns: mappedAddOns,
        address,
        notes: allergies,
        serviceFee: Number(serviceFee),
        pricing: selectedPackage,
        endDate: dateTime
          ? addUnits(
              dateTime?.toISOString(),
              duration || 1,
              "hours"
            ).toISOString()
          : undefined,
      });
    } else if (mode === SubmitMode.ADD_TO_CART) {
      const userId = sessionStorage.getUsername() || "";
      const { __typename, ...pricing } = selectedPackage || ({} as Pricing);
      const cartItem: CreateCartBookingInput = {
        startTime: dateTime?.toISOString() || null,
        totalPrice: Number(total),
        guestsOrQuantity: ticketsCount,
        addons: mappedAddOns,
        address,
        pricing: selectedPackage ? [pricing] : [],
        pricingName: selectedPackage?.name || "",
        pricingDescription: selectedPackage?.description || "",
        partyUid: partyId || "",
        vendorOfferName: vendor?.name || "",
        vendorId: vendor?.vendorId || -1,
        vendorOfferUid: vendor?.vendorOfferUid || "",
        vendorTypeId: vendor?.vendorTypeId || -1,
        thumbImage: vendor?.thumbImage,
        createTime: new Date().toISOString(),
        userId,
        notes: allergies || "",
        endTime: dateTime
          ? addUnits(
              dateTime?.toISOString(),
              duration || 1,
              "hours"
            ).toISOString()
          : undefined,
      };

      dispatch(addCartItem(cartItem));
      setIsModalVisible(true);
    }
  };

  const handleSelectPackage = (key: string) => () => {
    setSelectedPackageKey(key);
  };

  const handleSelectAddon = (key: string) => () => {
    setDisplayedAddons((prev) =>
      prev.map((addon) =>
        getKey(addon?.name || "", addon?.price || 0) === key
          ? { ...addon, selected: !addon.selected }
          : addon
      )
    );
  };

  const setTime = useCallback(
    (newTime: string) =>
      setDateTime((dt) =>
        dayjs(new Date(`${dayjs(dt).format("YYYY/MM/DD")} ${newTime}`))
      ),
    []
  );

  return (
    <Paper elevation={6} sx={{ padding: 3, borderRadius: 4 }}>
      <DynamicInfoPopup
        open={!!isModalVisible}
        onClose={() => {
          setIsModalVisible(false);
          navigate(RouteName.VENDOR);
        }}
        buttonText="Book more here!"
        title={
          <>
            Added to
            <br />
            Your Cart!
          </>
        }
        text={
          <>
            <b>Item "{selectedPackage?.name}"</b>
            <br />
            <b>was successfully added to the cart</b>
          </>
        }
      />
      <Typography
        display="flex"
        justifyContent="space-between"
        color={textColor}
        fontSize={24}
        fontWeight={600}
        marginBottom={3}
      >
        <span>Pricing</span>
        <span>from {priceComponent}</span>
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
      >
        <Typography variant="body1">Number of guests</Typography>
        <Box display="flex" alignItems="center">
          <MinusIcon
            fontSize="small"
            sx={{ marginRight: 1.5, cursor: "pointer" }}
            onClick={handleMinus}
          />
          <Typography
            display="flex"
            justifyContent="center"
            alignItems="center"
            width={14}
            fontSize={18}
            fontWeight={400}
          >
            {ticketsCount}
          </Typography>
          <PlusIcon
            fontSize="small"
            sx={{ marginLeft: 1.5, cursor: "pointer" }}
            onClick={handlePlus}
          />
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" mb={3}>
        <DatePicker
          value={dateTime}
          onChange={(newDate) => setDateTime(newDate)}
          renderInput={(props) => (
            <TextField {...props} sx={{ marginRight: 2 }} fullWidth />
          )}
          disablePast
          label="Date"
        />
        <TimePicker
          value={dateTime?.format("h:mm A") || ""}
          onChange={setTime}
        />
      </Box>
      <Box display="flex" justifyContent="space-between" mb={3}>
        <TextField
          fullWidth
          id="address"
          label="Address"
          value={address}
          onChange={(event) => setAddress(event.target.value)}
          placeholder="Enter your address"
          sx={{ mr: 2 }}
        />
        <TextField
          fullWidth
          id="allergies"
          label="Allergies"
          value={allergies}
          onChange={(event) => setAllergies(event.target.value)}
          placeholder="Tell us about your allergies"
        />
      </Box>
      {!!packages.length && (
        <Box my={2}>
          <Typography variant="body1">Select a package</Typography>
          <List
            sx={{
              listStyleType: "disc",
            }}
          >
            {packages.map(({ description, name, price }, i) => (
              <RadioListItem
                key={name + i}
                selected={selectedPackageKey === getKey(name, price)}
                title={name}
                description={description}
                price={price}
                onClick={handleSelectPackage(getKey(name, price))}
              />
            ))}
          </List>
        </Box>
      )}
      {!!displayedAddons.length && (
        <Box my={2}>
          <Typography variant="body1">Choose add-ons</Typography>
          <List sx={{ maxHeight: 380, overflow: "auto" }}>
            {displayedAddons.map(
              ({ name = "", description, price = 0, selected }) => (
                <SelectListItem
                  key={name}
                  title={name}
                  description={description}
                  price={price}
                  onClick={handleSelectAddon(getKey(name, price))}
                  selected={selected}
                />
              )
            )}
          </List>
        </Box>
      )}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="body1">
          <b>
            <i>{selectedPackage?.name}</i>
          </b>{" "}
          - {pricePerGroupComponent} x{" "}
          {selectedPackage?.perGroup ? 1 : ticketsCount}
        </Typography>
        <Typography fontSize={18} fontWeight={600} color={textColor}>
          {isLoading ? priceSkeleton : `$${packagePrice}`}
        </Typography>
      </Box>
      {selectedAddOns.map(({ price = 0, name, perPerson }) => (
        <Box
          key={name}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography variant="body1">
            <b>
              <i>{name}</i>
            </b>{" "}
            - ${price.toFixed(2)} x {perPerson ? ticketsCount : 1}
          </Typography>
          <Typography fontSize={18} fontWeight={600} color={textColor}>
            {isLoading
              ? priceSkeleton
              : `$${
                  perPerson
                    ? (price * ticketsCount).toFixed(2)
                    : price.toFixed(2)
                }`}
          </Typography>
        </Box>
      ))}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="body1" display="flex" alignItems="center">
          Transaction Fee
          <InfoTooltip text={TRANSACTION_FEE_TOOLTIP_TEXT} />
        </Typography>
        <Typography fontSize={18} fontWeight={600} color={textColor}>
          {isLoading ? priceSkeleton : `$${serviceFee}`}
        </Typography>
      </Box>
      <Divider />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={3}
        marginTop={2}
      >
        <Typography fontSize={16} fontWeight={600} color={textColor}>
          Total Payment
        </Typography>
        <Typography fontSize={18} fontWeight={600} color={textColor}>
          {isLoading ? priceSkeleton : `$${total}`}
        </Typography>
      </Box>
      {!!error && (
        <Typography color="red" mb={2}>
          {error}
        </Typography>
      )}
      {!!editedCartItem || !!editedBookingItem ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Button
            variant="contained"
            size="large"
            fullWidth
            onClick={handleSubmit(
              editedCartItem
                ? SubmitMode.UPDATE_CART_ITEM
                : SubmitMode.UPDATE_BOOKING
            )}
            disabled={isLoading}
            sx={{
              "&": {
                borderRadius: 10,
                textTransform: "none",
                paddingY: 2,
                color: "#FFF",
                mr: 2,
              },
              "&:hover": {
                background: theme.palette.primary.main,
                opacity: 0.9,
              },
            }}
          >
            Save {editedCartItem ? "cart" : "booking"} item
          </Button>
        </Box>
      ) : (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Button
            variant="contained"
            size="large"
            fullWidth
            onClick={handleSubmit(SubmitMode.BUY_NOW)}
            disabled={isLoading}
            sx={{
              "&": {
                borderRadius: 10,
                textTransform: "none",
                paddingY: 2,
                color: "#FFF",
                mr: 2,
              },
              "&:hover": {
                background: theme.palette.primary.main,
                opacity: 0.9,
              },
            }}
          >
            Buy now
          </Button>
          <Button
            variant="outlined"
            size="large"
            fullWidth
            onClick={handleSubmit(SubmitMode.ADD_TO_CART)}
            disabled={isLoading}
            sx={{
              borderRadius: 10,
              textTransform: "none",
              paddingY: 2,
              borderWidth: 2,
              "&:hover": {
                borderWidth: 2,
              },
            }}
          >
            Add to Cart
          </Button>
        </Box>
      )}
    </Paper>
  );
}
