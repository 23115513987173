import { ReactNode, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { MoreHoriz as MoreIcon } from "@mui/icons-material";

import {
  selectEvent,
  useAppDispatch,
  useAppSelector,
  deleteCustomCalendarEvent,
} from "src/store";
import { CalendarEvent as CalendarEventType, VendorTypeId } from "src/types";
import {
  getHourQuartersDifference,
  parseTime,
  roundTimeQuarterHour,
} from "src/utils";
import { useGetCurrentPartyInfo } from "src/hooks";
import { ReactComponent as DiningBlueIcon } from "src/design/images/dining-event-icon.svg";
import { ReactComponent as ActivityBlueIcon } from "src/design/images/activity-event-icon.svg";
import { ReactComponent as CustomBlueIcon } from "src/design/images/accomodation-event-icon.svg";

import { CalendarMenu } from "./CalendarMenu";

interface Props {
  event: CalendarEventType & { width: number; position: number };
  startHour: number;
  zIndex?: number;
}

const INITIAL_OFFSET_PX = 52;
const OFFSET_PER_HOUR_PX = 64;
const OFFSET_PER_15M_PX = 17;

export const CalendarEvent = ({ event, startHour, zIndex = 1 }: Props) => {
  const { isAdmin } = useGetCurrentPartyInfo();
  const dispatch = useAppDispatch();
  const { calendarEvents, activePartyId } = useAppSelector(
    ({ calendar, party }) => ({
      ...calendar,
      activePartyId: party.activePartyId,
    })
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { startTime, name, description, vendorTypeId, __typename, endTime } =
    event;
  const { calendarItemId = "" } =
    calendarEvents.find(
      ({
        startTime: _startTime,
        endTime: _endTime,
        name: _name,
        description: _descr,
      }) =>
        _startTime === startTime &&
        _endTime === endTime &&
        _name === name &&
        _descr === description
    ) || {};

  const bookingEventColors: Record<number, string> = {
    [VendorTypeId.ACTIVITY]: "#DBB656",
    [VendorTypeId.DINNING]: "#6C9B77",
  };

  const bookingEventIcons: Record<number, ReactNode> = {
    [VendorTypeId.ACTIVITY]: <ActivityBlueIcon height={14} stroke="#FFF" />,
    [VendorTypeId.DINNING]: <DiningBlueIcon height={14} fill="#FFF" />,
  };

  const roundedTime = roundTimeQuarterHour(startTime || new Date());
  const { hours, mins } = parseTime(roundedTime);
  const quartersCount = Math.round(mins / 15);
  const minsOffset = quartersCount ? OFFSET_PER_15M_PX * quartersCount : 0;
  const hoursDifference = hours - startHour;
  const offset =
    INITIAL_OFFSET_PX + OFFSET_PER_HOUR_PX * hoursDifference + minsOffset;
  const isCalendarEvent = __typename === "calendar";
  const eventColor = isCalendarEvent
    ? "#6977A6"
    : bookingEventColors[vendorTypeId || 2];
  const eventIcon = isCalendarEvent ? (
    <CustomBlueIcon height={14} fill="#FFF" />
  ) : (
    <span style={{ position: "relative", bottom: -2 }}>
      {bookingEventIcons[vendorTypeId || 2]}
    </span>
  );
  let [hoursDuration, quartersDuration] =
    startTime && endTime
      ? getHourQuartersDifference(startTime, endTime)
      : [1, 0];

  if (hours + hoursDuration > 24) {
    hoursDuration = 24 - hours;
  }

  const handleClick = () => {
    if (!isAdmin) {
      return;
    }
    dispatch(selectEvent(event));
  };

  const handleClickOnMore = (event: React.MouseEvent<HTMLElement>) => {
    if (!isAdmin) {
      return;
    }
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleDelete = () => {
    if (!isAdmin) {
      return;
    }
    dispatch(
      deleteCustomCalendarEvent({ calendarItemId, partyId: activePartyId })
    );
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        borderRadius={3}
        width={`${event?.width}%`}
        px={1.5}
        onClick={handleClick}
        position="absolute"
        minHeight={24}
        zIndex={zIndex}
        height={
          hoursDuration * OFFSET_PER_HOUR_PX +
          quartersDuration * OFFSET_PER_15M_PX
        }
        py={0.5}
        left={`${event?.position}%`}
        sx={{
          backgroundColor: eventColor,
          border: 1,
          borderColor: "transparent",
          top: `${offset}px`,
          cursor: "pointer",
          "&:hover": {
            zIndex: 200,
            opacity: 0.95,
            border: 1,
            borderColor: "#d9deed",
          },
        }}
        overflow="hidden"
        boxShadow="rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            variant="body1"
            fontWeight={700}
            color="#FFF"
            lineHeight="16px"
            fontSize={14}
            mb={0.4}
          >
            {eventIcon}
            {name}
          </Typography>
          {isCalendarEvent && (
            <IconButton onClick={handleClickOnMore}>
              <MoreIcon htmlColor="#FFF" />
            </IconButton>
          )}
        </Box>
        <Typography
          variant="body1"
          color="#FFF"
          fontStyle="italic"
          fontSize={12}
          lineHeight="12px"
        >
          {description}
        </Typography>
      </Box>
      {isCalendarEvent && (
        <CalendarMenu
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          onDelete={handleDelete}
        />
      )}
    </>
  );
};
