import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { AUTH_TYPE, createAuthLink } from "aws-appsync-auth-link";

import { ClientProvider, SessionKeys } from "src/types";

const region = "us-east-1";
const url = process.env.REACT_APP_GRAPH_URL || "";
const API_KEY = process.env.REACT_APP_AWS_GUEST_API_KEY || "";

const httpLink = createHttpLink({
  uri: url,
});

function setupApolloClientGetter() {
  let client: ApolloClient<NormalizedCacheObject> | null = null;
  let activeClientType: ClientProvider | "" = "";

  return () => {
    const token: string = window.localStorage.getItem(SessionKeys.TOKEN) || "";
    const isApolloInited = activeClientType === "apollo" && !!token;
    const isGuestInited = activeClientType === "guest" && !token;

    if (client && (isApolloInited || isGuestInited)) {
      // if proper provider set - get it from cache
      return client;
    }

    if (token) {
      // if provider unset and user auth. - create and set Cognito provider
      const cognitoAuth = {
        type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS as const,
        jwtToken: token ? `Bearer ${token}` : "",
      };

      const cognitoAuthLink = createAuthLink({
        auth: cognitoAuth,
        region,
        url,
      });

      const cognitoClient = new ApolloClient({
        name: "Cognito",
        link: ApolloLink.from([cognitoAuthLink, httpLink]),
        cache: new InMemoryCache(),
      });

      client = cognitoClient;
      activeClientType = "apollo";

      return cognitoClient;
    }
    // if provider unset and user is guest - create and set API Key provider
    const apiKeyAuth = {
      type: AUTH_TYPE.API_KEY as const,
      apiKey: API_KEY,
    };

    const apiKeyAuthLink = createAuthLink({
      auth: apiKeyAuth,
      region,
      url,
    });

    const guestClient = new ApolloClient({
      name: "API Key",
      link: ApolloLink.from([apiKeyAuthLink, httpLink]),
      cache: new InMemoryCache(),
    });

    client = guestClient;
    activeClientType = "guest";

    return guestClient;
  };
}

export const getApolloClient = setupApolloClientGetter();

export const apolloClient = getApolloClient();
