import { Dispatch, FormEvent, SetStateAction } from "react";
import { Button, Slider, Grid, Box, Typography, useTheme } from "@mui/material";

import { MAX_PARTY_VIBE_VALUE, MIN_PARTY_VIBE_VALUE } from "src/types";
import { useAppSelector } from "src/store";

import { PartyBrideVibe } from "./types";

interface Props {
  variant: "create" | "edit";
  vibe: PartyBrideVibe;
  setVibe: Dispatch<SetStateAction<PartyBrideVibe>>;
  onSubmit: () => void;
}

const valueText = (value: number) => {
  return `${value}`;
};

export const PartyBrideVibeForm = ({
  variant,
  vibe,
  setVibe,
  onSubmit,
}: Props) => {
  const theme = useTheme();
  const { isLoading } = useAppSelector(({ party }) => party);
  const handlePropertyChange =
    (fieldName: keyof PartyBrideVibe) =>
    (event: Event | null, newValue: number | number[]) => {
      setVibe((prev) => ({ ...prev, [fieldName]: newValue as number }));
    };

  const handleAdventureChange = handlePropertyChange("adventure");
  const handleChillChange = handlePropertyChange("chill");
  const handlePartyChange = handlePropertyChange("party");
  const handleWellnessChange = handlePropertyChange("wellness");

  const label = variant.charAt(0).toUpperCase() + variant.slice(1);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit();
  };

  return (
    <Box
      component="form"
      m="auto"
      noValidate
      onSubmit={handleSubmit}
      sx={{ mt: 5, maxWidth: 500 }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography alignSelf="center" variant="h4" sx={{ marginBottom: 2 }}>
            Bride's Vibe
          </Typography>
          <Typography>
            Use this sliding scale to tell us what type of weekend you want and
            watch the magic happen. Our technology will use the information you
            provide to create a curated list of bach recommendations.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>Party</Typography>
          <Slider
            aria-label="Party"
            aria-valuetext="Party"
            value={vibe.party}
            getAriaValueText={valueText}
            onChange={handlePartyChange}
            valueLabelDisplay="off"
            step={1}
            min={MIN_PARTY_VIBE_VALUE}
            max={MAX_PARTY_VIBE_VALUE}
          />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={-1.4}
          >
            <Typography
              fontSize={15}
              color="rgba(0, 0, 0, 0.6)"
              onClick={() => handlePartyChange(null, MIN_PARTY_VIBE_VALUE)}
              sx={{ "&:hover": { cursor: "pointer", opacity: 0.9 } }}
            >
              Girls' Night In
            </Typography>
            <Typography
              fontSize={15}
              color="rgba(0, 0, 0, 0.6)"
              onClick={() => handlePartyChange(null, MAX_PARTY_VIBE_VALUE)}
              sx={{ "&:hover": { cursor: "pointer", opacity: 0.9 } }}
            >
              Life of the Party!
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography>Adventure</Typography>
          <Slider
            aria-label="Adventure"
            aria-valuetext="Adventure"
            value={vibe.adventure}
            getAriaValueText={valueText}
            onChange={handleAdventureChange}
            valueLabelDisplay="off"
            step={1}
            min={MIN_PARTY_VIBE_VALUE}
            max={MAX_PARTY_VIBE_VALUE}
          />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={-1.4}
          >
            <Typography
              fontSize={15}
              color="rgba(0, 0, 0, 0.6)"
              onClick={() => handleAdventureChange(null, MIN_PARTY_VIBE_VALUE)}
              sx={{ "&:hover": { cursor: "pointer", opacity: 0.9 } }}
            >
              Adventure-averse
            </Typography>
            <Typography
              fontSize={15}
              color="rgba(0, 0, 0, 0.6)"
              onClick={() => handleAdventureChange(null, MAX_PARTY_VIBE_VALUE)}
              sx={{ "&:hover": { cursor: "pointer", opacity: 0.9 } }}
            >
              Thrill Seeker
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography>Chill</Typography>
          <Slider
            aria-label="Chill"
            aria-valuetext="Chill"
            value={vibe.chill}
            getAriaValueText={valueText}
            onChange={handleChillChange}
            valueLabelDisplay="off"
            step={1}
            min={MIN_PARTY_VIBE_VALUE}
            max={MAX_PARTY_VIBE_VALUE}
          />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={-1.4}
          >
            <Typography
              fontSize={15}
              color="rgba(0, 0, 0, 0.6)"
              onClick={() => handleChillChange(null, MIN_PARTY_VIBE_VALUE)}
              sx={{ "&:hover": { cursor: "pointer", opacity: 0.9 } }}
            >
              Can't Sit Still
            </Typography>
            <Typography
              fontSize={15}
              color="rgba(0, 0, 0, 0.6)"
              onClick={() => handleChillChange(null, MAX_PARTY_VIBE_VALUE)}
              sx={{ "&:hover": { cursor: "pointer", opacity: 0.9 } }}
            >
              Zen Master
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography>Wellness</Typography>
          <Slider
            aria-label="Wellness"
            aria-valuetext="Wellness"
            value={vibe.wellness}
            getAriaValueText={valueText}
            onChange={handleWellnessChange}
            valueLabelDisplay="off"
            step={1}
            min={MIN_PARTY_VIBE_VALUE}
            max={MAX_PARTY_VIBE_VALUE}
          />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={-1.4}
          >
            <Typography
              fontSize={15}
              color="rgba(0, 0, 0, 0.6)"
              onClick={() => handleWellnessChange(null, MIN_PARTY_VIBE_VALUE)}
              sx={{ "&:hover": { cursor: "pointer", opacity: 0.9 } }}
            >
              Not at all Into
            </Typography>
            <Typography
              fontSize={15}
              color="rgba(0, 0, 0, 0.6)"
              onClick={() => handleWellnessChange(null, MAX_PARTY_VIBE_VALUE)}
              sx={{ "&:hover": { cursor: "pointer", opacity: 0.9 } }}
            >
              Health Nut
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Button
        disabled={isLoading}
        type="submit"
        fullWidth
        variant="contained"
        sx={{
          "&": { mt: 3, mb: 2, color: "#FFF" },
          "&:hover": {
            background: theme.palette.primary.main,
            opacity: 0.9,
          },
        }}
      >
        {label} Party
      </Button>
    </Box>
  );
};
