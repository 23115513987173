import { ReactNode } from "react";
import { useNavigate } from "react-router";
import {
  Typography,
  ListItem,
  Chip,
  Box,
  useTheme,
  SxProps,
} from "@mui/material";

interface Props {
  title: string;
  icon: ReactNode;
  link: string;
  disabled?: boolean;
  active?: boolean;
  sx?: SxProps;
  onClick?: CallableFunction;
}

export function SidebarItem({
  link,
  title,
  icon,
  disabled,
  active,
  sx = {},
  onClick,
}: Props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const mainColor = theme.palette.primary.main;
  const opacity = disabled ? 0.5 : 1;
  const handleClick = () => {
    if (!disabled) {
      onClick ? onClick() : navigate(link);
    }
  };

  return (
    <ListItem
      onClick={handleClick}
      sx={{
        display: "flex",
        alignItems: "center",
        pl: 3,
        cursor: disabled ? "default" : "pointer",
        py: 2,
        "&:hover div": { display: "flex" },
        ...sx,
      }}
    >
      <Box
        display="flex"
        bgcolor={active ? mainColor : "transparent"}
        sx={{ opacity, p: 0.5, borderRadius: 3 }}
        color={active ? "#FFF" : "rgba(35, 35, 35, 0.5)"}
      >
        {icon}
      </Box>
      <Typography
        variant="body1"
        fontSize={20}
        sx={{
          ml: 2,
          opacity,
          color: active ? mainColor : "rgba(35, 35, 35, 0.5)",
        }}
      >
        {title}
      </Typography>
      {disabled && (
        <Chip
          label="Coming soon"
          variant="outlined"
          sx={{
            "&": {
              display: "none",
              height: "fit-content",
              color: "#394460",
              ml: 0.5,
            },
            "& span": { p: 0.5, fontSize: 10 },
          }}
        />
      )}
    </ListItem>
  );
}
