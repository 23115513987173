import { Box, CircularProgress, Typography } from "@mui/material";

import { PollAnswers, PollData, UpdatePollDataInput } from "src/graphql";

import { Poll, groupAnswersByPolls } from "../poll";

interface Props {
  items: PollData[];
  answers: PollAnswers[];
  isLoading?: boolean;
  onUpdate: (id: string) => void;
  onDelete: (id: string, partyUid: string) => void;
}

export function ViewPolls({
  items,
  answers,
  isLoading,
  onUpdate,
  onDelete,
}: Props) {
  const answersByPollId = groupAnswersByPolls(answers);

  return (
    <Box display="flex" flexDirection="column">
      {!items.length &&
        (isLoading ? (
          <CircularProgress />
        ) : (
          <Typography
            variant="body1"
            fontSize={20}
            fontWeight={500}
            alignSelf="center"
          >
            No polls yet
          </Typography>
        ))}
      {!!items.length &&
        items.map((p) => (
          <Poll
            key={p.pollId}
            data={p}
            answers={answersByPollId[p.pollId] || []}
            withManagement
            onUpdate={onUpdate}
            onDelete={onDelete}
          />
        ))}
    </Box>
  );
}
