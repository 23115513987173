import { createBrowserRouter, Navigate } from "react-router-dom";

import {
  SignUp,
  Login,
  ResetPassword,
  PrivacyPolicy,
  TermsAndConditions,
  CalendarPage,
  ContactPage,
  Dashboard,
} from "src/pages";
import {
  Party,
  CreateParty,
  Vendor,
  VendorDetails,
  MyBookings,
  Cart,
  EditParty,
  GiftcardPage,
  MyGuestsPage,
  ChecklistPage,
} from "src/features";
import { RouteName } from "src/types";
import {
  getIsAuthenticated,
  sessionStorage,
  trackRouteEvent,
} from "src/services";

import { Layout } from "../Layout";
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <PublicRoute>
        <Layout />
      </PublicRoute>
    ),
    children: [
      {
        index: true,
        element: (
          <Navigate
            to={
              !!sessionStorage.getToken()
                ? RouteName.PARTY
                : RouteName.ALL_VENDORS
            }
          />
        ),
      },
      {
        path: `${RouteName.ALL_VENDORS}`,
        element: <Vendor />,
        loader: () => trackRouteEvent(`${RouteName.ALL_VENDORS}`),
      },
      {
        path: `${RouteName.ALL_VENDORS}/:vendorId`,
        element: <VendorDetails />,
        loader: () => trackRouteEvent(`${RouteName.ALL_VENDORS}/:vendorId`),
      },
      {
        path: `${RouteName.CONTACT}`,
        element: <ContactPage />,
        loader: () => trackRouteEvent(RouteName.CONTACT),
      },
      {
        path: `${RouteName.GIFTCARD}`,
        element: <GiftcardPage />,
        loader: () => trackRouteEvent(RouteName.GIFTCARD),
      },
    ],
  },
  {
    path: "/",
    element: (
      <PrivateRoute>
        <Navigate to={RouteName.PARTY} />
      </PrivateRoute>
    ),
    loader: () => trackRouteEvent(RouteName.PARTY),
  },
  {
    path: RouteName.PRIVACY,
    element: <PrivacyPolicy />,
    loader: () => trackRouteEvent(RouteName.PRIVACY),
  },
  {
    path: RouteName.TERMS,
    element: <TermsAndConditions />,
    loader: () => trackRouteEvent(RouteName.TERMS),
  },
  {
    path: "/",
    element: (
      <PrivateRoute>
        <Layout />
      </PrivateRoute>
    ),
    loader: getIsAuthenticated,
    children: [
      {
        path: RouteName.DASHBOARD,
        element: <Dashboard />,
        loader: () => trackRouteEvent(RouteName.DASHBOARD),
      },
      {
        path: RouteName.PARTY,
        element: <Party />,
        loader: () => trackRouteEvent(RouteName.PARTY),
      },
      {
        path: RouteName.MY_BOOKINGS,
        element: <MyBookings />,
        loader: () => trackRouteEvent(RouteName.MY_BOOKINGS),
      },
      {
        path: RouteName.CREATE_PARTY,
        element: <CreateParty />,
        loader: () => trackRouteEvent(RouteName.CREATE_PARTY),
      },
      {
        path: `${RouteName.PARTY}/:partyId${RouteName.VENDOR}`,
        element: <Vendor />,
        loader: () =>
          trackRouteEvent(`${RouteName.PARTY}/:partyId${RouteName.VENDOR}`),
      },
      {
        path: `${RouteName.PARTY}/:partyId${RouteName.VENDOR}/:vendorId`,
        element: <VendorDetails />,
        loader: () =>
          trackRouteEvent(
            `${RouteName.PARTY}/:partyId${RouteName.VENDOR}/:vendorId`
          ),
      },
      {
        path: `${RouteName.PARTY}/:partyId${RouteName.EDIT_PARTY}`,
        element: <EditParty />,
        loader: () =>
          trackRouteEvent(`${RouteName.PARTY}/:partyId${RouteName.EDIT_PARTY}`),
      },
      {
        path: `${RouteName.CART}`,
        element: <Cart />,
        loader: () => trackRouteEvent(RouteName.CART),
      },
      {
        path: `${RouteName.MY_GUESTS}`,
        element: <MyGuestsPage />,
        loader: () => trackRouteEvent(RouteName.MY_GUESTS),
      },
      {
        path: `${RouteName.CHECKLIST}`,
        element: <ChecklistPage />,
        loader: () => trackRouteEvent(RouteName.CHECKLIST),
      },
      {
        path: `${RouteName.CALENDAR}`,
        element: <CalendarPage />,
        loader: () => trackRouteEvent(RouteName.CALENDAR),
      },
    ],
  },
  {
    path: RouteName.SIGN_UP,
    loader: async () => {
      trackRouteEvent(RouteName.SIGN_UP);
      return await getIsAuthenticated();
    },
    element: (
      <PublicRoute>
        <SignUp />
      </PublicRoute>
    ),
  },
  {
    path: RouteName.LOGIN,
    element: (
      <PublicRoute>
        <Login />
      </PublicRoute>
    ),
    loader: async () => {
      trackRouteEvent(RouteName.LOGIN);
      return await getIsAuthenticated();
    },
  },
  {
    path: RouteName.RESET_PASSWORD,
    element: <ResetPassword />,
    loader: () => trackRouteEvent(RouteName.RESET_PASSWORD),
  },
]);
