import { Calendar, VendorBooking } from "src/graphql";
import { CalendarEvent } from "src/types";

export const mapBookingToEvent = (events: VendorBooking[]): CalendarEvent[] =>
  events.map((event) => ({
    startTime: event.startTime,
    endTime: event.endTime,
    name: event.vendorOfferName || event.pricingName || "",
    description: event.pricingDescription || "",
    allDay: false,
    ...event,
  }));

export const mapCalendarEvent = (events: Calendar[]): CalendarEvent[] =>
  events.map(
    ({ startTime, endTime, __typename, name, description, allDay }) => ({
      startTime,
      endTime,
      name,
      description,
      __typename,
      allDay,
    })
  );
