import {
  Card,
  CardHeader,
  CardActionArea,
  Grid,
  Box,
  Skeleton,
  Typography,
} from "@mui/material";

export function GridItemSkeleton() {
  const skeletonTitle = (
    <Box display="flex" justifyContent="space-between">
      <Skeleton width={80} height={22} sx={{ display: "inline-flex" }} />
      <Typography fontSize={18}>
        from <Skeleton width={40} sx={{ display: "inline-flex" }} />
      </Typography>
    </Box>
  );

  return (
    <Grid item>
      <Card
        sx={{
          height: "auto",
          maxWidth: 280,
          borderRadius: 1,
          boxShadow: "none",
          overflow: "visible",
        }}
      >
        <CardActionArea sx={{ paddingX: 1.5, paddingY: 1.5, borderRadius: 4 }}>
          <Skeleton
            height={150}
            width={250}
            sx={{ borderRadius: 3, transform: "none" }}
          />
          <CardHeader
            title={skeletonTitle}
            subheader={<Skeleton width="70%" />}
          />
        </CardActionArea>
      </Card>
    </Grid>
  );
}
