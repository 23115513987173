import { CognitoUser } from "amazon-cognito-identity-js";
import { CognitoIdentity } from "aws-sdk";

import { CartBooking } from "src/graphql";
import { SessionKeys } from "src/types";

let cachedToken = "";
let cachedUser: CognitoUser | null = null;

export const sessionStorage = {
  setToken(token: string) {
    cachedToken = token;
    window.localStorage.setItem(SessionKeys.TOKEN, token);
  },

  getToken() {
    return cachedToken || window.localStorage.getItem(SessionKeys.TOKEN);
  },

  setUser(user: CognitoUser) {
    cachedUser = user;
    user.getUserAttributes((error, data) => {
      const fullName = data?.find((attr) => attr.Name === "name")?.getValue();
      window.sessionStorage.setItem(SessionKeys.FULLNAME, fullName || "");
    });

    window.sessionStorage.setItem(SessionKeys.USERNAME, user.getUsername());
  },

  getUser() {
    return cachedUser;
  },

  getUsername() {
    return (
      cachedUser?.getUsername() ||
      window.sessionStorage.getItem(SessionKeys.USERNAME)
    );
  },

  async getFullname() {
    const sessionFullName = window.sessionStorage.getItem(SessionKeys.FULLNAME);

    if (!sessionFullName) {
      const fullName: string = await new Promise((resolve, reject) => {
        cachedUser?.getUserAttributes((error, data) => {
          if (error) {
            return reject(error);
          }

          const fullNameValue =
            data?.find((attr) => attr.Name === "name")?.getValue() || "";
          window.sessionStorage.setItem(SessionKeys.FULLNAME, fullNameValue);
          return resolve(fullNameValue);
        });
      });
      return fullName;
    }

    return sessionFullName;
  },

  setCartItems(items: CartBooking[]) {
    window.sessionStorage.setItem(
      SessionKeys.CART_ITEMS,
      JSON.stringify(items)
    );
  },

  getCartItems() {
    const items = window.sessionStorage.getItem(SessionKeys.CART_ITEMS);

    return JSON.parse(items || "[]");
  },

  setGoogleIdToken(token: string) {
    window.localStorage.setItem(SessionKeys.GOOGLE_ID_TOKEN, token);
  },

  getGoogleIdToken() {
    const token = window.localStorage.getItem(SessionKeys.GOOGLE_ID_TOKEN);

    return token;
  },

  setGuestCredentials(credentials: CognitoIdentity.Credentials) {
    return window.localStorage.setItem(
      SessionKeys.GUEST_CREDENTIALS,
      JSON.stringify(credentials)
    );
  },

  getGuestCredentials(): CognitoIdentity.Credentials {
    return JSON.parse(
      window.localStorage.getItem(SessionKeys.GUEST_CREDENTIALS) || ""
    );
  },

  clear() {
    cachedToken = "";
    cachedUser = null;
    window.sessionStorage.clear();
    window.localStorage.clear();
  },
};
