import { gql } from "@apollo/client";

export const FETCH_POLL_ITEMS = gql`
  query Items($filter: TablePollDataFilterInput) {
    listPollData(filter: $filter) {
      items {
        options {
          type
          text
          value
        }
        partyUid
        pollId
        question
        type
        anonymous
      }
      nextToken
    }
  }
`;

export const FETCH_POLL_ANSWERS = gql`
  query Items($filter: TablePollAnswersFilterInput) {
    listPollAnswers(filter: $filter) {
      items {
        pollAnswerId
        pollId
        partyUid
        userId
        answers
      }
    }
  }
`;
