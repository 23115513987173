import { sessionStorage } from "src/services";
import { RouteName } from "src/types";

export const handleAuthError = (error: any) => {
  if (error?.message?.includes("401") && !!sessionStorage.getToken()) {
    window.location.href = window.location.href?.includes(RouteName.ALL_VENDORS)
      ? RouteName.ALL_VENDORS
      : RouteName.LOGIN;
  }
};
