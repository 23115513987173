import { FormEvent, useCallback, useEffect, useState } from "react";
import { useRevalidator } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";

import { loginCognitoUser, useOAuthForToken } from "src/services";
import {
  EMAIL_MIN_LENGTH,
  EMAIL_PATTERN,
  EMAIL_PATTERN_MESSAGE,
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  PASSWORD_PATTERN,
  PASSWORD_PATTERN_MESSAGE,
  RouteName,
  SessionKeys,
} from "src/types";
import { validate } from "src/utils";
import { Link, SimpleInput } from "src/components";
import { ReactComponent as GoogleIcon } from "src/design/images/google.svg";

import {
  BackgroundPane,
  BottomGradient,
  ContentPane,
  AuthWithProviderButton,
} from "./components";

interface ErrorState {
  email?: string;
  password?: string;
}

export function Login() {
  const { revalidate } = useRevalidator();
  const { handler } = useOAuthForToken("login");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorState, setErrorState] = useState<ErrorState>({});

  const validatePassword = useCallback(() => {
    const { error: passwordError } = validate("Password", password, {
      required: true,
      minLength: PASSWORD_MIN_LENGTH,
      maxLength: PASSWORD_MAX_LENGTH,
      trimmed: true,
      pattern: {
        regExp: PASSWORD_PATTERN,
        message: PASSWORD_PATTERN_MESSAGE,
      },
    });

    setErrorState((prev) => ({
      ...prev,
      password: passwordError,
    }));

    return passwordError;
  }, [password, setErrorState]);

  useEffect(() => {
    sessionStorage.setItem(SessionKeys.IS_FROM_LOGIN, "true")
  }, []);

  useEffect(() => {
    validatePassword();
  }, [validatePassword]);

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const { error: emailError } = validate("Email", email, {
      required: true,
      minLength: EMAIL_MIN_LENGTH,
      pattern: {
        regExp: EMAIL_PATTERN,
        message: EMAIL_PATTERN_MESSAGE,
      },
    });

    const passwordError = validatePassword();

    setErrorState({
      email: emailError,
      password: passwordError,
    });

    if (emailError || passwordError) {
      return;
    }

    loginCognitoUser({
      email,
      password,
      callback: revalidate,
    });
  }

  const handleOAuthLogin = () => {
    handler();
  };

  return (
    <Grid
      container
      component="main"
      sx={{ minHeight: "100vh", position: "relative", overflowX: "hidden" }}
    >
      <ContentPane title="Welcome">
        <AuthWithProviderButton
          text="Login with Google"
          icon={<GoogleIcon width={30} height={30} />}
          onClick={handleOAuthLogin}
        />
        <Typography
          variant="body2"
          fontStyle="italic"
          fontWeight={400}
          fontSize={14}
          lineHeight="17px"
          textAlign="center"
          my={4.2}
        >
          OR
        </Typography>
        <Box width={1} component="form" noValidate onSubmit={handleSubmit}>
          <Box display="flex" marginBottom={1} justifyContent="space-between">
            <Box width={1} mr={1.25}>
              <SimpleInput
                value={email}
                onChange={(value) => setEmail(value)}
                errorText={errorState.email}
                id="email"
                name="email"
                placeholder="Email Address"
                autoComplete="email"
                type="email"
              />
            </Box>
            <Box width={1} ml={1.25}>
              <SimpleInput
                value={password}
                onChange={(value) => setPassword(value)}
                errorText={errorState.password}
                id="password"
                name="password"
                placeholder="Password"
                autoComplete="new-password"
                type="password"
              />
            </Box>
          </Box>
          <FormControlLabel
            control={<Checkbox value="remember" color="success" />}
            label={
              <Typography variant="body1" color="#394460">
                Remember me
              </Typography>
            }
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              textTransform: "none",
              background:
                "linear-gradient(94.01deg, #B26E6F 0%, #FE9688 100.02%)",
              boxShadow: "0px 10px 50px rgba(254, 150, 136, 0)",
              borderRadius: 10,
              py: 1.5,
              fontSize: 16,
              color: "#FFF",
            }}
          >
            Login
          </Button>
          <Grid
            container
            gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))"
            justifyContent="space-between"
            gap={1}
          >
            <Grid item>
              <Link to={RouteName.RESET_PASSWORD}>
                <Typography color="#394460">
                  Forgot password?&nbsp;
                  <b>
                    <u>Reset here</u>
                  </b>
                </Typography>
              </Link>
            </Grid>
            <Grid item>
              <Link to={RouteName.SIGN_UP}>
                <Typography color="#394460">
                  Don't have an account?{" "}
                  <b>
                    <u>Sign Up</u>
                  </b>
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </ContentPane>
      <BackgroundPane />
      <BottomGradient />
    </Grid>
  );
}
