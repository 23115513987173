import { CognitoIdentity, config } from "aws-sdk";
import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserSession,
} from "amazon-cognito-identity-js";

import { sessionStorage } from "../storage/sessionStorage";

config.update({
  region: "us-east-1",
});

const poolData = {
  UserPoolId: process.env.REACT_APP_USER_POOL_ID ?? "",
  ClientId: process.env.REACT_APP_CLIENT_ID ?? "",
};
const identityPoolId = process.env.REACT_APP_IDENTITY_POOL_ID ?? "";

const userPool = new CognitoUserPool(poolData);
const cognitoIdentity = new CognitoIdentity();

interface SignUpPayload {
  email: string;
  phone: string;
  password: string;
  name: string;
}

interface LoginPayload {
  email: string;
  password: string;
  callback: Function;
}

interface CognitoCallbacks {
  onSuccess: (data: any) => void;
  onFailure: (err: Error) => void;
}

interface ResetPasswordPayload {
  email: string;
  callbacks: CognitoCallbacks;
}

interface ConfirmNewPasswordPayload {
  email: string;
  code: string;
  newPassword: string;
  callbacks: CognitoCallbacks;
}

const getCognitoUserByEmail = (email: string) =>
  new CognitoUser({
    Username: email,
    Pool: userPool,
  });

export async function registerCognitoUser({
  name,
  email,
  phone,
  password,
}: SignUpPayload) {
  const userAttributes = {
    email,
    name,
    phone_number: phone,
  };

  const attributeList = Object.entries(userAttributes)
    .map(([Name, Value]) => ({ Name, Value }))
    .map((dataAttr) => new CognitoUserAttribute(dataAttr));

  const user: CognitoUser = await new Promise((resolve, reject) => {
    userPool.signUp(
      email.toLowerCase(),
      password,
      attributeList,
      [],
      (err, result) => {
        if (err) {
          reject(err);
        }
        const cognitoUser = result?.user as CognitoUser;
        resolve(cognitoUser);
      }
    );
  });

  return user;
}

export async function loginCognitoUser({
  email,
  password,
  callback,
}: LoginPayload) {
  const authenticationDetails = new AuthenticationDetails({
    Username: email.toLowerCase(),
    Password: password,
    ClientMetadata: {
      AnalyticsEndpointId: email.toLowerCase(),
    },
  });

  const cognitoUser = getCognitoUserByEmail(email.toLowerCase());

  cognitoUser.authenticateUser(authenticationDetails, {
    onSuccess: async function (result) {
      const accessToken = result.getAccessToken().getJwtToken();
      sessionStorage.setToken(accessToken);
      sessionStorage.setUser(cognitoUser);
      callback();
    },

    onFailure: function (err) {
      alert(err.message || JSON.stringify(err));
    },
  });
}

export function resetPasswordCognitoUser({
  email,
  callbacks,
}: ResetPasswordPayload) {
  const cognitoUser = getCognitoUserByEmail(email.toLowerCase());
  cognitoUser.forgotPassword(callbacks);
}

export function confirmRestoredPasswordCognitoUser({
  email,
  code,
  newPassword,
  callbacks,
}: ConfirmNewPasswordPayload) {
  const cognitoUser = getCognitoUserByEmail(email.toLowerCase());
  cognitoUser.confirmPassword(code, newPassword, callbacks);
}

export async function getIsAuthenticated() {
  const isAuthenticated: boolean = await new Promise((resolve) => {
    const currentUser = userPool.getCurrentUser();

    if (!currentUser) {
      sessionStorage.clear();
      return resolve(false);
    }

    currentUser.getSession(
      (error: Error | null, session: CognitoUserSession) => {
        if (error) {
          // Handle error
          sessionStorage.clear();
          return resolve(false);
        }

        sessionStorage.setToken(session.getAccessToken().getJwtToken());
        sessionStorage.setUser(currentUser);

        resolve(session.isValid());
      }
    );
  });

  return isAuthenticated;
}

export async function signOutCognitoUser() {
  const currentUser = userPool.getCurrentUser();
  currentUser?.signOut();
}

export const getCredentialsForGuest = async () => {
  try {
    const { IdentityId = "" } = await cognitoIdentity
      .getId({ IdentityPoolId: identityPoolId })
      .promise();

    const data = await cognitoIdentity
      .getCredentialsForIdentity({ IdentityId })
      .promise();

    return sessionStorage.setGuestCredentials(data.Credentials!);
  } catch (error) {
    console.error(error);
  }

  return null;
};
