import { ReactNode } from "react";
import { Button } from "@mui/material";

interface Props {
  text: string;
  icon: ReactNode;
  onClick: () => void;
}

export const AuthWithProviderButton = ({ text, icon, onClick }: Props) => {
  return (
    <Button
      type="button"
      fullWidth
      onClick={onClick}
      variant="outlined"
      sx={{
        "&": {
          textTransform: "none",
          background: "transparent",
          filter: "drop-shadow(0px 10px 50px rgba(254, 150, 136, 0.4))",
          borderColor: "#394460",
          borderRadius: 10,
          alignItems: "center",
          px: 3,
          py: 1.5,
          fontSize: 16,
          lineHeight: "19px",
          fontWeight: 700,
          color: "#394460",
        },
        "&:hover": {
          borderColor: "#394460",
          opacity: 0.95,
        },
      }}
    >
      <span style={{ marginRight: 10 }}>{icon}</span>
      {text}
    </Button>
  );
};
