import {
  Query,
  CreateBachPartyInput,
  Mutation,
  UpdateBachPartyInput,
  getApolloClient,
} from "src/graphql";
import { handleAuthError } from "src/utils";

import { FETCH_PARTY_LIST } from "./queries";
import { SAVE_PARTY, UPDATE_PARTY } from "./mutations";

class PartyService {
  get apolloClient() {
    return getApolloClient();
  }

  async fetchPartyList(userName: string) {
    try {
      const response = await Promise.all([
        this.apolloClient.query<Query>({
          query: FETCH_PARTY_LIST,
          variables: {
            filter: {
              editUsers: {
                contains: userName,
              },
            },
          },
          fetchPolicy: "network-only",
        }),
        this.apolloClient.query<Query>({
          query: FETCH_PARTY_LIST,
          variables: {
            filter: {
              viewUsers: {
                contains: userName,
              },
            },
          },
          fetchPolicy: "network-only",
        }),
      ]);

      const partyItems = response?.[0]?.data?.listBachParties?.items;
      const viewingItems = response?.[1]?.data?.listBachParties?.items;
      if (!partyItems || !viewingItems) {
        throw new Error("Can not retrieve parties");
      }

      const partyIdsSet = new Set();
      const mergedParties = [...partyItems, ...viewingItems].filter((p) => {
        if (partyIdsSet.has(p?.partyUid)) {
          return false;
        }
        partyIdsSet.add(p?.partyUid);
        return true;
      });
      return mergedParties || [];
    } catch (error) {
      handleAuthError(error);
      throw error;
    }
  }

  async createParty(params: CreateBachPartyInput) {
    try {
      const response = await this.apolloClient.mutate<Mutation>({
        mutation: SAVE_PARTY,
        variables: {
          input: {
            brideName: params?.brideName,
            name: params?.name,
            endDate: params?.endDate,
            startDate: params?.startDate,
            bVibeAdv: params?.bVibeAdv,
            bVibeChill: params?.bVibeChill,
            bVibeParty: params?.bVibeParty,
            bVibeWellness: params?.bVibeWellness,
            editUsers: params?.editUsers,
            unknownDate: params?.unknownDate,
            location: params?.location,
            guests: params?.guests,
          },
        },
      });

      const createdParty = response?.data?.createBachParty;

      if (!createdParty) {
        throw new Error("Can not save the party");
      }

      return createdParty;
    } catch (error) {
      handleAuthError(error);
      throw error;
    }
  }

  async updateParty(params: UpdateBachPartyInput) {
    try {
      const response = await this.apolloClient.mutate<Mutation>({
        mutation: UPDATE_PARTY,
        variables: {
          input: {
            brideName: params?.brideName,
            name: params?.name,
            endDate: params?.endDate,
            startDate: params?.startDate,
            bVibeAdv: params?.bVibeAdv,
            bVibeChill: params?.bVibeChill,
            bVibeParty: params?.bVibeParty,
            bVibeWellness: params?.bVibeWellness,
            editUsers: params?.editUsers,
            unknownDate: params?.unknownDate,
            location: params?.location,
            guests: params?.guests,
            partyUid: params?.partyUid || "",
            viewUsers: params?.viewUsers,
          },
        },
      });

      const updatedParty = response?.data?.updateBachParty;

      if (!updatedParty) {
        throw new Error("Can not update the party");
      }

      return updatedParty;
    } catch (error) {
      handleAuthError(error);
      throw error;
    }
  }
}

export const partyService = new PartyService();
