import { ReactNode, memo } from "react";
import {
  Checkbox,
  FormControlLabel,
  FormControlLabelProps,
} from "@mui/material";

type Props = {
  text: string;
  value: string | number;
  onClick?: () => void;
  selected?: boolean;
  disabled?: boolean;
  control?: FormControlLabelProps["control"];
};

export const CheckboxOption = memo(
  ({ value, text, onClick, selected, disabled, control }: Props) => {
    return (
      <FormControlLabel
        disabled={disabled}
        value={value}
        control={control || <Checkbox checked={selected} onChange={onClick} />}
        label={text}
      />
    );
  }
);
