import { Box, useTheme } from "@mui/material";
import { RouteName } from "src/types";

import { Link } from "../ui-kit";
import { Copyright } from "./Copyright";

export function Footer() {
  const theme = useTheme();
  const textColor = theme.palette.secondary.light;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      mt={5}
      mb={1}
      gap={2}
    >
      <Copyright />
      <Link to={RouteName.PRIVACY} sx={{ color: textColor }}>
        Privacy policy
      </Link>
      <Link to={RouteName.TERMS} sx={{ color: textColor }}>
        Terms and conditions
      </Link>
    </Box>
  );
}
