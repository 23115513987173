export enum PollVariant {
  SelectOne = "select_one",
  SelectMany = "select_many",
  Text = "text",
}

export interface PollOption {
  text: string;
  value: string;
  type: string | null;
}

export interface PollData {
  id: string;
  type: PollVariant;
  question: string;
  options: PollOption[];
}
