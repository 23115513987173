import { useState } from "react";
import { useMediaQuery, useTheme } from "@mui/material";

import { LAPTOP_BREAKPOINT } from "src/types";

import { Sidebar } from "./Sidebar";
import { Header } from "./Header";
import { MobileHeader } from "../mobile/MobileHeader";

export function AppBarContent({ pagename }: { pagename: string }) {
  const theme = useTheme();
  const matchesSmall = useMediaQuery(theme.breakpoints.down(LAPTOP_BREAKPOINT));
  const [open, setOpen] = useState(true);

  const bgColor = theme.palette.primary.dark;
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <div style={{ backgroundColor: bgColor }}>
      {matchesSmall ? (
        <MobileHeader pagename={pagename} />
      ) : (
        <>
          <Header toggleOpen={toggleDrawer} />
          <Sidebar
            isOpen={open}
            toggleOpen={toggleDrawer}
            pagename={pagename}
          />
        </>
      )}
    </div>
  );
}
