import { gql } from "@apollo/client";

export const FETCH_CALENDAR_EVENTS_LIST = gql`
  query Items($filter: TableCalendarFilterInput) {
    listCalendars(filter: $filter) {
      items {
        allDay
        calendarItemId
        description
        endDate
        endTime
        name
        partyId
        startDate
        startTime
      }
    }
  }
`;
