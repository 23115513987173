export const addOrRemove = <T>(
  array: T[],
  newItem: T,
  primaryKey?: keyof T
) => {
  const index = array.findIndex((item) =>
    primaryKey ? item[primaryKey] === newItem[primaryKey] : item === newItem
  );

  return index === -1
    ? [...array, newItem]
    : array.filter((_, i) => i !== index);
};
