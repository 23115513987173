import { ALL_LOCATIONS_VALUE, PartyVendor } from "src/types";

export const filterByLocation = (
  data: PartyVendor[],
  selectedLocation: string
) =>
  selectedLocation === ALL_LOCATIONS_VALUE
    ? data
    : data.filter(
        ({ location }) =>
          location === selectedLocation || location === ALL_LOCATIONS_VALUE
      );
