import { useEffect } from "react";
import { sessionStorage } from "src/services";
import { fetchAllPartyInvites, useAppDispatch, useAppSelector } from "src/store";

type Props = {
  withInvites?: boolean;
}

const defaultProps: Props = {
  withInvites: false,
}

export const useGetCurrentPartyInfo = ({ withInvites }: Props = defaultProps) => {
  const username = sessionStorage.getUsername() ?? "";
  const dispatch = useAppDispatch();
  const { activePartyId, partyList, isLoading, invites } = useAppSelector(
    ({ party }) => party
  );
  const activeParty = partyList.find(
    ({ partyUid }) => partyUid === activePartyId
  );
  const isEditUser = activeParty?.editUsers
    ?.map((email) => email.toLocaleLowerCase())
    .includes(username.toLocaleLowerCase());

  const isViewUser = activeParty?.viewUsers
    ?.map((email) => email?.toLocaleLowerCase())
    .includes(username.toLocaleLowerCase());

    useEffect(() => {
      if (!withInvites) {
        return;
      }
      dispatch(fetchAllPartyInvites(activePartyId));
    }, [activePartyId, withInvites]);

  return {
    activeParty,
    isAdmin: isEditUser,
    isGuest: isViewUser,
    isPartyLoading: isLoading,
    invites,
  };
};
