import { createAsyncThunk } from "@reduxjs/toolkit";

import { vendorService } from "src/services";
import { VendorTab } from "src/types";

interface Payload {
  id: string;
  type: VendorTab;
}

export const fetchAllVendorsByType = createAsyncThunk(
  "vendor/getAllVendorsByType",
  async ({ type }: { type: VendorTab }) => {
    const vendors = await vendorService.fetchVendorListByType(type);

    return vendors;
  }
);

export const fetchVendorByIdAndType = createAsyncThunk(
  "vendor/getVendorByIdAndType",
  async ({ id, type }: Payload) => {
    const vendors = await vendorService.fetchVendorByIdAndType(id, type);

    return vendors;
  }
);
