import { gql } from "@apollo/client";

export const CREATE_POLL = gql`
  mutation createPoll($input: CreatePollDataInput!) {
    createPollData(input: $input) {
      options {
        type
        text
        value
      }
      partyUid
      pollId
      question
      type
      anonymous
      createTime
    }
  }
`;

export const UPDATE_POLL = gql`
  mutation updatePoll($input: UpdatePollDataInput!) {
    updatePollData(input: $input) {
      options {
        Type
        text
        value
      }
      partyUid
      pollId
      question
      type
      anonymous
      createTime
    }
  }
`;

export const DELETE_POLL = gql`
  mutation deletePoll($input: DeletePollDataInput!) {
    deletePollData(input: $input) {
      options {
        Type
        text
        value
      }
      partyUid
      pollId
      question
      type
      anonymous
      createTime
    }
  }
`;

export const CREATE_POLL_ANSWER = gql`
  mutation createPollAnswer($input: CreatePollAnswersInput!) {
    createPollAnswers(input: $input) {
      pollAnswerId
      pollId
      partyUid
      userId
      answers
    }
  }
`;
