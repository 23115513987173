import { useState } from "react";
import { DataGridPro, GridColDef, GridRowEditStopParams, useGridApiRef } from "@mui/x-data-grid-pro";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Add as PlusIcon } from "@mui/icons-material";

import {
  createNewInvite,
  deleteInvite,
  updateInvite,
  updateParty,
  useAppDispatch,
} from "src/store";
import { useGetCurrentPartyInfo } from "src/hooks";
import { BachPartyInvite } from "src/graphql";
import { toast } from "react-toastify";

const columns: GridColDef[] = [
  {
    field: "email",
    headerName: "Email",
    width: 300,
  },
  {
    field: "name",
    headerName: "Name",
    width: 200,
    editable: true,
  },
  {
    field: "status",
    headerName: "Status",
    width: 100,
  },
  {
    field: "phone",
    headerName: "Phone",
    width: 150,
    editable: true,
  },
  {
    field: "edit",
    headerName: "Actions",
    width: 150,
    align: "center",
    headerAlign: "center",
    renderCell: ({ row }) => (
      <Button
        variant="contained"
        onClick={() => row.onRemove()}
        size="small"
        disabled={row.isDisabled}
        sx={{
          "&": { color: "#FFF", borderRadius: 4 },
          "&:hover": {
            background: "#677FD9",
            opacity: 0.9,
          },
        }}
      >
        Remove
      </Button>
    ),
  },
];

export function MyGuestsPage() {
  const dispatch = useAppDispatch();
  const {
    isAdmin,
    activeParty: selectedParty,
    isPartyLoading: isLoading,
    invites,
  } = useGetCurrentPartyInfo({ withInvites: true });
  const [newGuestEmail, setNewGuestEmail] = useState("");
  const apiRef = useGridApiRef();

  const onAddGuest = () => {
    if (!isAdmin) {
      return;
    }
    const { __typename, ...oldPartyData } = selectedParty!;
    const newUsers = [...(selectedParty?.viewUsers || []), newGuestEmail]
      .map((email) => email?.trim()?.toLocaleLowerCase())
      .filter(Boolean) as string[];
    const uniqueUsers = Array.from(new Set(newUsers));
    dispatch(
      updateParty({
        ...oldPartyData,
        viewUsers: uniqueUsers,
      })
    );
    dispatch(
      createNewInvite({
        partyUid: selectedParty?.partyUid || "",
        name: "",
        email: newGuestEmail,
        status: "pending",
      })
    );
    setNewGuestEmail('');
  };

  const removeGuest = ({
    inviteId = "",
    partyUid = "",
    email,
  }: Partial<BachPartyInvite>) => {
    if (!isAdmin) {
      return;
    }
    const { __typename, ...oldPartyData } = selectedParty!;
    const filteredUsers = (selectedParty?.viewUsers || []).filter(
      (_email) => _email !== email
    );
    dispatch(
      updateParty({
        ...oldPartyData,
        viewUsers: filteredUsers,
      })
    );
    dispatch(
      deleteInvite({
        inviteId,
        partyUid,
      })
    );
  };

  const onUpdateRow = (params: GridRowEditStopParams) => {
    const { __typename, ...editedRow } =
      apiRef.current.getRowWithUpdatedValues(params.id, "");

    const { email, name, phone, status, inviteId, partyUid } = editedRow;

    const fieldsWithErrors = Object.entries(
      apiRef.current.store.getSnapshot().editRows[params.id] || {}
    ).filter(([, value]) => (value as { error: boolean }).error);

    if (fieldsWithErrors.length) {
      const columnName = fieldsWithErrors?.[0]?.[0] || "";
      toast.error(
        `Row was not saved because of error in "${columnName}" column.`,
        {
          position: "bottom-right",
        }
      );
      return;
    }

    dispatch(updateInvite({ email, name, phone, status, inviteId, partyUid }))
      .unwrap()
      .then(() => {
        toast.success(`Successfully updated "${email}" row.`, {
          position: "bottom-right",
        });
      });
  };

  const rows = invites.map(
    ({ email, name, status, inviteId, partyUid, phone, deleted }) => ({
      email,
      onRemove: () => removeGuest({ inviteId, partyUid, email }),
      isDisabled: !isAdmin,
      status,
      partyUid,
      inviteId,
      name,
      phone,
    })
  );

  console.log('ROWS:', rows)

  return (
    <Container maxWidth="xl" sx={{ mt: 5, mb: 4 }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paper
            elevation={4}
            sx={{
              p: 4,
              display: "flex",
              flexDirection: "column",
              borderRadius: 10,
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              style={{ width: "100%" }}
              mb={4}
            >
              <Typography variant="h1" fontSize={42} fontWeight={600}>
                My Guests
              </Typography>
              <Box display="flex" alignItems="center" width={300}>
                <TextField
                  value={newGuestEmail}
                  size="medium"
                  disabled={isLoading}
                  onChange={(event) => setNewGuestEmail(event.target.value)}
                  variant="outlined"
                  placeholder="Add guests by email"
                  fullWidth
                  sx={{
                    "&": { fontSize: 16 },
                    "& fieldset": { borderRadius: 12 },
                  }}
                />
                <IconButton
                  sx={{
                    "&": { backgroundColor: "#677FD9", ml: 1 },
                    "&:hover": { backgroundColor: "#677FD9", opacity: 0.9 },
                  }}
                  onClick={onAddGuest}
                >
                  <PlusIcon htmlColor="#FFF" />
                </IconButton>
              </Box>
            </Box>
            <Box height={400}>
              <DataGridPro
                rows={rows}
                apiRef={apiRef}
                getRowId={(r) => r.inviteId || r.email}
                columns={columns}
                autoPageSize
                onRowEditStop={params => onUpdateRow(params)}
                editMode="row"
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
