import { createAsyncThunk } from "@reduxjs/toolkit";

import { UpdatePartyChecklistItemInput } from "src/graphql";
import { checklistService } from "src/services";

type Payload = Parameters<typeof checklistService.getAllPartyChecklistItems>[0];

export const fetchAllChecklistItemsByPartyId = createAsyncThunk(
  "checklist/getAllChecklistItems",
  async ({ partyId }: Payload) => {
    const checklistItems = await checklistService.getAllPartyChecklistItems({
      partyId,
    });

    return checklistItems;
  }
);

export const updateChecklistItem = createAsyncThunk(
  "checklist/updateChecklistItem",
  async (input: UpdatePartyChecklistItemInput) => {
    const updatedItem = await checklistService.updateChecklistItem(input);

    return updatedItem;
  }
);
