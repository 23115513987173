import { useEffect } from "react";

import {
  useAppDispatch,
  useAppSelector,
  fetchAllPollItems,
  fetchAllPollAnswers,
} from "src/store";

export const usePolls = (partyId: string) => {
  const dispatch = useAppDispatch();
  const { items = [], isLoading, answers } = useAppSelector(({ poll }) => poll);

  const sortedItems = [...items].sort((a, b) => +new Date(b?.createTime) - +new Date(a?.createTime));

  useEffect(() => {
    if (fetchAllPollItems) {
      dispatch(fetchAllPollItems({ partyId }));
    }
  }, [partyId, dispatch]);

  useEffect(() => {
    if (!answers.length && fetchAllPollAnswers) {
      dispatch(fetchAllPollAnswers({ partyId }));
    }
  }, [partyId, answers.length, dispatch]);

  return {
    items: sortedItems,
    answers,
    isLoading,
  };
};
