import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ChevronRight as ChevronRightIcon } from "@mui/icons-material";

import { RouteName } from "src/types";
import { sessionStorage } from "src/services";
import { ActivePartySelect } from "src/features";
import { ReactComponent as Logo } from "src/design/images/BachPlace_Primary_Logo.svg";

import { UserMenu } from "./UserMenu";

interface Props {
  toggleOpen: () => void;
}

export const Header = ({ toggleOpen }: Props) => {
  const theme = useTheme();
  const isSmallDropdownNeeded = useMediaQuery(theme.breakpoints.down(1020));
  const [userName, setUserName] = useState("");

  const textColor = theme.palette.secondary.light;
  const bgColor = theme.palette.primary.dark;
  const isAuthenticated = sessionStorage.getToken();

  useEffect(() => {
    const getName = async () => {
      const username = await sessionStorage.getFullname();
      const [firstName] = username.split(" ");
      setUserName(firstName);
    };
    getName();
  }, []);

  return (
    <Box
      display="flex"
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      position="absolute"
      left={0}
      top={0}
      p={4}
      pb={2}
      bgcolor={bgColor}
    >
      <Box display="flex">
        <IconButton
          onClick={toggleOpen}
          sx={{
            alignSelf: "flex-start",
            borderRadius: 3,
            pl: 1,
            pr: 2.8,
            mr: 2,
          }}
        >
          <Logo width={130} height={42} />
          <ChevronRightIcon />
        </IconButton>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          pl={4}
        >
          <Typography
            variant="body1"
            color={textColor}
            fontSize={28}
            fontWeight={600}
            lineHeight="30px"
          >
            Hi{!!userName && `,${userName}`}
          </Typography>
          <Typography
            variant="body1"
            color={textColor}
            fontSize={18}
            fontWeight={400}
            lineHeight="20px"
          >
            Let's get planning!
          </Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        <NeedsHelpBadge />
        {!!isAuthenticated && (
          <Box
            width={isSmallDropdownNeeded ? 130 : 200}
            height={40}
            alignSelf="center"
          >
            <ActivePartySelect />
          </Box>
        )}
        <UserMenu />
      </Box>
    </Box>
  );
};

function NeedsHelpBadge() {
  const navigate = useNavigate();

  return (
    <Box
      mr={3}
      bgcolor="#F3F6FF"
      px={3}
      py={0.5}
      borderRadius={2.5}
      sx={{
        "&:hover": {
          cursor: "pointer",
          opacity: 0.9,
        },
      }}
      onClick={() => navigate(RouteName.CONTACT)}
    >
      <Typography
        variant="body1"
        fontWeight={500}
        fontSize={16}
        color="#2149e0"
      >
        Need&nbsp;help&nbsp;planning?
      </Typography>
    </Box>
  );
}
