import {
  ChangeEvent,
  Dispatch,
  FormEvent,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import dayjs, { Dayjs } from "dayjs";
import {
  Button,
  TextField,
  Grid,
  Box,
  Checkbox,
  Typography,
  FormControlLabel,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useTheme,
} from "@mui/material";

import { fetchAllLocations, useAppDispatch, useAppSelector } from "src/store";
import { useDebounce } from "src/utils";

import { PartyInfo } from "./types";
import { DateRangePicker } from "./DateRangePicker";

type PartyInfoKeys = keyof Omit<PartyInfo, "isNoDates">;

type ErrorFields = {
  [key in PartyInfoKeys]: string;
};

type TextChangeEvent = ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

type DateChangeValue = Dayjs | null;

interface Props {
  variant: "create" | "edit";
  partyInfo: PartyInfo;
  setPartyInfo: Dispatch<SetStateAction<PartyInfo>>;
  onSubmit: () => void;
}

const OTHER_VALUE = "other";

export const PartyInfoForm = ({
  variant,
  partyInfo,
  setPartyInfo,
  onSubmit,
}: Props) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { isLoading } = useAppSelector(({ party }) => party);
  const { isLoading: isLocationLoading, locationList } = useAppSelector(
    ({ location }) => location
  );
  const {
    partyName,
    brideName,
    startDate,
    endDate,
    isNoDates,
    location,
    numberOfGuests,
  } = partyInfo;
  const defaultPartyName = brideName?.trim()
    ? `${brideName}'s Bachelorette`
    : "";
  const label = variant.charAt(0).toUpperCase() + variant.slice(1);
  const partyNameDebounced = useDebounce(defaultPartyName, 300) as string;
  const [isPartyNameSet, setIsPartyNameSet] = useState(false);
  const [partyLocation, setPartyLocation] = useState(location);
  const [errorFields, setErrorFields] = useState<ErrorFields>({
    brideName: "",
    partyName: "",
    startDate: "",
    endDate: "",
    location: "",
    numberOfGuests: "",
  });

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (!isPartyNameSet && brideName?.trim()) {
      timeout = setTimeout(() => {
        setPartyInfo((prev) => ({ ...prev, partyName: partyNameDebounced }));
      }, 500);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [brideName, partyNameDebounced, isPartyNameSet, setPartyInfo]);

  useEffect(() => {
    if (!locationList.length) {
      dispatch(fetchAllLocations());
    }
  }, [locationList.length, dispatch]);

  useEffect(() => {
    const newLocation = partyLocation === OTHER_VALUE ? "" : partyLocation;
    setPartyInfo((prev) => ({ ...prev, location: newLocation }));
  }, [partyLocation, setPartyInfo]);

  const handleFieldChange =
    (
      fieldName: PartyInfoKeys,
      type: "text" | "date",
      validationMessage: string,
      callback = () => {}
    ) =>
    (event: TextChangeEvent | DateChangeValue) => {
      callback();
      const newValue =
        type === "text"
          ? (event as TextChangeEvent)?.target?.value || ""
          : (event as DateChangeValue);
      setPartyInfo((prev) => ({ ...prev, [fieldName]: newValue }));

      const isValueValid =
        type === "text"
          ? newValue?.toString()?.length
          : dayjs(newValue || null).isValid();

      const newErrorMessage = isValueValid ? "" : validationMessage;
      setErrorFields((prev) => ({ ...prev, [fieldName]: newErrorMessage }));
    };

  const handleBrideNameChange = handleFieldChange(
    "brideName",
    "text",
    "Bride's name is required."
  );
  const handlePartyNameChange = handleFieldChange(
    "partyName",
    "text",
    "Party name is required.",
    () => {
      if (!isPartyNameSet) {
        setIsPartyNameSet(true);
      }
    }
  );
  const handleStartDateChange = handleFieldChange(
    "startDate",
    "date",
    "Start date is invalid."
  );
  const handleEndDateChange = handleFieldChange(
    "endDate",
    "date",
    "End date is invalid."
  );
  const handleLocationChange = handleFieldChange(
    "location",
    "text",
    "Location is required."
  );
  const handleGuestsCountChange = (event: TextChangeEvent) => {
    const newCount = +event.target.value;
    if (isNaN(newCount) || newCount < 0) {
      return;
    }

    setPartyInfo((prev) => ({ ...prev, numberOfGuests: newCount }));
  };

  const toggleNoDates = () =>
    setPartyInfo((prev) => ({ ...prev, isNoDates: !isNoDates }));

  const hasErrors = Object.values(errorFields).some(Boolean);
  const isAllNamesFilled =
    brideName.trim() && partyName.trim() && location.trim();
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!hasErrors) {
      onSubmit();
    }
  };

  return (
    <Box
      component="form"
      m="auto"
      noValidate
      onSubmit={handleSubmit}
      sx={{ mt: 3, maxWidth: 500 }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            autoComplete="given-name"
            name="brideName"
            error={!!errorFields.brideName}
            helperText={errorFields.brideName}
            value={brideName}
            required
            fullWidth
            id="brideName"
            label="Bride's Name"
            autoFocus
            onChange={handleBrideNameChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            value={partyName}
            id="partyName"
            label="Party Name"
            name="partyName"
            error={!!errorFields.partyName}
            helperText={errorFields.partyName}
            onChange={handlePartyNameChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            type="number"
            fullWidth
            value={numberOfGuests.toString()}
            id="guests-count"
            label="Number of guests"
            name="guests-count"
            error={!!errorFields.numberOfGuests}
            helperText={errorFields.numberOfGuests}
            onChange={handleGuestsCountChange}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="select-location-label">Location *</InputLabel>
            <Select
              labelId="select-location-label"
              value={partyLocation}
              required
              label="Location"
              defaultValue={partyLocation}
              onChange={(event) => {
                setPartyLocation(event.target.value);
                setErrorFields((prev) => ({ ...prev, location: "" }));
              }}
              placeholder="Choose location"
            >
              {isLocationLoading ? (
                <Box display="flex" justifyContent="center">
                  <CircularProgress />
                </Box>
              ) : (
                locationList.map(({ location: name }) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))
              )}
              <MenuItem value={OTHER_VALUE}>Other</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {partyLocation === OTHER_VALUE && (
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              value={location}
              id="location"
              label="My Location"
              name="location"
              error={!!errorFields.location}
              helperText={errorFields.location}
              onChange={handleLocationChange}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <DateRangePicker
            dates={[startDate, endDate]}
            handlers={[handleStartDateChange, handleEndDateChange]}
            errorTexts={[errorFields.startDate, errorFields.endDate]}
          />
          <FormControlLabel
            control={<Checkbox value={isNoDates} onChange={toggleNoDates} />}
            label={
              <Typography variant="body1">
                Check here if you don't know your dates
              </Typography>
            }
          />
        </Grid>
      </Grid>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        disabled={hasErrors || isLoading || !isAllNamesFilled}
        sx={{
          "&": { mt: 3, mb: 2, color: "#FFF" },
          "&:hover": {
            background: theme.palette.primary.main,
            opacity: 0.9,
          },
        }}
      >
        {label} Party
      </Button>
    </Box>
  );
};
