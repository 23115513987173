import {
  CreatePollAnswersInput,
  CreatePollDataInput,
  DeletePollDataInput,
  getApolloClient,
  Mutation,
  PollAnswers,
  PollData,
  PollOptions,
  Query,
  UpdatePollDataInput,
} from "src/graphql";
import { handleAuthError } from "src/utils";

import { FETCH_POLL_ANSWERS, FETCH_POLL_ITEMS } from "./queries";
import {
  CREATE_POLL,
  CREATE_POLL_ANSWER,
  DELETE_POLL,
  UPDATE_POLL,
} from "./mutations";

class PollService {
  get apolloClient() {
    return getApolloClient();
  }

  public async fetchPollsList({ partyId }: { partyId: string }) {
    try {
      const response = await this.apolloClient.query<Query>({
        query: FETCH_POLL_ITEMS,
        variables: {
          filter: {
            partyUid: {
              contains: partyId,
            },
          },
        },
        fetchPolicy: "network-only",
      });

      const pollsList = response?.data?.listPollData?.items || [];
      if (!pollsList) {
        throw new Error("Can not retrieve polls");
      }
      return pollsList as PollData[];
    } catch (error) {
      handleAuthError(error);
      throw error;
    }
  }

  public async fetchPollAnswers({ partyId }: { partyId: string }) {
    try {
      const response = await this.apolloClient.query<Query>({
        query: FETCH_POLL_ANSWERS,
        variables: {
          filter: {
            partyUid: {
              contains: partyId,
            },
          },
        },
        fetchPolicy: "network-only",
      });

      const pollAnswers = response?.data?.listPollAnswers?.items || [];
      if (!pollAnswers) {
        throw new Error("Can not retrieve poll answers");
      }
      return pollAnswers as PollAnswers[];
    } catch (error) {
      handleAuthError(error);
      throw error;
    }
  }

  public async createNewPollAnswer({
    userId,
    partyUid,
    pollId,
    answers,
  }: CreatePollAnswersInput): Promise<Mutation["createPollAnswers"]> {
    try {
      const response = await this.apolloClient.mutate({
        mutation: CREATE_POLL_ANSWER,
        variables: {
          input: {
            userId,
            partyUid,
            pollId,
            answers,
            pollAnswerId: "",
          },
        },
      });

      const newAnswer = response?.data?.createPollAnswers;

      if (!newAnswer) {
        throw new Error("Can not create new poll answer");
      }

      return newAnswer;
    } catch (err) {
      throw err;
    }
  }

  public async createNewPoll(
    input: CreatePollDataInput
  ): Promise<Mutation["createPollData"]> {
    try {
      const response = await this.apolloClient.mutate({
        mutation: CREATE_POLL,
        variables: {
          input: {
            ...input,
            options:
              input.options &&
              transformPollOptions(input.options as PollOptions[]),
          },
        },
      });

      const newPoll = response?.data?.createPollData;

      if (!newPoll) {
        throw new Error("Can not create new poll");
      }

      return newPoll;
    } catch (err) {
      throw err;
    }
  }

  public async updatePoll(
    input: UpdatePollDataInput
  ): Promise<Mutation["updatePollData"]> {
    try {
      const response = await this.apolloClient.mutate({
        mutation: UPDATE_POLL,
        variables: {
          input: {
            ...input,
            options:
              input.options &&
              transformPollOptions(input.options as PollOptions[]),
          },
        },
      });

      const updatedPoll = response?.data?.updatePollData;

      if (!updatedPoll) {
        throw new Error("Can not update poll");
      }

      return updatedPoll;
    } catch (err) {
      throw err;
    }
  }

  public async deletePoll(
    input: DeletePollDataInput
  ): Promise<Mutation["deletePollData"]> {
    try {
      const response = await this.apolloClient.mutate({
        mutation: DELETE_POLL,
        variables: {
          input,
        },
      });

      const deletedPoll = response?.data?.deletePollData;

      if (!deletedPoll) {
        throw new Error("Can not delete poll");
      }

      return deletedPoll;
    } catch (err) {
      throw err;
    }
  }
}

export const pollsService = new PollService();

function transformPollOptions(pollOptions: PollOptions[]) {
  const mappedOptions = pollOptions.map((option) => {
    const mappedOption = Object.fromEntries(
      Object.entries(option || {}).map(([k, v]) => [k.toLocaleLowerCase(), v])
    );

    return mappedOption;
  });

  return mappedOptions;
}
