import { gql } from "@apollo/client";

export const LIST_PARTY_INVITES = gql`
  query listBachInvitesQuery($listBachPartyInvitesFilter: TableBachPartyInviteFilterInput) {
  listBachPartyInvites(filter: $listBachPartyInvitesFilter) {
    items {
      deleted
      email
      inviteId
      name
      partyUid
      phone
      status
    }
  }
}
`;
