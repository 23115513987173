import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@mui/material";

import {
  confirmRestoredPasswordCognitoUser,
  resetPasswordCognitoUser,
} from "src/services";
import {
  EMAIL_MIN_LENGTH,
  EMAIL_PATTERN,
  EMAIL_PATTERN_MESSAGE,
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  PASSWORD_PATTERN,
  PASSWORD_PATTERN_MESSAGE,
  RouteName,
} from "src/types";
import { validate } from "src/utils";
import { Link, SimpleInput } from "src/components";

import { BackgroundPane, BottomGradient, ContentPane } from "./components";

enum ResetPasswordStage {
  EMAIL,
  PASSWORD,
}

export function ResetPassword() {
  const navigate = useNavigate();
  const [currentStage, setCurrentStage] = useState(ResetPasswordStage.EMAIL);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const isEmailStage = currentStage === ResetPasswordStage.EMAIL;
  const isSubmitDisabled =
    isLoading ||
    (isEmailStage && !email) ||
    (!isEmailStage && !code && !newPassword);
  const isPasswordError = errorMessage
    ?.toLocaleLowerCase()
    ?.includes("password");

  const handleSubmit = () => {
    const onFailure = (error: Error) => {
      setErrorMessage(error?.message || JSON.stringify(error));
      setIsLoading(false);
    };

    if (isEmailStage) {
      const { error: emailError } = validate("Email", email, {
        required: true,
        minLength: EMAIL_MIN_LENGTH,
        pattern: {
          regExp: EMAIL_PATTERN,
          message: EMAIL_PATTERN_MESSAGE,
        },
      });

      setErrorMessage(emailError || "");

      if (emailError) {
        return;
      }

      const callbacks = {
        onSuccess: () => {
          setCurrentStage(ResetPasswordStage.PASSWORD);
          setErrorMessage("");
          setIsLoading(false);
        },
        onFailure,
      };
      setIsLoading(true);
      resetPasswordCognitoUser({ email, callbacks });

      return;
    }

    const { error: newPasswordError } = validate("Password", newPassword, {
      required: true,
      minLength: PASSWORD_MIN_LENGTH,
      maxLength: PASSWORD_MAX_LENGTH,
      trimmed: true,
      pattern: {
        regExp: PASSWORD_PATTERN,
        message: PASSWORD_PATTERN_MESSAGE,
      },
    });

    setErrorMessage(newPasswordError || "");

    if (newPasswordError) {
      return;
    }

    setIsLoading(true);
    confirmRestoredPasswordCognitoUser({
      email,
      code,
      newPassword,
      callbacks: {
        onSuccess: () => {
          setIsLoading(false);
          navigate(RouteName.LOGIN);
        },
        onFailure,
      },
    });
  };

  const handleBack = () => {
    setCurrentStage(ResetPasswordStage.EMAIL);
    setErrorMessage("");
  };

  return (
    <Grid
      container
      component="main"
      sx={{ minHeight: "100vh", position: "relative", overflowX: "hidden" }}
    >
      <ContentPane title="Reset Password">
        <Box width={1} component="form" noValidate onSubmit={handleSubmit}>
          {isEmailStage && (
            <Box width={1} mr={1.25}>
              <SimpleInput
                value={email}
                onChange={(value) => setEmail(value)}
                errorText={errorMessage}
                id="email"
                name="email"
                placeholder="Email Address"
                autoComplete="email"
                type="email"
              />
            </Box>
          )}
          {!isEmailStage && (
            <>
              <Box width={1} mb={2}>
                <SimpleInput
                  value={code}
                  onChange={(value) => setCode(value)}
                  errorText={isPasswordError ? "" : errorMessage}
                  id="verification-code"
                  placeholder="Verification Code"
                  name="verification-code"
                  type="text"
                  autoFocus
                />
              </Box>
              <Box width={1}>
                <SimpleInput
                  value={newPassword}
                  onChange={(value) => setNewPassword(value)}
                  errorText={isPasswordError ? errorMessage : ""}
                  id="new-password"
                  name="new-password"
                  placeholder="New Password"
                  autoComplete="new-password"
                  type="password"
                />
              </Box>
            </>
          )}
          <Box display="flex" justifyContent="space-between" width={1}>
            {!isEmailStage && (
              <Button
                type="button"
                disabled={isLoading}
                onClick={handleBack}
                fullWidth
                variant="outlined"
                sx={{
                  mt: 3,
                  mb: 2,
                  textTransform: "none",
                  background: "transparent",
                  boxShadow: "0px 10px 50px rgba(254, 150, 136, 0)",
                  borderRadius: 10,
                  borderColor: "#FE9688",
                  borderWidth: 1,
                  py: 1.5,
                  fontSize: 16,
                  color: "#394460",
                  mr: 2,
                }}
              >
                Back
              </Button>
            )}
            <Button
              type="button"
              disabled={isSubmitDisabled}
              onClick={handleSubmit}
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                textTransform: "none",
                background:
                  "linear-gradient(94.01deg, #B26E6F 0%, #FE9688 100.02%)",
                boxShadow: "0px 10px 50px rgba(254, 150, 136, 0)",
                borderRadius: 10,
                py: 1.5,
                fontSize: 16,
                color: "#FFF",
              }}
            >
              {isEmailStage ? "Reset" : "Submit"}
            </Button>
          </Box>
          <Grid
            container
            gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))"
            justifyContent="center"
            gap={1}
          >
            <Grid item>
              <Link to={RouteName.LOGIN}>
                <Typography color="#394460">
                  Remember your password?{" "}
                  <b>
                    <u>Back to login page</u>
                  </b>
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </ContentPane>
      <BackgroundPane />
      <BottomGradient />
    </Grid>
  );
}
