import { createSlice } from "@reduxjs/toolkit";

import { PollAnswers, PollData } from "src/graphql";

import {
  createPoll,
  createPollAnswer,
  deletePoll,
  fetchAllPollAnswers,
  fetchAllPollItems,
  updatePoll,
} from "./pollThunk";

export interface PollState {
  items: PollData[];
  answers: PollAnswers[];
  isLoading: boolean;
}

const initialState: PollState = {
  items: [],
  answers: [],
  isLoading: false,
};

const pollSlice = createSlice({
  name: "poll",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAllPollItems.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllPollItems.fulfilled, (state, action) => {
        const pollItems = action.payload;
        state.items = pollItems;
        state.isLoading = false;
      })
      .addCase(fetchAllPollItems.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(fetchAllPollAnswers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllPollAnswers.fulfilled, (state, action) => {
        const pollAnswers = action.payload;
        state.answers = pollAnswers;
        state.isLoading = false;
      })
      .addCase(fetchAllPollAnswers.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(createPollAnswer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createPollAnswer.fulfilled, (state, action) => {
        const pollAnswer = action.payload;
        state.answers = pollAnswer
          ? [...state.answers, pollAnswer]
          : state.answers;
        state.isLoading = false;
      })
      .addCase(createPollAnswer.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(createPoll.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createPoll.fulfilled, (state, action) => {
        const poll = action.payload;
        state.items = poll ? [...state.items, poll] : state.items;
        state.isLoading = false;
      })
      .addCase(createPoll.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(updatePoll.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePoll.fulfilled, (state, action) => {
        const updatedPoll = action.payload;
        state.items = state.items?.map((p) =>
          p.pollId === updatedPoll?.pollId ? updatedPoll : p
        );
        state.isLoading = false;
      })
      .addCase(updatePoll.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(deletePoll.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletePoll.fulfilled, (state, action) => {
        const deletedPoll = action.payload;
        state.items = state.items.filter(
          (p) => p.pollId !== deletedPoll?.pollId
        );
        state.isLoading = false;
      })
      .addCase(deletePoll.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const pollReducer = pollSlice.reducer;
