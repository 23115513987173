import { createSlice } from "@reduxjs/toolkit";

import { CalendarEvent } from "src/types";
import { Calendar } from "src/graphql";

import {
  removeCreatedEvent,
  removeSelectedEvent,
  selectEvent,
  startCreatingEvent,
} from "./calendarAction";
import {
  createNewCustomCalendarEvent,
  deleteCustomCalendarEvent,
  fetchAllCalendarEventsByPartyId,
} from "./calendarThunk";

export interface CalendarState {
  calendarEvents: Calendar[];
  isLoading: boolean;
  selectedEvent: CalendarEvent | null;
  createdEvent: Calendar | null;
}

const initialState: CalendarState = {
  calendarEvents: [],
  isLoading: false,
  selectedEvent: null,
  createdEvent: null,
};

const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAllCalendarEventsByPartyId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllCalendarEventsByPartyId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.calendarEvents = action.payload;
      })
      .addCase(fetchAllCalendarEventsByPartyId.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(createNewCustomCalendarEvent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createNewCustomCalendarEvent.fulfilled, (state, action) => {
        const newEvent = action.payload;
        if (!!newEvent) {
          state.calendarEvents = [...state.calendarEvents, newEvent];
        }
        state.isLoading = false;
        state.createdEvent = null;
      })
      .addCase(createNewCustomCalendarEvent.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(deleteCustomCalendarEvent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteCustomCalendarEvent.fulfilled, (state, action) => {
        const deletedEvent = action.payload;
        if (!!deletedEvent) {
          state.calendarEvents = state.calendarEvents.filter(
            ({ calendarItemId }) =>
              calendarItemId !== deletedEvent.calendarItemId
          );
        }
        state.isLoading = false;
        state.createdEvent = null;
      })
      .addCase(deleteCustomCalendarEvent.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(selectEvent, (state, action) => {
        state.selectedEvent = action.payload;
      })
      .addCase(removeSelectedEvent, (state) => {
        state.selectedEvent = null;
      })

      .addCase(startCreatingEvent, (state, action) => {
        state.createdEvent = {
          ...state.createdEvent,
          ...(action.payload as Calendar),
        };
      })
      .addCase(removeCreatedEvent, (state) => {
        state.createdEvent = null;
      });
  },
});

export const calendarReducer = calendarSlice.reducer;
