import { useNavigate } from "react-router";
import { Badge, IconButton } from "@mui/material";
import { ShoppingCartOutlined as MuiCartIcon } from "@mui/icons-material";

import { RouteName } from "src/types";
import { useCartItems } from "src/services";

const MAX_ITEMS_DISPLAY_COUNT = 9;

const colors = {
  PRIMARY: "primary" as const,
  SECONDARY: "secondary" as const,
  WHITE: "white" as const,
};

type ColorValues = (typeof colors)[keyof typeof colors];

const config = {
  [colors.PRIMARY]: {
    backgroundColor: "#677FD9",
    color: "#FFF",
  },
  [colors.WHITE]: {
    backgroundColor: "#FFF",
    color: "#232323",
  },
  [colors.SECONDARY]: {
    backgroundColor: "#677FD9",
    color: "#FFF",
  },
};

interface Props {
  colorMode?: ColorValues;
  rounded?: boolean;
}

export const CartIcon = ({ colorMode = colors.PRIMARY, rounded }: Props) => {
  const navigate = useNavigate();
  const { items } = useCartItems();

  const { color, backgroundColor } = config[colorMode];
  const cartComponent = (
    <Badge
      componentsProps={{ root: { onClick: () => navigate(RouteName.CART) } }}
      badgeContent={items.length}
      color="primary"
      max={MAX_ITEMS_DISPLAY_COUNT}
      showZero={false}
      slotProps={{
        badge: { style: { marginRight: -4, color, backgroundColor } },
      }}
    >
      {rounded ? <MuiCartIcon color="primary" /> : <MuiCartIcon />}
    </Badge>
  );

  return rounded ? (
    <IconButton
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: color,
        p: 1.5,
        borderRadius: "50%",
      }}
    >
      {cartComponent}
    </IconButton>
  ) : (
    cartComponent
  );
};
