import { gql } from "@apollo/client";

export const GET_GIFTCARD_BY_CODE = gql`
  query Items($code: String!) {
    getGiftPromo_bycode(code: $code) {
      active
      code
      discountPercent
      discountPrice
      expiration
      gift
      giftPromoId
      senderEmail
      senderPhone
      reciverEmail
      name
      paid
      purchaseDate
      purchaseEmail
      reciverName
      senderName
      note
    }
  }
`;
