import { useCallback, Dispatch, SetStateAction } from "react";
import { Box, Link, Typography } from "@mui/material";

import { PollOptionManagement } from "./PollOptionManagement";
import { Maybe, PollOptions } from "src/graphql";

interface Props {
  options: PollOptions[];
  isText?: boolean;
  setOptions: Dispatch<SetStateAction<Maybe<PollOptions>[]>>;
}

const defaultOption = { Type: null, text: "", value: "default" };

export function PollOptionsManagement({ options, isText, setOptions }: Props) {
  const handleAddOptions = useCallback(() => {
    setOptions((prev) => [...prev, defaultOption]);
  }, []);

  const handleDeleteOption = useCallback((index: number) => {
    setOptions((prev) => prev.filter((_, i) => i !== index));
  }, []);

  const handleChangeOption = useCallback(
    (index: number, payload: Partial<PollOptions>) => {
      setOptions((prev) =>
        prev.map((opt, i) => (i === index ? { ...opt, ...payload } : opt))
      );
    },
    []
  );

  const handleChangeCustomOption = useCallback(
    (index: number, payload: boolean) => {
      setOptions((prev) =>
        prev.map((opt, i) =>
          i === index
            ? { ...opt, Type: payload ? "text" : null }
            : { ...opt, Type: null }
        )
      );
    },
    []
  );

  return (
    <Box>
      <Typography
        variant="h5"
        fontSize={14}
        fontWeight={600}
        fontFamily="Urbanist"
        pl={1}
        pt={1}
        mb={1}
      >
        Options
      </Typography>
      <Box display="flex" flexDirection="column">
        {options.map(({ text, value, type }, i) => (
          <PollOptionManagement
            key={i}
            label={text || ""}
            value={value || `default${i}`}
            isCustomAnswer={type === "text" || isText}
            index={i}
            onChange={handleChangeOption}
            onDelete={handleDeleteOption}
            onChangeCustom={handleChangeCustomOption}
          />
        ))}
      </Box>
      <Link
        component="button"
        variant="body2"
        underline="hover"
        onClick={handleAddOptions}
      >
        + add option
      </Link>
    </Box>
  );
}
