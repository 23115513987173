import { Box, IconButton, LinearProgress, Typography } from "@mui/material";
import { Groups as GroupsIcon } from "@mui/icons-material";

import { PollAnswers, PollOptions } from "src/graphql";
import { PollResultAnswers } from "./PollResultAnswers";

interface Props {
  total: number;
  answers: PollAnswers[];
  anonymous?: boolean;
  withAnswers?: boolean;
  options?: PollOptions[];
}

export const PollResult = ({ total, answers, anonymous, withAnswers, options }: Props) => {
  const answersCount = answers.length;
  const percentage = (answersCount / total) * 100;

  return (
    <Box display="flex" alignItems="center" gap={1} height={4}>
      <LinearProgress
        variant="determinate"
        value={percentage}
        style={{ width: "100%" }}
      />
      <Typography variant="body2">{answersCount}</Typography>
      {!anonymous && (
        <PollResultAnswers answers={answers} withAnswers={withAnswers} options={options} />
      )}
    </Box>
  );
};
