import { gql } from "@apollo/client";

export const FETCH_VENDOR_BOOKING_LIST = gql`
  query Items($filter: TableVendorBookingFilterInput) {
    listVendorBookings(filter: $filter) {
      items {
        addons {
          description
          name
          perPerson
          price
        }
        address
        bookingId
        createTime
        createUser
        endTime
        guestsOrQuantity
        notes
        partyUid
        pricing {
          description
          duration
          maxGuests
          minGuests
          name
          perGroup
          price
        }
        pricingDescription
        pricingName
        startTime
        status
        totalPrice
        vendorId
        vendorOfferName
        vendorOfferUid
        vendorTypeId
      }
    }
  }
`;
