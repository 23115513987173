// Routes
export const routePageNameMapper = new Map([
  [/^(\/party)(\/)?$/i, "Parties"],
  [/^(\/contact)(\/)?$/i, "Contact us"],
  [/^(\/giftcard)(\/)?$/i, "Giftcard"],
  [/^(\/my-guests)(\/)?$/i, "My Guests"],
  [/^(\/my-bookings)(\/)?$/i, "My Bookings"],
  [/^(\/cart)(\/)?$/i, "Cart"],
  [/^(\/checklist)(\/)?$/i, "Party Checklist"],
  [/^(\/calendar)(\/)?$/i, "Calendar"],
  [/^(\/dashboard)(\/)?$/i, "Dashboard"],
  [/^(\/party\/create)(\/)?$/i, "Create Party"],
  [/^(\/party\/)[^/]+(\/edit)(\/)?$/i, "Edit party"],
  [/^(\/party\/)[^/]+(\/vendors)(\/)?$/i, "Browse"],
  [/^(\/party\/)[^/]+(\/vendors\/)[^/]+(\/)?$/i, "Book"],
]);

// Main

export const DRAWER_WIDTH = 240;

// Validation
export const US_PHONE_CODE = "1";

export const PHONE_MASK = "+1 XXX-XXX-XXXX";
export const MAX_PHONE_LENGTH = 12;
export const PHONE_PATTERN = /(\+1)[0-9]{10}$/;
export const PHONE_PATTERN_MESSAGE = `Phone number should be like ${PHONE_MASK}`;

export const NAME_MIN_LENGTH = 1;
export const NAME_MAX_LENGTH = 20;

export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 20;
export const PASSWORD_PATTERN =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\^$*. [\]{}()?"!@#%&/\\,><':;|_~`=+-])[A-Za-z\d^ $*.[\]{}()?"!@#%&/\\,><':;|_~`=+\-)]+$/;
export const PASSWORD_PATTERN_MESSAGE =
  "Password should contain both uppercase and lowercase letters, number and a special character, without surrounding spaces.";

export const EMAIL_MIN_LENGTH = 6;
export const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const EMAIL_PATTERN_MESSAGE =
  "Invalid email value, please use real email";

// Party
export const MIN_PARTY_VIBE_VALUE = 0;
export const DEFAULT_PARTY_VIBE_VALUE = 50;
export const MAX_PARTY_VIBE_VALUE = 100;

// Vendor
export const ALL_LOCATIONS_VALUE = "All locations";

// General
export const TRANSACTION_FEE_TOOLTIP_TEXT =
  "Vendor fees/similar taxes assessed on the booking fee, if applicable, are included";

export const LAPTOP_BREAKPOINT = 950;

export const MOBILE_BREAKPOINT = 550;

export const BLOG_URL = "https://www.bachplace.com/blog-posts";
export const PLANNING_KIT_URL = 'https://drive.google.com/drive/folders/14NkMzLjwNnSRrER152_3N0HV6WM6Ejsa';
