import { createSlice } from "@reduxjs/toolkit";

import { sessionStorage } from "src/services";
import { CartBooking } from "src/graphql";

import {
  addCartItem,
  bookAllCartItems,
  fetchAllCartItems,
  removeCartItem,
  updateCartItem,
} from "./cartThunk";

export interface CartState {
  items: CartBooking[];
  isLoading: boolean;
}

const initialState: CartState = {
  items: [],
  isLoading: false,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchAllCartItems.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllCartItems.fulfilled, (state, action) => {
        const newItems = action.payload;
        state.items = newItems;
        state.isLoading = false;
        sessionStorage.setCartItems(newItems);
      })
      .addCase(fetchAllCartItems.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(bookAllCartItems.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(bookAllCartItems.fulfilled, (state, action) => {
        const { newCartItems, redirectUrl } = action.payload;
        state.items = newCartItems;
        state.isLoading = false;
        sessionStorage.setCartItems(newCartItems);
        window.location.href = redirectUrl;
      })
      .addCase(bookAllCartItems.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(addCartItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addCartItem.fulfilled, (state, action) => {
        const newItems = [...state.items, action.payload] as CartBooking[];
        state.items = newItems;
        state.isLoading = false;
        sessionStorage.setCartItems(newItems);
      })
      .addCase(addCartItem.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(updateCartItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateCartItem.fulfilled, (state, action) => {
        const newItems = state.items.map((item) =>
          item.cartItemId !== action.payload?.cartItemId ? item : action.payload
        );
        state.items = newItems;
        state.isLoading = false;
        sessionStorage.setCartItems(newItems);
      })
      .addCase(updateCartItem.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(removeCartItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(removeCartItem.fulfilled, (state, action) => {
        const newItems = state.items.filter(
          ({ cartItemId }) => cartItemId !== action.payload?.cartItemId
        );
        state.items = newItems;
        state.isLoading = false;
        sessionStorage.setCartItems(newItems);
      })
      .addCase(removeCartItem.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const cartReducer = cartSlice.reducer;
