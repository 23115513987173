import { FC, ReactNode, useEffect } from "react";
import { Navigate, useLoaderData } from "react-router-dom";

import { useApolloClient } from "src/graphql";
import { RouteName } from "src/types";

interface Props {
  children: ReactNode;
}

export const PrivateRoute: FC<Props> = ({ children }) => {
  const isAuthenticated = useLoaderData();
  const { setClientName } = useApolloClient();

  useEffect(() => {
    setClientName(isAuthenticated ? "apollo" : "guest");
  }, [isAuthenticated, setClientName]);

  return isAuthenticated ? <>{children}</> : <Navigate to={RouteName.LOGIN} />;
};
