import { FormGroup } from "@mui/material";
import { TextOption } from "../options";
import { PollOptions } from "src/graphql";

interface Props {
  options: PollOptions[];
  selectedOptions: string[];
  setSelectedOptions: CallableFunction;
  customAnswer: string;
  setCustomAnswer: CallableFunction;
}

export const TextPoll = ({ options, customAnswer, setCustomAnswer }: Props) => {
  return (
    <FormGroup>
      {options.length ? (
        options.map(({ text }) => (
          <TextOption
            text={text || ''}
            value={customAnswer}
            setValue={setCustomAnswer}
            visible={true}
          />
        ))
      ) : (
        <TextOption
          text="Answer"
          value={customAnswer}
          setValue={setCustomAnswer}
          visible={true}
        />
      )}
    </FormGroup>
  );
};
