import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Box, Button, TextField } from "@mui/material";

import { PollData, PollOptions } from "src/graphql";

import { PollVariant } from "../poll";
import {
  AnonymousSwitch,
  PollTypeSelect,
  PollOptionsManagement,
} from "./components";
import { createPoll, updatePoll, useAppDispatch } from "src/store";
import { useGetCurrentPartyInfo } from "src/hooks";

interface Props {
  poll?: Omit<PollData, "__typename">;
  isLoading?: boolean;
  onSubmit: () => void;
}

export function ModifyPolls({ poll, isLoading, onSubmit }: Props) {
  const dispatch = useAppDispatch();
  const { activeParty } = useGetCurrentPartyInfo();
  const {
    anonymous,
    options,
    question: propsQuestion,
    type,
    pollId = "",
  } = poll || {};
  const isCreating = !poll;
  const [pollType, setPollType] = useState(
    (type as PollVariant) || PollVariant.SelectOne
  );
  const [isAnonymous, setIsAnonymous] = useState(anonymous || false);
  const [question, setQuestion] = useState(propsQuestion || "");
  const [pollOptions, setPollOptions] = useState(options || []);

  useEffect(() => {
    setIsAnonymous(anonymous || false);
  }, [anonymous]);

  useEffect(() => {
    setQuestion(propsQuestion || "");
  }, [propsQuestion]);

  useEffect(() => {
    setPollType((type as PollVariant) || PollVariant.SelectOne);
  }, [type]);

  useEffect(() => {
    setPollOptions(options || []);
  }, [options]);

  const handleAnonymousChange = useCallback(() => {
    setIsAnonymous((prev) => !prev);
  }, []);

  const handleQuestionChange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      setQuestion(target.value);
    },
    []
  );

  const handleCreatePoll = useCallback(() => {
    dispatch(
      createPoll({
        partyUid: activeParty?.partyUid || "",
        createTime: new Date()?.toISOString(),
        question,
        type: pollType,
        options: pollOptions,
        anonymous: isAnonymous,
      })
    );
    onSubmit();
  }, [
    onSubmit,
    question,
    activeParty?.partyUid,
    pollType,
    pollOptions,
    isAnonymous,
  ]);

  const handleUpdatePoll = useCallback(() => {
    dispatch(
      updatePoll({
        partyUid: activeParty?.partyUid || "",
        pollId,
        question,
        type: pollType,
        options: pollOptions,
      })
    );
    onSubmit();
  }, [onSubmit]);

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <PollTypeSelect pollType={pollType} setPollType={setPollType} />
        <AnonymousSwitch
          anonymous={isAnonymous}
          handleChange={handleAnonymousChange}
        />
      </Box>
      <TextField
        fullWidth
        label="Question"
        value={question}
        onChange={handleQuestionChange}
        placeholder="Enter your question"
        sx={{ my: 2 }}
      />
      <PollOptionsManagement
        options={pollOptions as PollOptions[]}
        setOptions={setPollOptions}
        isText={pollType === "text"}
      />
      <Button
        variant="contained"
        onClick={isCreating ? handleCreatePoll : handleUpdatePoll}
        disabled={isLoading}
        sx={{
          "&": {
            my: 1.5,
            color: "#FFF",
            backgroundColor: "#677FD9",
            padding: "8px 16px",
            textTransform: "none",
            borderRadius: 20,
            fontWeight: 400,
            fontSize: 16,
            width: 150,
          },
          "&:hover": {
            backgroundColor: "#677FD9",
            opacity: 0.95,
          },
        }}
      >
        {isCreating ? "Create" : "Save"}
      </Button>
    </Box>
  );
}
